import { Module } from "components/Module";
import { downloadImage, getCourses } from "hooks/commonData";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FilesSection } from "./FilesSection";
import { VideoSelectedSection } from "./VideoSelectedSection";

export function EditCoursesPMO() {
  const { id_course } = useParams();
  const [nameOCurse, setNameCourse] = useState("");
  const [status, setStatus] = useState([]);
  const [url, setUrl] = useState();
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [video, setVideo] = useState();

  const miniature = status[0]?.nombre_miniatura;

  const processCourseName = (id, position) => {
    const nameSeparated = id.split(",");
    return nameSeparated[position] || "";
  };

  useEffect(() => {
    if (id_course) {
      const name = processCourseName(id_course, 1);
      setNameCourse(name);
      getCourses(processCourseName(id_course, 0)).then((r) => setStatus(r));
      downloadImage(miniature).then((r) => setAvatarUrl(r));
    }
  }, [id_course, status]);

  const color_estado = status[0]?.estado?.color_id;
  const nombre_estado = status[0]?.estado?.nombre_estado;

  return (
    <Module title={`Editar Curso ${nameOCurse}`} back linkToBack={"/training"}>
      <div className="grid 2xl:grid-cols-3 sm:grid-cols-1 lg:grid-cols-2">
        <div>
          <FilesSection
            colorId={color_estado}
            statusName={nombre_estado}
            id_course={id_course}
            setUrl={setUrl}
            setVideo={setVideo}
          />
        </div>
        <div className="2xl:col-span-2 p-2">
          {
            url ?
              <VideoSelectedSection url={url} />
              :
              <img
                src={avatarUrl}
                alt=""
                className="grow rounded-lg slide-down-animation overflow-hidden curs"
              />
          }
        </div>
      </div>
    </Module>
  );
}
