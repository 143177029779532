import { useState, useEffect } from "react";
import { supabase } from "utils/SupabaseClient";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { Login } from "./modules/login";
import { Layout } from "./modules";
import { Toaster } from "sonner";
import { Load } from "components/tailwind/Load";
import { SignupForm } from "modules/login/SignupForm";

export default function App() {
  const [session, setSession] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);

  const checkUserInDatabase = async (userId) => {
    const { data, error } = await supabase
      .from("auth_users")
      .select("*")
      .eq("id", userId)
      .single();

    if (error || !data) {
      setShowForm(true);
    } else {
      setShowForm(false);
    }
  };

  useEffect(() => {
    const fetchSession = async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      setSession(session);

      if (session) {
        await checkUserInDatabase(session?.user?.id);
      }
      setIsLoading(false);
    };

    fetchSession();

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      if (session) {
        checkUserInDatabase(session?.user?.id);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <>
      {isLoading ? (
        <Load />
      ) : (
        <>
          {session ? (
            <>
              {showForm ? (
                <SignupForm />
              ) : (
                <>
                  <Toaster richColors position="top-center" />
                  <Layout />
                </>
              )}
            </>
          ) : (
            <>
              <Login>
                <Auth
                  providers={["google"]}
                  supabaseClient={supabase}
                  localization={{
                    variables: {
                      sign_in: {
                        email_label: "Correo electrónico",
                        email_input_placeholder: "Ej: correo@correo.com",
                        password_label: "Contraseña",
                        password_input_placeholder: "Tu contraseña de PlisBI",
                        button_label: "Ingresar",
                        loading_button_label: "Ingresando...",
                      },
                    },
                  }}
                  appearance={{
                    theme: ThemeSupa,
                    variables: {
                      default: {
                        colors: {
                          brand: "#2E86C1",
                          brandAccent: "#2874a6",
                        },
                      },
                    },
                  }}
                />
              </Login>
            </>
          )}
        </>
      )}
    </>
  );
}
