import { useEffect, useState } from "react";
import { toast } from "sonner";
import { supabase, getUserData } from "utils/SupabaseClient";
import { AppPMO } from "./AppPMO";

export function LayoutPMO({ items }) {
  const modules = items;
  const [user, setUser] = useState();

  useEffect(() => {
    async function getUser() {
      const userData = await getUserData();

      const { data, error } = await supabase
        .from("users")
        .select("role_id, first_name, last_name")
        .eq("id", `${userData.id}`);
      if (!error) {
        setUser(data[0]);
        toast.success(`Bienvenid@ ${data[0].first_name} ${data[0].last_name}!`);
      }
    }

    getUser();
  }, []);

  return (
    <AppPMO
      nombre={user ? user?.first_name : "...cargando"}
      apellido={user ? user?.last_name : ""}
      email={user ? user?.email : "...cargando"}
      modules={modules}
    />
  );
}