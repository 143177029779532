import { ModalBG } from "components/common/ModalBG";
import { ModalSuccess } from "components/modal/ModalSuccess";
import { useEffect, useState } from "react";

export function ModalTernarioSuccess({ submodule, success }) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (success) {
      setIsOpen(true);
    }
  }, [success]);

  return (
    <ModalBG
      open={isOpen}
      onClose={() => {
        window.location.href = "/support";
        setIsOpen(false);
      }}
    >
      <ModalSuccess
        message={
          submodule === "IhaveAQuestionVisual"
            ? "Puedes hacer seguimiento a tu solicitud en el módulo de Soporte, Posteriormente a la asignación de especialista, recibirás una sugerencia de fecha y hora para que se reúnan lo más pronto posible."
            : "Puedes hacer seguimiento a tu solicitud en el módulo de Soporte, Posteriormente a la asignación de especialista, recibirás tu Propuesta Económica o invitación para reunirnos y solucionar posibles dudas."
        }
        title="Recibimos tu solicitud"
        onClose={() => {
          window.location.href = "/support";
          setIsOpen(false);
        }}
      />
    </ModalBG>
  );
}
