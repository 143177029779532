// import { useState } from "react";
// import { MoveIcon } from "../../../components/SvgIcons/MoveIcon";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

export function ModuleItem({ Module, index }) {
  //   const [isOpen, setIsOpen] = useState(false);

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: Module.value,
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      {...attributes}
      {...listeners}
      ref={setNodeRef}
      className="grow hover:bg-slate-200 transition-colors"
      style={style}
    >
      {/* <button
        type="button"
        // onClick={() => setIsOpen(!isOpen)}
        className="border-2 border-slate-400 rounded-lg m-1 grid grid-2"
      >
        <MoveIcon h={"10"} w={"10"} /> */}
      <h1>{Module.title}</h1>
      {/* </button>
      <div className={`${isOpen === true ? "" : "hidden"}`}>aaaaaaaa</div> */}
    </div>
  );
}
