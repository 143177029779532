import { useEffect, useState } from "react";

export function FileUploadComponent({
  text,
  setInfoFiles,
  accept,
  selectedFilesProps,
  base64Props,
}) {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [base64String, setBase64String] = useState("");

  const handleFileChange = (event) => {
    const files = event.target.files;

    if (files) {
      const newFiles = Array.from(files).map((file) => file.name);
      setSelectedFiles(newFiles);
      setInfoFiles(files);

      Array.from(files).forEach((file) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          setBase64String(reader.result);
          base64Props(reader.result);
        };
        reader.readAsDataURL(file);
      });
    }
  };

  useEffect(() => {
    setSelectedFiles(selectedFilesProps);
  }, [selectedFilesProps]);

  return (
    <div className="flex flex-col mt-2">
      <label
        htmlFor="file"
        className="flex items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer hover:bg-slate-300 transition delay-150 duration-300 ease-in-out text-gray-300 hover:text-slate-400"
      >
        <div className="flex flex-col items-center justify-center pt-5 pb-6">
          <svg
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 16"
            className="w-10 stroke-gray-300"
          >
            <path
              stroke="currentColor"
              d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
            />
          </svg>
          <p className="mb-2 text-sm ">
            <span className="text-lg text-center">{text}</span>
          </p>
          <p className="text-red-500">Adjuntar solo archivos de word y excel</p>
        </div>
        <input
          id="file"
          type="file"
          accept={accept}
          className="hidden"
          multiple
          onChange={handleFileChange}
        />
      </label>
      <div className="flex flex-col grow h-full mt-1">
        <p className="font-semibold">Archivos seleccionados:</p>
        {selectedFiles.length > 0 && (
          <div className="grid grid-cols-1 gap-1 slide-down-animation">
            {selectedFiles.map((fileName, index) => (
              <p key={index}>{fileName}</p>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
