import { useEffect, useState } from "react";
import { DropDownIcon } from "../../../../components/SvgIcons/DropDownIcon";
import { Link } from "react-router-dom";
import { getVideos, getVideosWatched } from "hooks/commonData";
import { downloadVideo, insertStatusVideo, getStatusVideo } from "hooks/commonData";

export function DropDownModule({
  userId,
  module,
  id_module,
  id_course,
  id_video,
  setMainVideo,
  setUrl,
  setVideo,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [videos, setVideos] = useState([{}]);
  const [statevideo, setStatevideo] = useState();
  const [videoCurrent, setVideoCurrent] = useState();
  const [moduleView, setModuloView] = useState([]);
  const updatedVideos = new Set();


  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleOnCLick = (e) => {
    if (setMainVideo) {
      setMainVideo(e);
    }

    getVideos("", e).then((r) => {
      setVideo(r);  
      setVideoCurrent(r[0]);
      updatetStatusVideo(r[0].id);
      
    });

    getVideos("", e).then((r) => {
      setVideo(r);  
      setVideoCurrent(r[0]);

      // if(r[0].id && !updatedVideos.has(r[0].id)){
      //   updatetStatusVideo(r[0].id);
      // }
      
      downloadVideo(r[0].url).then((r) => setUrl(r));
    });

  };

  const updatetStatusVideo = async (id_video) => {
    if(updatedVideos.has(id_video)){
      return;
    }

    try {
      const videoStatus = await getStatusVideo(userId, id_video);
      setStatevideo(videoStatus.data);

      if (videoStatus.data.length === 0) {
        await insertStatusVideo(userId, id_video, id_course, new Date());
        updatedVideos.add(id_video);
      }
    } catch (error) {
      console.log('Error fetching user data:', error.message);
    }
  };

  useEffect(() => {
      getVideos(id_module, "", userId).then((r) => {
        setVideos(r);
        let viewVideos = 0
        r.map((video) => {
          if(video.visto === true){
            viewVideos += 1; 
          }
        })
  
        if(viewVideos === r.length){
          if(moduleView.length > 1){
            setModuloView([moduleView[0]]);
          }else{
            moduleView.push(true);
          }
          moduleView.push(true);
         
        }else{
          if(moduleView.length > 1){
            setModuloView([moduleView[0]]);
          }else{
            moduleView.push(false);
          }
        }
      });
  

  }, [videoCurrent, userId, id_module]);

  return (
    <>
      <div className="mr-2">
        <button
          className={`${moduleView[0] === true ? "bg-green-300 border" : "bg-white" } w-full p-2 rounded-lg slide-down-animation h-[40px] flex justify-between ml-2 hover:bg-yellow-100 transition-colors  ${isOpen ? "bg-yellow-100" : ""
            }`}
          onClick={handleOpen}
        >
          <label className="text-start transition duration-300 ease-in-out">
            {module}
          </label>
          <div className={`flex pt-[1px] mr-2 justify-end`}>
            <DropDownIcon w={"20"} h={"20"} isopen={isOpen} />
          </div>
        </button>
      </div>
      <div
        className={`m-2 border-t border-slate-400 ${!isOpen ? "hidden" : ""}`}
      >
        {videos.map((item) => (
          <div
            className={` ${Number(id_video) === Number(item.id) ? "bg-blue-100" : "bg-white"
              } p-2 border-b border-slate-400 hover:bg-blue-100 hover:bg-gradient-to-r transition-colors`}
          >
            <Link
              className="ml-5 slide-down-animation flex justify-between"
              to={`/training/course/${id_course}/video/${videoCurrent ? item.id : item.id}`}
              key={item.id}
              onClick={() => handleOnCLick(item.id)}
            >
              <h1>{item.titulo_video}</h1>
              <span
                className={`h-[10px] w-[10px] mt-2 rounded-full ${item.visto === true
                    ? "bg-green-400"
                    : "bg-slate-300"
                  }`}
              ></span>
            </Link>
          </div>
        ))}
      </div>
    </>
  );
}
