import { toast } from "sonner";
import { supabase } from "utils/SupabaseClient";

export async function Deletefiles({
  idUser,
  archivo,
  setDataArray,
  dataArray,
}) {
  try {
    const { error: deleteRow } = await supabase
      .from("user_files")
      .delete()
      .eq("file_name", archivo)
      .eq("user", idUser);

      const { error: deleteFiles } = await supabase.storage
      .from("archivos")
      .remove([archivo]);
    
    if (deleteFiles) {
      toast.error(`Error al eliminar el archivo del storage: ${deleteFiles.message}`);
      console.error("Error al eliminar el archivo del storage:", deleteFiles);
      return;
    }

    if (deleteRow) {
      toast.error(`Error al eliminar el archivo de la base de datos: ${deleteRow.message}`);
      console.error("Error al eliminar archivo de la base de datos:", deleteRow);
      return;
    }

    const updatedRows = dataArray.filter((row) => row.file_name !== archivo);
    setDataArray(updatedRows);

    toast.success(`Archivo ${archivo} eliminado exitosamente`);
  } catch (error) {
    toast.error("Error inesperado al eliminar el archivo.");
    console.error("Error inesperado al eliminar archivo:", error);
  }
}