import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getTicketDetails } from "hooks/commonData";
import { ViewIncidentUI } from "./ViewIncidentUI";

export function ViewIncident() {
  const { issue } = useParams();
  const documents = [
    {
      name: "Document.pdf",
      size: "1.5MB",
    },
    {
      name: "Document2.pdf",
      size: "2.5MB",
    },
  ];

  const comments = [
    {
      user: "Usuario",
      date: "Fecha",
      comment: "Comentario",
    },
    {
      user: "Usuario",
      date: "Fecha",
      comment: "Comentario",
    },
  ];

  const [ticketBasic, setTicketBasic] = useState();
  const [ticketFields, setTicketFields] = useState([]);

  useEffect(() => {
    getTicketDetails(issue, "basic").then((data) => {
      setTicketBasic(data);
    });
    getTicketDetails(issue, "fields").then((data) => {
      setTicketFields(data.ticket_fields);
    });
  }, []);

  if (!ticketBasic || !ticketFields) {
    return <p>Cargando...</p>;
  } else if (ticketBasic || ticketFields) {
    return (
      <ViewIncidentUI
        summary={ticketBasic.title}
        description={ticketBasic.description}
        createdBy={ticketBasic.created_by.email}
        project={ticketBasic.project_id.project_name}
        status={ticketBasic.status_id.status_name}
        service={ticketBasic.service_id.item_value}
        comments={comments}
        reason={
          ticketFields.find((e) => e.field_definition_id.id === 17).field_value
        }
        steps={
          ticketFields.find((e) => e.field_definition_id.id === 18).field_value
        }
        documents={documents}
      />
    );
  }
}
