import { THead } from "./THead";

export function Table({ nameRows, children }) {
  return (
    <div className="relative shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left rtl:text-right text-gray-500">
        <THead nameRows={nameRows} />
        <tbody>{children}</tbody>
      </table>
    </div>
  );
}
