import fileDownload from "js-file-download";

const { toast } = require("sonner");
const { supabase } = require("utils/SupabaseClient");

// Obtener detalles de un usuario

export async function getUserDetails(user, details) {
  let selectInput = "*";

  switch (details) {
    case "ids":
      selectInput = "company_id, role_id";
      break;
    case "basic":
      selectInput =
        "company_id(company_name), first_name, last_name, phone, additional_phone, email, role_id(role_name) created_by(email), modified_by(email)";
      break;
    case "projects":
      selectInput =
        "projects_users!user_id(project_id, project: project_id(project_name))";
      break;
    case "tickets":
      selectInput = "tickets!created_by(id)";
      break;
    case "all":
      selectInput = "*";
      break;
    default:
      selectInput = "*";
  }
  const { data, error } = await supabase
    .from("users")
    .select(selectInput)
    .eq("id", user);
  if (error) {
    toast.error("Error al obtener el detalle del usuario");
  }
  return data[0];
}

// Obtener detalles de un proyecto

export async function getProjectDetails(project, details) {
  let selectInput = "*";

  switch (details) {
    case "ids":
      selectInput = "id, company_id, manager_id, created_by, modified_by";
      break;
    case "basic":
      selectInput = `id, project_name, code, description, company_id(company_name), manager_id(email), created_by(email), modified_by(email)`;
      break;
    case "services":
      selectInput = `id,  projects_services(services: enum_field_items(id, item_value))`;
      break;
    case "users":
      selectInput = "id, projects_users(user_id)";
      break;
    case "tickets":
      selectInput = "id, tickets(id)";
      break;
    case "all":
      selectInput = "*";
      break;
    default:
      selectInput = "*";
  }
  const { data, error } = await supabase
    .from("projects")
    .select(selectInput)
    .eq("id", project);
  if (error) {
    toast.error("Error al obtener el detalle del proyecto");
  }
  return data[0];
}

// Obtener detalle de un ticket

export async function getTicketDetails(ticket, details) {
  let selectInput = "*";

  switch (details) {
    case "ids":
      selectInput =
        "id,project_id, type_id, assigned_to, status_id, created_by, modified_by";
      break;
    case "basic":
      selectInput = `id, title, description, project_id(project_name), type_id(type_name), service_id(item_value),
                     assigned_to(email), status_id(status_name), created_by(email), modified_by(email)`;
      break;
    case "fields":
      selectInput =
        "id, ticket_fields(field_definition_id(id, field_name), field_value)";
      break;
    case "all":
      selectInput = "*, ticket_fields(field_definition_id, field_value)";
      break;
    default:
      selectInput = "*";
  }

  const { data, error } = await supabase
    .from("tickets")
    .select(selectInput)
    .eq("id", ticket);
  if (error) {
    toast.error("Error al obtener el detalle del ticket");
  }
  return data[0];
}

// Obtener detalle de una empresa

export async function getOrganizationDetails(organization, details) {
  let selectInput = "*";

  switch (details) {
    case "basic":
      selectInput = `id, company_name, nit, address(project_name), industry, created_by(email), modified_by(email)`;
      break;
    case "users":
      selectInput =
        "id, users!company_id(id, first_name, last_name, email, role_id(id, role_name))";
      break;
    case "projects":
      selectInput = "id, proejcts(id, project_name)";
      break;
    case "all":
      selectInput = "*";
      break;
    default:
      selectInput = "*";
  }

  const { data, error } = await supabase
    .from("companies")
    .select(selectInput)
    .eq("id", organization);
  if (error) {
    toast.error("Error al obtener el detalle de la empresa");
  }
  return data[0];
}

// Obtener todos los proyectos

export async function getAllProjects() {
  const { data, error } = await supabase.from("projects").select("*");
  if (error) {
    toast.error("Error obteniendo proyectos", error);
    return [];
  }
  return data;
}

// Obtener todos los tickets del usuario actual

export async function getAllUserTickets({ loggedUserId }) {
  const projects = await getUserDetails(loggedUserId, "projects");

  const { data, error } = await supabase
    .from("tickets")
    .select(
      "id, title, description, type_id, type: type_id(type_name), status_id, status: status_id(status_name), service_id(item_value)"
    )
    .in(
      "project_id",
      projects.projects_users.map((project) => project.project_id)
    );
  if (error) {
    toast.error("Error obteniendo los tickets", error);
    return [];
  }
  return data;
}

// Obtener todos los tickets de un usuario

export async function getAllUserProjects(userId) {
  const projects = await getUserDetails(userId, "projects");

  const { data, error } = await supabase
    .from("projects")
    .select(
      "id, project_name, description, projects_services(services: enum_field_items(item_value))"
    )
    .in(
      "id",
      projects.projects_users.map((project) => project.project_id)
    );
  if (error) {
    toast.error("Error obteniendo los tickets", error);
    return [];
  }
  return data;
}

// Obtener los tipos de ticket

export async function getTicketTypes() {
  const { data, error } = await supabase
    .from("ticket_types")
    .select("id, type_name");
  if (error) {
    toast.error("Error obteniendo el tipo de ticket", error);
    return [];
  }
  return data;
}

// Items de los desplgables de los formularios de tickets

export async function getEnumFieldItems(field) {
  const { data, error } = await supabase
    .from("enum_field_items")
    .select("id,item_value, field_definition_id")
    .eq("field_definition_id", field);
  if (error) {
    toast.error("Error obteniendo los items del desplegable", error);
    return [];
  }
  return data;
}

// obtener todos los estados de los tickets

export async function getStatusValues() {
  const { data, error } = await supabase
    .from("ticket_statuses")
    .select("id, status_name");
  if (error) {
    toast.error("Error obteniendo los items del desplegable", error);
    return [];
  }
  return data;
}
// obtener los cursos

export async function getCourses() {
  let query = supabase.from("dim_cursos").select(
    `
      id,
      nombre_curso,
      descripcion,
      nombre_miniatura,
      valor_curso,
      estado_curso,
      estado: estado_curso(nombre_estado,color_id)
    `
  );

  const { data, error } = await query;
  if (error) {
    toast.error("Error obteniendo los items del desplegable", error);
    return [];
  }

  return data;
}
// obtener los cursos comprados

export async function getCoursesStudent(id_usuario) {
  let query = supabase
    .from("curso_comprado")
    .select(
      `
    id_curso,
    estado_curso(id, nombre_estado , color_id),
    info_curso: id_curso(
    nombre_curso,
      descripcion,
      nombre_miniatura,
      valor_curso,
      estado_curso)
    `
    )
    .eq("id_usuario", id_usuario);
  const { data, error } = await query;
  if (error) {
    toast.error("Error obteniendo los items del desplegable", error);
    return [];
  }
  return data;
}

// obtener los cursos comprados

export async function getUserFiles(id_user) {
  let query = supabase
    .from("user_files")
    .select(
      "user_project, file_name, created_at, user(first_name, last_name), user"
    )
    .eq("user", id_user);
  const { data, error } = await query;
  if (error) {
    toast.error("Error obteniendo los items del desplegable", error);
    return [];
  }
  return data;
}

// obtener los cursos comprados

export async function getVideosWatched(id_usuario, id_video) {
  let query = supabase
    .from("estado_videos_usuarios")
    .select(
      `
id_estado
    `
    )
    .eq("id_usuario", id_usuario)
    .eq("id_video", id_video);
  const { data, error } = await query;
  if (error) {
    toast.error("Error obteniendo los items del desplegable", error);
    return [];
  }
  return data;
}

// obtener modulos
export async function getModule(course_id) {
  let query = supabase
    .from("dim_modulos")
    .select(
      `
      id,
      nombre_modulo,
      info_curso: curso_id(nombre_curso,nombre_miniatura)
      `
    )
    .eq("curso_id", course_id);

  const { data, error } = await query;

  if (error) {
    toast.error("Error obteniendo los items del desplegable", error);
    return [];
  }
  console.log(data);
  return data;
}

// obtener pbi
export async function getPBI(user_id) {
  let query = supabase
    .from("dashboards_pbi")
    .select(`id, link_pbi, name`)
    .eq("id_user", user_id);

  const { data, error } = await query;

  if (error) {
    toast.error("Error obteniendo los items del desplegable", error);
    return [];
  }
  return data;
}

// obtener Videos de los modulos
export async function getVideos(module_id, video_id) {
  let query;

  // Usando un switch para aplicar las condiciones
  // eslint-disable-next-line default-case
  switch (true) {
    case module_id !== "" && video_id !== "":
      query = supabase
        .from("dim_videos")
        .select(
          `
        id,
        titulo_video,
        url,
        info_curso:id_modulo(nombre_modulo ,curso_id(id,nombre_curso , descripcion)),
        id_modulo
        `
        )
        .eq("id_modulo", module_id)
        .eq("id", video_id);
      break;
    case module_id !== "":
      query = supabase
        .from("dim_videos")
        .select(
          `
        id,
        titulo_video,
        url,
        info_curso:id_modulo(nombre_modulo ,curso_id(id,nombre_curso , descripcion)),
        id_modulo
        `
        )
        .eq("id_modulo", module_id);
      break;
    case video_id !== "":
      query = supabase
        .from("dim_videos")
        .select(
          `
        id,
        titulo_video,
        url,
        info_curso:id_modulo(nombre_modulo ,curso_id(id,nombre_curso , descripcion)),
        id_modulo
        `
        )
        .eq("id", video_id);
      break;
  }

  const { data, error } = await query;

  if (error) {
    toast.error("Error obteniendo los items del desplegable", error);
    return [];
  }

  return data;
}
// descargar archivos

export const DownloadFile = async (bucket, path) => {
  try {
    const { data, error } = await supabase.storage.from(bucket).download(path);
    if (error) throw new Error("An error occured downloading files");
    console.log(data);
    return data;
  } catch (error) {
    console.log(error);
  }
};
// obtener las imagenes "miniaturas" de los cursos

export async function downloadImage(path) {
  try {
    const { data } = supabase.storage
      .from("cursos")
      .getPublicUrl(`miniaturas/${path}`);

    const url = data.publicUrl;
    return url;
  } catch (error) {
    console.log("Error descargando imagen: ", error.message);
  }
}

// obtener las imagenes "miniaturas" de los cursos

export async function downloadVideo(path) {
  try {
    const { data } = supabase.storage.from("videos").getPublicUrl(`${path}`);

    const url = data.publicUrl;
    return url;
  } catch (error) {
    console.log("Error descargando imagen: ", error.message);
  }
}
