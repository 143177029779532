import { createCustumerTicket } from "api/tickets/route";
import { getUserDetails } from "hooks/commonData";
import { toast } from "sonner";
import { getUserData, supabase } from "utils/SupabaseClient";

export async function Develope({
  settings,
  tecnology,
  description,
  nameConsult,
  name,
  company,
  country,
  state,
  city,
  location,
  mail,
  phone,
  aditionalDescription,
  setIsLoading,
  setError,
  setSuccess,
  setAditionalInfo,
}) {
  setIsLoading(true);

  const descriptionComplete = `${description}

    Herramientas:
    ${settings.length !== 0 ? settings : "ninguna"}

    Tecnologias:
    ${tecnology.length !== 0 ? tecnology : "ninguna"}
        `;

  const user = await getUserData();
  const subcompany = await getUserDetails(user.id, "subcompanies");

  const subCompanyName = subcompany?.company?.projects.find((e) =>
    e?.razon_social.includes("Principal")
  );

  const dataTicket = await createCustumerTicket({
    ticketTypeId: 2,
    serviceId: 50,
    projectId: "fae4b943-f791-4b92-9283-d5f0ef68c862",
    description: descriptionComplete,
    summary: nameConsult,
    subcompanyId: subCompanyName?.id,
  });
  console.log(dataTicket);

  const ticketId = dataTicket[0];
  const createdBy = dataTicket[1];

  const { data: DataFields, error: ErrFields } = await supabase
    .from("th_ticket_fields")
    .insert([
      {
        ticket_id: ticketId,
        field_definition_id: 12,
        field_value: "Comercia",
        created_by: createdBy,
        modified_by: createdBy,
      },
      {
        ticket_id: ticketId,
        field_definition_id: 13,
        field_value: "Comercia",
        created_by: createdBy,
        modified_by: createdBy,
      },
      {
        ticket_id: ticketId,
        field_definition_id: 14,
        field_value: "Comercia",
        created_by: createdBy,
        modified_by: createdBy,
      },
      {
        ticket_id: ticketId,
        field_definition_id: 15,
        field_value: "Comercia",
        created_by: createdBy,
        modified_by: createdBy,
      },
      {
        ticket_id: ticketId,
        field_definition_id: 16,
        field_value: "Comercia",
        created_by: createdBy,
        modified_by: createdBy,
      },
    ]);
  console.log(ErrFields);
  const items = {
    herramientas: String(settings)
      .replace("[", "")
      .replace("]", "")
      .replace('"', ""),
    tecnologias: String(tecnology)
      .replace("[", "")
      .replace("]", "")
      .replace('"', ""),
    nombre_consulta: nameConsult,
    descipcion_general: description,
    seccion: "Quiero un desarrollo",
    nombre_responsable: name,
    nombre_empresa: company,
    direccion: location,
    pais: country,
    estado: state,
    ciudad: city,
    correo: mail,
    telefono: phone,
    observacion_adicional: aditionalDescription,
  };

  const { data: DataComercial, error: ErrComercial } = await supabase
    .from("th_comercial")
    .insert(items);

  if (!dataTicket || ErrComercial || ErrFields) {
    toast.error("¡No fue posible enviar tu solicitud!");

    setError(true);
  } else {
    setAditionalInfo(false);
    setSuccess(true);
  }
}
