import { Link } from "react-router-dom";
import { NewIcon } from "../../../components/svg/NewIcon";

export function NewCourseButton() {
  return (
    <Link
      to="/training/new"
      className="fixed bottom-4 right-4 slide-down-animation w-10 cursor-pointer"
    >
      <NewIcon />
    </Link>
  );
}
