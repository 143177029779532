export function StepsComponent({ currentStep, countSteps, isText }) {
  return (
    <div className="flex items-center w-full h-full">
      {countSteps.map((step, index) => (
        <>
          <div
            key={index}
            className="relative rounded-full flex items-center justify-center"
          >
            <label
              className={`${
                !isText && "hidden"
              }  md:block absolute truncate text-sm  text-center transform translate-y-7 ${
                currentStep > index
                  ? "text-yellow-600"
                  : currentStep === index
                  ? "text-yellow-600"
                  : "text-gray-400"
              }`}
            >
              {step.label}
            </label>
            {/* Background Circle */}
            <span
              className={`transition-all duration-300 left-0 w-8 h-8 rounded-full ${
                currentStep > index
                  ? "bg-yellow-500"
                  : currentStep === index
                  ? "bg-yellow-500"
                  : "bg-gray-300"
              }`}
            />
          </div>
          {/* Line between circles */}
          {index !== countSteps.length - 1 && (
            <div
              className={`transition-all duration-300 transform  w-full h-1 ${
                currentStep >= index + 1 ? "bg-yellow-500" : "bg-gray-300"
              }`}
            />
          )}
        </>
      ))}
    </div>
  );
}
