import { ButtonSave } from "components/common/ButtonSave";
import { ChargingComponent } from "components/common/ChargingComponent";
import { InputText } from "components/common/InputText";
import MultipleSelect from "components/common/MultipleSelect";
import { SearchSelect } from "components/tailwind/SearchSelect";
import { getTypeDocument, getUserDetails } from "hooks/commonData";
import { useEffect, useState } from "react";
import { getProvider, getUserData } from "utils/SupabaseClient";
import { ActiveButton } from "./ActiveButton";

export function StructureModal({
  active,
  setIsActive,
  modal,
  isLoading,
  setName,
  name,
  setLastName,
  lastName,
  setPhone,
  phone,
  setEmail,
  email,
  subCompanySelected,
  setSubCompany,
  subCompany,
  setTypeDocument,
  typeDocument,
  setDocumento,
  documento,
  setPassword,
  password,
  form,
}) {
  const [typeDocumentData, setTypeDocumentData] = useState([]);
  const [subCompanyData, setSubCompanyData] = useState([]);
  const [conditions, setConditions] = useState(false);

  useEffect(() => {
    if (modal === "edit") {
      if (
        name &&
        lastName &&
        phone &&
        email &&
        subCompany.lengt !== 0 &&
        documento &&
        typeDocument
      ) {
        setConditions(true);
      } else setConditions(false);
    } else if (modal === "add") {
      if (
        name &&
        lastName &&
        phone &&
        email &&
        documento &&
        typeDocument &&
        password
      ) {
        setConditions(true);
      } else {
        setConditions(false);
      }
    }
  }, [
    modal,
    name,
    lastName,
    phone,
    email,
    subCompany,
    documento,
    typeDocument,
  ]);

  useEffect(() => {
    getTypeDocument().then((r) =>
      setTypeDocumentData(
        r.map((item) => ({ value: item.tipo, label: item.tipo }))
      )
    );

    getUserData().then((user) => {
      getUserDetails(user.id, "subcompanies").then((e) => {
        setSubCompanyData(
          e.company.projects.map((item) => ({
            value: item.id,
            label: item.razon_social,
          }))
        );
      });
    });
  }, []);

  return (
    <>
      <div className={isLoading ? "appear-animation" : "hidden"}>
        <ChargingComponent />
      </div>
      <div
        className={`overflow-y-auto  h-auto max-h-[80vh] ${
          !isLoading ? "appear-animation" : "hidden"
        }`}
      >
        {modal === "edit" && (
          <div className="flex">
            <ActiveButton setIsToggled={setIsActive} isToggled={active} />
            <label className="mt-2 ml-2">
              {`usuario ${String(active)
                .replace("true", "activo")
                .replace("false", "inactivo")}`}
            </label>
          </div>
        )}
        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-2">
          <InputText
            setValue={setName}
            value={name}
            placeholder={"Nombres"}
            required
          />
          <InputText
            setValue={setLastName}
            placeholder={"Apellidos"}
            value={lastName}
            required
          />
        </div>
        <div className="my-2 grid grid-cols-1 md:grid-cols-3 md:gap-2">
          <SearchSelect
            items={typeDocumentData}
            setOption={setTypeDocument}
            option={typeDocument}
            defaultOption={typeDocument}
            title={"Tipo documento"}
          />
          <div className="md:col-span-2">
            <InputText
              setValue={setDocumento}
              value={documento}
              placeholder="N° Documento"
              required
            />
          </div>
        </div>
        <InputText
          setValue={setPhone}
          value={phone}
          placeholder={"Teléfono"}
          required
        />
        <div className="mt-2 grid grid-cols-1 md:grid-cols-2 md:gap-4">
          <InputText
            setValue={setEmail}
            value={email}
            placeholder={"E-mail"}
            required
            disabled={modal === "edit"}
          />
          {getProvider !== "google" && (
            <InputText
              setValue={setPassword}
              value={password}
              placeholder="Contraseña"
              required={modal === "add"}
            />
          )}
        </div>
        {modal === "edit" && (
          <MultipleSelect
            title={"Subcompañias"}
            list={subCompanyData}
            setValue={setSubCompany}
            OptionsSelected={subCompanySelected}
          />
        )}
        {conditions ? (
          <ButtonSave HadleSubmit={form} />
        ) : (
          <div className="flex justify-end mt-2">
            <label className="bg-red-200 border-2 border-red-700 text-red-700 p-1 rounded-lg">
              te faltan campos
            </label>
          </div>
        )}
      </div>
    </>
  );
}
