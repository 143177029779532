import { GradiendBanner } from "components/common/GradiendBanner";

import { Form } from "./Form";
import { Information } from "./Information";
import { AutoHeigth } from "components/common/AutoHeigth";

export function Visual({ submodule, setSubmodule }) {
  return (
    <div
      className={`${
        submodule === "IhaveAQuestionVisual" || submodule === "WantDevelope"
          ? "slide-down-animation"
          : "hidden"
      }`}
    >
      <AutoHeigth>
        <GradiendBanner>
          <h1 className="font-semibold  text-white text-center text-md">
            ¡Nos encanta lo que hacemos y queremos compartir toda nuestra
            experiencia para que trabajemos juntos!
          </h1>
        </GradiendBanner>

        <div className="md:grid md:grid-cols-3 md:gap-2 mt-1">
          <Information submodule={submodule} />
          <Form submodule={submodule} setSubmodule={setSubmodule} />
        </div>
      </AutoHeigth>
    </div>
  );
}
