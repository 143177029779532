import { getUserDetails } from "hooks/commonData";
import { useEffect, useState } from "react";
import { getUserData } from "utils/SupabaseClient";

export function PlanLeyend({ expanded }) {
  const [plan, setPlan] = useState("");

  useEffect(() => {
    getUserData().then((user) =>
      getUserDetails(user.id, "plan").then((r) =>
        setPlan(r?.company_id?.id_plan)
      )
    );
  }, []);

  const colorId = plan?.color_id;

  const colors = colorId ? JSON.parse(colorId) : { BG: "", Border: "" };

  const redirect = () => {
    window.location.href = "/plans";
  };

  return (
    <div className="flex w-full items-center">
      <hr className="border border-slate-300 grow" />
      <button
        type="button"
        onClick={redirect}
        className={`cursor-pointer flex text-[15px] px-0.5 py-0.2 bg-[#${
          colors.BG
        }] border border-[#${colors.Border}] text-[#${
          colors.Border
        }] rounded-lg appear-animation transition-all  ${
          expanded ? "w-40 h-6 truncate" : "w-10 hidden"
        }`}
      >
        <label className="w-full text-center">{plan?.plan_type}</label>
      </button>
      <hr className="border border-zinc-300 grow" />
    </div>
  );
}
