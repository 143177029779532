import { Question } from "api/budget/Quiestion";
import { AutoHeigth } from "components/common/AutoHeigth";
import { BackGroundSections } from "components/common/BackGroundSections";
import { ButtonSave } from "components/common/ButtonSave";
import { ChargingComponent } from "components/common/ChargingComponent";
import { InputText } from "components/common/InputText";
import MultipleSelect from "components/common/MultipleSelect";
import { Subtitle } from "components/common/Subtitle";
import { BackIcon } from "components/svg/BackIcon";
import { TextArea } from "components/tailwind/TextArea";
import { useEffect, useState } from "react";
import { ModalTernarioSuccess } from "./ModalTernarioSuccess";
import { ModalAditionalInfo } from "./ModalAditionalInfo";

export function Form({ setSubmodule, submodule }) {
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [aditionalInfo, setAditionalInfo] = useState(false);

  const [settings, setSetting] = useState("");
  const [tecnology, setTecnology] = useState("");
  const [nameConsult, setNameConsult] = useState("");
  const [description, setDescription] = useState("");

  const settingsList = [
    { label: "Power BI", value: "Power BI" },
    { label: "Power APPS", value: "Power APPS" },
    { label: "Power Automate", value: "Power Automate" },
    { label: "Power Platform", value: "Power Platform" },
    { label: "Ms. Fabrics", value: "Ms. Fabrics" },
    { label: "Looker Studio", value: "Looker Studio" },
    { label: "AppSheets", value: "AppSheets" },
    { label: "Pentaho", value: "Pentaho" },
    { label: "Python", value: "Python" },
    { label: "Supabase", value: "Supabase" },
    { label: "React", value: "React" },
    { label: "Tableau", value: "Tableau" },
    { label: "Otros", value: "Otros" },
  ];

  const tecnologyList = [
    { label: "BI", value: "BI" },
    { label: "BA", value: "BA" },
    { label: "IA", value: "IA" },
    { label: "ML", value: "ML" },
    { label: "DL", value: "DL" },
    { label: "RPA", value: "RPA" },
    { label: "API", value: "API" },
    { label: "ETL", value: "ETL" },
    { label: "DWH", value: "DWH" },
    { label: "Fabrica de Software", value: "Fabrica de Software" },
    { label: "Otros", value: "Otros" },
  ];

  const Insert = async () => {
    if (submodule === "IhaveAQuestionVisual") {
      await Question({
        settings,
        tecnology,
        description,
        nameConsult,
        setIsLoading,
        setError,
        setSuccess,
      });
    } else {
      setAditionalInfo(true);
    }
  };

  useEffect(() => {
    if (success || error) {
      setIsLoading(false);
      setSuccess(false);
      setError(false);
      setNameConsult("");
      setDescription("");
      setSetting([]);
      setTecnology([]);
    }
  }, [success, error]);

  return (
    <div className="col-span-2">
      <ModalAditionalInfo
        aditionalInfo={aditionalInfo}
        setAditionalInfo={setAditionalInfo}
        settings={settings}
        tecnology={tecnology}
        description={description}
        nameConsult={nameConsult}
        setIsLoading={setIsLoading}
        setError={setError}
        setSuccess={setSuccess}
        isLoading={isLoading}
      />
      <ModalTernarioSuccess submodule={submodule} success={success} />
      <BackGroundSections>
        <div className={`${isLoading ? "appear-animation" : "hidden"}`}>
          <ChargingComponent />
        </div>
        <div className={`${!isLoading ? "appear-animation" : "hidden"}`}>
          <AutoHeigth maxPx="130px">
            <div className="flex justify-between my-1">
              <div className="w-full text-center">
                <Subtitle
                  text={
                    submodule === "IhaveAQuestionVisual"
                      ? "TENGO UNA PREGUNTA"
                      : "QUIERO UN DESARROLLO"
                  }
                />
              </div>
              <button onClick={() => setSubmodule("MenuVisualBudget")}>
                <BackIcon w={30} h={30} />
              </button>
            </div>
            <MultipleSelect
              title={"Herramientas"}
              list={settingsList}
              setValue={setSetting}
              required
            />
            <MultipleSelect
              title={"Tecnología"}
              list={tecnologyList}
              setValue={setTecnology}
              required
            />
            <InputText
              placeholder={
                submodule === "IhaveAQuestionVisual"
                  ? "Nombre de la consulta:"
                  : "Nombre del proyecto"
              }
              setValue={setNameConsult}
              value={nameConsult}
              required
            />
            <TextArea
              title="Descripción General"
              onChange={setDescription}
              value={description}
              required
            />
            <div
              className={`${
                settings !== "" && description !== ""
                  ? "appear-animation"
                  : "hidden"
              } flex justify-center`}
            >
              <ButtonSave HadleSubmit={Insert} />
            </div>
          </AutoHeigth>
        </div>
      </BackGroundSections>
    </div>
  );
}
