import { useEffect, useState } from "react";

export function ProgressBar({ videosLength, videoWatched }) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if(videosLength && videoWatched){
      setProgress(Math.floor((videoWatched / videosLength) * 100));
    }
  }, [videoWatched, videosLength]);

  return (
    <div className="flex grow transition-all">
      <label className="font-semibold text-lg">{`${progress}%`}</label>
      <div
        className="bg-green-400 h-[5px] rounded-l-full ml-[5px] mt-[10px]"
        style={{ width: `${progress}%` }}
      ></div>
      <div
        className="bg-slate-300 h-[5px] grow rounded-r-full mt-[10px]"
        style={{ width: `${100 - progress}%` }}
      ></div>
    </div>
  );
}
