import { ViewIssueField } from "components/ServiceDesk/ViewIssueField";
import { ViewIssueHeader } from "components/ServiceDesk/ViewIssueHeader";
import { ViewIssueFieldStatus } from "components/ServiceDesk/ViewIssueFieldStatus";
import { ViewIssueDocuments } from "components/ServiceDesk/ViewIssueDocuments";
import { ViewIssueDescription } from "components/ServiceDesk/ViewIssueDescription";
import { Comment } from "components/ServiceDesk/FormComment";
import { TextArea } from "components/ServiceDesk/TextArea";
import { Pagination } from "components/ServiceDesk/Pagination";

export function ViewRequestUI({
  summary,
  description,
  createdBy,
  project,
  status,
  service,
  documents,
  comments,
  setMessage,
  saveComment,
  updateCommnet,
  value,
  formatDate,
  isUpdate,
  setIsUpdate,
  handleUpdateComment,
  countR,
  getComments,
}) {
  
  return (
    <div className="space-y-4 p-4 sm:px-8 sm:py-6 lg:p-4 xl:px-8 xl:py-6">
      <ViewIssueHeader
        summary={summary}
        issueType={2}
        text={"Información detallada de la solicitud."}
      />
      <div className="grid grid-cols-6 gap-16 mt-6">
        <div className="col-span-4">
          <div className="border border-gray-200 p-4 rounded-md">
            <p>
              <strong>Usuario</strong> generó la solicitud el "fecha"
            </p>
            <div className="mt-4">
              <ViewIssueDescription label="Descripción" value={description} />
            </div>
          </div>
          <div className="mt-4">
            <h2 className="text-md font-bold leading-6 text-gray-900">
              Actividad
            </h2>
            <h2 className="text-md font-bold leading-6 text-gray-900 mt-4">
              Comentarios
            </h2>
            <section className="mt-1 leading-6 px-4 pb-4">
              {comments.map((comment, index) => (
                <div key={index} className="mt-4 w-full">
                  <div className="flex items-start justify-between">
                    <div className="flex">
                      <img
                        alt="PlisBI Icon"
                        src="../../assets/img/icons/principal.png"
                        className="h-8 w-8 rounded-full"
                      />
                      <div className="pl-4">
                        <p className="text-sm mt-1">
                          <strong>{comment.user} </strong> {comment.updated_at != comment.created_at ? formatDate(comment.updated_at) : formatDate(comment.created_at)} 
                          <span className="italic ml-2 text-gray-500">{comment.updated_at != comment.created_at ? "(Editado)" : ""}</span>
                        </p>
                        <p className="mt-2 text-sm">{comment.comment}</p>
                      </div>
                    </div>
                    <div className="flex items-center cursor-pointer"
                      onClick={() => {handleUpdateComment(comment.comment, comment.id)}}
                    >
                      <i className="lni lni-pencil-alt"></i>
                    </div>
                  </div>
                  <hr className="my-4" />
                </div>

              ))}
              <Pagination size={countR} getData={getComments}></Pagination>
            </section>
            <Comment isUpdate={isUpdate} setIsUpdate={setIsUpdate} setMessage={setMessage} message={value} saveComment={saveComment} updateCommnet={updateCommnet}>
              <TextArea
                title={`${isUpdate ? "Actualiza el comentario":"Nuevo comentario"}`}
                field="comment"
                description="Agrega un comentario..."
                onChange={setMessage}
                value={value}
              />
            </Comment>
          </div>
        </div>
        <div className="col-span-2">
          <div className="border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
              <ViewIssueFieldStatus label="Estado" statusId={status} />
              <ViewIssueField label="Creado por" value={createdBy} />
              <ViewIssueField label="Proyecto" value={project} />
              <ViewIssueField label="Servicio" value={service} />
              <ViewIssueDocuments documents={documents} />
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}
