import { useEffect, useRef, useState } from "react";
import { DropDownIcon } from "components/svg/DropDownIcon";

export function SearchSelect({
  items,
  setOption,
  option,
  title,
  className,
  required,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(null);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  const listItemRefs = useRef([]); // Array to hold references for each list item

  useEffect(() => {
    if (!option) {
      setSearchTerm("");
    }
  }, [option]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        inputRef.current &&
        !inputRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    if (!isOpen) {
      setIsOpen(!isOpen);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const [optionSelected, setSelectedOption] = useState("Selecciona una opción");

  useEffect(() => {
    if (option) {
      setSelectedOption(
        items.filter((item) => item.value === option)[0]?.label
      );
      setOption(option);
    }
  }, [option]);

  const filteredItems = items.filter((item) =>
    item.label?.toLowerCase()?.includes(searchTerm)
  );

  const handleEnterPress = (event) => {
    if (event.key === "Enter" && filteredItems.length > 0) {
      const selectedItem = filteredItems[selectedIndex];
      if (selectedItem) {
        setSelectedOption(selectedItem.label);
        setOption(selectedItem.value);
        setSearchTerm(selectedItem.label);
        setSelectedIndex(0);
        setIsOpen(false); // Cierra el dropdown
      }
    }
  };

  const handleArrowKeyPress = (event) => {
    let newSelectedIndex = selectedIndex;
    if (event.key === "ArrowDown") {
      newSelectedIndex =
        selectedIndex === null
          ? 0
          : Math.min(filteredItems.length - 1, selectedIndex + 1);
    } else if (event.key === "ArrowUp") {
      newSelectedIndex =
        selectedIndex === null ? 0 : Math.max(0, selectedIndex - 1);
    }
    setSelectedIndex(newSelectedIndex);

    // Desplazar la opción seleccionada al centro de la vista
    if (listItemRefs.current[newSelectedIndex]) {
      listItemRefs.current[newSelectedIndex].scrollIntoView({
        behavior: "smooth",
        block: "start", // Puedes ajustar esto a "start" o "end" si prefieres un desplazamiento diferente
      });
    }
  };

  return (
    <div className={`w-full ${className}`}>
      <label className={`text-black bg-white transition-all text-sm`}>
        {title}{" "}
        <span className="text-red-500 text-lg font-bold">
          {required && !option && "*"}
        </span>
      </label>
      <div
        className={`transition-all relative group mt-2 border rounded-md h-9 ${
          isOpen ? "border-[#F1C40F]" : "border-slate-300"
        }`}
      >
        <button
          id="dropdown-button"
          className={`flex justify-between items-center w-full ${
            !isOpen && "p-1.5"
          } focus:border-none focus:transition-all`}
          onClick={toggleDropdown}
          type="button"
        >
          {isOpen ? (
            <input
              ref={inputRef}
              id="search-input"
              className="pl-2 focus:ring-0 focus:border-none w-full h-8 text-sm border-0 bg-none rounded-md"
              type="text"
              placeholder="Escribe..."
              autoComplete="on"
              value={searchTerm}
              onChange={handleSearch}
              onKeyDown={(e) => {
                handleEnterPress(e);
                handleArrowKeyPress(e);
              }}
              required={required}
            />
          ) : (
            <span className="mx-2 truncate text-sm text-gray-500">
              {optionSelected}
            </span>
          )}

          <DropDownIcon
            className="w-5 h-5 mr-1 p-1 rounded-full transition-colors"
            isopen={isOpen}
          />
        </button>

        <div
          ref={dropdownRef}
          id="dropdown-menu"
          className={`absolute z-10 w-full bg-white rounded-md shadow-md border border-gray-300 mt-1 ${
            isOpen ? "block" : "hidden"
          }`}
          style={{ maxHeight: "180px", overflowY: "auto" }}
        >
          <ul className="py-1">
            {filteredItems.map((item, index) => (
              <li
                key={index}
                ref={(el) => (listItemRefs.current[index] = el)} // Asignar referencia a cada elemento
                onClick={() => {
                  setSelectedOption(item.label);
                  setSearchTerm(item.label);
                  setOption(item.value);
                  setIsOpen(false);
                }}
                className={`px-4 py-2 hover:bg-gray-100 cursor-pointer ${
                  selectedIndex === index ? "bg-gray-200" : ""
                }`}
                onKeyDown={(e) => {
                  handleArrowKeyPress(e);
                }}
                onMouseEnter={() => setSelectedIndex(index)}
              >
                {item.label}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
