import { Button } from "components/common/Button";
import { ModalBG } from "components/common/ModalBG";
import { BackIcon } from "components/svg/BackIcon";
import { EyeIcon } from "components/svg/EyeIcon";
import { useEffect, useRef, useState } from "react";
import { FiUploadCloud } from "react-icons/fi";
import { toast } from "sonner";

export function FileUploadComponent({
  text,
  redText,
  accept,
  setSelectedFilesProps,
  setLength,
  recharge,
}) {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [filePreviews, setFilePreviews] = useState([]); // Renombrado para más flexibilidad
  const [nameFile, setNameFile] = useState("");
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [fullScreenFile, setFullScreenFile] = useState(null);

  useEffect(() => {
    if (recharge) {
      setSelectedFiles([]);
    }
  }, [recharge]);

  const handleFileChange = (event) => {
    const files = event.target.files;

    setSelectedFilesProps(files);
    setLength(Number(files.length));

    const newFiles = Array.from(files);

    newFiles.forEach((file) => {
      if (file.size > 5000000000) {
        toast.error(`El archivo ${file.name} es demasiado grande`);
      } else {
        setSelectedFiles((prevFiles) => [...prevFiles, file]);
      }
    });

    const previews = newFiles.map((file) => {
      if (file.type.startsWith("image/")) {
        return URL.createObjectURL(file); // Vista previa para imágenes
      } else if (file.type === "application/pdf") {
        return "pdf-icon"; // Ícono para PDFs
      } else if (file.type.startsWith("text/")) {
        return "text-icon"; // Ícono para archivos de texto
      } else if (file.type.startsWith("video/")) {
        return URL.createObjectURL(file); // Vista previa para videos
      } else {
        return null;
      }
    });
    setFilePreviews(previews);
  };

  const handleDelete = (index) => {
    const updatedFilePreviews = [...filePreviews];
    updatedFilePreviews.splice(index, 1);
    setFilePreviews(updatedFilePreviews);
    setLength(Number(updatedFilePreviews.length));

    const updatedSelectedFiles = [...selectedFiles];
    updatedSelectedFiles.splice(index, 1);
    setSelectedFiles(updatedSelectedFiles);
  };

  const handleFullScreen = (preview, name) => {
    setIsFullScreen(true);
    setFullScreenFile(preview);
    setNameFile(name);
  };

  const closeFullScreen = () => {
    setIsFullScreen(false);
    setFullScreenFile(null);
  };

  return (
    <div className="flex flex-col mt-2 w-full">
      <>
        <label
          htmlFor="file"
          className="flex items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer hover:bg-slate-300 transition delay-150 duration-300 ease-in-out text-gray-300 hover:text-slate-400"
        >
          <div className="flex flex-col items-center justify-center pt-5 pb-6">
            <FiUploadCloud className="w-20 h-20" />

            <p className="mb-2 text-sm ">
              <span className="text-lg text-center">{text}</span>
            </p>
            <p className="text-red-500">{redText}</p>
          </div>
          <input
            id="file"
            type="file"
            accept={accept}
            className="hidden"
            multiple
            onChange={handleFileChange}
          />
        </label>
        <div className="flex flex-col w-full h-full mt-1 ">
          {filePreviews.length > 0 && (
            <>
              <p className="font-semibold">Archivos seleccionados:</p>
              <div
                className="grid grid-cols-2 gap-1"
                style={{ maxHeight: "180px", overflowY: "auto" }}
              >
                {filePreviews.map((preview, index) => {
                  const file = selectedFiles[index];
                  return (
                    preview && (
                      <div
                        key={index}
                        className="flex justify-start mt-2 hover:bg-slate-200 transition-all rounded-lg border border-slate-300 appear-animation"
                      >
                        <button
                          className="relative w-20 h-20 rounded-lg object-cover bg-black"
                          onClick={() => handleFullScreen(preview, file?.name)}
                        >
                          {/* Vista previa del archivo */}
                          {file.type.startsWith("image/") && (
                            <img
                              src={preview}
                              alt={`Preview ${index}`}
                              className="absolute h-full top-0 hover:opacity-40 transition-all rounded-lg"
                            />
                          )}
                          {file.type.startsWith("video/") && (
                            <video
                              className="absolute h-full top-0 hover:opacity-40 transition-all rounded-lg"
                              src={preview}
                              autoPlay
                              muted
                              loop
                            />
                          )}
                          {file.type === "application/pdf" && (
                            <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-gray-500 opacity-70 text-white">
                              PDF
                            </div>
                          )}
                          {file.type.startsWith("text/") && (
                            <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-gray-500 opacity-70 text-white">
                              TXT
                            </div>
                          )}
                          <div className="w-full h-full items-center flex justify-center">
                            <EyeIcon
                              className="h-10 w-10 opacity-100 hover:opacity-0"
                              hidden={true}
                            />
                          </div>
                        </button>
                        <div className="w-full">
                          <div className=" grid grid-cols-2">
                            <div className="col-start-2">
                              <div className="flex justify-end">
                                <Button
                                  className="w-7 h-7"
                                  icon={<BackIcon />}
                                  onClick={() => handleDelete(index)}
                                />
                              </div>
                            </div>
                          </div>
                          <Button
                            className="text-sky-800 text-start ml-2 truncate md:w-60 lg:70"
                            text={file?.name}
                            onClick={() =>
                              handleFullScreen(preview, file?.name)
                            }
                          />
                        </div>
                      </div>
                    )
                  );
                })}
              </div>
            </>
          )}
        </div>
      </>

      {isFullScreen && (
        <ModalBG onClose={closeFullScreen} open={isFullScreen} title={nameFile}>
          <div style={{ maxHeight: "380px", overflowY: "auto" }}>
            {fullScreenFile && fullScreenFile.includes("video/") ? (
              <video
                className="max-w-full max-h-full object-contain"
                controls
                src={fullScreenFile}
              />
            ) : (
              <img
                src={fullScreenFile}
                alt="Full Screen"
                className="max-w-full max-h-full object-contain"
              />
            )}
          </div>
        </ModalBG>
      )}
    </div>
  );
}
