import { ChangePassword } from "api/user-module/ChangePassword";
import { ButtonSave } from "components/common/ButtonSave";
import { InputText } from "components/common/InputText";
import { TextInformation } from "components/common/TextInformation";
import { EyeIcon } from "components/svg/EyeIcon";
import { useEffect, useState } from "react";
import { getProvider } from "utils/SupabaseClient";

export function ChangePasswordSection({ setisLoading }) {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [FirstPassword, setFirstPassword] = useState(true);
  const [seconPassword, setSeconPassword] = useState(true);
  const [isGoodPassword, setIsGoodPassword] = useState(null);

  const savePassword = async () => {
    await ChangePassword({
      password,
      setPassword,
      setConfirmPassword,
      setisLoading,
    });
  };

  useEffect(() => {
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;

    if (password !== confirmPassword) {
      setIsGoodPassword({
        is: false,
        label: "Las contraseñas no coinciden",
      });
    } else if (!passwordRegex.test(password)) {
      setIsGoodPassword({
        is: false,
        label:
          "La contraseña debe tener al menos 8 caracteres, incluyendo un número y una letra",
      });
    } else {
      setIsGoodPassword({
        is: true,
        label: "ninguna",
      });
    }
  }, [password, confirmPassword]);

  return (
    <div
      className={`${
        getProvider !== "google"
          ? "grid grid-cols-3 gap-4 appear-animation"
          : "hidden"
      } `}
    >
      <div className="mt-2">
        <TextInformation
          text={"Actualice su contraseña asociada a su cuenta."}
        />
      </div>

      <div className="col-span-2 col-start-2">
        <div className="flex">
          <InputText
            placeholder={"Contraseña"}
            setValue={setPassword}
            className={"w-full mb-4"}
            type={FirstPassword ? "password" : "text"}
          />
          <button onClick={() => setFirstPassword(!FirstPassword)}>
            <EyeIcon
              hidden={FirstPassword}
              className={"mt-[20px] w-8 h-8 ml-3 mr-2"}
            />
          </button>
        </div>
        <div className="flex">
          <InputText
            placeholder={"Confirmar contraseña"}
            setValue={setConfirmPassword}
            className={"w-full"}
            type={seconPassword ? "password" : "text"}
          />
          <button onClick={() => setSeconPassword(!seconPassword)}>
            <EyeIcon
              hidden={seconPassword}
              className={"mt-[20px] w-8 h-8 ml-3 mr-2"}
            />
          </button>
        </div>
        {isGoodPassword?.is ? (
          <div className={"flex justify-center mb-4"}>
            <ButtonSave HadleSubmit={savePassword} />
          </div>
        ) : (
          <>
            {password && (
              <div className="mt-10 mb-4 rounded-lg w-full border-2 border-red-700 bg-red-200 text-red-700 p-3">
                <label>{isGoodPassword?.label}</label>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
