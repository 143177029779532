import { Form } from "components/ServiceDesk/Forms";
import { Text } from "components/ServiceDesk/Text";
import { Select } from "components/ServiceDesk/Select";
import { TextArea } from "components/ServiceDesk/TextArea";
import { Files } from "components/ServiceDesk/Files";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "sonner";

import { getUserDetails, getProjectDetails } from "hooks/commonData";
import { saveCustumerIncident } from "api/incidents/route";
import { getUserData } from "utils/SupabaseClient";
import { ChargingComponent } from "components/ChargingComponent";

export function Incidents({ ticketTypeId }) {
  const navigate = useNavigate();


  const [user, setUser] = useState("");
  const [enumServices, setEnumServices] = useState([]);
  const [enumProjects, setEnumProjects] = useState([]);
  const [loading, setLoading] = useState(false);

  const consultarProyectos = user !== "";

  useEffect(() => {
    getUserData().then((data) => {
      setUser(data.id);
    });
  }, []);

  useEffect(() => {
    if (consultarProyectos) {
      getUserDetails(user, "projects").then((data) => {
        setEnumProjects(data.projects_users);
      });
    }
  }, [consultarProyectos]);

  const [selectedService, setSelectedService] = useState(-1);
  const [selectedProject, setSelectedProject] = useState(-1);
  const [summary, setSummary] = useState("");
  const [description, setDescription] = useState("");
  const [reason, setReason] = useState("");
  const [steps, setSteps] = useState("");
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (selectedProject != -1) {
      getProjectDetails(selectedProject, "services").then((data) => {
        setEnumServices(data.projects_services);
      });
    }
    else { setEnumServices([]) }
  }, [selectedProject]);


  const handleSave = async () => {
    if (selectedService === -1) {
      toast.error("Por favor selecciona un servicio.");
      return;
    }

    if (selectedProject === -1) {
      toast.error("Por favor selecciona un proyecto.");
      return;
    }

    if (summary === "") {
      toast.error("Por favor escribe un resumen.");
      return;
    }

    if (description === "") {
      toast.error("Por favor escribe una descripción.");
      return;
    }

    if (reason === "") {
      toast.error("Por favor escribe un motivo.");
      return;
    }

    if (steps === "") {
      toast.error("Por favor escribe los pasos.");
      return;
    }

    setLoading(true);
    try {
      try {
        const result = await saveCustumerIncident({
          ticketTypeId,
          projectId: selectedProject,
          summary,
          description,
          serviceId: selectedService,
          reason,
          steps,
          attachment: "Adjunto",
          files,
        });

        if (result?.error) {
          toast.error(result.error);
          return;
        }

        toast.success("Incidencia creada exitosamente.");
      } catch (error) {
        console.error('Error inesperado:', error.message);
        return;
      }
      ChargingComponent();
      navigate("/issues");
    } catch (error) {
      toast.error(`${error.message || 'Error desconocido'}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mx-auto max-w-7xl p-6 lg:p-8">
      {loading ? <ChargingComponent /> : (
        <Form
          title={"Incidencia"}
          description={"Por favor describe tú incidencia."}
          onClick={handleSave}
        >
          <Select
            title={"Proyecto"}
            field={"project"}
            onChange={setSelectedProject}
            required={true}
            options={enumProjects.map((proyecto) => ({
              value: proyecto.project_id,
              name: proyecto.project.project_name,
            }))}
          />
          <Select
            title={"Servicio"}
            field={"service"}
            onChange={setSelectedService}
            required={true}
            options={enumServices.map((item) => ({
              value: item.services.id,
              name: item.services.item_value,
            }))}
          />
          <Text title="Resumen" onChange={setSummary} />
          <TextArea
            title={"Descripción"}
            onChange={setDescription}
            required={true}
            description={"Por favor describemos el error."}
          />
          <TextArea
            title={"Motivo del error"}
            onChange={setReason}
            required={true}
            description={
              "Descripción de que realizo para identificar que fue lo que ocasiono el error."
            }
          />
          <TextArea
            title={"Paso a paso de como ocurrio el error"}
            onChange={setSteps}
            required={true}
            description={
              "Paso a paso de los procesos que realizo para que se le generara el error."
            }
          />
         <Files title={"Adjuntos"} message={"PNG, JPG, SVG, XLSX, CSV, PDF, DOC up to 50MB"} files={files} setFiles={setFiles} />
        </Form>
      )}

    </div>
  );
}
