import { PhotoIcon } from "@heroicons/react/24/solid";
import { ViewIssueDocuments } from "components/ServiceDesk/ViewIssueDocuments";
import { toast } from "sonner";
import { useRef } from 'react';

export function Files({ title, message, files, setFiles, disabled }) {
  const fileInputRef = useRef(null);

  const removeFile = (index) => {
    const filesUpdate = files.filter((_, i) => i !== index);
    setFiles(filesUpdate);
  }

  const addFile = (newFiles) => {
    Array.from(newFiles).forEach(file => {
      if (file.size > 50000000) {
        toast.error(`El archivo ${file.name} es demasiado grande`)
      } else {
        setFiles(prevFiles => [...prevFiles, file])
      }
      fileInputRef.current.value = null;
    });
  }

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFiles = e.dataTransfer.files;
    if (droppedFiles?.length > 0) {
      addFile(droppedFiles);
    }
  }

  const handleDragOver = (e) => {
    e.preventDefault();
  }

  return (
    <div className="col-span-full">
      <label
        htmlFor="cover-photo"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {title}
      </label>
      <div className="mt-1 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-5"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <div className="text-center">
          <PhotoIcon
            aria-hidden="true"
            className="mx-auto h-12 w-12 text-gray-300"
          />
          <div className="flex text-sm leading-6 text-gray-600">
            <label
              htmlFor="file-upload"
              className="relative cursor-pointer rounded-md bg-white font-semibold text-sky-700 focus-within:outline-none focus-within:ring-2 focus-within:ring-sky-700 focus-within:ring-offset-2 hover:text-sky-700"
            >
              <span>Subir archivo</span>
              <input
                disabled={disabled}
                multiple
                accept=".png, .jpg, .svg, .xlsx, .csv, .pdf, .docx, .doc"
                id="file-upload"
                name="file-upload"
                type="file"
                className="sr-only"
                ref={fileInputRef}
                onChange={(e) => addFile(e.target.files)}
              />
            </label>
            <p className="pl-1">o arrasta el archivo</p>
          </div>
          <p className="text-xs leading-5 text-gray-600">{message}</p>
        </div>
      </div>
      <div className="flex flex-col grow h-full mt-2">
        {
          files?.length > 0 && <ViewIssueDocuments documents={files} close={true} removeFile={removeFile} />
        }

      </div>
    </div>
  );
}
