import { useState } from "react";

import { FormAPI } from "./FormAPI";
import { ModalBG } from "components/common/ModalBG";
import { ModalSuccess } from "components/modal/ModalSuccess";
import { ChargingComponent } from "components/common/ChargingComponent";
import { ModalError } from "components/modal/ModalError";

export function NewRowModal({ newRow, provider, onClose, isActive }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  return (
    <ModalBG
      open={newRow}
      onClose={onClose}
      title={
        isSuccess
          ? "Creado Correctamente"
          : `Creación Conexión Api ${provider.toUpperCase()}`
      }
    >
      {isLoading && <ChargingComponent />}
      {!isLoading && (
        <>
          {!isActive && (
            <ModalError
              title="No tienes conexiones disponibles"
              message="Por favor elimina una conexión existente o actualiza tu plan para poder crear una nueva."
              onClose={onClose}
            />
          )}
          {isActive && (
            <>
              {isSuccess ? (
                <ModalSuccess
                  title="Creado"
                  message="Se ha creado el registro correctamente, recarga la pagina para poder visualizar tu nueva conexión"
                />
              ) : (
                <FormAPI
                  provider={provider}
                  isActive={isActive}
                  newRow={newRow}
                  setIsSuccess={setIsSuccess}
                  setIsLoading={setIsLoading}
                />
              )}
            </>
          )}
        </>
      )}
    </ModalBG>
  );
}
