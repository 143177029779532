import { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { Module } from "components/common/Module";
import { TarjetCourses } from "./trainingComponets/TarjetCourses";
import { ChargingComponent } from "components/common/ChargingComponent";
import { GradiendBanner } from "components/common/GradiendBanner";
import { GetCourse } from "api/training/GetCourse";

export function Training() {
  const [courses, setCourses] = useState([]);
  const [userCourses, setUserCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const [courseStates, setCourseStates] = useState([]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 250);

    return () => clearTimeout(timeoutId);
  }, [searchTerm]);

  const getCourse = async () => {
    GetCourse({
      setLoading,
      setUserCourses,
      setCourses,
      setCourseStates,
    });
  };

  useEffect(() => {
    setLoading(true);
    getCourse();
  }, []);

  const getBlockedState = () => {
    return courseStates.find((state) => state.id === 2);
  };

  const filteredCourses = courses.filter((course) =>
    course?.nombre_curso
      ?.toLowerCase()
      .includes(debouncedSearchTerm.toLowerCase())
  );

  return (
    <Module>
      {loading ? (
        <ChargingComponent />
      ) : (
        <>
          <h1 className=" md:hidden text-2xl font-semibold text-sky-700 mb-4 text-center">
            Cursos disponibles
          </h1>

          <GradiendBanner>
            <div className="w-full flex justify-between">
              <h1 className="text-center text-white font-bold uppercase w-full my-6">
                ¡Aprende todos los días algo nuevo!
              </h1>
              <div>
                <img src="assets/gift/write.gif" className="w-20 2xl:w-36" />
              </div>
            </div>
          </GradiendBanner>

          <h1 className=" hidden md:block text-2xl font-semibold text-sky-700 mb-4">
            Cursos disponibles
          </h1>

          <div className="mb-4 relative">
            <input
              type="text"
              placeholder="Buscar cursos..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="p-2 pl-10 pr-4 border rounded-md w-full"
            />
            <FiSearch
              className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500"
              size={20}
            />
          </div>
          {filteredCourses.length === 0 ? (
            "No hay cursos disponibles"
          ) : (
            <div className="lg:grid lg:grid-cols-4 gap-4 p-4 slide-down-animation">
              {filteredCourses.map((course) => {
                const userCourse = userCourses.find((userCourse) => {
                  return userCourse.id_curso === course.id;
                });
                const isAssigned = userCourse !== undefined;

                let courseStatus = "No asignado";
                let courseColor = "#D1D5DB";

                if (isAssigned) {
                  courseStatus = userCourse.estado_curso.nombre_estado;
                  courseColor = userCourse.estado_curso.color_id;
                } else {
                  const blockedState = getBlockedState();
                  if (blockedState) {
                    courseStatus = blockedState?.nombre_estado;
                    courseColor = blockedState.color_id;
                  }
                }

                return (
                  <TarjetCourses
                    key={course.id}
                    to={isAssigned ? `/training/course/${course.id}` : "#"}
                    title={course.nombre_curso}
                    description={course.descripcion}
                    image={course.nombre_miniatura}
                    value={course.valor_curso}
                    status={courseStatus}
                    colorId={courseColor}
                    isLocked={!isAssigned}
                    courseId={course.id}
                  />
                );
              })}
            </div>
          )}
        </>
      )}
    </Module>
  );
}
