import { useState, useEffect } from "react";

import { Select } from "components/ServiceDesk/Select";
import { Request } from "./requests";
import { Requeriments } from "./requeriments";
import { Incidents } from "./incidents";
import { Changes } from "./changes";
import { Problems } from "./problems";

import { getTicketTypes } from "hooks/commonData";

export function CreateIssues() {
  const [listTicketType, setLisTicketType] = useState([]);
  const [selectedTicketType, setSelectedTicketType] = useState(-1);

  useEffect(() => {
    getTicketTypes().then((data) => {
      setLisTicketType(data);
    });
  }, []);

  const renderComponent = () => {
    switch (selectedTicketType) {
      case "1":
        return <Request ticketTypeId={selectedTicketType} />;
      case "2":
        return <Requeriments ticketTypeId={selectedTicketType} />;
      case "3":
        return <Changes ticketTypeId={selectedTicketType} />;
      case "4":
        return <Incidents ticketTypeId={selectedTicketType} />;
      case "5":
        return <Problems ticketTypeId={selectedTicketType} />;
      default:
        return (
          <p className="text-center p-12">
            Por favor selecciona un tipo de incidencia{" "}
          </p>
        );
    }
  };

  return (
    <>
      <div className="bg-white px-12 py-4 mx-12 mt-4 rounded-md">
        <Select
          title={"Tipo de incidencia"}
          options={listTicketType.map((e) => ({
            value: e.id,
            name: e.type_name,
          }))}
          onChange={setSelectedTicketType}
          required={true}
        />
      </div>
      <div className="mx-12 bg-white rounded-md mb-8">{renderComponent()}</div>
    </>
  );
}
