import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";

export function Dropdown({
  firstName,
  downloadAction,
  secondName,
  deleteAction,
  isCustom,
}) {
  return (
    <Menu
      as="div"
      className="relative inline-block text-left rounded-full border"
    >
      <div>
        <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-full bg-white p-2 text-sm font-semibold text-gray-900 shadow-xs ring-1 ring-inset ring-gray-300 hover:bg-gray-50 ">
          <EllipsisVerticalIcon
            aria-hidden="true"
            className="size-5 text-gray-400"
          />
        </MenuButton>
      </div>
      <MenuItems
        transition
        className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-hidden data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <div className="py-1">
          <MenuItem>
            <div
              onClick={deleteAction}
              className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-hidden cursor-pointer"
            >
              {firstName}
            </div>
          </MenuItem>
          {!isCustom && (
            <MenuItem>
              <div
                onClick={downloadAction}
                className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-hidden cursor-pointer"
              >
                {secondName}
              </div>
            </MenuItem>
          )}
        </div>
      </MenuItems>
    </Menu>
  );
}
