import { ChargingComponent } from "components/ChargingComponent";
import { useState } from "react";

export function ReportInformation({ name, link }) {
  const [abierto, setAbierto] = useState(false);
  return (
    <div className="rounded shadow-md mt-3 mb-2 mr-2 ml-2 p-2 bg-white transition-all slide-down-animation">
      <div
        className={`pr-2 hover:text-black ${
          abierto ? "text-black" : "text-slate-400"
        } transition-colors`}
      >
        <button
          type="button"
          onClick={() => setAbierto(!abierto)}
          className="flex justify-between border border-l-4 border-t-0 border-b-0 border-r-0 border-l-blue-900 w-full  hover:border-l-yellow-100 transition-colors outline-none"
        >
          <p className="pl-2  text-md">{name}</p>
        </button>
      </div>
      <div
        className={`grow mt-2 transition-all duration-300 ${
          abierto ? "opacity-100 scale-100" : "opacity-0 scale-95"
        }`}
        style={{
          visibility: abierto ? "visible" : "hidden",
          transform: abierto ? "scale(1)" : "scale(0.95)",
        }}
      >
        {abierto && (
          <>
            {link ? (
              <iframe
                title="g"
                className="w-full h-[500px]"
                src={link}
                allowFullScreen={true}
              ></iframe>
            ) : (
              <div className="flex justify-center">
                <ChargingComponent />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
