import { Link } from "react-router-dom";

const features = [
  {
    name: "Requerimientos",
    description:
      "Solicita un nuevo software o caracteristica para tu organización.",
    icon: "add-files",
  },
  {
    name: "Incidentes",
    description:
      "Informa un error o falla en tus productos para que sea solucionado.",
    icon: "warning",
  },
  {
    name: "Problemas",
    description: "Informanos de un fallo general o problema en tus productos.",
    icon: "bug",
  },
  {
    name: "Cambios",
    description:
      "Solicita un cambio de versión o actualización de tus productos.",
    icon: "spinner-arrow",
  },
  {
    name: "Solicitudes",
    description: "Necesitas que carguemos una base de información.",
    icon: "envelope",
  },
];

export default function Home() {
  return (
    <>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto lg:text-center p-4">
          <h2 className="text-base font-semibold leading-7 text-sky-600">
            Del dato al conocimiento
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Transforma tu organización
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Solicita nuevas funcionalidades o informanos de un problema en tus
            productos, <strong>¡Todo desde PlisBI!</strong>.
          </p>
        </div>
        <div className="mx-auto my-8 max-w-2xl sm:mt-10 lg:mt-8 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-4 lg:max-w-none lg:gap-y-4">
            {features.map((feature) => (
              <Link
                to="/requests"
                key={feature.name}
                className="bg-white border-md p-4 hover:bg-sky-50"
              >
                <dt className="flex items-center text-base font-semibold leading-7 text-sky-900">
                  <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-sky-700 mr-4">
                    <i className={`lni lni-${feature.icon} text-white`}></i>
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">
                  {feature.description}
                </dd>
              </Link>
            ))}
          </dl>
        </div>
      </div>
    </>
  );
}
