import { assignFreeCoursesToUser } from "hooks/commonData";
import { supabase } from "utils/SupabaseClient";

export async function createUser({
  id,
  firstName,
  lastName,
  email,
  phoneNumber,
  jobTitle,
  countryIsoCode,
  stateIsoCode,
  cityName,
  phoneCode,
}) {
  try {
    const { data, error } = await supabase
      .from("auth_users")
      .insert([
        {
          id,
          first_name: firstName,
          last_name: lastName,
          email,
          phone: phoneNumber,
          position_title: jobTitle,
          country: countryIsoCode,
          state: stateIsoCode,
          city: cityName,
          indicativo: phoneCode,
          activo: true,
        },
      ])
      .select();

    await assignFreeCoursesToUser(id);

    return handleDbResponse({ data, error });
  } catch (error) {
    console.error("Error creating user:", error.message);
    return { success: false, error: error.message };
  }
}

export async function createCompany({ user, name }) {
  const { data, error } = await supabase
    .from("auth_user_companies")
    .insert([
      {
        company_name: name,
        created_by: user.id,
        modified_by: user.id,
        email: user.email,
        indicativo_celular: user.indicativo,
        celular: user.phone,
        es_empresa: false,
        id_plan: 1,
      },
    ])
    .select();

  if (error) {
    console.log("error", error);
    return [];
  }
  return data;
}

export async function updateUser({ user, companyId }) {
  const { data, error } = await supabase
    .from("auth_users")
    .update({ company_id: companyId })
    .eq("id", user)
    .select();

  if (error) {
    console.log("error", error);
    return [];
  }
  return data;
}

export async function createSubcompany({ user, name }) {
  let companyName = name;
  if (!name) {
    companyName = `${user.first_name} ${user.last_name}`;
  }

  const companyData = await createCompany({ user, name: companyName });
  await updateUser({
    user: companyData[0].created_by,
    companyId: companyData[0].id,
  });

  const { data, error } = await supabase
    .from("auth_user_subcompanies")
    .insert([
      {
        company_id: companyData[0].id,
        razon_social: `${companyData[0].company_name} Principal`,
        nombre_responsable: user.first_name,
        apellido_responsable: user.last_name,
        correo_responsable: user.email,
        indicativo_celular: user.indicativo,
        celular_responsable: user.phone,
        is_principal: true,
        created_by: user.id,
        updated_by: user.id,
      },
    ])
    .select();

  if (error) {
    console.log("error", error);
    return [];
  }
  return data;
}

export async function createUserRole({ id }) {
  try {
    const { data, error } = await supabase
      .from("auth_user_roles")
      .insert([{ user_id: id, role_id: 11 }]);

    return handleDbResponse({ data, error });
  } catch (error) {
    console.error("Error creating user role:", error.message);
    return { success: false, error: error.message };
  }
}

export async function setChannelsInfo({ contactInformation }) {
  try {
    const { data, error } = await supabase
      .from("dim_canales_contacto")
      .select("id")
      .ilike("fuente", contactInformation);

    if (error) throw new Error(error.message);

    if (!data || data.length === 0) {
      throw new Error("Fuente de contacto no válida.");
    }

    return { success: true, data };
  } catch (error) {
    console.error("Error setting channel info:", error.message);
    return { success: false, error: error.message };
  }
}

export async function createChannelsInfo({ id, type }) {
  try {
    const { data, error } = await supabase
      .from("th_contact_chanels_info")
      .insert([{ id_user: id, id_fuente: type }]);

    console.log("data", data);
    console.log("error", error);

    return handleDbResponse({ data, error });
  } catch (e) {
    console.error("Error creating channel info:", e.message);
    return { success: false, error: e.message };
  }
}

export function handleDbResponse({ data, error }) {
  if (error) {
    return { success: false, error: error.message };
  }
  return { success: true, data };
}

export async function createUserSubcompanyRelation(id_user, id_subcompany) {
  try {
    const { data, error } = await supabase
      .from("th_user_subcompanies")
      .insert([
        {
          id_user: id_user,
          id_subcompanies: id_subcompany,
        },
      ])
      .select();

    if (error) {
      console.error(
        "Error inserting into th_user_subcompanies:",
        error.message
      );
      return { success: false, error: error.message };
    }

    console.log("Subcompany relation created:", data);
    return { success: true, data };
  } catch (error) {
    console.error("Error inserting user-subcompany relation:", error.message);
    return { success: false, error: error.message };
  }
}
