import { useState } from "react";

export function TarjetBudget({
  colorDefault,
  colorHovering,
  title,
  description,
  pathImage,
  onClick,
}) {
  const [color, setColor] = useState(colorDefault);

  return (
    <button
      onMouseEnter={() => setColor(colorHovering)}
      onMouseLeave={() => setColor(colorDefault)}
      onClick={onClick}
      className="relative rounded-lg text-center text-white overflow-hidden h-full "
    >
      <img
        src={pathImage}
        className="absolute inset-0 w-full object-cover"
        alt="background"
      />

      <h1
        className={`absolute w-full top-0 z-10 ${color} font-semibold text-lg p-3 rounded-t-lg transition-all uppercase`}
      >
        {title}
      </h1>

      <div
        className={`absolute w-full bottom-0 z-10 ${color} h-36 rounded-b-lg mt-40 transition-all px-3 py-4 text-md`}
      >
        {description}
      </div>
    </button>
  );
}
