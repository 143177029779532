import { supabase } from "utils/SupabaseClient";
import { convertToBlob } from "utils/Operations";
import { toast } from "sonner";
import fileDownload from "js-file-download";

export async function downloadFile({ path, fileName }) {
  const { data, error } = await supabase.storage
    .from("reportes")
    .download(path);

  if (error) {
    toast.error("Error al descargar el archivo.");
    return;
  }
  fileDownload(data, fileName);

  return data;
}

export async function uploadFile({ fileName, file }) {
  const fileConverted = await convertToBlob(file);

  console.log("fileConverted", fileConverted);

  const { data, error } = await supabase.storage
    .from("reportes")
    .upload(fileName, fileConverted, {
      contentType:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

  if (error) {
    toast.error("Error al subir el archivo");
    console.error(error);
  }

  return data;
}

export async function deleteFile({ fileName }) {
  const { data, error } = await supabase.storage
    .from("reportes")
    .remove([fileName]);

  if (error) {
    toast.error("Error al eliminar el archivo");
  }

  return data;
}

export function validateFileName({ type, year, month, fileName }) {
  switch (type) {
    case "yyyymm":
      return `${year}${month}_${fileName.replace(/ /g, "_")}.xlsx`;
    case "yyyy":
      return `${year}_${fileName.replace(/ /g, "_")}.xlsx`;
    case "na":
      return `${fileName}.xlsx`;
    default:
      return "error.xlsx";
  }
}

export function validateFile({ format, year, month }) {
  const isYearValid = year && year != -1;
  const isMonthValid = month && month != -1;

  switch (format) {
    case "yyyymm":
      return isYearValid && isMonthValid ? true : false;
    case "yyyy":
      return isYearValid ? true : false;
    case "na":
      return true;
    default:
      return false;
  }
}
