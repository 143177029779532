import { useEffect, useMemo, useState } from "react";

import { Table } from "./Table";
import { Text } from "components/tailwind/Text";
import { IntegrationsBanner } from "components/common/IntegrationsBanner";
import { ModalPlan } from "components/modal/ModalPlan";
import { Button } from "components/common/Button";

export function ConnectSource({
  submodule,
  setSubmodule,
  connections,
  setIsRecharge,
  isActive,
}) {
  useEffect(() => {
    setSubmodule(submodule);
  }, [submodule]);

  const [active, setActive] = useState(true);
  const [search, setSearch] = useState(null);
  const [newPlan, setNewPlan] = useState(false);

  const filteredConnections = useMemo(() => {
    if (!search) return connections;

    const lowerCaseSearchTerm = search.toLowerCase();
    return connections.filter(
      (item) =>
        item.auth_user_subcompanies.razon_social
          .toLowerCase()
          .includes(lowerCaseSearchTerm) ||
        item.proveedor.toLowerCase().includes(lowerCaseSearchTerm) ||
        item.tipo_archivo.toLowerCase().includes(lowerCaseSearchTerm) ||
        item.archivo.toLowerCase().includes(lowerCaseSearchTerm)
    );
  }, [search, connections]);

  const handleClick = () => {
    setNewPlan(!newPlan);
  };

  return (
    <div
      className={`overflow-y-auto h-[88vh] 2xl:max-h-[94vh] ${
        submodule === "Conexiones" ? "slide-down-animation" : "hidden"
      }`}
    >
      <IntegrationsBanner
        setActive={setActive}
        isConnections
        items={connections.map((item) => ({
          subcompany_id: item.auth_user_subcompanies.id,
          file: item.archivo,
        }))}
        setRecharge={setIsRecharge}
        isActive={isActive}
      />
      <div className="bg-white p-4 rounded-md mt-4">
        <div className="pb-4">
          <div className="flex justify-between items-center">
            <h2 className="text-sky-800 text-lg 2xl:text-xl font-semibold">
              Mis Archivos
            </h2>
            {!isActive && (
              <Button
                type="'button"
                className="bg-red-300 px-1 py-0.5 font-bold text-red-500 text-xs rounded-xs border border-red-400"
                text="Has usado todas las conexiones disponibles."
                onClick={handleClick}
              />
            )}
          </div>
          <Text
            title="Buscador"
            field="input-search"
            value={search}
            onChange={setSearch}
          />
        </div>
        <Table
          active={active}
          items={filteredConnections}
          setRecharge={setIsRecharge}
        />
      </div>
      <ModalPlan
        plan={{ plan_type: "Free", user_count: 5 }}
        users={1}
        newPlan={newPlan}
        onClose={handleClick}
      />
    </div>
  );
}
