import { useState, useEffect } from "react";
import { BackGroundSections } from "components/BackGroundSections";
import { ViewTable } from "../../components/ViewTable";
import { buttonColor } from "utils/Colors";
import { Module } from "components/Module";
import { getUserData, supabase } from "utils/SupabaseClient";
import { getUserFiles } from "hooks/commonData";
import { Deletefiles } from "api/deleteFiles/route";
import { ChargingComponent } from "components/ChargingComponent";

export function DeleteDatabase() {
  const tittles = [
    "Proyecto",
    "Archivo",
    "Fecha Conexión",
    "Responsable",
    "Acciones",
  ];
  const [dataArray, setDataArray] = useState([]);
  const [loading, setLoading] = useState(true);
  const [idUser, setIdUser] = useState("");

  useEffect(() => {
    getUserData().then((r) => setIdUser(r.id));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const files = await getUserFiles(idUser);
        const newArray = files.map((item) => {
          const date = new Date(item.created_at);
          const formattedDate = date.toLocaleDateString("es-CO", {
            day: "2-digit",
            month: "short",
            year: "numeric",
          });

          return {
            user_project: item.user_project,
            file_name: String(item.file_name)/* .split('/')[2], */,
            created_at: formattedDate,
            user: `${item.user.first_name} ${item.user.last_name}`,
          };
        });
        setDataArray(newArray);
      } catch (error) {
        console.error("Error fetching data from Supabase:", error);
      } finally {
        setLoading(false);
      }
    };

    if (idUser) {
      fetchData();
    }
  }, [idUser]);

  const handleDelete = async (archivo) => {
    await Deletefiles({ idUser, archivo, setDataArray, dataArray });
  };

  return (
    <Module title="Eliminar archivos" back={true} linkToBack={"/databases"}>
      <BackGroundSections>
        {loading ? (
          <ChargingComponent />
        ) : (
          <ViewTable
            rows={dataArray.map((row) => ({
              ...row,
              acciones: (
                <button
                  onClick={() => handleDelete(row.file_name)}
                  className={buttonColor}
                >
                  Eliminar
                </button>
              ),
            }))}
            tittles={tittles}
          />
        )}
      </BackGroundSections>
    </Module>
  );
}
