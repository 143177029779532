import { ConfirmDelete } from "components/common/ConfirmDelete";
import { ModalSuccess } from "./ModalSuccess";
import { ModalBG } from "components/common/ModalBG";
import { ChargingComponent } from "components/common/ChargingComponent";
import { useState } from "react";

export function DeleteModal({
  title,
  menssage,
  menssageSuccess,
  open,
  onClose,
  handleDelete,
  isSuccess,
  isLoading,
}) {
  const [isDelete, setIsDelete] = useState(false);

  return (
    <ModalBG title={title} open={open} onClose={onClose}>
      {!isLoading && (
        <>
          {!isSuccess && (
            <>
              <h1 className="block text-md font-medium leading-6 text-gray-900">
                {menssage}
              </h1>
              <ConfirmDelete message="para confirmar" onConfirm={setIsDelete} />
              <div className="flex justify-end pt-2">
                <button
                  onClick={handleDelete}
                  className="bg-red-500 px-4 py-2 rounded-md text-white hover:bg-red-400"
                  disabled={!isDelete}
                >
                  Eliminar
                </button>
              </div>
            </>
          )}
          {isSuccess && (
            <ModalSuccess
              title="Eliminado"
              message={menssageSuccess}
              onClose={onClose}
            />
          )}
        </>
      )}
      {isLoading && <ChargingComponent />}
    </ModalBG>
  );
}
