import { Button } from "components/common/Button";
import { Isotype } from "components/svg/Isotype";
import { downloadPicture, getUserDetails } from "hooks/commonData";
import { useEffect, useState } from "react";
import { getUserData } from "utils/SupabaseClient";
import { ModalChangeProfilePicture } from "./ModalChangeProfilePicture";

export function UserImage() {
  const [src, setSrc] = useState("");
  const [user, setUser] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    getUserData().then((user) => {
      setUser(user.id);
      getUserDetails(user.id, "image").then((r) => {
        {
          if (!r[0]?.profile_picture) {
            setSrc("");
          } else {
            downloadPicture("Perfiles", r[0]?.profile_picture).then((r) => {
              setSrc(r);
            });
          }
        }
      });
    });
  }, []);

  return (
    <div className="flex justify-center relative">
      <ModalChangeProfilePicture
        open={open}
        setOpen={() => setOpen(false)}
        user={user}
      />

      <div className="relative">
        {!src ? (
          <Isotype className={"my-10 ml-7 h-32"} />
        ) : (
          <img
            src={src}
            alt="User profile"
            className="rounded-full w-32 h-32 my-10 ml-7"
          />
        )}
        {/* Aquí posicionamos el botón sobre la imagen */}
        <div className="absolute top-0 ml-7 my-10 flex justify-center items-center opacity-0 hover:opacity-100 transition-opacity">
          <Button
            text={"Cambiar foto de perfil"}
            onClick={() => setOpen(true)}
            className={"w-32 h-32 rounded-full bg-[#1a3365] text-white"}
          />
        </div>
      </div>

      <div className="mt-20 ml-4">
        {/* Puedes quitar este div si no necesitas el botón por fuera de la imagen */}
      </div>
    </div>
  );
}
