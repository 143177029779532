export function ButtonSave({ HadleSubmit, className }) {
  return (
    <div className="flex justify-end">
      <button
        className={` bg-sky-800 border-2 text-white hover:bg-white border-sky-800  hover:text-sky-800  p-2 rounded-lg mt-4 transition-all`}
        onClick={HadleSubmit}
      >
        Guardar
      </button>
    </div>
  );
}
