import React from "react";
import jsPDF from "jspdf";
import img from "../utils/Certificado.png"

const generateCertification = (name, course) =>{
    const doc = jsPDF('l', 'mm', [1920, 1080]);

    doc.addImage(img, 'PNG', 0, 0, doc.internal.pageSize.getWidth(), doc.internal.pageSize.getHeight());

    doc.setFont("montserrat", "normal", 30)
    doc.setFontSize(150);

    const nameWidth = doc.getTextWidth(name);
    const nameX = (doc.internal.pageSize.getWidth() - nameWidth) / 2; 

    doc.text(name, nameX, 455);

    doc.setFont("montserrat", "bold", 30);
    doc.setFontSize(70); 
    const courseWidth = doc.getTextWidth(course);
    const courseX = (doc.internal.pageSize.getWidth() - courseWidth) / 2; 
    doc.text(course, courseX, 560, );

    doc.setFontSize(50); 
    doc.text(new Date().toLocaleDateString(), 350, 828)

    doc.save(`${course}.pdf`);
}

function CertificateGenerator(props) {
    return (
      <div>
        <button className="p-2 border rounded-md"
        onClick={() => generateCertification(props.name, props.course)}>Generar Certificado</button>
      </div>
    );
  }

export default CertificateGenerator