import { getOrganizationDetails, getUserDetails } from "hooks/commonData";
import { useEffect, useState } from "react";
import { getUserData } from "utils/SupabaseClient";

export function Organization() {
  const [userId, setUserId] = useState("");
  const [organizationUsers, setOrganizationUsers] = useState([]);

  const consultarUsuarios = userId !== "";

  useEffect(() => {
    getUserData().then((data) => {
      setUserId(data.id);
    });
  }, []);

  useEffect(() => {
    async function getCompanyUsers() {
      const userIds = await getUserDetails(userId, "ids");
      getOrganizationDetails(userIds.company_id, "users").then((data) => {
        setOrganizationUsers(data.users);
      });
    }

    if (consultarUsuarios) {
      getCompanyUsers();
    }
  }, [consultarUsuarios]);

  return (
    <ul role="list" className="divide-y divide-gray-100">
      {organizationUsers.map((person) => (
        <li
          key={person.email}
          className="flex bg-white rounded-md justify-between gap-x-6 p-4 mt-4"
        >
          <div className="flex min-w-0 gap-x-4">
            <img
              alt=""
              src={"assets/img/icons/principal.png"} // <-- PENDIENTE
              className="h-12 w-12 flex-none rounded-full bg-gray-50"
            />
            <div className="min-w-0 flex-auto">
              <p className="text-sm font-semibold leading-6 text-gray-900">
                {person.first_name} {person.last_name}
              </p>
              <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                {person.email}
              </p>
            </div>
          </div>
          <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
            <p className="text-sm leading-6 text-gray-900">
              {person.role_id.role_name}
            </p>
            {false ? ( // <-- PENDIENTE
              <div className="mt-1 flex items-center gap-x-1.5">
                <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                  <div className="h-1.5 w-1.5 rounded-full bg-red-500" />
                </div>
                <p className="text-xs leading-5 text-gray-500">De baja</p>
              </div>
            ) : (
              <div className="mt-1 flex items-center gap-x-1.5">
                <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                  <div className="h-1.5 w-1.5 rounded-full bg-emerald-500" />
                </div>
                <p className="text-xs leading-5 text-gray-500">Activo</p>
              </div>
            )}
          </div>
        </li>
      ))}
    </ul>
  );
}
