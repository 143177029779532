import { toast } from "sonner";
import { getUserData, supabase } from "utils/SupabaseClient";

export async function AddSection({ setSections, id_curso, setRecharge }) {
  const newSection = {
    id: Date.now(),
    title: "Nuevo módulo",
    videoUrl: "",
    subsections: [],
  };

  try {
    const user = await getUserData();
    if (!user) {
      toast.error("Usuario no autenticado.");
      return;
    }

    const id_user = user.id;

    const { data, error } = await supabase
      .from("dim_capacitacion_modulos")
      .insert([
        {
          nombre_modulo: newSection.title,
          curso_id: id_curso,
          uuid_creado: id_user,
          uuid_actualizado: id_user,
        },
      ]);

    if (error) {
      throw error;
    }

    if (!error) {
      setRecharge(true);
    }
  } catch (error) {
    toast.error("Error al añadir el módulo: " + error.message);
    setSections((prevSections) =>
      prevSections.filter((section) => section.id !== newSection.id)
    );
  }
}
