import { ViewIssueField } from "components/ServiceDesk/ViewIssueField";
import { ViewIssueHeader } from "components/ServiceDesk/ViewIssueHeader";
import { ViewIssueFieldStatus } from "components/ServiceDesk/ViewIssueFieldStatus";
import { ViewIssueDocuments } from "components/ServiceDesk/ViewIssueDocuments";
import { ViewIssueDescription } from "components/ServiceDesk/ViewIssueDescription";
import { Comment } from "components/ServiceDesk/FormComment";
import { TextArea } from "components/ServiceDesk/TextArea";

export function ViewIncidentUI({
  summary,
  description,
  createdBy,
  project,
  status,
  service,
  reason,
  steps,
  comments,
  documents,
  saveComment,
  setMessage,
}) {
  return (
    <div className="space-y-4 p-4 sm:px-8 sm:py-6 lg:p-4 xl:px-8 xl:py-6">
      <ViewIssueHeader
        summary={summary}
        issueType={2}
        text={"Información detallada de la solicitud."}
      />
      <div className="border border-white bg-white rounded-md p-4">
        <p>
          <strong>Usuario</strong> generó la solicitud el "fecha"
        </p>
        <div className="mt-4">
          <ViewIssueDescription label="Descripción" value={description} />
        </div>
      </div>
      <div className="grid grid-cols-6 gap-8 mt-6">
        <div className="col-span-4 border border-white rounded-md bg-white p-4">
          <div className="mt-4">
            <h2 className="text-md font-bold leading-6 text-gray-900">
              Actividad
            </h2>
            <h2 className="text-md font-bold leading-6 text-gray-900 mt-4">
              Comentarios
            </h2>
            <section>
              {comments.map((comment, index) => (
                <div key={index} className="mt-4 w-full">
                  <div className="flex">
                    <img
                      alt="PlisBI Icon"
                      src="../../assets/img/icons/principal.png"
                      className="h-8 w-8 rounded-full "
                    />
                    <div className="pl-4">
                      <p className="text-sm mt-1">
                        <strong>{comment.user}</strong> fecha
                      </p>
                      <p className="mt-2 text-sm">{comment.comment}</p>
                    </div>
                  </div>
                  <hr className="my-4" />
                </div>
              ))}
            </section>
            <Comment onClick={saveComment}>
              <TextArea
                title="Nuevo comentario"
                field="comment"
                description="Agrega un nuevo comentario..."
                onChange={setMessage}
              />
            </Comment>
          </div>
        </div>
        <div className="col-span-2 border border-white rounded-md bg-white p-4">
          <div className="mt-6 border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
              <ViewIssueFieldStatus label="Estado" statusId={status} />
              <ViewIssueField label="Creado por" value={createdBy} />
              <ViewIssueField label="Proyecto" value={project} />
              <ViewIssueField label="Servicio" value={service} />
              <ViewIssueField label="Motivo del error" value={reason} />
              <ViewIssueField
                label="Paso a paso de como ocurrio el error"
                value={steps}
              />
              <ViewIssueDocuments documents={documents} />
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}
