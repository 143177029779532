export function DateInput({ title, field, value, onChange, min, max, }) {
  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <div className="sm:col-span-6">
      <label
        htmlFor={field}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {title}
      </label>
      <div className="mt-2">
        <input
          id={field}
          name={field}
          type="date"
          value={value}
          onChange={handleChange}
          min={min && min}
          max={max && max}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-xs ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-sky-700 sm:max-w-xs sm:text-sm sm:leading-6"
        />
      </div>
    </div>
  );
}
