export function TextArea({
  title,
  description,
  field,
  onChange,
  value,
  required,
  className,
}) {
  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <div className={`col-span-full ${className}`}>
      <label
        htmlFor={field}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {title}{" "}
        <span className="text-red-500 text-lg font-bold">
          {required && !value && "*"}
        </span>
      </label>
      <div className="mt-2">
        <textarea
          id={field}
          name={field}
          onChange={handleChange}
          rows={3}
          className={`border border-slate-300 w-full p-2 rounded-md focus:outline-[#F1C40F] focus:border-none focus:transition-all`}
          defaultValue={""}
          value={value}
        />
      </div>
      <p className="mt-3 text-sm leading-6 text-gray-600">{description}</p>
    </div>
  );
}
