import { toast } from "sonner";
import { getUserData, supabase } from "utils/SupabaseClient";

export async function VideoUpload({
  files,
  id_curso,
  currentModule,
  setRecharge,
  setIsLoading,
  setUploadProgress,
  onUploadProgress, // Mantener el onUploadProgress
  setIsSuccess,
}) {
  const file = files[0];

  if (file) {
    const fileSizeInMB = file.size / 1024 / 1024;
    const MAX_FILE_SIZE_MB = 5000;

    if (fileSizeInMB > MAX_FILE_SIZE_MB) {
      toast.error(
        `El archivo excede el tamaño máximo de ${MAX_FILE_SIZE_MB} MB.`
      );
      return;
    }

    try {
      const user = await getUserData();
      if (!user) {
        toast.error("Usuario no autenticado.");
        return;
      }

      const id_user = user.id;
      const courseFolder = `${id_curso}`;
      const sanitizedFileName = file.name
        .toLowerCase()
        .replace(/\s+/g, "-")
        .replace(/[^\w\-\.]/g, "");
      const timestamp = Date.now();
      const filePath = `${courseFolder}/${String(
        currentModule.id
      )}/${timestamp}-${sanitizedFileName}`;

      // Subir archivo a Supabase
      const { data: uploadData, error: uploadError } = await supabase.storage
        .from("videos")
        .upload(filePath, file, {
          onUploadProgress: (progressEvent) => {
            const percent = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            setUploadProgress(percent); // Actualiza el progreso
            onUploadProgress(percent); // Ejecuta el onUploadProgress de tu código
          },
        });

      if (uploadError) {
        throw uploadError;
      }

      // Insertar metadata del video en la base de datos
      const { data: videoData, error: videoError } = await supabase
        .from("dim_capacitacion_videos")
        .insert([
          {
            id_modulo: currentModule,
            uuid_creado: id_user,
            uuid_actualizado: id_user,
            url: filePath,
            titulo_video: sanitizedFileName,
          },
        ]);

      if (videoError) {
        throw videoError;
      }

      toast.success("Video cargado correctamente.");
      setRecharge(true);
      setIsSuccess(true);
      setIsLoading(false); // Finaliza la carga
    } catch (error) {
      toast.error("Error al subir el video: " + error.message);
      setIsLoading(false); // En caso de error, detén la carga
    }
  }
}
