import { ModalBG } from "components/common/ModalBG";
import {
  getRoles,
  getSubCompañias,
  getSubCompañiasPorUsuario,
  getTypeDocument,
} from "hooks/commonData";
import { useEffect, useState } from "react";
import { StructureModal } from "./StructureModal";
import { UpdateUser } from "api/user-module/UpdateUser";

export function ModalEditUser({
  isOpenEdit,
  setIsOpenEdit,
  dataEdit,
  companyId,
  setRecharge,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const [typeDocumentData, setTypeDocumentData] = useState([]);
  const [rolesData, setRolesData] = useState([]);
  const [subCompanyData, setSubCompanyData] = useState([]);
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [subCompany, setSubCompany] = useState("");
  const [subCompanySelected, setSubCompanySelected] = useState("");
  const [documento, setDocumento] = useState("");
  const [typeDocument, setTypeDocument] = useState("");
  const [password, setPassword] = useState("");
  const [active, setIsActive] = useState(false);

  useEffect(() => {
    if (!isOpenEdit) {
      setTypeDocumentData("");
      setRolesData("");
      setSubCompanyData("");
      setName("");
      setLastName("");
      setPhone("");
      setEmail("");
      setSubCompany([]);
      setDocumento("");
      setTypeDocument("");
      setPassword("");
    }
  }, [isOpenEdit]);

  useEffect(() => {
    if (dataEdit) {
      setName(dataEdit?.first_name);
      setLastName(dataEdit?.last_name);
      setPhone(dataEdit?.phone);
      setEmail(dataEdit?.email);
      setDocumento(dataEdit?.documento);
      setTypeDocument(dataEdit?.tipo_documento);
      setIsActive(dataEdit?.activo);
    }
  }, [dataEdit]);

  useEffect(() => {
    if (success || error) {
      setRecharge(true);
      setIsLoading(false);
    }
    if (success) {
    }
  }, [success, error]);

  useEffect(() => {
    getTypeDocument().then((r) =>
      setTypeDocumentData(
        r.map((item) => ({ value: item.tipo, label: item.tipo }))
      )
    );
    getRoles().then((r) =>
      setRolesData(r.map((item) => ({ value: item.id, label: item.rol })))
    );
    getSubCompañias(companyId).then((r) => {
      setSubCompanyData(
        r.map((item) => ({ value: item.id, label: item.subcompañia }))
      );
    });
    getSubCompañiasPorUsuario(dataEdit?.id).then((r) => {
      setSubCompanySelected(
        r
          .filter(
            (value, index, self) =>
              index === self.findIndex((t) => t.id === value.id)
          )
          .map((item) => ({ value: item.id, label: item.subCompañia }))
      );
    });
  }, [dataEdit]);

  const updateUser = async () => {
    await UpdateUser({
      user: dataEdit?.id,
      active,
      name,
      lastName,
      phone,
      email,
      subCompany,
      documento,
      typeDocument,
      password,
      setIsLoading,
      setSuccess,
      setError,
      setIsOpenEdit,
      setRecharge,
    });
  };

  return (
    <ModalBG
      open={isOpenEdit}
      onClose={() => setIsOpenEdit(false)}
      title="Editar Usuario"
    >
      <StructureModal
        modal="edit"
        user={dataEdit?.id}
        active={active}
        setIsActive={setIsActive}
        isLoading={isLoading}
        setName={setName}
        name={name}
        setLastName={setLastName}
        lastName={lastName}
        setPhone={setPhone}
        phone={phone}
        setEmail={setEmail}
        email={email}
        rolesData={rolesData}
        subCompanyData={subCompanyData}
        subCompanySelected={subCompanySelected}
        setSubCompany={setSubCompany}
        subCompany={subCompany}
        typeDocumentData={typeDocumentData}
        setTypeDocument={setTypeDocument}
        typeDocument={typeDocument}
        setDocumento={setDocumento}
        documento={documento}
        setPassword={setPassword}
        password={password}
        form={updateUser}
      />
    </ModalBG>
  );
}
