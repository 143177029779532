import { useState, useEffect } from "react";

import { toast } from "sonner";
import { App } from "../modules/App";
import { ServiceDesk } from "./service-desk";
import { LayoutPMO } from "./service-desk/pmo/LayoutPMO";

import { supabase, getUserData } from "utils/SupabaseClient";
import { getUserModules } from "api/utils/NavbarItems";

export function Layout() {
  const [user, setUser] = useState();
  const [rol, setRol] = useState(-1);

  useEffect(() => {
    async function getUser() {
      const userData = await getUserData();

      const { data, error } = await supabase
        .from("users")
        .select("role_id, first_name, last_name")
        .eq("id", `${userData?.id}`);
      if (!error) {
        setUser(data[0]);
        setRol(data[0].role_id);
        toast.success(`Bienvenid@ ${data[0].first_name} ${data[0].last_name}!`);
      }
    }

    getUser();
  }, []);

  const [items, setItems] = useState([]);

  //TODO: Implementar mejor logica para cargar los modulos
  useEffect(() => {
    getUserModules(rol)
      .then((data) => {
        const formattedItems = data.map((module) => {
          return {
            icon: (
              <i
                className={`lni lni-${module.tmp_modulos_plataforma.icon}`}
              ></i>
            ),
            text: module.tmp_modulos_plataforma.modulo,
            active: false,
            alert: false,
            to: module.tmp_modulos_plataforma.path,
          };
        });
        setItems(formattedItems);
      })
      .catch((error) => {
        toast.error("Error al cargar los módulos", error);
        setItems([]);
      });
  }, [rol]);
  //TODO: Fin todo

  if (rol === 1 || rol === 4) {
    return (
      <App
        nombre={user ? user.first_name : "...cargando"}
        apellido={user ? user.last_name : "...cargando"}
        email={user ? user.email : "...cargando"}
        isTrainee={rol === 4}
        items={items}
      />
    );
  } else if (rol === 2) {
    return <ServiceDesk />;
  } else if (rol === 3) {
    return <LayoutPMO items={items}/>;
  }
}
