import { AllScreen } from "components/svg/AllScreenIcon";
import { PauseIcon } from "components/svg/PauseIcon";

const formatTime = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;
};

export function VideoControls({
  progress,
  duration,
  isPlaying,
  volume,
  playbackRate,
  isFullscreen,
  togglePlay,
  handleVolumeChange,
  handlePlaybackRateChange,
  toggleFullScreen,
  handleProgressChange,
}) {
  return (
    <div
      className={`slide-up-animation w-full absolute bottom-0 left-0  ${
        isPlaying
          ? "hidden group-hover:grid group-hover:grid-cols-1 transition-all"
          : ""
      }`}
    >
      <div className="relative w-full h-1 5 bg-gray-600 rounded-full mr-2 hover:bg-[#E0E2BE] outline-hidden transition-all">
        <input
          type="range"
          className="absolute top-0 left-0 w-full h-full opacity-0
              cursor-pointer "
          min="0"
          max={duration}
          step={1}
          value={progress}
          onChange={handleProgressChange}
        />
        <div
          className="absolute top-0 left-0 h-full bg-blue-500 rounded-full"
          style={{ width: `${(progress / duration) * 100}%` }}
        ></div>
      </div>
      <div
        className={`w-full px-4 py-[2px] flex items-center ☐ bg-black bg-black/70`}
      >
        <div className="flex items-center justify-between gap-3 w-full">
          <div>
            <button
              className="text-white focus: outline-hidden mt-[1px]"
              onClick={togglePlay}
            >
              <PauseIcon h={"15"} w={"15"} ispause={isPlaying} />
            </button>
            <span className="text-white mx-2">{`${formatTime(
              progress
            )}/${formatTime(duration)}`}</span>
          </div>
          <div>
            <div className="flex">
              <div className="flex items-center">
                <input
                  type="range"
                  className="w-[1px] hover:w-24 h-[3px] bg-white rounded-full hover:cursor-pointer hover:bg-[#E0E2BE] outline-hidden transition-all ml-2"
                  min={0}
                  max={1}
                  step={0.1}
                  value={volume}
                  onChange={handleVolumeChange}
                  style={{
                    WebkitAppearance: "none",
                    appearance: "none",
                  }}
                />
                <select
                  className="bg-black text-white px-2 py-1 rounded-md focus: outline-hidden ml-2"
                  value={playbackRate}
                  onChange={handlePlaybackRateChange}
                >
                  <option value="0.5" className="slide-up-animation">
                    0.5x
                  </option>
                  <option value="1" className="slide-up-animation">
                    1x
                  </option>
                  <option value="1.5" className="slide-up-animation">
                    1.5x
                  </option>
                  <option value="2" className="slide-up-animation">
                    2x
                  </option>
                </select>
              </div>
              <button
                className="text-white focus: outline-hidden ml-2"
                onClick={toggleFullScreen}
              >
                <AllScreen h={"25"} w={"25"} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
