import { AddIcon } from "components/SvgIcons/AddIcon";
import { SectionSubmodules } from "./SectionSubmodules";
import { Subsection } from "./SubSection";
import { useEffect, useState } from "react";
import { toast } from "sonner";
import { getVideos, addVideo } from "hooks/commonData";

export function Section({
  sectionId,
  title,
  onAddSubsection,
  onRemoveSection,
  onRemoveSubsection,
  setUrl,
  setVideo,
}) {

  const [videos, setVideos] = useState([]);
  const [newVideo, setNewVideo] = useState();

  const handleAddSubsection = () => {
    const newSubsection = { id: Date.now(), title: "New Subsection" };
    onAddSubsection(sectionId, newSubsection);
  };

  const handleFileChange = (event) =>{
    setNewVideo(event.target.files[0])
    // addVideo("videos", "pruebas", video)
  }

  const handleAddNewVideo = async () =>{
    try {
      await addVideo("videos", "pruebas", newVideo);
      toast.success("El video se cargo exitosamente");
    } catch (error) {
      toast.error(error.message);
    }
  }

  const handleRemoveSection = () => {
    onRemoveSection(sectionId);
  };

  const handleOnCLick = (e) => {
    getVideos("", e).then((r) => {
      setVideo(r);
    });
  };

  useEffect(() => {
    getVideos(sectionId, "", "").then((video) => {
      setVideos(video);
    })
  }, [])

  return (
    <div className="mb-5 p-2 border border-gray-300">
      <div className="flex border-b border-slate-400">
        <SectionSubmodules onClick={handleRemoveSection} text={"Módulo"} />
        <input
          defaultValue={title}
          placeholder="nombre del módulo..."
          className="border-none rounded-lg w-full"
        />
        
      </div>
{/* 
      <button
        onClick={handleAddSubsection}
        className="flex justify-between stroke-black w-full hover:bg-slate-100 hover:scale-105 rounded-lg p-2 mb-2 transition-all"
      >
        <label>Añadir Sección</label>
        <AddIcon h={"20"} w={"20"} />
      </button> */}
      <button
        onClick={handleAddNewVideo}
        className="flex justify-between stroke-black w-full hover:bg-slate-100 hover:scale-105 rounded-lg p-2 mb-2 transition-all"
      >
        <label>Añadir Video</label>
        <AddIcon h={"20"} w={"20"} />
        
      </button>
      <input 
      onClick={handleFileChange}
      type="file" accept="video/*" />
      <ul>
        {/* {subsections.map((subsection) => (
          <li key={subsection.id}>
            <Subsection
              sectionId={sectionId}
              subsection={subsection}
              onRemoveSubsection={onRemoveSubsection}
            />
          </li>
        ))} */}
        {
          videos && videos?.map((video) => (
            <li key={video.id}
              onClick={() => handleOnCLick(video.id)}
              className="cursor-pointer ml-5"
            >
              {video.titulo_video}
            </li>
          ))
        }
      </ul>
    </div>
  );
}
