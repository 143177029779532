import { BackGroundSections } from "components/BackGroundSections";
import { VideosCourseComponents } from "components/VideosCourseComponents";

export function VideoSelectedSection({url, video}) {
  return (
    <div className="col-span-2">
      <BackGroundSections>
        <VideosCourseComponents src={url}/>
      </BackGroundSections>
    </div>
  );
}
