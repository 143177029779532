import { useState, useEffect } from "react";
import { Country, State, City } from "country-state-city";
import { ButtonSave } from "components/common/ButtonSave";
import { ChargingComponent } from "components/common/ChargingComponent";
import { InputText } from "components/common/InputText";
import { SearchSelect } from "components/tailwind/SearchSelect";
import { fetchContactChannels } from "hooks/commonData";
import { InsertNewUser } from "api/login/Insert";
import { Subtitle } from "components/common/Subtitle";
import { StepsComponent } from "modules/budget/submodules/form/StepsComponent";
import { Button } from "components/common/Button";

export function SignupForm() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState(null);
  const [jobTitle, setJobTitle] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [contactInformation, setContactInformation] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [countries, setCountries] = useState([]);
  const [phoneCodes, setPhoneCodes] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [contactChannels, setContactChannels] = useState([]);

  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isNext, setIsNext] = useState(false);

  useEffect(() => {
    const countriesData = Country.getAllCountries();
    setCountries(
      countriesData.map((item) => ({
        value: { id: item.isoCode, name: item.name },
        label: item.name,
      }))
    );
    setPhoneCodes(
      countriesData.map((item) => ({
        name: item.name,
        phonecode: item.phonecode,
      }))
    );
  }, []);
  useEffect(() => {
    if (selectedCountry) {
      const statesData = State.getStatesOfCountry(selectedCountry.id);
      setStates(
        statesData.map((item) => ({
          value: { id: item.isoCode, name: item.name },
          label: item.name,
        }))
      );
      setSelectedState(null);
      setCities([]);
      setSelectedCity(null);
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState) {
      const citiesData = City.getCitiesOfState(
        selectedCountry.id,
        selectedState.id
      );

      setCities(
        citiesData.map((item) => ({
          label: item.name,
          value: item.name,
        }))
      );
      setSelectedCity(null);
    }
  }, [selectedState, selectedCountry]);

  useEffect(() => {
    fetchContactChannels().then((r) => {
      setContactChannels(
        r.map((item) => ({
          value: { id: item.id, name: item.name },
          label: item.name,
        }))
      );
    });
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    await InsertNewUser({
      setIsSuccess,
      setIsLoading,
      selectedCountry,
      selectedState,
      selectedCity,
      selectedCountry,
      phoneCodes,
      firstName,
      lastName,
      phoneNumber,
      jobTitle,
      companyName,
      contactInformation,
    });
  };

  const [cont, setcont] = useState(0);

  const nextSections = () => {
    setcont(cont + 1);
  };

  const BackSections = () => {
    setcont(cont - 1);
  };

  const steps = [
    { label: "", value: 1 },
    { label: "", value: 2 },
    { label: "", value: 3 },
    { label: "", value: 4 },
  ];

  useEffect(() => {
    switch (cont) {
      case 0:
        setIsNext(true);
        break;
      case 1:
        if (!firstName || !lastName || !companyName) {
          setIsNext(false);
        } else {
          setIsNext(true);
        }
        break;
      case 2:
        if (!jobTitle || !selectedCountry) {
          setIsNext(false);
        } else {
          setIsNext(true);
        }
        break;
      case 3:
        if (!contactInformation || !phoneNumber) {
          setIsNext(false);
        } else {
          setIsNext(true);
        }
        break;
      default:
        setIsNext(true);
        break;
    }
  }, [
    cont,
    firstName,
    lastName,
    companyName,
    jobTitle,
    selectedCountry,
    selectedState,
    selectedCity,
    contactInformation,
    phoneNumber,
  ]);

  return (
    <>
      {isLoading ? (
        <div className="col-span-2 text-center py-4">
          <ChargingComponent />
        </div>
      ) : (
        <div className="max-w-3xl mx-auto bg-white shadow-lg rounded-xl p-8">
          <div className="flex justify-start mb-6">
            <img
              src="assets/img/horizontal/principal.png"
              alt="login-image"
              className="w-36"
            />
          </div>

          <div className="mx-4">
            <StepsComponent countSteps={steps} currentStep={cont} />
          </div>

          <div className={cont === 0 ? "appear-animation" : "hidden"}>
            <div className="text-xl text-center mb-2 mt-16">
              <Subtitle text="¡Bienvenido!" size="3xl" />
            </div>

            <p className="text-gray-600 text-sm mb-8 text-center">
              Complete el registro de su cuenta para continuar en la plataforma
            </p>
          </div>

          <form onSubmit={handleSubmit} className="appear-animation mt-16">
            <div className={cont === 1 ? "appear-animation" : "hidden"}>
              <div className="grid grid-cols-1 gap-2 md:grid-cols-2 ">
                <InputText
                  placeholder="Nombre"
                  setValue={setFirstName}
                  value={firstName}
                  required={true}
                />

                <InputText
                  placeholder="Apellido"
                  setValue={setLastName}
                  value={lastName}
                  required={true}
                />
              </div>

              <InputText
                placeholder="Nombre de la Empresa"
                setValue={setCompanyName}
                value={companyName}
                required
              />
            </div>

            <div
              className={
                cont === 2
                  ? "appear-animation grid grid-cols-1 gap-2 md:grid-cols-2 "
                  : "hidden"
              }
            >
              <InputText
                placeholder="Profesión"
                setValue={setJobTitle}
                value={jobTitle}
                required
              />

              <SearchSelect
                title="País"
                items={countries}
                setOption={setSelectedCountry}
                option={selectedCountry}
                required
              />

              <SearchSelect
                title="Departamento/Estado/Provincia"
                items={states}
                setOption={setSelectedState}
                option={selectedState}
              />

              <SearchSelect
                title="Ciudad"
                items={cities}
                setOption={setSelectedCity}
                option={selectedCity}
              />
            </div>
            <div
              className={
                cont === 3
                  ? "appear-animation grid grid-cols-1 gap-2 md:grid-cols-2 "
                  : "hidden"
              }
            >
              <InputText
                placeholder="Número de Teléfono (Opcional)"
                setValue={setPhoneNumber}
                value={phoneNumber}
                required
              />

              <SearchSelect
                title="¿Cómo nos encontraste?"
                items={contactChannels}
                setOption={setContactInformation}
                option={contactInformation}
                required
              />
            </div>
          </form>
          <div
            className={`mt-4 flex ${
              cont === 0 ? "justify-end" : "justify-between"
            }`}
          >
            {cont === 1 || cont === 2 || cont === 3 ? (
              <div className="mt-4">
                <Button onClick={BackSections} text={"atras"} type="button" />
              </div>
            ) : (
              <></>
            )}
            {cont === 0 || cont === 1 || cont === 2 ? (
              <div className={isNext ? "appear-animation mt-4" : "hidden"}>
                <Button
                  onClick={nextSections}
                  text={"siguiente"}
                  type="button"
                />
              </div>
            ) : (
              <div className={isNext ? "appear-animation mt-4" : "hidden"}>
                <ButtonSave HadleSubmit={handleSubmit} />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
