import { BackGroundSections } from "components/BackGroundSections";
import { Module } from "components/Module";
import { VideosCourseComponents } from "components/VideosCourseComponents";
import {
  DownloadFile,
  downloadVideo,
  getModule,
  getVideos,
} from "hooks/commonData";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DropDownModuleInVideo } from "./DropDownModuleInVideo";
import fileDownload from "js-file-download";
import { Button } from "components/Button";
import { AutoHeigth } from "components/AutoHeigth";
import { DownloadIcon } from "components/SvgIcons/DownloadIcon";

export function VideosModules() {
  const { id_course } = useParams();
  const { id_video } = useParams();
  const [video, setVideo] = useState([{}]);
  const [videosUrl, setVideosUrl] = useState([{}]);
  const [mainVideo, setMainVideo] = useState(id_video);
  const [modules, setModules] = useState([{}]);

  useEffect(() => {
    getVideos("", mainVideo).then((r) => {
      setVideo(r);
      downloadVideo(r[0].url).then((r) => setVideosUrl(r));
    });
    getModule(id_course).then((r) => setModules(r));
  }, [mainVideo, id_course]);

  const downloadFile = async () => {
    const file = await DownloadFile("cursos", "insumos/Insumo_BI.zip");
    fileDownload(file, "Power BI Insumo.zip");
  };

  const coursename = video[0]?.info_curso?.curso_id?.nombre_curso;
  const VideoName = video[0]?.titulo_video;

  return (
    <Module
      title={`Curso ${coursename}`}
      back={true}
      linkToBack={`/training/course/${id_course}`}
    >
      <div className="grid grid-cols-3 slide-down-animation">
        <div className="col-span-2">
          <BackGroundSections>
            <VideosCourseComponents src={videosUrl} />
          </BackGroundSections>
          <BackGroundSections>
            <div className="flex justify-between">
              <h2 className="text-xl font-semibold ">{VideoName}</h2>
              <div className="">
                <Button
                  text={"Descargar Insumos"}
                  onClick={downloadFile}
                  isIcon={true}
                  icon={<DownloadIcon h={"20"} w={"20"} />}
                />
              </div>
            </div>
          </BackGroundSections>
        </div>
        <BackGroundSections>
          {/* <AutoHeigth maxPx={"290px"}> */}
          <div className="overflow-y-scroll">
            {modules.map((item, index) => (
              <DropDownModuleInVideo
                module={item.nombre_modulo}
                id_module={item.id}
                id_video={id_video}
                setMainVideo={setMainVideo}
                key={index}
              />
            ))}
          </div>
          {/* </AutoHeigth> */}
        </BackGroundSections>
      </div>
    </Module>
  );
}
