export function TextArea({ title, description, field, onChange, value }) {
  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <div className="col-span-full">
      <label
        htmlFor={field}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {title}
      </label>
      <div className="mt-2">
        <textarea
          id={field}
          name={field}
          onChange={handleChange}
          rows={3}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-700 sm:text-sm sm:leading-6"
          defaultValue={""}
          value={value}
        />
      </div>
      <p className="mt-3 text-sm leading-6 text-gray-600">{description}</p>
    </div>
  );
}
