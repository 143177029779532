import { useState, useEffect } from "react";

import { Table } from "./Table";
import { Button } from "components/common/Button";
import { ModalPlan } from "components/modal/ModalPlan";
import { BackGroundSections } from "components/common/BackGroundSections";
import { IntegrationsBanner } from "components/common/IntegrationsBanner";

export function Integrations({
  submodule,
  setSubmodule,
  connections,
  setIsRecharge,
  isActive,
}) {
  useEffect(() => {
    setSubmodule(submodule);
  }, [submodule]);

  const [newPlan, setNewPlan] = useState(false);

  const handleClick = () => {
    setNewPlan(!newPlan);
  };

  return (
    <div
      className={`${
        submodule === "Integraciones" ? "slide-down-animation" : "hidden"
      }`}
    >
      <IntegrationsBanner setRecharge={setIsRecharge} isActive={isActive} />
      <BackGroundSections>
        <div className="flex justify-between items-center">
          <label className="text-sky-700 text-xl font-semibold">
            Mis Conexiones
          </label>
          {!isActive && (
            <Button
              type="'button"
              className="bg-red-300 px-1 py-0.5 font-bold text-red-500 text-xs rounded-xs border border-red-400"
              text="Has usado todas las conexiones disponibles."
              onClick={handleClick}
            />
          )}
        </div>
        <Table items={connections} setRecharge={setIsRecharge} />
      </BackGroundSections>
      <ModalPlan
        plan={{ plan_type: "Free", user_count: 5 }}
        users={1}
        newPlan={newPlan}
        onClose={handleClick}
      />
    </div>
  );
}
