import { useEffect, useState } from "react";

import { TbApi } from "react-icons/tb";
import { RiFileExcel2Fill } from "react-icons/ri";
import {
  BackwardIcon,
  InformationCircleIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";

import { formatDate } from "utils/Operations";

import { TD } from "components/table/TD";
import { Row } from "components/table/Row";
import { Table } from "components/table/Table";
import { Button } from "components/common/Button";
import { FormAPI } from "../integrations/FormAPI";
import { downloadPicture } from "hooks/commonData";
import { InputText } from "components/common/InputText";
import { FormConnection } from "../connections/FormConnection";
import { FileUploadComponent } from "components/tailwind/FileUploadComponent";

const defaultImg =
  "https://hiflqitghybyfrjspcdu.supabase.co/storage/v1/object/public/miniaturaReportes/miniaturas/Default/MiniatureDefault.png";
const connectionsDetail = [
  {
    connection: "API",
    title: "Conexión API",
    decription:
      "Permite realizar la conexión de forma directa mediante una API, para ello se requiere de una clave de acceso y un token de seguridad.",
  },
  {
    connection: "EXCEL",
    title: "Cargar Archivos Excel",
    decription:
      "Permite la carga de archivos de Excel, para ello se requiere de un archivo con extensión .xls o .xlsx.",
  },
];

export function ReportDetail({
  item,
  isEdit,
  setSelectedFiles,
  setLength,
  setName,
  setDescription,
  name,
  description,
  setLink,
  link,
}) {
  const [miniature, setMiniature] = useState("");

  useEffect(() => {
    if (item) {
      setName(item.title);
      setDescription(item.description);
      setLink(item.url);
      downloadPicture("miniaturaReportes", item.miniature).then((r) => {
        setMiniature(r);
      });
    }
  }, [item]);

  return (
    <>
      <div
        className={
          isEdit
            ? "overflow-y-auto max-h-full md:max-h-80 2xl:max-h-full appear-animation"
            : "grid grid-cols-3 gap-4 items-center appear-animation"
        }
      >
        <div className={!isEdit && "col-span-1 flex justify-center"}>
          <div className={isEdit ? "appear-animation" : "hidden"}>
            <FileUploadComponent
              text="Subir miniatura"
              redText=".png y .jpg"
              accept=".png, .jpg"
              setLength={setLength}
              setSelectedFilesProps={setSelectedFiles}
            />
          </div>
          <div
            className={`${
              item.miniature && "border-2 border-slate-300 border-dashed"
            } ${!isEdit ? "appear-animation" : "hidden"}`}
          >
            <img
              src={item.miniature ? miniature : defaultImg}
              alt={item.title}
              className="self-start"
            />
          </div>
        </div>
        <div className="col-span-2 text-gray-700">
          <section className="mb-4">
            {!isEdit ? (
              <p className="text-base">
                <strong className="text-sky-700">Nombre: </strong>
                {item.title}
              </p>
            ) : (
              <div className="border-t border-slate-400 mt-2 grid md:grid-cols-2 md:gap-2">
                <InputText
                  placeholder="Nombre"
                  setValue={setName}
                  value={name}
                />
                <InputText
                  placeholder="Descripción"
                  setValue={setDescription}
                  value={description}
                />
                <InputText
                  placeholder="Link del reporte"
                  setValue={setLink}
                  value={link}
                />
              </div>
            )}
            <p className={!isEdit ? "text-base appear-animation" : "hidden"}>
              <strong className="text-sky-700">Última actualización: </strong>
              {formatDate(item.updated_at)}
            </p>
            {!isEdit && (
              <p className="text-base">
                <strong className="text-sky-700">Descripción: </strong>
                {item.description}
              </p>
            )}
          </section>
          {/* TODO: Implementar cuando este PlisBI Proyectos
          <section className={!isEdit ? "appear-animation" : "hidden"}>
            <h3 className="text-sky-700 font-bold">Orígenes de datos:</h3>
            <Table nameRows={[{ label: "Conexión" }, { label: "Tipo" }]}>
              {item?.templates.map((template) => (
                <Row key={template?.data_source?.id}>
                  <TD item={template?.data_source?.directory} />
                  <TD item={template?.data_source?.type} />
                </Row>
              ))}
            </Table>
            <h3 className="text-sky-700 font-bold pt-4">Documentos:</h3>
            {item.id_proveedor === 48 && (
              <p className="mb-4">
                Informe personalizado, no hay documentación.
              </p>
            )}
            {!(item.id_proveedor === 48) && (
              <ViewDocFiles submodule="Documentos" setSubmodule={() => {}} />
            )}
          </section> */}
        </div>
      </div>
    </>
  );
}

export function ReportTemplate({
  item,
  onClose,
  connectionsActive,
  globalRecharge,
}) {
  const [step, setStep] = useState(1);
  const [connection, setConnection] = useState(null);

  const handleClose = () => {
    onClose();
    setStep(1);
    globalRecharge(true);
  };
  return (
    <>
      <Steps actualStep={step} />
      {step === 1 && (
        <FirstStep
          item={item}
          connection={connection}
          setConnection={setConnection}
          onClick={() => {
            setStep(2);
          }}
        />
      )}
      {step === 2 && (
        <SecondStep
          item={item}
          connection={connection}
          connectionsActive={connectionsActive}
          onClick={() => {
            setStep(3);
          }}
          returnStep={() => {
            setStep(1);
          }}
        />
      )}
      {step === 3 && <ThirdStep item={item} onClick={handleClose} />}
    </>
  );
}

function ActionButtons({ setConnection }) {
  const [isDetails, setIsDetails] = useState(null);
  const [details, setDetails] = useState(null);
  const [optionSelected, setOptionSelected] = useState(null);

  return (
    <>
      {isDetails && (
        <div className="border border-zinc-300 rounded-md p-4">
          <div className="flex justify-between items-center w-full">
            <h3 className="text-sm font-semibold leading-6 text-sky-700">
              {details.title}
            </h3>
            <Button
              type="button"
              onClick={() => {
                setIsDetails(false);
                setDetails(null);
              }}
              className="bg-red-500 p-1 text-white rounded-lg transition-colors"
              icon={<XMarkIcon className="h-4" />}
            />
          </div>
          <div className="text-gray-700 mt-2">
            <p className="text-xs">{details.decription}</p>
          </div>
        </div>
      )}
      {!isDetails && (
        <>
          <h3 className="font-bold">Selecciona una conexión</h3>
          <div
            onClick={() => {
              setConnection("EXCEL");
              setOptionSelected("EXCEL");
            }}
            className={`flex w-full border border-zinc-300 rounded-md text-emerald-600 hover:bg-yellow-50 hover:text-yellow-500 cursor-pointer ${
              optionSelected === "EXCEL" && "bg-sky-700 text-yellow-500"
            }`}
          >
            <div className="flex space-x-4 items-center grow text-2xl p-1">
              <div className="h-12 w-12 flex items-center justify-center rounded-full bg-gray-50">
                <RiFileExcel2Fill />
              </div>
              <h3
                className={`text-xs font-semibold leading-6 text-sky-700 ${
                  optionSelected === "EXCEL" && " text-yellow-400"
                }`}
              >
                Cargar Archivos Excel
              </h3>
            </div>
            <div className="px-1 flex items-center justify-between">
              <Button
                type="button"
                onClick={() => {
                  setDetails(connectionsDetail[1]);
                  setIsDetails(true);
                }}
                className="bg-sky-800 p-2 text-white rounded-lg transition-colors"
                icon={<InformationCircleIcon className="h-4" />}
              />
            </div>
          </div>
          <div
            onClick={() => {
              setConnection("API");
              setOptionSelected("API");
            }}
            className={`flex w-full border border-zinc-300 rounded-md text-emerald-600 hover:bg-yellow-50 hover:text-yellow-500 cursor-pointer ${
              optionSelected === "API" && "bg-sky-700 text-yellow-500"
            }`}
          >
            <div className="flex space-x-4 items-center grow text-2xl p-1">
              <div className="h-12 w-12 flex items-center justify-center rounded-full bg-gray-50">
                <TbApi />
              </div>
              <h3
                className={`text-xs font-semibold leading-6 text-sky-700 ${
                  optionSelected === "API" && " text-yellow-400"
                }`}
              >
                Conexión API
              </h3>
            </div>
            <div className="px-1 flex items-center justify-between">
              <Button
                type="button"
                onClick={() => {
                  setIsDetails(true);
                  setDetails(connectionsDetail[0]);
                }}
                className="bg-sky-800 p-2 text-white rounded-lg transition-colors"
                icon={<InformationCircleIcon className="h-4" />}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}

function FirstStep({ item, connection, setConnection, onClick }) {
  return (
    <>
      <div className="grid grid-cols-3 gap-4 text-sm mt-2">
        <div className="col-span-2 mb-4 text-gray-600">
          <p className="font-bold uppercase text-gray-500">
            <strong className="text-sky-700">Nombre: </strong>
            {item.title}
          </p>
          <p>
            <strong className="text-sky-700 uppercase">
              Última actualización:{" "}
            </strong>
            {formatDate(item.updated_at)}
          </p>
          <p>
            <strong className="text-sky-700 uppercase">Descripción: </strong>
            {item.description}
          </p>
          <p>
            <strong className="text-sky-700 uppercase">
              Orígenes de datos:{" "}
            </strong>
            Este informe se basa en la información obtenida de la plataforma, y
            tendrás la posibilidad de elegir la forma en que deseas conectarte:
          </p>
          <div className="text-sm text-gray-500 py-4">
            <p className="font-bold">Opción 1: Cargar archivos de Excel</p>
            <p className="font-bold">Opción 2: Conexión directa mediante API</p>
          </div>
          <Table nameRows={[{ label: "Nombre" }, { label: "Tipo" }]}>
            {item.templates.map((template) => (
              <Row key={template.data_source?.id}>
                <TD item={template.data_source?.tipo} />
                <TD item="Archivo Excel" />
              </Row>
            ))}
          </Table>
        </div>
        <div className="col-span-1 space-y-2 flex flex-col justify-center items-center">
          <ActionButtons setConnection={setConnection} />
        </div>
      </div>
      {!connection && (
        <div className="bg-red-500 text-center px-2 py-2 mt-2 rounded-xs">
          <p className="text-white text-xs font-bold">
            ¡Selecciona un tipo de conexión!
          </p>
        </div>
      )}
      {connection && (
        <div className="flex justify-end col-span-3">
          <Button
            className="bg-sky-800 p-2 text-white rounded-lg transition-colors"
            text="Siguiente"
            onClick={onClick}
          />
        </div>
      )}
    </>
  );
}

function SecondStep({
  item,
  connection,
  onClick,
  returnStep,
  connectionsActive,
}) {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      onClick();
    }
  }, [isSuccess]);

  return (
    <>
      <div
        className="flex items-center bg-sky-800 hover:bg-yellow-400 mt-2 p-2 text-white rounded-lg transition-colors cursor-pointer w-auto"
        onClick={returnStep}
      >
        <BackwardIcon className="h-4 pr-2" />
        Atras
      </div>
      {connection === "EXCEL" && (
        <FormConnection
          files={[]}
          isActive={connectionsActive} //TODO: Implementar conexiones de BI
          provider={item.templates[0].data_source.proveedor} //TODO: Implementar una mejor forma
          isSuccess={isSuccess}
          setIsSuccess={setIsSuccess}
          setIsLoading={setIsLoading}
        />
      )}
      {connection === "API" && (
        <FormAPI
          provider={item.id_proveedor == 44 ? "siigo" : "quickbooks"} //TODO: Implementar proveedor: {item.id_proveedor}
          newRow={false}
          setIsLoading={setIsLoading}
          setIsSuccess={setIsSuccess}
        />
      )}
    </>
  );
}

function ThirdStep({ onClick }) {
  return (
    <>
      <div className="flex space-x-4 py-4">
        <div className="basis-1/3 items-center justify-center flex py-4">
          <img
            className="object-contain w-40 h-40"
            src="assets/img/items/bi-image.svg"
            alt="bi-img"
          />
        </div>
        <div className="basis-2/3 space-y-4 text-center items-center justify-center flex flex-col">
          <h3 className="text-xl font-bold uppercase">
            ¡Gracias por tu solicitud!
          </h3>
          <p className="text-base">
            Estamos trabajando en la integración de tus tados. Haz clic aquí
            para seguir el progreso.
          </p>
        </div>
      </div>
      <div className="flex justify-end col-span-3">
        <Button
          className="bg-sky-800 p-2 text-white rounded-lg transition-colors"
          text="Cerrar"
          onClick={onClick}
        />
      </div>
    </>
  );
}

function Steps({ actualStep }) {
  return (
    <div className="grid grid-cols-3 gap-4 border border-zinc-300 rounded-md p-1">
      <Step
        step="1"
        text="¡Conéctate de Forma Rápida!, Elige tu Opción"
        actualStep={actualStep}
      />
      <Step
        step="2"
        text="¡Carga tu Archivo o Activa tu API en un instante!"
        actualStep={actualStep}
      />
      <Step
        step="3"
        text="¡Listo para Recibir tu Solicitud! Nosotros lo Conectamos"
        actualStep={actualStep}
      />
    </div>
  );
}

function Step({ step, text, actualStep }) {
  const active = actualStep >= step;
  return (
    <div className={active ? "text-sky-700" : "text-gray-500"}>
      <hr
        className={
          active ? "border-2 border-sky-700" : "border-2 border-zinc-300"
        }
      />
      <div className="flex text-xs">
        <div className="flex-none font-bold">PASO {step}</div>
        <div className="flex-1 text-center font-medium">{text}</div>
      </div>
    </div>
  );
}
