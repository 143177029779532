import { useEffect, useState } from "react";
import { getAllUserProjects } from "hooks/commonData";
import { getUserData } from "utils/SupabaseClient";

export function Projects() {
  const [userId, setUserId] = useState("");
  const [projectDetail, setProjectDetail] = useState([]);

  const consultarProyectos = userId !== "";

  useEffect(() => {
    getUserData().then((data) => {
      setUserId(data.id);
    });
  }, []);

  useEffect(() => {
    if (consultarProyectos) {
      getAllUserProjects(userId).then((data) => {
        setProjectDetail(data);
      });
    }
  }, [consultarProyectos]);

  return (
    <section>
      <header class="rounded-md bg-white space-y-4 p-4 sm:px-8 sm:py-6 lg:p-4 xl:px-8 xl:py-6 mt-4">
        <div class="flex items-center justify-between">
          <h2 class="font-semibold text-slate-900">Proyectos</h2>
          <a
            href="#"
            class="hover:bg-sky-600 group flex items-center rounded-md bg-sky-700 text-white text-sm font-medium pl-2 pr-3 py-2 shadow-sm"
          >
            <svg
              width="20"
              height="20"
              fill="currentColor"
              class="mr-2"
              aria-hidden="true"
            >
              <path d="M10 5a1 1 0 0 1 1 1v3h3a1 1 0 1 1 0 2h-3v3a1 1 0 1 1-2 0v-3H6a1 1 0 1 1 0-2h3V6a1 1 0 0 1 1-1Z" />
            </svg>
            New
          </a>
        </div>
        <form class="group relative">
          <svg
            width="20"
            height="20"
            fill="currentColor"
            class="absolute left-3 top-1/2 -mt-2.5 text-slate-400 pointer-events-none group-focus-within:text-sky-700"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
            />
          </svg>
          <input
            class="focus:ring-2 focus:ring-sky-700 focus:outline-none appearance-none w-full text-sm leading-6 text-slate-900 placeholder-slate-400 rounded-md py-2 pl-10 ring-1 ring-slate-200 shadow-sm"
            type="text"
            aria-label="Filter projects"
            placeholder="Filtrar proyectos..."
          />
        </form>
      </header>
      <ul role="list" className="divide-y divide-gray-100">
        {projectDetail.map((project) => (
          <li
            key={project.description}
            className="flex bg-white rounded-md justify-between gap-x-6 p-4 mt-4"
          >
            <div className="flex min-w-0 gap-x-4">
              <img
                alt=""
                src={"assets/img/icons/principal.png"} // <-- PENDIENTE
                className="h-12 w-12 flex-none rounded-full bg-gray-50"
              />
              <div className="min-w-0 flex-auto">
                <p className="text-sm font-semibold leading-6 text-gray-900">
                  {project.project_name}
                </p>
                <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                  {project.description}
                </p>
              </div>
            </div>
            <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
              <p className="text-sm leading-6 text-gray-900">
                {project.projects_services
                  .map((item) => item.services.item_value)
                  .join(", ")}
              </p>
              {false ? ( // <-- PENDIENTE
                <div className="mt-1 flex items-center gap-x-1.5">
                  <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                    <div className="h-1.5 w-1.5 rounded-full bg-emerald-500" />
                  </div>
                  <p className="text-xs leading-5 text-gray-500">Completo</p>
                </div>
              ) : (
                <div className="mt-1 flex items-center gap-x-1.5">
                  <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                    <div className="h-1.5 w-1.5 rounded-full bg-blue-500" />
                  </div>
                  <p className="text-xs leading-5 text-gray-500">Activo</p>
                </div>
              )}
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
}
