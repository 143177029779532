import React, { useEffect, useState, Suspense } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  getModule,
  getCountVideos,
  getCountVideosViewed,
  getDataUser,
  downloadImage,
  DownloadFile,
} from "hooks/commonData";
import { getUserData, supabase } from "utils/SupabaseClient";
import fileDownload from "js-file-download";
import { useRoleContext } from "modules/auth/admin/RoleContex";
import { DropDownModule } from "modules/training/course/modules/DropDownModule";
import { Module } from "components/common/Module";
import { AutoHeigth } from "components/common/AutoHeigth";
import { BackGroundSections } from "components/common/BackGroundSections";
import { ProgressBar } from "components/training/ProgressBar";
import { StatusDetail } from "components/training/StatusDetail";
import CertificateGenerator from "components/training/CertificateGenerator";
import { VideosCourseComponents } from "components/training/VideosCourseComponents";
import Commentaries from "../trainingComponets/Commentaries";

const StatusDetailMemo = React.memo(StatusDetail);
const ProgressBarMemo = React.memo(ProgressBar);

export function Course() {
  const navigate = useNavigate();
  const { id_course } = useParams();
  const { id_video } = useParams();
  const [userId, setUserId] = useState(null);
  const [courseData, setCourseData] = useState({
    modules: [{}],
    avatarUrl: null,
    stateCourse: [{}],
    url: null,
    video: null,
    countVideos: 0,
    countVideosView: 0,
    dataUser: null,
    insumos: [],
  });
  const { userRole } = useRoleContext();

  const {
    modules,
    avatarUrl,
    userId: courseUserId,
    stateCourse,
    url,
    video,
    countVideos,
    countVideosView,
    insumos,
    dataUser,
  } = courseData;

  const setUrl = (url) => setCourseData((prevState) => ({ ...prevState, url }));
  const setVideo = (video) =>
    setCourseData((prevState) => ({ ...prevState, video }));

  const VideoName = video && video[0]?.titulo_video;

  const course = modules.map((item) => item.info_curso);
  const nameCourse = course[0]?.nombre_curso;
  const miniature = course[0]?.nombre_miniatura;
  const colorId = stateCourse[0]?.estado_curso?.color_id;
  const status = stateCourse[0]?.estado_curso?.nombre_estado;

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await getUserData();
        if (userData && userData.id) {
          setUserId(userData.id);
        } else {
          console.error("No user data found.");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (!userId || !id_course) return;

      try {
        const [countViewed, countTotal, userData, modulesData] =
          await Promise.all([
            getCountVideosViewed(userId, id_course),
            getCountVideos(id_course),
            getDataUser(userId),
            getModule(id_course),
          ]);

        setCourseData((prevState) => ({
          ...prevState,
          countVideosView: countViewed,
          countVideos: countTotal,
          dataUser: userData[0],
          modules: modulesData,
        }));

        const avatar = await downloadImage(miniature);
        setCourseData((prevState) => ({
          ...prevState,
          avatarUrl: avatar,
        }));

        const completionPercentage = (countViewed / countTotal) * 100;

        let status = "Activo";
        if (completionPercentage >= 100) {
          status = "Finalizado";
        } else if (completionPercentage > 0 && completionPercentage < 100) {
          status = "En Progreso";
        }

        await updateCourseStatusInDatabase(userId, id_course, status);
      } catch (error) {
        console.error("Error fetching course data:", error);
      }
    };

    fetchData();
  }, [userId, id_course, video, miniature]);

  const updateCourseStatusInDatabase = async (userId, courseId, status) => {
    let statusId;

    if (status === "Activo") {
      statusId = 1;
    } else if (status === "En Progreso") {
      statusId = 3;
    } else if (status === "Finalizado") {
      statusId = 4;
    }

    try {
      const { data, error } = await supabase
        .from("th_capacitacion_curso_comprado")
        .update({
          estado_curso: statusId,
        })
        .eq("id_usuario", userId)
        .eq("id_curso", courseId);
    } catch (error) {
      console.error("Error updating course status:", error);
    }
  };

  const linkToBack = userRole === 7 ? "/trainingpmo" : "/training";

  return (
    <Module title={`Curso ${nameCourse}`} linkToBack={linkToBack} back>
      <BackGroundSections>
        <div>
          <div className="grid grid-cols-3">
            <div className="mb-2 flex col-span-2">
              <StatusDetailMemo
                colorId={colorId}
                statusName={
                  countVideosView === countVideos
                    ? "Finalizado"
                    : countVideosView === 0
                    ? "Activo"
                    : countVideosView >= 1
                    ? "En Progreso"
                    : status
                }
              />
            </div>
            <div>
              <ProgressBarMemo
                videosLength={countVideos}
                videoWatched={countVideosView}
              />
            </div>
          </div>
        </div>
        <div className="lg:grid lg:grid-cols-3">
          <div className="col-span-2">
            {url ? (
              <BackGroundSections>
                <VideosCourseComponents src={url} />
                <BackGroundSections>
                  <div className="flex justify-between items-center">
                    <h2 className="text-xl font-semibold">{VideoName}</h2>
                    {countVideosView === countVideos && dataUser && (
                      <CertificateGenerator
                        name={`${dataUser?.first_name} ${dataUser?.last_name}`}
                        course={nameCourse}
                        userId={userId}
                        courseId={id_course}
                      />
                    )}
                  </div>
                  {video && video[0]?.descripcion && (
                    <p className="mt-4 text-sm text-gray-600">
                      {video[0]?.descripcion}
                    </p>
                  )}
                </BackGroundSections>
              </BackGroundSections>
            ) : (
              <img
                src={avatarUrl}
                alt="Avatar"
                className="grow rounded-lg slide-down-animation overflow-hidden curs"
              />
            )}
            {url && <Commentaries videoId={id_video} userId={userId} />}
          </div>

          <div className="overflow-y-auto max-h-[800px] lg:max-h-[800px] xl:max-h-[800px]">
            <AutoHeigth maxPx={"180px"}>
              {modules &&
                modules.map((item, index) => (
                  <Suspense
                    key={item.id ? item.id : `${item.id}-${index}`}
                    fallback={<div>Loading...</div>}
                  >
                    <DropDownModule
                      userId={userId}
                      module={item.nombre_modulo}
                      id_module={item.id}
                      id_course={id_course}
                      setUrl={setUrl}
                      setVideo={setVideo}
                    />
                  </Suspense>
                ))}
            </AutoHeigth>
          </div>
        </div>
      </BackGroundSections>
    </Module>
  );
}
