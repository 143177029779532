import { Link } from "react-router-dom";

export function ModuleItem({ to, item, imageURL }) {
  return (
    <Link
      to={to}
      className="flex justify-center lg:bg-white lg:hover:scale-105 rounded-md px-[8px] py-[8px] transition-transform border-b border-red lg:border-none"
    >
      <div>
        <img src={imageURL} alt="" className="w-32 flex justify-center" />
        <p className="mt-2 text-sky-800 text-lg">{item}</p>
      </div>
    </Link>
  );
}
