import { addUser } from "api/user-module/AddUser";
import { ModalBG } from "components/common/ModalBG";
import { useEffect, useState } from "react";
import { StructureModal } from "./StructureModal";
import { getUserData } from "utils/SupabaseClient";

export function ModalAddUser({ isOpen, setIsOpen, companyId, setRecharge }) {
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [subCompany, setSubCompany] = useState("");
  const [documento, setDocumento] = useState("");
  const [typeDocument, setTypeDocument] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useState("");

  useEffect(() => {
    getUserData().then((r) => setUser(r.id));
  }, []);

  const Insert = async () => {
    await addUser({
      companyId,
      subCompany,
      name,
      lastName,
      phone,
      email,
      documento,
      typeDocument,
      password,
      setSuccess,
      setError,
      setIsLoading,
    });
  };

  useEffect(() => {
    if (success || error) {
      setIsLoading(false);
      setSuccess(false);
      setError(false);
      setRecharge(true);
      setIsOpen(false);
      setName("");
      setLastName("");
      setPhone("");
      setEmail("");
      setSubCompany("");
      setDocumento("");
      setTypeDocument("");
      setPassword("");
    }
  }, [success, error]);

  return (
    <ModalBG
      open={isOpen}
      onClose={() => setIsOpen(false)}
      title={"Añadir Nuevo usuario"}
    >
      <StructureModal
        modal="add"
        user={user}
        isLoading={isLoading}
        setName={setName}
        name={name}
        setLastName={setLastName}
        lastName={lastName}
        setPhone={setPhone}
        phone={phone}
        setEmail={setEmail}
        email={email}
        setSubCompany={setSubCompany}
        subCompany={subCompany}
        setTypeDocument={setTypeDocument}
        typeDocument={typeDocument}
        setDocumento={setDocumento}
        documento={documento}
        setPassword={setPassword}
        password={password}
        form={Insert}
      />
    </ModalBG>
  );
}
