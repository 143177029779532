import { toast } from "sonner";
import { getUserData, supabase } from "utils/SupabaseClient";

export async function UpdatePersonalUser({
  name,
  lastName,
  phone,
  setisLoading,
  setRecharge,
  setError,
  setEdit,
}) {
  setisLoading(true);

  const user = await getUserData();

  const itemsUser = {
    first_name: name,
    last_name: lastName,
    phone: phone,
  };

  const { data: User, error: ErrUser } = await supabase
    .from("auth_users")
    .update(itemsUser)
    .eq("id", user.id);

  if (ErrUser) {
    setError(ErrUser);
    setisLoading(false);
    setEdit(true);
    toast.error("Error al actualizar los datos");
  } else {
    setisLoading(false);
    setRecharge(true);
    setEdit(true);
    toast.success("Se ha actualizado los datos");
  }
}
