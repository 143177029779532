export function PauseIcon({ w, h, ispause }) {
  return (
    <>
      {ispause ? (
        <svg
          width={w}
          height={h}
          viewBox="0 0 8 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="fill-white hover:fill-[#E0E2BE] transition-all"
        >
          <path d="M2 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V9C0 9.26522 0.105357 9.51957 0.292893 9.70711C0.48043 9.89464 0.734784 10 1 10H2C2.26522 10 2.51957 9.89464 2.70711 9.70711C2.89464 9.51957 3 9.26522 3 9V1C3 0.734784 2.89464 0.48043 2.70711 0.292893C2.51957 0.105357 2.26522 0 2 0ZM7 0H6C5.73478 0 5.48043 0.105357 5.29289 0.292893C5.10536 0.48043 5 0.734784 5 1V9C5 9.26522 5.10536 9.51957 5.29289 9.70711C5.48043 9.89464 5.73478 10 6 10H7C7.26522 10 7.51957 9.89464 7.70711 9.70711C7.89464 9.51957 8 9.26522 8 9V1C8 0.734784 7.89464 0.48043 7.70711 0.292893C7.51957 0.105357 7.26522 0 7 0Z" />
        </svg>
      ) : (
        <svg
          width={w}
          height={h}
          viewBox="0 0 8 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="fill-slate-200 hover:fill-[#feffe2] transition-all"
        >
          <path d="M7.99989 4.70084C8.00243 4.88705 7.96206 5.07151 7.88166 5.24102C7.79722 5.42036 7.66773 5.57593 7.50447 5.69477L2.07176 9.75152C1.88051 9.89143 1.65243 9.97747 1.41308 10H1.2836C1.0879 10.0001 0.894972 9.95564 0.720623 9.87036C0.509064 9.77068 0.330029 9.61727 0.202982 9.4268C0.0759338 9.23632 0.00573468 9.01607 1.55818e-05 8.79V1.22748C-0.00115123 1.00425 0.0632343 0.785215 0.185797 0.595469C0.304404 0.405737 0.474302 0.250331 0.677481 0.145722C0.880661 0.0411136 1.10955 -0.00880021 1.3399 0.00127143C1.57184 0.012075 1.79703 0.081218 1.99295 0.201138L7.43128 3.6799C7.5968 3.79009 7.73529 3.93378 7.83662 4.10124C7.94359 4.2849 7.99989 4.49071 7.99989 4.70084Z" />
        </svg>
      )}
    </>
  );
}
