export function AutoHeigth({ children, maxPx }) {
  return (
    <div
      className="overflow-y-scroll"
      style={{ height: `calc(100vh - ${maxPx ? maxPx : "87px"})` }}
    >
      {children}
    </div>
  );
}
