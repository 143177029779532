import { ModalBG } from "components/common/ModalBG";
import { useEffect, useState } from "react";
import { StructureModalSubCompany } from "./StructureModalSubCompany";
import { EditSubCompany } from "api/user-module/EditSubCompany";

export function ModalEditSubCompanie({
  setIsOpen,
  isOpen,
  setRecharge,
  companyId,
  countries,
  dataEdit,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const [subCompany, setSubCompany] = useState("");
  const [subCompanyInsert, setSubCompanyInsert] = useState("");
  const [nit, setNit] = useState("");
  const [indicative, setIndicative] = useState("");
  const [phone, setPhone] = useState("");
  const [mail, setMail] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [active, setIsActive] = useState(false);

  useEffect(() => {
    if (success || error) {
      setRecharge(true);
      setIsOpen(false);
      setIsLoading(false);
      setSuccess(false);
      setError(false);
      setSubCompany("");
      setNit("");
      setIndicative("");
      setPhone("");
      setMail("");
      setName("");
      setLastName("");
    }
  }, [success, error]);

  useEffect(() => {
    if (dataEdit) {
      setSubCompanyInsert(dataEdit?.id);
      setSubCompany(dataEdit?.razon_social);
      setMail(dataEdit?.correo_responsable);
      setNit(dataEdit?.numero_documento);
      setIndicative(dataEdit?.indicativo_celular);
      setPhone(dataEdit?.celular_responsable);
      setName(dataEdit?.nombre_responsable);
      setLastName(dataEdit?.apellido_responsable);
      setIsActive(dataEdit?.activo);
    }
  }, [dataEdit]);

  const funcion = async () => {
    await EditSubCompany({
      active,
      companyId,
      subCompany,
      subCompanyInsert,
      name,
      lastName,
      nit,
      indicative,
      phone,
      mail,
      setIsLoading,
      setError,
      setSuccess,
      setRecharge,
    });
  };

  const hadleClose = () => {
    setIsOpen(false);
    setSubCompany("");
    setNit("");
    setIndicative("");
    setPhone("");
    setMail("");
    setName("");
    setLastName("");
  };

  return (
    <ModalBG open={isOpen} onClose={hadleClose} title={"Editar Sub Compañia"}>
      <StructureModalSubCompany
        principal={dataEdit?.is_principal}
        modalType="edit"
        setIsActive={setIsActive}
        active={active}
        isLoading={isLoading}
        setSubCompany={setSubCompany}
        subCompany={subCompany}
        setMail={setMail}
        mail={mail}
        setNit={setNit}
        nit={nit}
        countries={countries}
        setIndicative={setIndicative}
        indicative={indicative}
        setPhone={setPhone}
        phone={phone}
        setName={setName}
        name={name}
        setLastName={setLastName}
        lastName={lastName}
        form={funcion}
      />
    </ModalBG>
  );
}
