import { addPicture, removePicture } from "hooks/commonData";
import { toast } from "sonner";
import { convertToBlob } from "utils/Operations";

import { getUserData, supabase } from "utils/SupabaseClient";
import { UpdateDataMiniatureReport } from "./UpdateDataMiniatureReport";

export async function fetchTemplates() {
  const { data, error } = await supabase.from("dim_bi_plantillas").select(
    `id, 
      title, 
      description, 
      url, 
      updated_at,
      id_proveedor,
      templates:dim_bi_plantillas_relacion!report_id(
       data_source:dim_bi_origenes!origin_id(
        id, proveedor, tipo
       )
      ),
      miniature
      `
  );

  if (error) {
    toast.error("Error al cargar plantillas.");
    return [];
  }
  return data;
}

export async function fetchReports(subcompanies) {
  const { data, error } = await supabase
    .from("th_bi_link_report")
    .select(
      `
      id,
      title,
      description,
      url,
      updated_at,
      id_proveedor,
      subcompany_id,
      templates:th_bi_origen_report!report_id(
        data_source:th_bi_origenes!origin_id(
          id, proveedor, type, directory
        )
      ),
      miniature
    `
    )
    .in(
      "subcompany_id",
      subcompanies.map((subcompany) => subcompany.id_subcompanies.id)
    );
  if (error) {
    toast.error("¡Error al cargar reportes!");
    return [];
  }
  return data;
}

async function saveReport({
  subcompany_id,
  title,
  description,
  url,
  id_proveedor,
  user_id,
}) {
  const { data, error } = await supabase
    .from("th_bi_link_report")
    .insert([
      {
        subcompany_id: subcompany_id,
        title: title,
        description: description,
        url: url,
        tipo_cliente: "cliente",
        provider_view: "otro",
        id_proveedor: id_proveedor,
        created_by: user_id,
        updated_by: user_id,
      },
    ])
    .select()
    .single();

  if (error) {
    toast.error("Error al guardar el reporte");
    return [];
  }
  return data;
}

async function saveConnection({
  subCompanyId,
  proveedor,
  tipoArchivo,
  archivo,
  source,
  user_id,
  storage,
}) {
  const { data, error } = await supabase
    .from("th_bi_origenes")
    .insert({
      subcompany_id: subCompanyId,
      proveedor: proveedor,
      type: tipoArchivo,
      directory: archivo,
      source: source,
      storage: storage,
      created_by: user_id,
      updated_by: user_id,
    })
    .select()
    .single();

  if (error) {
    toast.error("No se pudo crear la nueva conexión, intenta nuevamente.");
  }
  return data;
}

export async function createReport({
  file,
  report_name,
  description,
  url_report,
  subcompany_id,
  provider,
  is_project,
}) {
  const user = await getUserData();
  const uniqueFile = file[0];
  const fileName = uniqueFile?.name;
  const extension = String(fileName).split(".")[1];
  const reportData = await saveReport({
    subcompany_id,
    title: report_name,
    description: description,
    url: url_report,
    id_proveedor: provider,
    user_id: user.id,
  });

  if (!!fileName) {
    const src = !fileName
      ? null
      : `miniaturas/${reportData.id}/${report_name}.${extension}`;

    UpdateDataMiniatureReport({
      idReport: reportData.id,
      src: src,
      name: report_name,
      description: description,
      setIsLoading: () => {},
      setEdit: () => {},
      setRecharge: () => {},
    });

    const blop = !fileName ? null : await convertToBlob(uniqueFile);

    const addImage = await addPicture("miniaturaReportes", src, blop);

    if (addImage[0] === "error") {
      toast.error("¡No fue posible guardar la miniatura!");
      return [];
    }
  }

  //TODO: Implementar con proyectos
  // if (is_project) {
  //   const originData = await saveConnection({
  //     subCompanyId: subcompany_id,
  //     proveedor: provider,
  //     tipoArchivo: "custom",
  //     user_id: user.id,
  //     storage: true,
  //   });

  //   const { data, error } = await supabase
  //     .from("th_bi_origen_report")
  //     .insert([
  //       {
  //         report_id: reportData.id,
  //         origin_id: originData.id,
  //         created_by: user.id,
  //         updated_by: user.id,
  //       },
  //     ])
  //     .select();

  //   if (error) {
  //     toast.error("Error al guardar el informe.");
  //     return [];
  //   }
  //   return data;
  // }

  return true;
}

export async function deleteReport(reportId, prevImage) {
  const { error: origenReportError } = await supabase
    .from("th_bi_origen_report")
    .delete()
    .match({ report_id: reportId });

  if (origenReportError) {
    console.error(
      "Error al eliminar las relaciones en th_bi_origen_report:",
      origenReportError
    );
    return false;
  }

  if (prevImage) {
    const removeImage = await removePicture(
      "miniaturaReportes",
      "miniaturas/50/HOLA.png"
    );

    if (removeImage[0] === "error") {
      console.log(removeImage[1]);

      toast.error(`${prevImage} ¡No fue posible eliminar la miniatura!`);
      return false;
    }
  }

  const { data: origenData, error: origenError } = await supabase
    .from("th_bi_origen_report")
    .select("origin_id")
    .match({ report_id: reportId });

  if (origenError) {
    console.error("Error al obtener los origin_id:", origenError);
    return false;
  }

  if (origenData && origenData.length > 0) {
    const originIds = origenData.map((item) => item.origin_id);

    const { error: origenesError } = await supabase
      .from("th_bi_origenes")
      .delete()
      .in("id", originIds);

    if (origenesError) {
      console.error(
        "Error al eliminar las relaciones en th_bi_origenes:",
        origenesError
      );
      return false;
    }
  }

  const { error: reportError } = await supabase
    .from("th_bi_link_report")
    .delete()
    .match({ id: reportId });

  if (reportError) {
    console.error(
      "Error al eliminar el reporte en th_bi_link_report:",
      reportError
    );
    return false;
  }

  console.log("Reporte y sus relaciones eliminadas correctamente.");
  return true;
}
