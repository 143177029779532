import React, { useEffect, useState } from "react";
import { supabase } from "utils/SupabaseClient";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import MercadoPagoButton from "hooks/MercadoPagoButton";

const PricingPlans = () => {
  const [plans, setPlans] = useState([]);
  const [isYearly, setIsYearly] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPlans = async () => {
      const { data, error } = await supabase
        .from("dim_plans")
        .select(
          "id, plan_type, plan_description, plan_price, user_count, report_count, connection_count, integration_count, support_acces_level"
        );

      if (error) {
        console.error("Error fetching plans:", error);
      } else {
        setPlans(data);
      }
    };

    fetchPlans();
  }, []);

  const handlePurchase = (plan) => {
    if (plan.plan_type === "Plis BI Gratis") {
      navigate("/");
    } else if (plan.plan_type === "Plis BI Proyectos") {
      navigate("/budget");
    } else {
      console.log(`Iniciando compra para el plan: ${plan.plan_type}`);
    }
  };

  return (
    <div className="flex flex-col items-center space-y-4 py-10 px-4 md:px-8">
      <div className="flex space-x-4 bg-gray-200 p-2 rounded-lg">
        <button
          onClick={() => setIsYearly(false)}
          className={`px-4 py-2 rounded-md text-lg font-semibold ${
            !isYearly ? "bg-blue-600 text-white" : "bg-gray-200 text-gray-800"
          }`}
        >
          Facturación mensual
        </button>
        <button
          onClick={() => setIsYearly(true)}
          className={`px-4 py-2 rounded-md text-lg font-semibold ${
            isYearly ? "bg-blue-600 text-white" : "bg-gray-200 text-gray-800"
          }`}
        >
          Facturación anual{" "}
          <span className="text-green-600">Ahorra hasta 16%</span>
        </button>
      </div>

      <div className="flex flex-wrap justify-center gap-6 mt-8 transition-all slide-down-animation overflow-y-auto max-h-full md:max-h-[60vh] 2xl:max-h-[80vh] py-4">
        {plans.map((plan) => {
          let priceText = "";
          if (plan.plan_type === "Plis BI Gratis") {
            priceText = "Free";
          } else if (plan.plan_type === "Plis BI Proyectos") {
            priceText = "Custom";
          } else {
            priceText = isYearly
              ? `$${plan.plan_price * 10}`
              : `$${plan.plan_price}`;
          }

          return (
            <div
              key={plan.id}
              className="appear-animation w-72 p-6 border rounded-lg shadow-md relative bg-white flex flex-col lg:hover:scale-105 transition-transform duration-300 ease-in-out"
            >
              {plan.plan_type === "Plis BI PRO" && (
                <span className="absolute top-2 right-2 bg-black text-white text-xs px-2 py-1 rounded">
                  Popular
                </span>
              )}
              <h3 className="text-xl font-semibold text-gray-800">
                {plan.plan_type}
              </h3>
              <p className="text-3xl font-bold my-2 text-blue-600">
                {priceText}
                <span className="text-sm">
                  {isYearly ? " al año " : " por mes "}
                </span>
              </p>
              <p className="text-gray-600">{plan.plan_description}</p>

              <div className="flex-grow flex items-end">
                {plan.plan_type !== "Plis BI Gratis" &&
                plan.plan_type !== "Plis BI Proyectos" ? (
                  <MercadoPagoButton
                    title={plan.plan_type}
                    price={isYearly ? plan.plan_price * 10 : plan.plan_price}
                    courseId={plan.id}
                  />
                ) : (
                  <button
                    className="w-full mt-4 bg-green-500 text-white py-2 rounded-md hover:bg-green-600"
                    onClick={() => handlePurchase(plan)}
                  >
                    Empieza ahora
                  </button>
                )}
              </div>

              <div className="mt-4">
                <h4 className="font-semibold">Carácteristicas</h4>
                <ul className="mt-2 space-y-1">
                  <li className="flex items-center text-gray-700">
                    <CheckCircleIcon className="w-4 h-4 text-green-500 mr-2" />
                    {plan.user_count} usuarios permitidos
                  </li>
                  <li className="flex items-center text-gray-700">
                    <CheckCircleIcon className="w-4 h-4 text-green-500 mr-2" />
                    {plan.report_count} reportes disponibles
                  </li>
                  <li className="flex items-center text-gray-700">
                    <CheckCircleIcon className="w-4 h-4 text-green-500 mr-2" />
                    {plan.connection_count} proyectos disponibles
                  </li>
                  <li className="flex items-center text-gray-700">
                    <CheckCircleIcon className="w-4 h-4 text-green-500 mr-2" />
                    {plan.integration_count} integraciones disponibles
                  </li>
                </ul>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PricingPlans;
