import { toast } from "sonner";
import { supabase } from "utils/SupabaseClient";
import { decode } from "base64-arraybuffer";

export async function NewfilesInsert({
  name,
  user,
  selectedProject,
  fileData,
  newFiles,
  base64Props,
  folder
}) {
  const { error: insertData } = await supabase.from("user_files").insert([
    {
      user: String(user),
      user_project: String(selectedProject),
      file_name: `${selectedProject}/${folder}/${name}`,
    },
  ]);

  if (insertData) {
    toast.error(
      `Error al insertar el archivo en la base de datos: ${insertData.message}`
    );
    console.error("Error al insertar en la base de datos:", insertData);
    return;
  } else {
    const base64String = base64Props.replace(/^data:.+;base64,/, "");

    const buffer = decode(base64String);

    const fileBlob = new Blob([buffer]);

    const { error: uploadFiles } = await supabase.storage
      .from("archivos")
      .upload(`${selectedProject}/${folder}/${name}`, fileBlob, {
        contentType: "application/octet-stream",
      });

    if (uploadFiles) {
      toast.error(`Error al subir el archivo: ${uploadFiles.message}`);
      console.error("Error al subir archivo:", uploadFiles);
      return;
    } else {
      toast.success(`Archivo ${name} guardado exitosamente`);
      newFiles.push(fileData);
    }
  }
}
