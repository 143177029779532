import { ButtonSave } from "components/common/ButtonSave";
import { ModalBG } from "components/common/ModalBG";
import { FileUploadComponent } from "components/tailwind/FileUploadComponent";
import { useEffect, useState } from "react";
import { toast } from "sonner";

export function ModalChangeProfilePicture({ open, setOpen, user }) {
  const [isSave, setIsSave] = useState(false);
  const [selectedFilesProps, setSelectedFilesProps] = useState([]);
  const [length, setLength] = useState(null);
  const [isLoading, setIsLoading] = useState();

  useEffect(() => {
    validateColumnsFile(length);
  }, [length]);

  const validateColumnsFile = (file) => {
    if (file > 1) {
      toast.error("¡Solo puedes agregar una foto de perfil!");

      setIsSave(false); // Deshabilitar guardar
    } else if (file === 1) {
      setIsSave(true); // Habilitar guardar
    } else {
      setIsSave(false); // Habilitar guardar
    }
  };

  const Save = async () => {};

  return (
    <ModalBG onClose={setOpen} open={open} title="Cambio de foto de perfil">
      <FileUploadComponent
        text="Sube tu nueva foto de perfil"
        redText="Solo se permite .png, .jpg"
        accept=".png, .jpg"
        setSelectedFilesProps={setSelectedFilesProps}
        setLength={setLength}
      />
      <div className={isSave ? "appear-animation" : "hidden"}>
        <ButtonSave HadleSubmit={() => {}} />
      </div>
    </ModalBG>
  );
}
