export function Comment({ children, saveComment, isUpdate, setIsUpdate, setMessage, message, updateCommnet }) {
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleCancelUpdateCommmet = () => {
    setIsUpdate(false);
    setMessage("");
  }

  return (
    <form
      className="border border-gray-200 p-4 rounded-md"
      onSubmit={handleSubmit}
    >
      {children}
      <div className="flex items-center justify-end gap-x-6">
        {
          isUpdate ?
            <>
              <button
                type="submit"
                onClick={handleCancelUpdateCommmet}
                className="rounded-md bg-sky-700 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-sky-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-700"
              >
                Cancelar
              </button>
              <button
                type="submit"
                onClick={updateCommnet}
                disabled={message == "" ? true : false}
                className={`rounded-md bg-sky-700 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-sky-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-700 ${message == "" && "opacity-40"}`}
              >
                Actualizar
              </button>
            </>
            :
            <button
              type="submit"
              onClick={saveComment}
              disabled={message == "" ? true : false}
              className={`rounded-md bg-sky-700 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-sky-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-700 ${message == "" && "opacity-40"}`}
            >
              Guardar
            </button>
        }
      </div>
    </form>
  );
}
