import NavBar from "../../components/ServiceDesk/NavBar";
import { Routes, Route } from "react-router-dom";

import Home from "./home";
import { Organization } from "./organization";
import { CreateIssues } from "./modules";
import { Projects } from "./projects";
import { Issues } from "./issues/Issues";
import { ViewRequest } from "./modules/requests/ViewRequest";
import { ViewRequeriment } from "./modules/requeriments/ViewRequeriment";
import { ViewChange } from "./modules/changes/ViewChange";
import { ViewIncident } from "./modules/incidents/ViewIncident";
import { ViewProblem } from "./modules/problems/ViewProblem";

export function ServiceDesk() {
  return (
    <>
      <NavBar />
      <div className="container mx-auto">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/requests" element={<CreateIssues />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/organization" element={<Organization />} />
          <Route path="/issues" element={<Issues />} />
          <Route path="/issues/1/:issue" element={<ViewRequest />} />
          <Route path="/issues/2/:issue" element={<ViewRequeriment />} />
          <Route path="/issues/3/:issue" element={<ViewChange />} />
          <Route path="/issues/4/:issue" element={<ViewIncident />} />
          <Route path="/issues/5/:issue" element={<ViewProblem />} />
        </Routes>
      </div>
    </>
  );
}
