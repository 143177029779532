import { useEffect, useState } from "react";
import { getUserInfromation } from "hooks/commonData";
import { Button } from "components/common/Button";
import { InputText } from "components/common/InputText";
import { ButtonSave } from "components/common/ButtonSave";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { BackIcon } from "components/svg/BackIcon";
import { UpdatePersonalUser } from "api/user-module/UpdatePersonalUser";

export function DataUser({ setisLoading }) {
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [edit, setEdit] = useState(true);
  const [recharge, setRecharge] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    getUserInfromation().then((r) => {
      if (r.length !== 0) {
        setName(r[0].first_name);
        setLastName(r[0].last_name);
        setEmail(r[0].email);
        setPhone(r[0].phone);
        setRecharge(false);
      }
    });
  }, [recharge]);

  const saveUser = async () => {
    await UpdatePersonalUser({
      name,
      lastName,
      phone,
      setisLoading,
      setRecharge,
      setError,
      setEdit,
    });
  };

  return (
    <div>
      <div className="flex justify-end">
        <div
          className={`${
            !edit ? "flex appear-animation" : "hidden"
          }  transition-all`}
        >
          <ButtonSave HadleSubmit={saveUser} />
          <button onClick={() => setEdit(!edit)} className="mt-4 ">
            <BackIcon w={36} h={36} />
          </button>
        </div>

        <div
          className={`${
            edit ? "flex appear-animation" : "hidden"
          }  transition-all`}
        >
          <Button
            onClick={() => setEdit(!edit)}
            text={"editar"}
            className="bg-sky-800 hover:bg-yellow-500 grow p-2 text-white rounded-lg transition-colors"
            icon={<PencilSquareIcon className="h-6 pb-1" />}
          />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-2">
        <InputText
          value={name}
          setValue={setName}
          placeholder={"Nombre"}
          type={"text"}
          disabled={edit}
        />

        <InputText
          value={lastName}
          setValue={setLastName}
          placeholder={"Apellido"}
          type={"text"}
          disabled={edit}
        />
        <div className="col-span-2">
          <InputText
            value={phone}
            setValue={setPhone}
            placeholder={"Telefono"}
            type={"text"}
            disabled={edit}
          />
        </div>
      </div>

      <div className="border border-x-0 border-slate-300 ">
        <InputText
          value={email}
          setValue={setEmail}
          placeholder={"Correo"}
          type={"text"}
          disabled={true}
        />
      </div>
    </div>
  );
}
