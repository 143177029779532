import { AutoHeigth } from "components/common/AutoHeigth";
import { GradiendBanner } from "components/common/GradiendBanner";
import { TarjetBudget } from "components/common/TarjetBudget";

export function MenuVisualBudget({ submodule, setSubmodule }) {
  return (
    <div
      className={`${
        submodule === "MenuVisualBudget" ? "slide-down-animation" : "hidden"
      }`}
    >
      <GradiendBanner>
        <h1 className="font-semibold text-lg text-white text-center">
          ¡Nos apasiona lo que hacemos y queremos compartir nuestra experiencia
          contigo!
        </h1>
      </GradiendBanner>
      <AutoHeigth maxPx="158px">
        <div className="mx-10 lg:mx-32 mt-5 mb-2 grid md:grid-cols-2 gap-10 h-[800px] lg:h-[398px] 2xl:h-[700px]">
          <TarjetBudget
            onClick={() => setSubmodule("IhaveAQuestionVisual")}
            colorDefault="bg-[#2f5f98]"
            colorHovering="bg-blue-400"
            title="tengo una pregunta"
            description="Podrás reunirte de manera sincrónica con un experto y conversar
              del reto que tienes."
            pathImage="/assets/img/atencion_al_cliente.png"
          />
          <TarjetBudget
            onClick={() => setSubmodule("WantDevelope")}
            colorDefault="bg-[#389164]"
            colorHovering="bg-green-400"
            title="quiero un desarrollo"
            description="Arma tu equipo de desarrollo. 
            Sin importar el tamaño del proyecto o la tecnología que necesites, estamos listos para apoyarte."
            pathImage="/assets/img/desarrollo.png"
          />
        </div>
      </AutoHeigth>
    </div>
  );
}
