import { toast } from "sonner";
import { supabase, supabaseAddUser } from "utils/SupabaseClient";

export async function addUser({
  companyId,
  subCompany,
  name,
  lastName,
  phone,
  email,
  documento,
  typeDocument,
  password,
  setSuccess,
  setError,
  setIsLoading,
}) {
  setIsLoading(true);

  const { data: Auth, error: ErrAuth } =
    await supabaseAddUser.auth.admin.createUser({
      email: email,
      email_confirm: true,
      password: password,
    });

  if (ErrAuth) {
    toast.error("¡este usuario ya se encuentra creado!");
    setError(true);
  } else {
    const newUserId = Auth.user.id;

    const itemsUser = {
      activo: true,
      id: newUserId,
      company_id: companyId,
      first_name: name,
      last_name: lastName,
      phone: phone,
      email: email,
      documento: documento,
      tipo_documento: typeDocument,
    };

    const itemsRelation = {
      id_user: newUserId,
      id_subcompanies: subCompany,
    };

    const { data: User, error: ErrUser } = await supabase
      .from("auth_users")
      .insert(itemsUser);

    const { data: Relation, error: ErrRelation } = await supabase
      .from("th_user_subcompanies")
      .insert(itemsRelation);

    const { data: Role, error: ErrRole } = await supabase
      .from("auth_user_roles")
      .insert({ user_id: newUserId, role_id: 11 });

    if (ErrUser || ErrRelation || ErrRole) {
      toast.error("¡error al crear el usuario!");
      setError(true);
    } else {
      toast.success("¡Usuario creado correctamente!");
      setSuccess(true);
    }
  }
}
