import { useEffect, useState } from "react";

import { getColor, getIconColor, types } from "../../../utils/Colors";
import { getAllProjects, getAllUserTickets, getStatusValues } from "hooks/commonData";

import { Pagination } from "components/ServiceDesk/Pagination";

import { Link } from "react-router-dom";
import { getUserData } from "utils/SupabaseClient";
import { Select } from "components/ServiceDesk/Select";
import { DateInput } from "components/ServiceDesk/DateInput";
import { ChargingComponent } from "components/ChargingComponent";

export function Issues() {

  const [userId, setUserId] = useState("");
  const [ticketDetail, setTicketDetail] = useState([]);
  const [countR, setCountR] = useState();
  const [states, setStates] = useState([]);
  const [projects, setPtojects] = useState([]);
  const [stateSelected, setStateSelected] = useState("-1");
  const [projectSelected, setProjectSelected] = useState("-1");
  const [dateStart, setDateStart] = useState();
  const [dateEnd, setDateEnd] = useState();
  const [isLoadingTicket, setIsLoadingTicket] = useState(false);

  const consultarTickets = userId !== "";

  const getUserTicket = async (limit, offset) => {
    setIsLoadingTicket(true);
    const datafilter = {
      "stateSelected": stateSelected,
      "projectSelected": projectSelected,
      "dateStart": dateStart,
      "dateEnd": dateEnd,
    }
    const data = await getAllUserTickets(userId, limit, offset, datafilter);
    setTicketDetail(data?.data);
    setCountR(data?.count)
    setIsLoadingTicket(false);
  }

  const handleSelectedState = async (value) => {
    setStateSelected(value)
  }

  const handleSelectedProject = (value) => {
    setProjectSelected(value)
  }

  const handleDateStart = (value) => {
    setDateStart(value)
  }

  const handleDateEnd = (value) => {
    setDateEnd(value)
  }

  const handleClearFilter = () => {
    setStateSelected("-1");
    setProjectSelected("-1")
    setDateStart("");
    setDateEnd("");
  }

  useEffect(() => {
    getUserData().then((data) => {
      setUserId(data.id);
    });
    getStatusValues().then((state) => {
      setStates(state);
    });

    getAllProjects().then((project) => {
      setPtojects(project)
    })
  }, []);

  useEffect(() => {
    if (userId) {
      getUserTicket(10, 0);
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      getUserTicket(10, 0);
    }
  }, [stateSelected, projectSelected, dateStart, dateEnd])

  return (
    <section>
      <header class="space-y-4 p-4 sm:px-8 sm:py-6 lg:p-4 xl:px-8 xl:py-6">
        <div class="flex items-center justify-between">
          <h2 class="text-xl font-semibold text-slate-900">Incidencias</h2>
          <Select
            title={"Estado"}
            value={stateSelected}
            options={states.map((e) => ({
              value: e.id,
              name: e.status_name,
            }))}
            onChange={handleSelectedState}
          />

          <Select
            title={"Proyecto"}
            value={projectSelected}
            options={projects.map((e) => ({
              value: e.id,
              name: e.project_name,
            }))}
            onChange={handleSelectedProject}
          />

          <DateInput
            title="Fecha Inicio"
            field="dateStart"
            value={dateStart}
            onChange={handleDateStart}
            min={dateEnd && dateStart}
            max={dateEnd ? dateEnd : new Date().toISOString().split("T")[0]}
          />

          <DateInput
            title="Fecha Fin"
            field={"dateEnd"}
            value={dateEnd}
            onChange={handleDateEnd}
            min={dateStart && dateStart}
            max={new Date().toISOString().split("T")[0]}
          />

          <button
            class="hover:bg-sky-600 border-2 group flex items-center rounded-md shadow-sm bg-white text-black text-sm font-medium pl-2 pr-3 py-2 mt-7 "
            onClick={handleClearFilter}
          >
            Quitar filtros
          </button>

          <Link
            to="/requests"
            class="hover:bg-sky-600 group flex items-center rounded-md bg-sky-700 text-white text-sm font-medium pl-2 pr-3 py-2 mt-7 shadow-sm"
          >
            <svg
              width="20"
              height="20"
              fill="currentColor"
              class="mr-2"
              aria-hidden="true"
            >
              <path d="M10 5a1 1 0 0 1 1 1v3h3a1 1 0 1 1 0 2h-3v3a1 1 0 1 1-2 0v-3H6a1 1 0 1 1 0-2h3V6a1 1 0 0 1 1-1Z" />
            </svg>
            Nuevo
          </Link>
        </div>
      </header>

      {
        isLoadingTicket ? <ChargingComponent/> :
          <>
            <ul role="list" className="divide-y divide-gray-100 px-8">
              {ticketDetail.map((ticket) => {
                const service = ticket.service_id.item_value;
                const color = getColor(ticket.status_id);
                const iconColor = getIconColor(ticket.type_id);

                return (
                  <Link key={ticket.id} to={`${ticket.type_id}/${ticket.id}`}>
                    <li className="flex bg-white rounded-md mb-4 p-4 justify-between gap-x-6 hover:bg-sky-50">
                      <div className="flex min-w-0 gap-x-4">
                        <div className="min-w-0 flex-auto">
                          <p className="text-sm font-semibold leading-6 text-gray-900 flex items-center">
                            <span
                              className={`flex items-center justify-center h-5 w-5 rounded mr-2 ${iconColor} text-white`}
                            >
                              {types[ticket.type_id]}
                            </span>
                            {ticket.title}
                          </p>
                          <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                            {ticket.description}
                          </p>
                        </div>
                      </div>
                      <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                        <p className="text-sm leading-6 text-gray-900">{service}</p>
                        <div className="mt-1 flex items-center gap-x-1.5">
                          <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                            <div className={`h-1.5 w-1.5 rounded-full ${color}`} />
                          </div>
                          <p className="text-xs leading-5 text-gray-500">
                            {ticket.status.status_name}
                          </p>
                        </div>
                      </div>
                    </li>
                  </Link>
                );
              })}
            </ul>
          </>
      }
      <Pagination size={countR} getData={getUserTicket} />
    </section>
  );
}
