import { Module } from "components/Module";
import { NewCourseButton } from "components/trainingComponetsPMO/NewCourseButton";
import { TarjetCoursesPMO } from "components/trainingComponetsPMO/TarjetCoursesPMO";
import { getCourses } from "hooks/commonData";
import { useEffect, useState } from "react";

export function TrainingPMO() {
  const [courses, setCourses] = useState([{}]);

  useEffect(() => {
    getCourses().then((r) => setCourses(r));
  }, []);

  return (
    <Module title={"Cursos"} back={false}>
      <NewCourseButton/>
      <div className="grid grid-cols-4 gap-4 m-2">
        {courses?.map((item, index) => (
          <TarjetCoursesPMO
            colorId={item.estado?.color_id}
            image={item.nombre_miniatura}
            status={item.estado?.nombre_estado}
            title={item.nombre_curso}
            to={`/training/course/${item.id},${item.nombre_curso}`}
            key={index}
            id={item.id}
          />
        ))}
      </div>
    </Module>
  );
}
