import { AddIcon } from "components/SvgIcons/AddIcon";
import React, { useEffect, useState } from "react";
import { Section } from "./Section";
import { getModule, getVideos } from "hooks/commonData";

export default function ModuleManager({ id_course, setUrl, setVideo }) {
  const [sections, setSections] = useState([]);
  const id_curso = String(id_course).split(",")[0];

  useEffect(() => {
    getModule(id_curso).then((r) => {
      setSections(
        r.map((item) => ({
          id: item.id,
          title: item.nombre_modulo,
          subsections: [],
        }))
      );
    });
  }, [id_curso]);

  const handleAddSection = () => {
    const newSection = {
      id: Date.now(),
      title: "Nuevo módulo",
      subsections: [],
    }; // Aseguramos que las subsecciones estén inicializadas como array
    setSections([...sections, newSection]);
  };

  const handleAddSubsectionToSection = (sectionId, newSubsection) => {
    setSections((prevSections) =>
      prevSections.map((section) => {
        if (section.id === sectionId) {
          // Aseguramos que las subsecciones estén inicializadas como array
          return {
            ...section,
            subsections: [...(section.subsections || []), newSubsection],
          };
        }
        return section;
      })
    );
  };

  const handleRemoveSection = (sectionId) => {
    setSections((prevSections) =>
      prevSections.filter((section) => section.id !== sectionId)
    );
  };

  const handleRemoveSubsection = (sectionId, subsectionId) => {
    setSections((prevSections) =>
      prevSections.map((section) => {
        if (section.id === sectionId) {
          return {
            ...section,
            subsections: (section.subsections || []).filter(
              (subsection) => subsection.id !== subsectionId
            ),
          };
        }
        return section;
      })
    );
  };

  return (
    <div
      className="p-[20px] overflow-x-auto"
      style={{ height: `calc(100vh - "500px")` }}
    >
      <button
        onClick={handleAddSection}
        className="flex justify-between stroke-black w-full hover:bg-slate-100 hover:scale-105 rounded-lg p-2 mb-2 transition-all"
      >
        <label>Añadir Módulo</label>
        <AddIcon h={"20"} w={"20"} />
      </button>
      <div>
        {sections.map((section) => (
          <Section
            key={section.id}
            sectionId={section.id}
            title={section.title}
            subsections={section.subsections || []} // Aseguramos que subsections nunca sea undefined
            onAddSubsection={handleAddSubsectionToSection}
            onRemoveSection={handleRemoveSection}
            onRemoveSubsection={handleRemoveSubsection}
            setUrl={setUrl}
            setVideo={setVideo}
          />
        ))}
      </div>
    </div>
  );
}
