import { toast } from "sonner";

import { useEffect, useState } from "react";

import { formatDate } from "utils/Operations";
import { supabase } from "utils/SupabaseClient";
import { deleteFile, downloadFile } from "api/bi-module/storage";

import { RiFileExcel2Fill } from "react-icons/ri";
import { LineIcon } from "components/svg/LineIcon";

import { ModalBG } from "components/common/ModalBG";
import { Dropdown } from "components/common/Dropdown";
import { ModalSuccess } from "components/modal/ModalSuccess";
import { ConfirmDelete } from "components/common/ConfirmDelete";
import { ChargingComponent } from "components/common/ChargingComponent";

function DeleteModal({ item, open, onClick }) {
  const [isDelete, setIsDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  async function handleDelete(item) {
    await deleteFile({
      fileName: `${item.company_id}/${item.provider}/${item.type}/${item.file}`,
    });
    try {
      const { data: linkReports, error: linkReportsError } = await supabase
        .from("th_bi_link_report")
        .select("*")
        .eq("id", item.id);

      if (linkReports && linkReports.length > 0) {
        const { error: deleteLinkReportError } = await supabase
          .from("th_bi_link_report")
          .delete()
          .eq("id", item.id);
      }

      const { error: originReportsError } = await supabase
        .from("th_bi_origen_report")
        .delete()
        .eq("origin_id", item.id);

      const { error: connectionError } = await supabase
        .from("th_bi_origenes")
        .delete()
        .eq("id", item.id);

      setIsSuccess(true);
      return true;
    } catch (error) {
      toast.error("Error al eliminar la conexión.");
      return false;
    }
  }

  return (
    <ModalBG title="Eliminar conexion" open={open} onClose={onClick}>
      {!isLoading && (
        <>
          {!isSuccess && (
            <>
              <h1 className="block text-md font-medium leading-6 text-gray-900">
                ¿Seguro que quiere eliminar el archivo{" "}
                <strong>{item.file}</strong> de la empresa{" "}
                <strong>{item.company}</strong>?.
              </h1>
              <ConfirmDelete message="para confirmar" onConfirm={setIsDelete} />
              <div className="flex justify-end pt-2">
                <button
                  onClick={() => handleDelete(item)}
                  className="bg-red-500 px-4 py-2 rounded-md text-white hover:bg-red-400"
                  disabled={!isDelete}
                >
                  Eliminar
                </button>
              </div>
            </>
          )}
          {isSuccess && (
            <ModalSuccess
              title="Eliminado"
              message="Conexión eliminada correctamente, por favor recarga la página nuevamente."
              onClose={onClick}
            />
          )}
        </>
      )}
      {isLoading && <ChargingComponent />}
    </ModalBG>
  );
}

function Row({ item, setModal, setRecharge }) {
  const [open, setOpen] = useState(false);
  const handleModal = () => {
    setOpen(!open);
    setModal(open);
  };
  const handleSuccess = () => {
    setOpen(!open);
    setModal(open);
    setRecharge();
  };
  const handleDownload = () => {
    downloadFile({
      path: `${item.company_id}/${item.provider}/${item.type}/${item.file}`,
      fileName: item.file,
    });
  };

  return (
    <>
      <tr className="bg-white border-b">
        <th
          scope="row"
          className="px-3 md:px-6 py-4 font-medium text-gray-900 whitespace-nowrap hidden md:table-cell"
        >
          {item.company}
        </th>
        <td className="px-3 md:px-6 py-4 hidden lg:table-cell">
          {item.provider}
        </td>
        <td className="px-3 md:px-6 py-4 hidden lg:table-cell">{item.type}</td>
        <td className="px-3 md:px-6 py-4">
          <p className="hidden md:table-cell">{item.file}</p>
          <div className="md:hidden">
            <File item={item} />
          </div>
        </td>
        <td className="px-3 md:px-6 py-4 hidden xl:table-cell">
          {item.createdDate}
        </td>
        {setRecharge && (
          <td className="px-3 md:px-6 py-4">
            <div className="hidden md:table-cell">
              <div className="flex">
                {item.type !== "custom" && (
                  <LineIcon
                    name="download"
                    color="blue"
                    onClick={handleDownload}
                  />
                )}
                <LineIcon name="minus" color="red" onClick={handleModal} />
              </div>
            </div>
            <div className="md:hidden">
              <Dropdown
                firstName="Eliminar"
                downloadAction={handleDownload}
                secondName="Descargar"
                deleteAction={handleModal}
                isCustom={item.type === "custom"}
              />
            </div>
          </td>
        )}
      </tr>
      <DeleteModal item={item} open={open} onClick={handleSuccess} />
    </>
  );
}

function File({ item }) {
  return (
    <div className="flex min-w-0 gap-x-4">
      <div className="h-12 w-12 flex items-center justify-center rounded-full bg-gray-50 text-2xl">
        <RiFileExcel2Fill className="text-emerald-600" />
      </div>
      <div className="min-w-0 flex-auto">
        <p className="text-sm font-semibold leading-6 text-gray-900">
          {item.type}
        </p>
        <p className="mt-1 truncate text-xs leading-5 text-gray-500">
          {item.company}
        </p>
      </div>
    </div>
  );
}

export function Table({ active, items, setRecharge }) {
  const [totalItems, setTotalItems] = useState(0);
  const [modalActive, setModalActive] = useState(false);

  useEffect(() => {
    if (items) setTotalItems(items.length);
  }, [items]);

  return (
    <>
      {items === null ? (
        <ChargingComponent />
      ) : (
        <>
          <div className="relative shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500">
              <thead
                className={`text-xs text-sky-800 uppercase bg-gray-50 top-0 z-20 ${
                  active && modalActive ? "sticky" : ""
                }`}
              >
                <tr>
                  <th
                    scope="col"
                    className="px-3 mix-blend-normal py-3 hidden md:table-cell"
                  >
                    Empresa
                  </th>
                  <th
                    scope="col"
                    className="px-3 mix-blend-normal py-3 hidden lg:table-cell"
                  >
                    Proveedor
                  </th>
                  <th
                    scope="col"
                    className="px-3 mix-blend-normal py-3 hidden lg:table-cell"
                  >
                    Tipo
                  </th>
                  <th scope="col" className="px-3 mix-blend-normal py-3">
                    Archivo
                  </th>
                  <th
                    scope="col"
                    className="px-3 mix-blend-normal py-3 hidden xl:table-cell"
                  >
                    Fecha
                  </th>
                  <th scope="col" className="px-3 mix-blend-normal py-3">
                    Acción
                  </th>
                </tr>
              </thead>
              <tbody>
                {items.map((item) => (
                  <Row
                    key={item.id}
                    item={{
                      id: item.id,
                      company: item.auth_user_subcompanies.razon_social,
                      company_id: item.auth_user_subcompanies.id,
                      provider: item.proveedor,
                      type: item.tipo_archivo,
                      file: item.archivo,
                      createdDate: formatDate(item.created_at),
                    }}
                    setModal={setModalActive}
                    setRecharge={setRecharge}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
}
