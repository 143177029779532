import { useEffect, useState } from "react";
import { ViewTable } from "../../components/ViewTable";
import { BackGroundSections } from "components/BackGroundSections";
import { Module } from "components/Module";
import { getUserData } from "utils/SupabaseClient";
import { DownloadFile, getUserFiles } from "hooks/commonData";
import { ChargingComponent } from "components/ChargingComponent";
import fileDownload from "js-file-download";

import { Button } from "components/Button";
import { DownloadIcon } from "components/SvgIcons/DownloadIcon";

import * as XLSX from "xlsx";
import mammoth from "mammoth";
import { Modal } from "components/Modal";

export function ViewDatabases() {
  const tittles = [
    "Proyecto",
    "Archivo",
    "Fecha Conexión",
    "Responsable",
    "Ver archivo",
    "Descargar archivo",
  ];
  const [dataArray, setDataArray] = useState([]);
  const [loading, setLoading] = useState(true);
  const [idUser, setIdUser] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [filePreviewContent, setFilePreviewContent] = useState("");
  const [fileType, setFileType] = useState("");

  useEffect(() => {
    getUserData().then((r) => setIdUser(r.id));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const files = await getUserFiles(idUser);
        const newArray = files.map((item) => {
          const date = new Date(item.created_at);
          const formattedDate = date.toLocaleDateString("es-CO", {
            day: "2-digit",
            month: "short",
            year: "numeric",
          });

          return {
            user_project: item.user_project,
            file_name: String(item.file_name).split("/")[2],
            created_at: formattedDate,
            user: `${item?.user?.first_name} ${item?.user?.last_name}`,
            verArchivo: (
              <Button
                text={"Ver"}
                onClick={() => handlePreview(item.file_name)}
                isIcon={false}
              />
            ),
            acciones: (
              <Button
                text={"Descargar"}
                onClick={() => handleDownload(item.file_name)}
                isIcon={true}
                icon={<DownloadIcon h={"20"} w={"20"} />}
              />
            ),
          };
        });
        setDataArray(newArray);
      } catch (error) {
        console.error("Error al traer información de la base de datos:", error);
      } finally {
        setLoading(false);
      }
    };

    if (idUser) {
      fetchData();
    }
  }, [idUser]);

  const handleDownload = async (fileName) => {
    try {
      const blob = await DownloadFile("archivos", fileName);
      console.log("Archivo descargado:", blob);
      fileDownload(blob, fileName);
    } catch (error) {
      console.error("Error al descargar el archivo:", error);
    }
  };

  const handlePreview = async (fileName) => {
    try {
      const fileBlob = await DownloadFile("archivos", fileName);
      const fileExt = fileName.split(".").pop().toLowerCase();

      if (fileExt === "xlsx") {
        setFileType("excel");
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "binary" });
          const sheetNames = workbook.SheetNames;
          const sheet = workbook.Sheets[sheetNames[0]];
          const html = XLSX.utils.sheet_to_html(sheet);
          setFilePreviewContent(html);
          setShowModal(true);
        };
        reader.readAsBinaryString(fileBlob);
      } else if (fileExt === "docx") {
        setFileType("word");
        const arrayBuffer = await fileBlob.arrayBuffer();
        const result = await mammoth.convertToHtml({
          arrayBuffer: arrayBuffer,
        });
        setFilePreviewContent(result.value);
        setShowModal(true);
      } else {
        setFilePreviewContent("El archivo no es compatible para vista previa.");
        setShowModal(true);
      }
    } catch (error) {
      console.error("Error al obtener el archivo para vista previa:", error);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setFilePreviewContent("");
  };

  return (
    <Module title="Ver archivos" back={true} linkToBack={"/databases"}>
      <BackGroundSections>
        {loading ? (
          <ChargingComponent />
        ) : (
          <ViewTable to="/databases" rows={dataArray} tittles={tittles} />
        )}
      </BackGroundSections>

      {showModal && (
        <Modal onClose={closeModal}>
          <h3>Vista previa del archivo</h3>
          {fileType === "excel" ? (
            <div dangerouslySetInnerHTML={{ __html: filePreviewContent }} />
          ) : fileType === "word" ? (
            <div dangerouslySetInnerHTML={{ __html: filePreviewContent }} />
          ) : (
            <p>{filePreviewContent}</p>
          )}
        </Modal>
      )}
    </Module>
  );
}
