import { toast } from "sonner";

import { getUserData, supabase } from "utils/SupabaseClient";

//Crud Archivos
export async function fetchConnectFiles({ proveedor }) {
  const { data, error } = await supabase
    .from("dim_bi_origenes")
    .select("id, tipo, formato_fecha, columnas, columna_encabezados")
    .eq("proveedor", proveedor);

  if (error) {
    toast.error("Ha ocurrido un error, por favor intenta de nuevo.");
    return [];
  }
  return data;
}

//Crud Conexiones
export async function saveConnection({
  subCompanyId,
  proveedor,
  tipoArchivo,
  archivo,
  storage,
}) {
  const user = await getUserData();

  const { data, error } = await supabase.from("th_bi_origenes").insert({
    subcompany_id: subCompanyId,
    proveedor: proveedor,
    type: tipoArchivo,
    directory: archivo,
    source: `${subCompanyId}/${proveedor}/${tipoArchivo}/${archivo}`,
    storage: storage,
    created_by: user.id,
    updated_by: user.id,
  });

  if (!error) {
    await crearTicket(
      "2cc0996e-786f-4b7c-b25b-74749efce29c",
      "conexión de archivo",
      user.id,
      tipoArchivo,
      subCompanyId
    );
  }

  if (error) {
    toast.error("No se pudo crear la nueva conexión, intenta nuevamente.");
  }

  return data;
}

export async function fetchConnections(subcompanies) {
  const { data, error } = await supabase
    .from("th_bi_origenes")
    .select(
      `
      id,
      proveedor,
      type,
      directory,
      created_at,
      source,
      auth_user_subcompanies(
        id,
        razon_social
      )
    `
    )
    .in(
      "subcompany_id",
      subcompanies.map((item) => item.id_subcompanies.id)
    );

  if (error) {
    toast.error("Ha ocurrido un error, por favor intenta de nuevo.");
    return [];
  }
  return data;
}

export function connectionToConnections(data) {
  return data
    .filter((item) => item.type !== "API")
    .map((item) => ({
      id: item.id,
      proveedor: item.proveedor,
      tipo_archivo: item.type,
      archivo: item.directory,
      created_at: item.created_at,
      auth_user_subcompanies: item.auth_user_subcompanies,
    }));
}

export async function deleteConnection({ id }) {
  const { data, error } = await supabase
    .from("th_bi_origenes")
    .delete()
    .eq("id", id)
    .select();

  if (error) {
    toast.error("Ha ocurrido un error, por favor intenta de nuevo.");
    return [];
  }
  return data;
}

async function crearTicket(
  project_id,
  subcompany_name,
  user_id,
  proyect,
  subcompany_id
) {
  const message = `Usuario solicito ${subcompany_name}, el archivo es: ${proyect}, el tipo de conexión es: `;

  const { data, error } = await supabase.from("th_tickets").insert({
    project_id,
    title: "Solicitud conexión archivo " + proyect,
    description: message,
    type_id: 2,
    status_id: 1,
    created_by: user_id,
    modified_by: user_id,
    service_id: 47,
    subcompany_id,
  });
  if (error) return [];

  return data;
}
