import { useEffect, useState } from "react";
import { Module } from "../../components/Module";
import { getCoursesStudent } from "../../hooks/commonData";
import { getUserData } from "utils/SupabaseClient";
import { ChargingComponent } from "components/ChargingComponent";
import { TarjetCourses } from "components/TarjetCourses";

export function Training() {
  const [courses, setCourses] = useState([{}]);
  const [userId, setUserId] = useState([{}]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getUserData().then((r) => setUserId(r?.id));
    getCoursesStudent(userId).then((r) => setCourses(r));
    setLoading(false);
  }, [userId]);

  return (
    <Module title="Cursos Actuales" back={false}>
      {
        loading ?
          <ChargingComponent />
          : courses.length === 0 ? "No tienes cursos"
            :
            <div
              className={`lg:grid lg:grid-cols-4 gap-4 p-4 slide-down-animation`}
            >
              {courses.map((course) => (
                <TarjetCourses
                  key={course.id_curso}
                  to={
                    course?.estado_curso?.nombre_estado === "Bloqueado"
                      ? ""
                      : `/training/course/${course.id_curso}`
                  }
                  title={course?.info_curso?.nombre_curso}
                  description={course?.info_curso?.descripcion}
                  image={course?.info_curso?.nombre_miniatura}
                  value={course?.info_curso?.valor_curso}
                  status={course?.estado_curso?.nombre_estado}
                  colorId={course?.estado_curso?.color_id}
                />
              ))}
            </div>
      }
    </Module>
  );
}
