import { Button } from "components/common/Button";

export function AddReportButton({ handleOpen }) {
  return (
    <div
      onClick={handleOpen}
      className="hover:border-yellow-500 border-4 border-dashed border-slate-400 hover:opacity-75 p-8 flex rounded-lg hover:text-white text-slate-400 hover:bg-yellow-500 justify-center transition-all appear-animation h-auto cursor-pointer"
    >
      <Button className="text-3xl text-center" text="Añadir reporte +" />
    </div>
  );
}
