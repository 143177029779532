import { FilterComponent } from "./FilterComponent";

function THead({ tittle, filterText, data, setvalue }) {
  return (
    <th className="border border-slate-300 bg-slate-100 py-2">
      <div className="flex justify-between">
        <label>{tittle}</label>
        {filterText && <FilterComponent data={data} setvalue={setvalue} />}
      </div>
    </th>
  );
}

export function TRow({ row }) {
  return (
    <tr>
      {Object.values(row).map((value, index) => (
        <td
          key={index}
          className="border border-slate-200 text-gray-600 px-4 py-1"
        >
          {value}
        </td>
      ))}
    </tr>
  );
}

export function Table({ tittles, children, filterText, data, setvalue }) {
  return (
    <table className="table-auto border-collapse border border-slate-400 w-full">
      <thead>
        <tr>
          {tittles.map((tittle, index) => (
            <THead
              key={index}
              tittle={tittle}
              data={data}
              setvalue={setvalue}
              filterText={filterText}
            />
          ))}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
}
