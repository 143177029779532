import { supabase } from "utils/SupabaseClient";

export async function getUserModules(rolId) {
  const { data, error } = await supabase
    .from("tmp_modulos_roles")
    .select("tmp_modulos_plataforma(modulo, path, icon)")
    .eq("rol_id", rolId);
  if (error) {
    console.error(error);
  }
  return data;
}

export async function getNavbarItems(rol_id) {
  getUserModules(rol_id)
    .then((data) => {
      return data.map((module) => {
        return {
          icon: <i className={module.tmp_modulos_plataforma.icon}></i>,
          text: module.tmp_modulos_plataforma.modulo,
          active: false,
          alert: false,
          to: module.tmp_modulos_plataforma.path,
        };
      });
    })
    .catch((error) => {
      return {
        error: error,
        data: [],
      };
    });
}
