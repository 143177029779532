import { BackIcon } from "../components/SvgIcons/BackIcon";
import { Link } from "react-router-dom";
import { AutoHeigth } from "./AutoHeigth";

export function Module({ title, children, linkToBack, back }) {
  return (
    <div className="grow slide-down-animation">
      <div className="flex justify-between h-[45px] p-[7px]">
        <h1 className="text-2xl font-semibold text-sky-700">{title}</h1>
        <Link to={linkToBack} className={`${back === false ? "hidden" : ""}`}>
          <BackIcon h={"30"} w={"30"} />
        </Link>
      </div>
      <AutoHeigth>{children}</AutoHeigth>
    </div>
  );
}
