import { Route, Routes } from "react-router-dom";

import { Sidebar, SidebarItem, sideBarItems } from "../components/Sidebar";

import { Develop } from "./develop";
import { Training } from "./training";
import { NewCourse } from "./training/new";

import { DatabaseManagement } from "./databases";
import { NewDatabase } from "./databases/NewDatabase";
import { ViewDatabases } from "./databases/ViewDatabases";
import { DeleteDatabase } from "./databases/DeleteDatabase";
import { Course } from "./training/course";
import { VideosModules } from "./training/course/modules/videos";
import { ReportInformation } from "./reports/ReportInformation";
import { Reports } from "./reports";
import { ViewDocFiles } from "./documents";

export function App({ nombre, apellido, email }) {
  const modules = sideBarItems;

  return (
    <div className="flex">
      <Sidebar user={`${nombre} ${apellido}`} email={email}>
        {modules.map((item, index) => (
          <SidebarItem
            key={index}
            icon={item.icon}
            text={item.text}
            active={item.active}
            alert={item.alert}
            to={item.to}
          />
        ))}
      </Sidebar>
      <main className="grow bg-[#ebebeb]">
        <div className="p-[20px]">
          <Routes>
            <Route path="/" element={<h1>Bienvenid@! </h1>} />
            <Route path="/databases" element={<DatabaseManagement />} />
            <Route path="/databases/new" element={<NewDatabase />} />
            <Route
              path="/databases/view"
              element={<ViewDatabases user={nombre.toLowerCase()} />}
            />
            <Route path="/databases/delete" element={<DeleteDatabase />} />
            <Route
              path="/reports"
              element={<Reports nombre={`${nombre} ${apellido}`} />}
            />
            <Route
              path="/ReportInformation/:id_pbi"
              element={<ReportInformation />}
            />
            <Route path="/develop" element={<Develop />} />
            <Route
              path="/documents"
              element={<ViewDocFiles user={nombre.toLowerCase()} />}
            />
            <Route path="/training" element={<Training />} />
            <Route path="/training/new" element={<NewCourse />} />
            <Route path="/training/course/:id_course" element={<Course />} />
            <Route
              path="/training/course/:id_course/video/:id_video"
              element={<VideosModules />}
            />
            <Route path="*" element={<h1>404 Not Found</h1>} />
          </Routes>
        </div>
      </main>
    </div>
  );
}
