import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { getUserDetails } from "hooks/commonData";
import { getUserData } from "utils/SupabaseClient";
import { OtherNewApi } from "api/bi-module/integration";

import { Text } from "components/tailwind/Text";
import { ModalBG } from "components/common/ModalBG";
import { Select } from "components/tailwind/Select";
import { TextArea } from "components/tailwind/TextArea";
import { ModalError } from "components/modal/ModalError";
import { ButtonSave } from "components/common/ButtonSave";
import { ModalSuccess } from "components/modal/ModalSuccess";
import { ChargingComponent } from "components/common/ChargingComponent";

export function OtherModal({ otherRow, onClose, isActive }) {
  const navigate = useNavigate();
  const [company, setCompany] = useState([]);
  const [companyId, setCompanyId] = useState();
  const [companyName, setCompanyName] = useState();
  const [resumen, setResumen] = useState("");
  const [description, setDescription] = useState("");
  const [isSuccess, setIsSucces] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [ticketId, setTicketId] = useState(null);

  const redirectURL = () => {
    navigate(`/support/2/${ticketId}`);
  };

  const handleChange = async () => {
    setIsLoading(true);
    OtherNewApi({
      subcompany_id: companyId,
      title: resumen,
      description,
      companyName,
    }).then((ticket) => setTicketId(ticket));
    setIsLoading(false);
    setIsSucces(true);
  };

  useEffect(() => {
    getUserData().then((user) =>
      getUserDetails(user.id, "subcompanies").then((project) => {
        setCompany(project.company.projects);
      })
    );
  }, []);
  useEffect(() => {
    let companyfilter = company.filter((item) => companyId === item.id);
    setCompanyName(
      companyfilter.length > 0 ? companyfilter[0].razon_social : ""
    );
  }, [company, companyId]);

  return (
    <ModalBG open={otherRow} onClose={onClose} title="Solicitud de nueva API">
      {isLoading && <ChargingComponent />}
      {!isLoading && (
        <>
          {!isActive && (
            <ModalError
              title="No tienes conexiones disponibles"
              message="Por favor elimina una conexión existente o actualiza tu plan para poder crear una nueva."
              onClose={onClose}
            />
          )}
          {isActive && (
            <>
              {isSuccess ? (
                <ModalSuccess
                  title="Solicitud creada"
                  message="Se ha creado solicitud correctamente."
                  onClose={redirectURL}
                />
              ) : (
                <div className="grid gap-4">
                  <Select
                    field="company-connection"
                    title="Empresa"
                    onChange={setCompanyId}
                    options={company.map((item) => ({
                      value: item.id,
                      name: item.razon_social,
                    }))}
                    value={companyId}
                  />
                  <Text
                    field="reason-connection"
                    title="Motivo de la conexión"
                    value={resumen}
                    onChange={setResumen}
                  />
                  <TextArea
                    field="description-connection"
                    title="Descripción de la conexión"
                    value={description}
                    onChange={setDescription}
                  />
                  <ButtonSave HadleSubmit={handleChange} />
                </div>
              )}
            </>
          )}
        </>
      )}
    </ModalBG>
  );
}
