import { useNavigate } from "react-router-dom";
import { LogoSiigo } from "components/svg/LogoSiigo";
import { QuickBooksIcon } from "components/svg/QuickBooksIcon";
import { ArsaIsoType } from "components/svg/ArsaIsoType";

export function TemplateBanner({ children }) {
  return <div className="flex justify-center h-32 mb-4">{children}</div>;
}

export function ItemTemplate({
  title,
  custom,
  setActive,
  setReport,
  report,
  icon,
}) {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/budget");
  };
  const handleClick = () => {
    setActive(true);
    setReport(report);
  };

  return (
    <div className="appear-animation hover:scale-110 transition-all bg-white border border-gray-300 rounded-lg flex flex-col justify-center text-center w-28 px-2 pt-1 pb-2 mx-2">
      <div className="flex justify-center mb-2">
        {icon === 44 && <LogoSiigo className="h-6 w-18 mb-1" />}
        {icon === 46 && <QuickBooksIcon className="h-6 w-18 mb-1" />}
        {!icon && <ArsaIsoType className="ml-3 h-9 w-14 " />}
      </div>
      <hr className="border-sky-500" />
      <img
        className="object-contain h-16 w-full py-1"
        src="https://learn.microsoft.com/es-es/power-bi/consumer/media/end-user-report-open/power-bi-dashboards.png"
        alt={title}
      />
      <h2 className="text-[0.5rem] truncate">{title}</h2>
      <div
        onClick={custom ? handleClick : handleNavigate}
        className="text-[0.5rem] text-yellow-500 underline cursor-pointer"
      >
        {custom ? "Ver plantilla" : "Cotiza un desarrollo a la medida"}
      </div>
    </div>
  );
}
