import { Link } from "react-router-dom";
import { Files } from "components/tailwind/Files";
import { Comments } from "components/tailwind/Comments";
import { TextArea } from "components/tailwind/TextArea";
import { Comment } from "components/tailwind/FormComment";
import { DataPreview } from "components/common/DataPreview";
import { Pagination } from "components/tailwind/Pagination";
import { ViewIssueField } from "components/tailwind/ViewIssueField";
import { ViewIssueHeader } from "components/tailwind/ViewIssueHeader";
import { ViewIssueDocuments } from "components/tailwind/ViewIssueDocuments";
import { ViewIssueDescription } from "components/tailwind/ViewIssueDescription";
import { ViewIssueFieldStatus } from "components/tailwind/ViewIssueFieldStatus";

export function ViewIncidentUI({
  ticketBasic,
  ticketFields,
  documents,
  comments,
  setMessage,
  saveComment,
  updateCommnet,
  value,
  formatDate,
  isUpdate,
  setIsUpdate,
  handleUpdateComment,
  countR,
  getComments,
  user,
  files,
  setFiles,
  tabComment,
  setTabComment,
  filesLoaded,
  isLoadingComments,
}) {
  return (
    <div className="space-y-4 p-4 sm:px-8 sm:py-6 lg:p-4 xl:px-8 xl:py-6">
      <div className="flex justify-between">
        <ViewIssueHeader
          summary={ticketBasic.title}
          issueType={2}
          text={"Información detallada de la solicitud."}
        />
        <Link
          to="/support"
          className="hover:bg-sky-600 group flex items-center rounded-md bg-sky-700 text-white text-sm font-medium shadow-xs px-6 2xl:py-2 2xl:px-8"
        >
          Volver
        </Link>
      </div>
      <div className="h-[72vh] 2xl:h-[80vh] overflow-auto">
        <div className="border border-white bg-white rounded-md p-4">
          <p>
            <strong>
              {user?.first_name} {user?.last_name}
            </strong>{" "}
            generó la solicitud el {formatDate(ticketBasic.created_at)}
          </p>
          <div className="mt-4">
            <ViewIssueDescription
              label="Descripción"
              value={ticketBasic.description}
            />
          </div>
        </div>
        <div className="grid grid-cols-6 gap-8 mt-6">
          <div className="col-span-4 border border-white rounded-md bg-white p-4">
            <div className="mt-4">
              <h2 className="text-md font-bold leading-6 text-gray-900">
                Actividad
              </h2>
              <div className="flex gap-3">
                <button
                  className={`text-md leading-6 text-gray-900 mt-4 ${
                    tabComment === 1 && "font-bold"
                  }`}
                  onClick={() => setTabComment(1)}
                >
                  Comentarios
                </button>
                <button
                  className={`text-md leading-6 text-gray-900 mt-4 ${
                    tabComment !== 1 && "font-bold"
                  }`}
                  onClick={() => setTabComment(2)}
                >
                  Adjuntos
                </button>
              </div>
              <section className="mt-1 leading-6">
                {!isLoadingComments ? (
                  <>
                    {tabComment === 1 ? (
                      <div>
                        <div className="h-96 overflow-y-auto">
                          <Comments
                            comments={comments}
                            formatDate={formatDate}
                            handleUpdateComment={handleUpdateComment}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="h-96">
                        <div className="h-96 overflow-y-auto">
                          {documents?.length > 0 && (
                            <ViewIssueDocuments
                              documents={filesLoaded}
                              sectionComments={true}
                              formatDate={formatDate}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <DataPreview count={3} high={"h-16"} />
                )}
                {countR > 0 && (
                  <Pagination size={countR} getData={getComments} />
                )}
              </section>
              <Comment
                id="comment"
                isUpdate={isUpdate}
                setIsUpdate={setIsUpdate}
                setMessage={setMessage}
                message={value}
                saveComment={saveComment}
                updateCommnet={updateCommnet}
              >
                <TextArea
                  title={`${
                    isUpdate ? "Actualiza el comentario" : "Nuevo comentario"
                  }`}
                  field="comment"
                  description="Agrega un comentario..."
                  onChange={setMessage}
                  value={value}
                />
                <Files
                  title={"Adjuntos"}
                  message={"PNG, JPG, SVG, XLSX, CSV, PDF, DOC up to 50MB"}
                  files={files}
                  setFiles={setFiles}
                />
              </Comment>
            </div>
          </div>
          <div className="col-span-2 border border-white rounded-md bg-white p-4">
            <div className="mt-6 border-t border-gray-100">
              <dl className="divide-y divide-gray-100">
                <ViewIssueFieldStatus
                  label="Estado"
                  statusId={ticketBasic.status_id.status_name}
                />
                <ViewIssueField
                  label="Creado por"
                  value={ticketBasic.created_by.email}
                />
                <ViewIssueField
                  label="Proyecto"
                  value={ticketBasic.project_id.project_name}
                />
                <ViewIssueField
                  label="Servicio"
                  value={ticketBasic.service_id.item_value}
                />
                <ViewIssueField
                  label="Motivo del error"
                  value={
                    ticketFields?.find((e) => e.field_definition_id.id === 17)
                      ?.field_value
                  }
                />
                <ViewIssueField
                  label="Paso a paso de como ocurrio el error"
                  value={
                    ticketFields?.find((e) => e.field_definition_id.id === 18)
                      ?.field_value
                  }
                />
                {documents?.length > 0 && (
                  <ViewIssueDocuments documents={documents} />
                )}
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
