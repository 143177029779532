import { useState } from "react";

import { FormConnection } from "./FormConnection";
import { ModalBG } from "components/common/ModalBG";
import { ModalError } from "components/modal/ModalError";
import { ModalSuccess } from "components/modal/ModalSuccess";
import { ChargingComponent } from "components/common/ChargingComponent";

export function NewFile({ newRow, onClose, files, isActive, provider }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  return (
    <ModalBG open={newRow} onClose={onClose} title="Cargue archivo">
      {isLoading && <ChargingComponent />}
      {!isLoading && (
        <>
          {!isActive && (
            <ModalError
              title="No tienes conexiones disponibles"
              message="Por favor elimina una conexión existente o actualiza tu plan para poder crear una nueva."
              onClose={onClose}
            />
          )}
          {isActive && (
            <>
              {!isSuccess && (
                <FormConnection
                  files={files}
                  isSuccess={isSuccess}
                  setIsSuccess={setIsSuccess}
                  setIsLoading={setIsLoading}
                  isActive={isActive}
                  provider={provider}
                />
              )}
              {isSuccess && (
                <ModalSuccess
                  onClose={onClose}
                  title="Creado"
                  message="Se ha creado el registro correctamente, recarga la pagina para
              poder visualizar tu nueva conexión."
                />
              )}
            </>
          )}
        </>
      )}
    </ModalBG>
  );
}
