import { BackGroundSections } from "components/BackGroundSections";
import { Module } from "../../components/Module";
import { ModuleItem } from "../../components/ModuleItem";

export function DatabaseManagement() {
  return (
    <Module title="Gestion de bases de datos" back={false}>
      <div className="grid grid-cols-3 gap-9 p-9">
        <ModuleItem
          item="Ver archivos"
          imageURL="/assets/img/items/ver-conexiones.png"
          to="/databases/view"
        />
        <ModuleItem
          item="Cargar archivo"
          imageURL="assets/img/items/nueva-conexion.png"
          to="/databases/new"
        />
        <ModuleItem
          item="Eliminar archivo"
          imageURL="assets/img/items/eliminar-conexion.png"
          to="/databases/delete"
        />
      </div>
    </Module>
  );
}
