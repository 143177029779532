import { toast } from "sonner";

import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { FaLock } from "react-icons/fa";
import { XMarkIcon } from "@heroicons/react/24/solid";

import { downloadImage, getPublishedCourses } from "hooks/commonData";

import { CoursePurchaseModal } from "./CoursePurchaseModal";
import { StatusDetail } from "components/training/StatusDetail";

export function TarjetCourses({
  to,
  title,
  image,
  value,
  status,
  colorId,
  isLocked,
  courseId,
}) {
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [courseDetails, setCourseDetails] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();

  const fetchCourseDetails = async (id) => {
    try {
      const courses = await getPublishedCourses();
      const course = courses.find((course) => course.id === id);
      if (course) {
        setCourseDetails({
          title: course.nombre_curso,
          description: course.descripcion,
          image: course.nombre_miniatura,
          price: course.valor_curso,
        });
      }
    } catch (error) {
      toast.error("Ocurrio un error.");
    }
  };

  useEffect(() => {
    if (isLocked) {
      fetchCourseDetails(courseId);
    }
  }, [isLocked, courseId]);

  useEffect(() => {
    if (image) {
      downloadImage(image).then((url) => {
        setAvatarUrl(url);
      });
    }
  }, [image]);

  const handleClick = () => {
    if (isLocked && courseDetails) {
      setIsModalOpen(true);
    }
  };

  const handleExit = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {isLocked ? (
        <div
          onClick={handleClick}
          className="relative lg:bg-white lg:hover:scale-105 rounded-md px-[8px] py-[8px] transition-transform border-b border-red lg:border-none"
        >
          <img
            src={avatarUrl}
            alt={title}
            className="w-full rounded-lg slide-down-animation truncate"
          />
          <div className="absolute inset-0 bg-black/50 flex justify-center items-center rounded-lg">
            <FaLock className="text-white text-4xl" />
          </div>

          <div className="text-start ml-1">
            <p className="text-lg text-black slide-down-animation truncate">
              {title}
            </p>
          </div>
          <StatusDetail colorId={colorId} statusName={status} />
        </div>
      ) : (
        <Link
          to={to}
          className="relative lg:bg-white lg:hover:scale-105 rounded-md px-[8px] py-[8px] transition-transform border-b border-red lg:border-none"
        >
          <img
            src={avatarUrl}
            alt={title}
            className="w-full rounded-lg slide-down-animation"
          />
          <div className="text-start ml-1">
            <p className="text-lg text-black-900 slide-down-animation truncate">
              {title}
            </p>
          </div>
          <StatusDetail colorId={colorId} statusName={status} />
        </Link>
      )}

      {isModalOpen && courseDetails && (
        <CoursePurchaseModal
          isOpen={isModalOpen}
          onClose={handleExit}
          course={courseDetails}
        />
      )}

      {isModalOpen && (
        <div className="fixed inset-0 bg-black/50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-lg relative max-h-[80vh] overflow-y-auto">
            <button
              type="button"
              onClick={handleExit}
              className="absolute top-4 right-4 bg-red-500 hover:bg-yellow-500 p-2 text-white rounded-lg transition-colors"
            >
              <XMarkIcon className="h-4" />
            </button>
            <h2 className="text-2xl font-semibold text-left text-blue-600 mb-4">
              {courseDetails.title}
            </h2>
            <img
              src={avatarUrl}
              alt={courseDetails.title}
              className="w-full h-48 object-cover rounded-lg mb-4"
            />
            <p className="text-gray-700 mb-4 truncate">
              {courseDetails.description}
            </p>
            <p className="text-xl font-bold text-center">{`Precio: $${courseDetails.price} USD`}</p>
            <p className="text-gray-700 text-center">
              Podras disfrutar de este curso en un plan de pago.
            </p>
            <div className="mt-4 text-center">
              <div className="mt-4">
                <button
                  className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition cursor-pointer"
                  onClick={() => {
                    navigate("/plans");
                  }}
                >
                  Cambiar de plan
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
