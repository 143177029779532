import { addPicture, removePicture } from "hooks/commonData";
import { toast } from "sonner";
import { convertToBlob } from "utils/Operations";
import { UpdateDataMiniatureReport } from "./UpdateDataMiniatureReport";

export async function ReportImage({
  idReport,
  file,
  action,
  prevImage,
  link,
  setIsLoading,
  setEdit,
  name,
  description,
  setRecharge,
}) {
  setIsLoading(true);
  const uniqueFile = file[0];
  const fileName = uniqueFile?.name;
  const src = !fileName ? null : `miniaturas/${idReport}/${fileName}`;
  const blop = !fileName ? null : await convertToBlob(uniqueFile);
  console.log("uniqueFile", uniqueFile);
  console.log("prevImage", prevImage);
  console.log("blop", blop);
  console.log("src", src);

  if (action === "update") {
    if (uniqueFile && !prevImage && src && blop) {
      console.log("1");
      const addImage = await addPicture("miniaturaReportes", src, blop);
      if (addImage[0] === "error") {
        console.log(addImage[1]);
        toast.error("¡No fue posible guardar la miniatura!");
        setIsLoading(false);
      } else {
        await UpdateDataMiniatureReport({
          idReport,
          setIsLoading,
          setEdit,
          src,
          link,
          name,
          description,
          setRecharge,
        });
      }
    }

    if (uniqueFile && prevImage) {
      console.log("2");
      // File update with previous image
      const removeImage = await removePicture("miniaturaReportes", prevImage);
      if (removeImage[0] === "error") {
        console.log(removeImage[1]);
        toast.error("¡No fue posible guardar la miniatura!");
        setIsLoading(false);
      } else {
        const addImage = await addPicture("miniaturaReportes", src, blop);
        if (addImage[0] === "error") {
          console.log(addImage[1]);
          toast.error("¡No fue posible guardar la miniatura!");
          setIsLoading(false);
        } else {
          await UpdateDataMiniatureReport({
            idReport,
            setIsLoading,
            setEdit,
            src,
            link,
            name,
            description,
            setRecharge,
          });
        }
      }
    }

    if (!uniqueFile && prevImage) {
      console.log("3");
      // No new file or just updating prev image
      await UpdateDataMiniatureReport({
        idReport,
        setIsLoading,
        setEdit,
        src: prevImage,
        link,
        name,
        description,
        setRecharge,
      });
    }

    if (!uniqueFile && !prevImage && !src && !blop) {
      console.log("4");
      // No new file or just updating prev image
      await UpdateDataMiniatureReport({
        idReport,
        setIsLoading,
        setEdit,
        src: prevImage,
        link,
        name,
        description,
        setRecharge,
      });
    }
  }
}
