import { useState } from "react";

export function ButtonSlice({ icon, text, onClick, notification }) {
  const [hover, setHover] = useState(false);

  return (
    <div className="relative cursor-pointer">
      {notification && (
        <div className="absolute top-0 right-0">
          <span class="relative flex size-3">
            <span class="absolute inline-flex h-full w-full animate-ping rounded-full bg-yellow-300 opacity-75"></span>
            <span class="relative inline-flex size-3 rounded-full bg-yellow-500"></span>
          </span>
        </div>
      )}
      <button
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        className="bg-sky-800 hover:bg-yellow-500 p-2 text-white rounded-lg mt-1 mr-1 w-10 hover:w-40 transition-all duration-500 flex  cursor-pointer"
        type="button"
        onClick={onClick}
      >
        {icon}
        <span
          className={`overflow-hidden transition-all  ${
            hover ? "w-full" : "w-0"
          }`}
        >
          {text}
        </span>
      </button>
    </div>
  );
}
