import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  getVideos,
  downloadVideo,
  getStatusVideo,
  insertStatusVideo,
} from "hooks/commonData";
import { DropDownIcon } from "components/svg/DropDownIcon";

export function DropDownModule({
  userId,
  module,
  id_module,
  id_course,
  id_video,
  setMainVideo,
  setUrl,
  setVideo,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [videos, setVideos] = useState([]);
  const [statevideo, setStatevideo] = useState();
  const [videoCurrent, setVideoCurrent] = useState();
  const [moduleView, setModuloView] = useState([false]);

  const updatedVideos = new Set();

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleOnClick = (e) => {
    if (setMainVideo) {
      setMainVideo(e);
    }

    getVideos("", e)
      .then((r) => {
        setVideo(r);
        setVideoCurrent(r[0]);
        updateStatusVideo(r[0].id);
        return downloadVideo(r[0].url);
      })
      .then((url) => {
        setUrl(url);
      })
      .catch((error) => {
        console.error("Error handling video click:", error);
      });
  };

  const updateStatusVideo = async (id_video) => {
    try {
      const videoStatus = await getStatusVideo(userId, id_video);

      if (videoStatus.data.length === 0) {
        await insertStatusVideo(userId, id_video, id_course, new Date());
      }
    } catch (error) {
      console.error("Error fetching video status:", error);
    }
  };

  useEffect(() => {
    if (!id_module) {
      console.error("id_module is undefined or null");
      return;
    }

    getVideos(id_module, "", userId)
      .then((r) => {
        setVideos(r);
        let viewVideos = r.filter((video) => video.visto === true).length;
        setModuloView([viewVideos === r.length]);
      })
      .catch((error) => {
        console.error("Error fetching videos:", error);
      });
  }, [id_module, userId]);

  return (
    <>
      <div className="mr-2">
        <button
          className={`${
            moduleView[0] ? "bg-green-300 border" : "bg-white"
          } w-full p-2 rounded-lg slide-down-animation h-[40px] flex justify-between ml-2 hover:bg-yellow-100 transition-colors ${
            isOpen ? "bg-yellow-100" : ""
          }`}
          onClick={handleOpen}
        >
          <label className="text-start transition duration-300 ease-in-out">
            {module}
          </label>
          <div className={`flex pt-[1px] mr-2 justify-end`}>
            <DropDownIcon className={"w-5 h-5"} isopen={isOpen} />
          </div>
        </button>
      </div>
      <div
        className={`m-2 border-t border-slate-400 ${!isOpen ? "hidden" : ""}`}
      >
        {videos.map((item) => (
          <div
            key={item.id}
            className={`${
              Number(id_video) === Number(item.id) ? "bg-blue-100" : "bg-white"
            } p-2 border-b border-slate-400 hover:bg-blue-100 hover:bg-gradient-to-r transition-colors`}
          >
            <Link
              className="ml-5 slide-down-animation flex justify-between"
              to={`/training/course/${id_course}/video/${
                videoCurrent ? item.id : item.id
              }`}
              onClick={() => handleOnClick(item.id)}
            >
              <h1>{item.titulo_video}</h1>
              <span
                className={`h-[10px] w-[10px] mt-2 rounded-full ${
                  item.visto === true ? "bg-green-400" : "bg-slate-300"
                }`}
              ></span>
            </Link>
          </div>
        ))}
      </div>
    </>
  );
}
