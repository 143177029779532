import { toast } from "sonner";
import { supabase } from "utils/SupabaseClient";

export async function UpdateDataMiniatureReport({
  idReport,
  setIsLoading,
  setEdit,
  src,
  link,
  name,
  description,
  setRecharge,
}) {
  const { error } = await supabase
    .from("th_bi_link_report")
    .update({
      miniature: src,
      title: name,
      description: description,
    })
    .eq("id", idReport);

  if (error) {
    toast.error("¡No fue posible guardar la información!");
    setIsLoading(false);
  } else {
    setRecharge(true);
    setIsLoading(false);
    setEdit(false);
  }
}
