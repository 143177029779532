import { BackGroundSections } from "components/BackGroundSections";
import { StatusDetail } from "components/StatusDetail";
import ModuleManager from "./moduleManagerComponents/ModuleManager";

export function FilesSection({ colorId, statusName, id_course, setUrl, setVideo }) {
  return (
    <BackGroundSections>
      <div className="flex">
        <StatusDetail colorId={colorId} statusName={statusName} />
      </div>
      <ModuleManager id_course={id_course} setUrl={setUrl} setVideo={setVideo} />
    </BackGroundSections>
  );
}
