import { Button } from "components/common/Button";
import React, { useState, useEffect } from "react";
import { Section } from "./Sections";
import { getModule } from "hooks/commonData";
import { AddSection } from "api/training-module/AddSection";
import { AddIcon } from "components/svg/AddIcon";
import { ButtonAddFooter } from "components/common/ButtonAddFooter";
import { AddReportButton } from "modules/bi/submodules/reports/AddReportButton";

export default function ModuleManager({ id_course, setUrl, setVideo }) {
  const [sections, setSections] = useState([]);
  const [idCurso, setIdCurso] = useState(null);
  const [recharge, setRecharge] = useState(false);

  useEffect(() => {
    setIdCurso(String(id_course).split(",")[0]);
  }, [id_course]);

  useEffect(() => {
    if (idCurso || recharge) {
      getModule(idCurso).then((r) => {
        if (recharge && r) {
          setRecharge(false);
        }
        setSections(
          r.map((item) => ({
            id: item.id,
            title: item.nombre_modulo,
            state: item.estado,
            videoUrl: item.videoUrl || "",
            subsections: [],
          }))
        );
      });
    }
  }, [idCurso, recharge]);

  const handleAddSection = async () => {
    await AddSection({ setSections, id_curso: idCurso, setRecharge });
  };

  return (
    <div className="p-6 overflow-hidden">
      <div className={sections.length !== 0 ? "appear-animation" : "hidden"}>
        <Button
          onClick={handleAddSection}
          text="Añadir módulo"
          icon={<AddIcon className="w-4 h-4 mr-2" />}
          className="flex items-center bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-700 transition-all cursor-pointer"
        />
      </div>
      <div className="transition-all slide-down-animation overflow-y-auto max-h-full md:max-h-[450px] 2xl:max-h-[600px] p-4 ">
        {sections.map((section) => (
          <div key={section.id}>
            <Section
              sectionId={section.id}
              title={section.title}
              videoUrl={section.videoUrl}
              subsections={section.subsections || []}
              setUrl={setUrl}
              setVideo={setVideo}
              setRecharge={setRecharge}
              recharge={recharge}
              idCurso={idCurso}
            />
          </div>
        ))}

        <div className={sections.length !== 0 ? "appear-animation" : "hidden"}>
          <ButtonAddFooter onClick={handleAddSection} />
        </div>

        <div className={sections.length === 0 ? "appear-animation" : "hidden"}>
          <AddReportButton handleOpen={handleAddSection} />
        </div>
      </div>
    </div>
  );
}
