import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { validate as uuidValidate } from "uuid";

import { ViewRequestUI } from "./ViewRequestUI";
import { getTicketDetails } from "hooks/commonData";
import { toast } from "sonner";
import { getUserData, supabase } from "utils/SupabaseClient";
import { juan_projects_2_bd } from "utils/msalData";

export function ViewRequest() {
  const { issue } = useParams();
  const [message, setMessage] = useState("");
  const [user, setUser] = useState("");
  const [comments, setComments] = useState([]);
  const [ticketBasic, setTicketBasic] = useState();
  const [ticketFields, setTicketFields] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [idCommentForUpdate, setIdCommentForUpdate] = useState(null);
  const [countR, setCountR] = useState();

  //TODO: Implement S3 integration to get the real documents
  const documents = [
    {
      name: "Document.pdf",
      size: "1.5MB",
    },
    {
      name: "Document2.pdf",
      size: "2.5MB",
    },
  ];

  const getComments = async (limit, offset) => {
    let {
      data: ticket_comments,
      count,
      error,
    } = await supabase
      .from("ticket_comments")
      .select("*", { count: "exact" })
      .order("updated_at", { ascending: false })
      .range(offset, offset + limit - 1);
    if (error) {
      console.error("Error fetching comments:", error);
      return;
    }
    setComments(ticket_comments);
    setCountR(count);
  };

  const handleUpdateComment = (comment, id) => {
    setMessage(comment);
    setIdCommentForUpdate(id);
    setIsUpdate(true);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("es-ES", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  const handleSaveComment = async () => {
    const body = {
      ticket_id: ticketBasic.id,
      user_id: user,
      comment: message,
      created_by: user,
      modified_by: user,
    };
    const { data, error } = await supabase
      .from("ticket_comments")
      .insert([body])
      .select();

    if (error) {
      toast.error("Se produjo un error al intertan agregar su comentario");
      return;
    }
    setMessage("");
    await getComments(10, 0);
    toast.success("Su comentario se agregó exitosamente");
  };

  const UpdateComment = async () => {
    const body = {
      comment: message,
      updated_at: new Date(),
      modified_by: user,
    };

    const { data, error } = await supabase
      .from("ticket_comments")
      .update(body)
      .eq("id", idCommentForUpdate)
      .select();
    if (error) {
      toast.error("No se pudo actualizar el comentario");
    }
    setMessage("");
    setIsUpdate(false);
    await getComments(10, 0);
    toast.success("Su comentario se actualizó exitosamente");
  };

  useEffect(() => {
    getTicketDetails(issue, "basic").then((data) => {
      setTicketBasic(data);
    });
    getTicketDetails(issue, "fields").then((data) => {
      setTicketFields(data.ticket_fields);
    });

    getUserData().then((data) => {
      setUser(data.id);
    });
  }, []);

  useEffect(() => {
    getComments(10, 0);
  }, []);

  if (!ticketBasic || !ticketFields) {
    return <p>Cargando...</p>;
  } else if (ticketBasic || ticketFields) {
    return (
      <ViewRequestUI
        summary={ticketBasic.title}
        description={ticketBasic.description}
        createdBy={ticketBasic.created_by.email}
        project={ticketBasic.project_id.project_name}
        status={ticketBasic.status_id.status_name}
        service={ticketBasic.service_id.item_value}
        documents={documents}
        comments={comments}
        setMessage={setMessage}
        saveComment={handleSaveComment}
        updateCommnet={UpdateComment}
        formatDate={formatDate}
        value={message}
        isUpdate={isUpdate}
        setIsUpdate={setIsUpdate}
        handleUpdateComment={handleUpdateComment}
        countR={countR}
        getComments={getComments}
      />
    );
  }
}
