import { toast } from "sonner";
import { getUserData, supabase } from "utils/SupabaseClient";

export async function EditCompany({
  companyId,
  name,
  nit,
  address,
  email,
  setisLoading,
  setRecharge,
  setEdit,
}) {
  setisLoading(true);

  const user = await getUserData();

  const itemsUser = {
    company_name: name,
    nit: nit,
    address: address,
    email: email,
    modified_by: user.id,
  };

  const { data: User, error: ErrUser } = await supabase
    .from("auth_user_companies")
    .update(itemsUser)
    .eq("id", companyId);

  if (ErrUser) {
    setisLoading(false);
    setEdit(true);
    toast.error("Error al actualizar los datos");
  } else {
    setisLoading(false);
    setRecharge(true);
    setEdit(true);
    toast.success("Se ha actualizado los datos");
  }
}
