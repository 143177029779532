// import { useEffect, useState } from "react";
// import { NewConnection } from "../../components/NewConnection";
// import { BackGroundSections } from "components/BackGroundSections";
// import { Module } from "components/Module";
// import { NewfilesInsert } from "api/newFiles/route";
// import { getUserData } from "utils/SupabaseClient";

// export function NewDatabase() {
//   const projectList = ["Proyecto Lanuza", "Proyecto BI_Vistas"];
//   const [selectedProject, setSelectedProject] = useState(projectList[0]);
//   const [user, setUser] = useState("");
//   const [selectedFilesProps, setSelectedFilesProps] = useState([]);
//   const [base64Props, setBase64Props] = useState([]);
//   const [fileType, setFileType] = useState("");

//   useEffect(() => {
//     getUserData().then((r) => setUser(String(r.id)));
//   }, []);
//   const handleFileChange = async (files) => {
//     try {
//       const newFiles = [];
//       for (let i = 0; i < files.length; i++) {
//         const file = files[i];
//         const name = file.name;

//         const folder = fileType === "document" ? "DOC" : "BD";
//         const fileData = {
//           name: name,
//           project: selectedProject,
//           user: user,
//           folder: folder,
//         };

//         await NewfilesInsert({
//           name,
//           user,
//           selectedProject,
//           fileData,
//           newFiles,
//           base64Props,
//           file,
//           folder,
//         });
//       }
//     } catch (error) {
//       console.error("Error cargando archivos:", error);
//     } finally {
//       setSelectedFilesProps([]);
//     }
//   };

//   return (
//     <Module title="Crear archivos" back={true} linkToBack={"/databases"}>
//       <BackGroundSections>
//         <NewConnection
//           base64Props={setBase64Props}
//           projectList={projectList}
//           setSelectedProject={setSelectedProject}
//           setFileType={setFileType}
//           fileType={fileType}
//           informationUpload={
//             <div className="mt-5">
//               <h3>Archivos cargados</h3>
//             </div>
//           }
//           handleFileChange={handleFileChange}
//           selectedFilesProps={selectedFilesProps}
//         />
//       </BackGroundSections>
//     </Module>
//   );
// }

import { useEffect, useState } from "react";
import { NewConnection } from "../../components/NewConnection";
import { BackGroundSections } from "components/BackGroundSections";
import { Module } from "components/Module";
import { NewfilesInsert } from "api/newFiles/route";
import { getUserData } from "utils/SupabaseClient";

export function NewDatabase() {
  const projectList = ["Proyecto Lanuza", "Proyecto BI_Vistas"];
  const [selectedProject, setSelectedProject] = useState(projectList[0]);
  const [user, setUser] = useState("");
  const [selectedFilesProps, setSelectedFilesProps] = useState([]);
  const [base64Props, setBase64Props] = useState([]);
  const [fileType, setFileType] = useState("");

  useEffect(() => {
    getUserData().then((r) => setUser(String(r.id)));
  }, []);
  const handleFileChange = async (files) => {
    try {
      const newFiles = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const name = file.name;

        const folder = fileType === "document" ? "DOC" : "BD";
        const fileData = {
          name: name,
          project: selectedProject,
          user: user,
          folder: folder,
        };

        await NewfilesInsert({
          name,
          user,
          selectedProject,
          fileData,
          newFiles,
          base64Props,
          file,
          folder,
        });
      }
    } catch (error) {
      console.error("Error cargando archivos:", error);
    } finally {
      setSelectedFilesProps([]);
    }
  };

  return (
    <Module title="Crear archivos" back={true} linkToBack={"/databases"}>
      <BackGroundSections>
        <NewConnection
          base64Props={setBase64Props}
          projectList={projectList}
          setSelectedProject={setSelectedProject}
          setFileType={setFileType}
          fileType={fileType}
          informationUpload={
            <div className="mt-5">
              <h3>Archivos cargados</h3>
            </div>
          }
          handleFileChange={handleFileChange}
          selectedFilesProps={selectedFilesProps}
        />
      </BackGroundSections>
    </Module>
  );
}
