import { Module } from "../../../components/Module";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useState } from "react";
import { ModuleItem } from "./ModuleItem";

export function NewCourse() {
  const [defaultList, setDefaultList] = useState([
    { title: "Nuevo Modulo", value: 1 },
    { title: "Nuevo Modulo 2", value: 2 },
    { title: "Nuevo Modulo 3", value: 3 },
  ]);

  const handleDragEnd = () => {};

  return (
    <Module
      title={"Creación de un Nuevo Curso"}
      back={true}
      linkToBack={"/training"}
    >
      <div className="ml-1 grow mt-2">
        <div class="grid grid-cols-3">
          <div class=" bg-white p-2 rounded-lg">
            <DndContext
              colisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={defaultList}
                strategy={verticalListSortingStrategy}
              >
                {defaultList.map((item) => (
                  <ModuleItem Module={item} />
                ))}
              </SortableContext>
            </DndContext>
          </div>
          <div class="ml-2 col-span-2 bg-white p-2 rounded-lg">03</div>
        </div>
      </div>
    </Module>
  );
}
