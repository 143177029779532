import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InformationCircleIcon, TrashIcon } from "@heroicons/react/20/solid";
import { Button } from "components/common/Button";
import { ModalBG } from "components/common/ModalBG";
import { ReportDetail, ReportTemplate } from "./ReportModal";
import { BackIcon } from "components/svg/BackIcon";
import { ButtonSlice } from "components/common/ButtonSlice";
import { Subtitle } from "components/common/Subtitle";
import { deleteReport } from "api/bi-module/reports";
import { toast } from "sonner";
import { ButtonSave } from "components/common/ButtonSave";
import { ReportImage } from "api/bi-module/AddMiniature";
import { ChargingComponent } from "components/common/ChargingComponent";
import { DeleteModal } from "components/modal/ModalDelete";

export function ReportInformation({
  report,
  setReport,
  setActive,
  isTemplate,
  setIsRecharge,
  globalRecharge,
  connectionsActive,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [recharge, setRecharge] = useState(false);

  useEffect(() => {
    if (recharge) {
      setIsRecharge(true);
      handleExit();
    }
    if (isOpen) {
      setRecharge(false);
    }
  }, [recharge, isOpen]);

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleOpenDelete = () => {
    setIsDelete(!isDelete);
  };

  const handleExit = () => {
    setActive(false);
    setReport(null);
  };

  const handleDelete = async () => {
    setIsLoading(true);
    const isDeleted = await deleteReport(report.id, report.miniature);
    if (isDeleted) {
      setActive(false);
      setIsRecharge(true);
      setReport(null);
      setIsSuccess(true);
      setIsLoading(false);
      toast.success("¡Reporte eliminado exitosamente!");
    }
  };

  return (
    <>
      <div className="slide-down-animation">
        <div className="uppercase font-bold pb-4 flex items-center justify-between transition-all">
          <div className="text-sky-900">
            <Subtitle text={report.title} />
          </div>
          <div className="flex items-center space-x-2 mt-1">
            <ButtonSlice
              icon={<InformationCircleIcon className="h-6" />}
              text="Información"
              onClick={handleOpen}
              notification
            />
            {!isTemplate && (
              <Button
                icon={
                  <TrashIcon className="h-6 text-white-600 cursor-pointer" />
                }
                onClick={handleOpenDelete}
                className="h-10 bg-slate-400 text-white font-bold rounded-md w-10 mt-1 hover:bg-red-500 transition-all"
              />
            )}

            <BackIcon w={30} h={30} onClick={handleExit} />
          </div>
        </div>
        <div className="border-4 border-zinc-300 bg-white rounded-md p-1">
          <iframe
            title="g"
            className="w-full h-[30rem] 2xl:h-[50rem]"
            src={report.url}
            allowFullScreen={true}
          />
        </div>

        <DeleteModal
          handleDelete={handleDelete}
          isLoading={isLoading}
          isSuccess={isSuccess}
          title="Eliminar Reporte"
          onClose={handleOpenDelete}
          open={isDelete}
          message="para confirmar"
        />

        <ReportModal
          item={report}
          open={isOpen}
          recharge={recharge}
          onClose={handleOpen}
          isTemplate={isTemplate}
          setRecharge={setRecharge}
          globalRecharge={globalRecharge}
          connectionsActive={connectionsActive}
        />
      </div>
    </>
  );
}

function ReportModal({
  item,
  open,
  onClose,
  isTemplate,
  globalRecharge,
  setRecharge,
  recharge,
  connectionsActive,
}) {
  const navigate = useNavigate();
  const [isEdit, setEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSave, setIsSave] = useState(false);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [length, setLength] = useState(0);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");

  const handleSupport = () => {
    navigate("/support");
  };

  useEffect(() => {
    if (length <= 1) {
      setIsSave(true);
    } else {
      setIsSave(false);
      toast.error("¡solo puedes subir una miniatura!");
    }
  }, [length]);

  const save = async () => {
    await ReportImage({
      idReport: item.id,
      name,
      description,
      file: selectedFiles,
      action: "update",
      prevImage: item.miniature,
      link,
      setIsLoading,
      setEdit,
      setRecharge,
    });
  };

  useEffect(() => {
    if (recharge) {
      setName("");
      setDescription("");
      setLink("");
    }
  }, [recharge]);

  return (
    <ModalBG
      title={
        isTemplate
          ? `¡Conecta, carga y recibe tu reporte ${item.title}!`.toLocaleUpperCase()
          : `${isEdit && "Editar "}Información del reporte`.toLocaleUpperCase()
      }
      open={open}
      onClose={onClose}
    >
      <div className={isLoading ? "appear-animation" : "hidden"}>
        <ChargingComponent />
      </div>
      <div className={!isLoading ? "appear-animation" : "hidden"}>
        {isTemplate && (
          <ReportTemplate
            globalRecharge={globalRecharge}
            connectionsActive={connectionsActive}
            item={item}
            onClose={onClose}
          />
        )}
        {!isTemplate && (
          <>
            <ReportDetail
              item={item}
              isEdit={isEdit}
              setSelectedFiles={setSelectedFiles}
              setLength={setLength}
              setDescription={setDescription}
              description={description}
              setName={setName}
              name={name}
              link={link}
              setLink={setLink}
            />
            <div className="flex justify-end space-x-4">
              <div className={isEdit && "mt-5"}>
                <Button
                  type="button"
                  text={!isEdit ? "Editar" : "Cancelar"}
                  className={
                    !isEdit
                      ? ""
                      : "bg-slate-400 hover:bg-red-500 transition-all rounded-lg text-white p-2"
                  }
                  onClick={() => setEdit(!isEdit)}
                />
              </div>
              {isEdit ? (
                <div className={!isSave ? "hidden" : "appear-animation"}>
                  <ButtonSave HadleSubmit={save} />
                </div>
              ) : (
                <Button
                  onClick={handleSupport}
                  className="bg-gray-400 px-2 py-1 rounded-md text-white font-bold hover:bg-yellow-500 transition-all"
                  type="button"
                  text="Ir a soporte"
                />
              )}
            </div>
          </>
        )}
      </div>
    </ModalBG>
  );
}
