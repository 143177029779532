export function InputNumber({ title, field, onChange, required }) {
    const handleChange = (e) => {
      onChange(e.target.value);
    };
  
    return (
      <div className="col-span-full w-full">
        <label
          htmlFor={field}
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {title} {required && <span className="text-red-500">*</span>}
        </label>
        <div className="mt-2">
          <input
            id={field}
            name={field}
            type="number"
            onChange={handleChange}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-700 sm:text-sm sm:leading-6"
          />
        </div>
      </div>
    );
  }
  