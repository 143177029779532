const { toast } = require("sonner");
const { supabase, getUserData } = require("utils/SupabaseClient");

// Obtener detalles de un usuario

export async function getUserDetails(user, details) {
  let selectInput = "*";

  switch (details) {
    case "plan":
      selectInput =
        "first_name, last_name, activo, company_id(id,id_plan(*)), auth_user_roles(role_id(id, role_name))";
      break;
    case "basic":
      selectInput = "id, first_name, last_name, company_id, activo";
      break;
    case "projects":
      selectInput = `company:auth_user_companies!company_id( projects:th_user_projects!company_id(id, project_name))`;
      break;
    //TODO: Delete conexión de commonData
    case "subcompanies":
      selectInput =
        "company:auth_user_companies!company_id( projects:auth_user_subcompanies!company_id(id, numero_documento, razon_social, nombre_responsable, apellido_responsable, activo,correo_responsable, celular_responsable,indicativo_celular, is_principal))";
      break;
    case "subcompanies-relation":
      selectInput =
        "company:th_user_subcompanies!id_user( id_subcompanies(id, numero_documento, razon_social, nombre_responsable, apellido_responsable, activo,correo_responsable, celular_responsable,indicativo_celular) )";
      break;
    //TODO: END Todo para remplazar datos
    case "tickets":
      selectInput = "th_tickets!created_by(id)";
      break;
    case "image":
      selectInput = "id, profile_picture";
      break;
    case "all":
      selectInput = "*";
      break;
    default:
      selectInput = "*";
      break;
  }

  const { data, error } = await supabase
    .from("auth_users")
    .select(selectInput)
    .eq("id", user);

  if (error) {
    console.log(error.message);
    toast.error("Error al obtener el detalle del usuario");
  }

  return data[0];
}

export async function getUsersCompany(companyId) {
  const { data, error } = await supabase
    .from("auth_users")
    .select(
      `id, first_name, last_name, activo, company_id(id,id_plan(*)), auth_user_roles(role_id(id, role_name)), company_id, email, documento, activo, tipo_documento, phone, documento`
    )
    .eq("company_id", companyId);
  return data;
}

export async function getStateCourses() {
  const { data, error } = await supabase
    .from("dim_capacitacion_estados_cursos")
    .select("id, nombre_estado, color_id");

  if (error) {
    console.error("Error al obtener los estados de los cursos:", error);
  } else {
    return data;
  }
}

// Obtener detalle de los módulos de usuario

export async function getUserModules() {
  const { data, error } = await supabase
    .from("dim_modulos_plataforma")
    .select("id, modulo, path, icon");

  if (error) {
    console.error(error);
    return [];
  }

  return data;
}

// Obtener detalles de un proyecto

export async function getProjectDetails(project, details) {
  let selectInput = "*";

  switch (details) {
    case "ids":
      selectInput = "id, company_id, manager_id, created_by, modified_by";
      break;
    case "basic":
      selectInput = `id, project_name, code, description, company_id(company_name), manager_id(email), created_by(email), modified_by(email)`;
      break;
    case "services":
      selectInput = `id,  th_user_projects_services(services: enum_field_items(id, item_value))`;
      break;
    case "users":
      selectInput = "id, th_user_projects_users(user_id)";
      break;
    case "tickets":
      selectInput = "id, th_tickets(id)";
      break;
    case "all":
      selectInput = "*";
      break;
    default:
      selectInput = "*";
  }
  const { data, error } = await supabase
    .from("th_user_projects")
    .select(selectInput)
    .eq("id", project);
  if (error) {
    toast.error("Error al obtener el detalle del proyecto");
  }
  return data[0];
}

// Obtener detalle de un ticket

export async function getTicketDetails(ticket, details) {
  let selectInput = "*";

  switch (details) {
    case "ids":
      selectInput =
        "id,project_id, type_id, assigned_to, status_id, created_by, modified_by";
      break;
    case "basic":
      selectInput = `id, title, description, project_id(project_name), created_at, type_id(type_name), service_id(item_value),
                     assigned_to(email), status_id(status_name), created_by(email), modified_by(email)`;
      break;
    case "fields":
      selectInput =
        "id, th_ticket_fields(field_definition_id(id, field_name), field_value)";
      break;
    case "all":
      selectInput = "*, th_ticket_fields(field_definition_id, field_value)";
      break;
    default:
      selectInput = "*";
  }

  const { data, error } = await supabase
    .from("th_tickets")
    .select(selectInput)
    .eq("id", ticket);
  if (error) {
    toast.error("Error al obtener el detalle del ticket");
  }
  return data[0];
}

// Obtener detalle de una empresa

export async function getOrganizationDetails(organization, details) {
  let selectInput = "*";

  switch (details) {
    case "basic":
      selectInput = `id, company_name, nit, address, industry, created_by, modified_by`;
      break;
    case "users":
      selectInput =
        "id, auth_users(id, first_name, last_name, email, role_id(id, role_name))";
      break;
    case "projects":
      selectInput = "id, th_user_projects(id, project_name)";
      break;
    case "all":
      selectInput = "*";
      break;
    default:
      selectInput = "*";
  }

  const { data, error } = await supabase
    .from("auth_user_companies")
    .select(selectInput)
    .eq("id", organization);

  if (error) {
    toast.error("Error al obtener el detalle de la empresa");
    return null;
  }

  return data ? data[0] : null;
}

// Obtener todos los proyectos

export async function getAllProjects() {
  const { data, error } = await supabase.from("th_user_projects").select("*");
  if (error) {
    toast.error("Error obteniendo proyectos", error);
    return [];
  }
  return data;
}

// Obtener todos los tickets del usuario actual
export async function getAllUserTickets(
  loggedUserId,
  limit,
  offset,
  dataFilter
) {
  try {
    // Obtener los detalles del usuario, como los proyectos a los que tiene acceso
    const projects = await getUserDetails(loggedUserId, "subcompanies");

    if (
      !projects ||
      !projects.company.projects ||
      projects.company.projects.length === 0
    ) {
      toast.error("No se encontraron empresas asociados al usuario.");
      return [];
    }

    // Iniciar la consulta
    const query = supabase
      .from("th_tickets")
      .select(
        "id, title, created_at, description, type_id, type: type_id(type_name), status_id, status: status_id(status_name), service_id(item_value)",
        { count: "exact" }
      )
      .in(
        "subcompany_id",
        projects.company.projects.map((project) => project.id)
      )
      .range(offset, offset + limit - 1);

    if (dataFilter.stateSelected && dataFilter.stateSelected !== "-1") {
      query.eq("status_id", dataFilter.stateSelected);
    }
    if (dataFilter.projectSelected && dataFilter.projectSelected !== "-1") {
      query.eq("project_id", dataFilter.projectSelected);
    }

    if (dataFilter.dateStart) {
      let startDateTime = `${dataFilter.dateStart} 00:00:00`;
      query.gte("created_at::date", startDateTime); // gte: mayor o igual que
    }

    if (dataFilter.dateEnd) {
      let endDateTime = `${dataFilter.dateEnd} 00:00:00`;
      query.lte("created_at", endDateTime); // lte: menor o igual que
    }

    const { data, count, error } = await query;
    // Manejo de errores de la consulta
    if (error) {
      toast.error("Error obteniendo los tickets", error);
      return [];
    }

    return { data, count, error };
  } catch (error) {
    toast.error("Error inesperado al obtener los tickets", error);
    return [];
  }
}

// Obtener todos los tickets de un usuario
export async function getAllUserProjects(userId) {
  const projects = await getUserDetails(userId, "projects");

  const { data, error } = await supabase
    .from("th_user_projects")
    .select(
      "id, project_name, description, th_user_projects_services(services: enum_field_items(item_value))"
    )
    .in(
      "id",
      projects.company.projects.map((project) => project.id)
    );
  if (error) {
    toast.error("Error obteniendo los tickets", error);
    return [];
  }
  return data;
}

// Obtener proyectos por una compañia especifico
export async function getAllProyectForCompany(companyId) {
  const { data, error } = await supabase
    .from("th_user_projects")
    .select("project_name, id")
    .eq("company_id", companyId);

  if (error) {
    toast.error("Error obteniendo los proyectos", error);
    return [];
  }

  return data;
}

// Obtener los tipos de ticket
export async function getTicketTypes() {
  const { data, error } = await supabase
    .from("dim_ticket_types")
    .select("id, type_name");
  if (error) {
    toast.error("Error obteniendo el tipo de ticket", error);
    return [];
  }
  return data;
}

// Items de los desplgables de los formularios de tickets
export async function getEnumFieldItems(field) {
  const { data, error } = await supabase
    .from("enum_field_items")
    .select("id,item_value, field_definition_id")
    .eq("field_definition_id", field);
  if (error) {
    toast.error("Error obteniendo los items del desplegable", error);
    return [];
  }
  return data;
}

// obtener todos los estados de los tickets
export async function getStatusValues() {
  const { data, error } = await supabase
    .from("dim_ticket_statuses")
    .select("id, status_name");
  if (error) {
    toast.error("Error obteniendo los items del desplegable", error);
    return [];
  }
  return data;
}

export async function getCoursesToBuy() {
  const { data, error } = await supabase
    .from("dim_capacitacion_cursos")
    .select("id, nombre_curso, descripcion, valor_curso, nombre_miniatura")
    .eq("estado_curso", "activo");

  if (error) {
    console.error("Error obteniendo los cursos: ", error);
    return [];
  }

  return data;
}

// obtener los cursos
export async function getCourses(id) {
  let query = supabase.from("dim_capacitacion_cursos").select(
    `
      id,
      nombre_curso,
      descripcion,
      nombre_miniatura,
      valor_curso,
      estado_curso,
      estado: estado_curso(nombre_estado,color_id)
    `
  );

  if (id) {
    query = query.eq("id", id);
  }

  const { data, error } = await query;
  if (error) {
    toast.error("Error obteniendo los items del desplegable", error);
    return [];
  }

  return data;
}

// Consulta para obtener los detalles de un curso (estado y publicado)
export async function getCourseDetails(id) {
  const { data, error } = await supabase
    .from("dim_capacitacion_cursos")
    .select("estado_curso, publicado")
    .eq("id", id)
    .single();

  if (error) {
    console.error("Error obteniendo los detalles del curso:", error);
    return null;
  }

  return data;
}

// Consulta para actualizar el estado y la visibilidad del curso
export async function updateCourseStatus(id, newStatus, newPublished) {
  const { data, error } = await supabase
    .from("dim_capacitacion_cursos")
    .update({ estado_curso: newStatus, publicado: newPublished })
    .eq("id", id);

  if (error) {
    console.error("Error actualizando el curso:", error);
    return { error };
  }

  return { data };
}

// Obtener todos los cursos publicados
export async function getPublishedCourses() {
  try {
    let query = supabase
      .from("dim_capacitacion_cursos")
      .select(
        "id, nombre_curso, descripcion, nombre_miniatura, valor_curso, estado_curso(nombre_estado,color_id), publicado"
      )
      .eq("publicado", true);

    const { data, error } = await query;

    if (error) {
      console.error("Error al obtener los cursos publicados:", error);
      return [];
    }

    return data;
  } catch (error) {
    console.error("Error en la obtención de cursos publicados:", error);
    return [];
  }
}

// obtener los cursos comprados
export async function getCoursesStudent(id_usuario) {
  try {
    let query = supabase
      .from("th_capacitacion_curso_comprado")
      .select(
        `
        id_curso,
        estado_curso(id, nombre_estado , color_id),
        info_curso: id_curso(
          nombre_curso,
          descripcion,
          nombre_miniatura,
          valor_curso,
          estado_curso
        )
      `
      )
      .eq("id_usuario", id_usuario);

    const { data, error } = await query;

    if (error) {
      console.error("Error obteniendo los cursos del usuario:", error);
      return [];
    }

    return data;
  } catch (error) {
    console.error("Error en la obtención de cursos:", error);
    return [];
  }
}

// Obtener plan del usuario según compañía asignada
export async function getUserCompanyPlan(userId) {
  try {
    const { data: userData, error: userError } = await supabase
      .from("auth_users")
      .select("company_id")
      .eq("id", userId)
      .single();

    if (userError) {
      console.error("Error al obtener la compañía del usuario:", userError);
      return null;
    }

    const { data: companyData, error: companyError } = await supabase
      .from("auth_user_companies")
      .select("id_plan")
      .eq("id", userData.company_id)
      .single();

    if (companyError) {
      console.error("Error al obtener el plan de la compañía:", companyError);
      return null;
    }

    return {
      company_id: userData.company_id,
      id_plan: companyData.id_plan,
    };
  } catch (error) {
    console.error("Error al obtener los datos de la compañía y plan:", error);
    return null;
  }
}

// Cursos plan gratis
export async function assignFreeCoursesToUser(id_usuario) {
  try {
    let { data: cursosGratis, error: cursosError } = await supabase
      .from("dim_capacitacion_cursos")
      .select("id")
      .eq("es_pago", false);

    if (cursosError) throw new Error(cursosError.message);

    const cursoInsertPromises = cursosGratis.map(async (curso) => {
      const { data: existingCourse, error: checkError } = await supabase
        .from("th_capacitacion_curso_comprado")
        .select("id_curso")
        .eq("id_usuario", id_usuario)
        .eq("id_curso", curso.id)
        .single();

      if (!existingCourse) {
        const { error } = await supabase
          .from("th_capacitacion_curso_comprado")
          .insert([{ id_usuario, id_curso: curso.id, estado_curso: 1 }]);

        if (error) {
          console.error("Error al asignar curso:", error);
        }
      }
    });

    await Promise.all(cursoInsertPromises);
    return "Cursos gratuitos asignados correctamente.";
  } catch (error) {
    console.error("Error en la asignación de cursos gratuitos:", error);
    return [];
  }
}

// Cursos usuario con plan pago
export async function assignActiveCoursesToUser(id_usuario) {
  try {
    let { data: cursosActivos, error: cursosError } = await supabase
      .from("dim_capacitacion_cursos")
      .select("id")
      .eq("plan_activo", true);

    if (cursosError) throw new Error(cursosError.message);

    const cursoInsertPromises = cursosActivos.map(async (curso) => {
      const { data: existingCourse, error: checkError } = await supabase
        .from("th_capacitacion_curso_comprado")
        .select("id_curso")
        .eq("id_usuario", id_usuario)
        .eq("id_curso", curso.id)
        .single();

      if (!existingCourse) {
        const { error } = await supabase
          .from("th_capacitacion_curso_comprado")
          .insert([{ id_usuario, id_curso: curso.id, estado_curso: 1 }]);

        if (error) {
          console.error("Error al asignar curso:", error);
        }
      }
    });

    await Promise.all(cursoInsertPromises);
    return "Cursos activos asignados correctamente.";
  } catch (error) {
    console.error("Error en la asignación de cursos activos:", error);
    return [];
  }
}

// Archivos de usuario
export async function getUserFiles(id_user) {
  let query = supabase
    .from("th_databases_user_files")
    .select(
      "user_project, file_name, created_at, user(first_name, last_name), user"
    )
    .eq("user", id_user);
  const { data, error } = await query;
  if (error) {
    toast.error("Error obteniendo los items del desplegable", error);
    return [];
  }
  return data;
}

// Contador de videos
export async function getVideosWatched(id_usuario, id_video) {
  let query = supabase
    .from("th_capacitacion_estado_videos_usuarios")
    .select(
      `
id_estado
    `
    )
    .eq("id_usuario", id_usuario)
    .eq("id_video", id_video);
  const { data, error } = await query;
  if (error) {
    toast.error("Error obteniendo los items del desplegable", error);
    return [];
  }
  return data;
}

// obtener modulos
export async function getModule(course_id) {
  let query = supabase
    .from("dim_capacitacion_modulos")
    .select(
      `
      id,
      nombre_modulo,
      info_curso: curso_id(nombre_curso,nombre_miniatura)
      `
    )
    .eq("curso_id", course_id)
    .order("id", { ascending: true });

  const { data, error } = await query;

  if (error) {
    toast.error("Error obteniendo los items del desplegable", error);
    return [];
  }
  return data;
}

// obtener pbi
export async function getSUPPLIES(video_id) {
  let query = supabase
    .from("dim_capacitacion_insumos")
    .select(`name_file`)
    .eq("id_video", video_id);

  const { data, error } = await query;
  if (error) {
    toast.error("Error obteniendo los items del desplegable", error);
    return [];
  }
  return data;
}

// obtener insumos
export async function getPBI(user_id) {
  let query = supabase
    .from("th_report_dashboards_pbi")
    .select(`id, link_pbi, name`)
    .eq("id_user", user_id);

  const { data, error } = await query;

  if (error) {
    toast.error("Error obteniendo los items del desplegable", error);
    return [];
  }
  return data;
}

// obtener Videos de los modulos
export async function getVideos(module_id, video_id, userId) {
  let query = supabase.from("dim_capacitacion_videos").select(
    `
      id,
      titulo_video,
      url,
      descripcion,
      info_curso:id_modulo(nombre_modulo, curso_id(id,nombre_curso, descripcion)),
      id_modulo
    `
  );

  if (module_id !== "") {
    query.eq("id_modulo", module_id);
  }

  if (video_id !== "") {
    query.eq("id", video_id);
  }

  const { data, error } = await query;

  if (error) {
    toast.error("Error obteniendo los items del desplegable", error);
    return [];
  }

  let videosConEstado;
  videosConEstado = data;
  if (userId !== "") {
    videosConEstado = await Promise.all(
      data.map(async (video) => {
        try {
          const data = await getStatusVideo(userId, video.id);
          if (data.data.length > 0) {
            video.status = data?.data[0].estado;
          } else {
            video.status = undefined;
          }
        } catch (err) {
          console.error("Error al obtener el estado del video:", err);
          video.status = err;
        }

        return video;
      })
    );
  }
  return videosConEstado;
}
// obtener Videos de los modulos sin estado
export async function getVideosWithoutState(id_modulo) {
  console.log(id_modulo);
  const { data, error } = await supabase
    .from("dim_capacitacion_videos")
    .select(
      `
      id,
      titulo_video,
      url,
      descripcion,
      info_curso:id_modulo(nombre_modulo, curso_id(id,nombre_curso, descripcion)),
      id_modulo
    `
    )
    .eq("id_modulo", id_modulo);

  if (error) {
    toast.error("Error obteniendo los items del desplegable", error);
    return [];
  } else {
    return data;
  }
}

// descargar archivos
export const DownloadFile = async (bucket, path) => {
  try {
    const { data, error } = await supabase.storage
      .from(bucket)
      .download(path[0].name_file);
    if (error) throw new Error("An error occured downloading files");

    return data;
  } catch (error) {
    console.log(error);
  }
};

// // obtener las imagenes "miniaturas" de los cursos
export async function downloadImage(path) {
  try {
    const { data } = supabase.storage
      .from("cursos")
      .getPublicUrl(`miniaturas/${path}`);

    const url = data.publicUrl;
    return url;
  } catch (error) {
    console.log("Error descargando imagen: ", error.message);
  }
}

// obtener las imagenes "miniaturas" de los cursos con path cifrado
// export async function downloadVideo(path) {
//   try {
//     const { data } = supabase.storage.from("videos").getPublicUrl(`${path}`);

//     const url = data.publicUrl;
//     return url;
//   } catch (error) {
//     console.log("Error descargando imagen: ", error.message);
//   }
// }

export async function downloadVideo(path) {
  try {
    const { data, error } = await supabase.storage
      .from("videos")
      .createSignedUrl(path, 8);

    if (error) {
      throw new Error(error.message);
    }

    const url = data.signedUrl;
    return url;
  } catch (error) {
    console.log("Error generando URL firmada: ", error.message);
  }
}

// Obtiene el estado del los videos para saber si ya fue visto o no
export async function getStatusVideo(id_user, id_video) {
  try {
    if (id_user) {
      const { data, error } = await supabase
        .from("th_capacitacion_video_progress")
        .select("*")
        .eq("id_usuario", id_user)
        .eq("id_video", id_video);

      if (error) {
        throw new Error(error);
      }

      return { data: data, error: error };
    }
    return { data: null, error: null };
  } catch (error) {
    return { data: null, error: error.message };
  }
}

export async function getCountVideosViewed(userId, courseId) {
  let query = supabase
    .from("th_capacitacion_video_progress")
    .select("*")
    .eq("id_usuario", userId)
    .eq("id_curso", courseId);

  const { data, error } = await query;
  if (error) {
    console.error(error);
  }

  return data.length;
}

// Insertar un registro para saber si el video fue visto
export async function insertStatusVideo(
  id_user,
  id_video,
  id_course,
  viewDate
) {
  try {
    const { data, error } = await supabase
      .from("th_capacitacion_video_progress")
      .upsert([
        {
          id_usuario: id_user,
          id_video: id_video,
          id_curso: id_course,
          fecha_ultima_vista: viewDate,
        },
      ]);

    if (error) {
      console.error("Error updating video status:", error);
    }

    return data;
  } catch (error) {
    console.error("Error inserting video status:", error);
  }
}

export async function getCountVideos(courseId) {
  const { data, error } = await supabase
    .from("dim_capacitacion_videos")
    .select("id_modulo(curso_id)");

  if (error) {
    console.error("Error obteniendo videos:", error);
    return 0;
  }

  const count = data.filter(
    (video) => video.id_modulo?.curso_id === parseInt(courseId, 10)
  ).length;
  return count;
}

export async function getDataUser(user_id) {
  const { data, error } = await supabase
    .from("auth_users")
    .select(" first_name, last_name")
    .eq("id", `${user_id}`);
  if (error) {
    console.error(error);
  }
  return data;
}

export async function getAllUser() {
  const { data, error } = await supabase
    .from("auth_users")
    .select("id, first_name, last_name");
  if (error) {
    console.error(error);
    return [];
  }
  return data;
}

// Obtener los usuarios asignados a un curso
export async function getUsersInCourse(courseId) {
  const { data, error } = await supabase
    .from("th_user_courses")
    .select("user_id, created_at")
    .eq("course_id", courseId);

  if (error) {
    console.error("Error al obtener los usuarios del curso", error);
    return [];
  }

  return data;
}

export async function getUserInfromation() {
  const user = await getUserData();

  const { data, error } = await supabase
    .from("auth_users")
    .select("*")
    .eq("id", user.id);

  if (error) {
    toast.error("Error al obtener información del usuario", error);
    return [];
  }

  return data;
}

// Asignar un usuario a un curso
export async function assignUserToCourse(courseId, userId) {
  const { error } = await supabase
    .from("th_user_courses")
    .insert([{ course_id: courseId, user_id: userId }]);

  if (error) {
    throw new Error("Error al asignar usuario al curso: " + error.message);
  }
}

// Eliminar un usuario de un curso
export async function removeUserFromCourse(courseId, userId) {
  const { error } = await supabase
    .from("th_user_courses")
    .delete()
    .eq("course_id", courseId)
    .eq("user_id", userId);

  if (error) {
    throw new Error("Error al eliminar usuario del curso: " + error.message);
  }
}

export async function getSubmodules(module_id) {
  const { data, error } = await supabase
    .from("dim_submodulos")
    .select("id, nombre")
    .eq("id_modulo", module_id);
  if (error) {
    console.error(error);
  }
  return data;
}

export async function getTypeDocument() {
  const { data, error } = await supabase
    .from("dim_tipos_documentos")
    .select("tipo");
  if (error) {
    console.error(error);
  }
  return data.map((item) => ({ tipo: item.tipo }));
}

export async function getRoles() {
  const { data, error } = await supabase
    .from("auth_roles")
    .select("id, role_name");
  if (error) {
    console.error(error);
  }

  return data.map((item) => ({ id: item.id, rol: item.role_name }));
}

export async function getSubCompañias(companyid) {
  let query = supabase
    .from("auth_user_subcompanies")
    .select(
      "id, numero_documento, razon_social, logo, nombre_responsable, apellido_responsable, correo_responsable, indicativo_celular, celular_responsable, tipo_documento"
    );

  if (companyid) {
    query = query.eq("company_id", companyid);
  }

  const { data, error } = await query;

  if (error) {
    console.error(error);
  }
  return data.map((item) => ({
    id: item.id,
    numero_documento: item.numero_documento,
    subcompañia: item.razon_social,
    logo: item.logo,
    nombre_responsable: `${item.nombre_responsable} ${item.apellido_responsable}`,
    correo_responsable: item.correo_responsable,
    indicativo_celular: item.indicativo_celular,
    celular_responsable: item.celular_responsable,
    tipo_documento: item.tipo_documento,
  }));
}

export async function getSubCompañiasPorUsuario(user) {
  let query = supabase
    .from("th_user_subcompanies")
    .select("id_subcompanies(id, razon_social)");

  if (user) {
    query = query.eq("id_user", user);
  }

  const { data, error } = await query;

  if (error) {
    console.error(error);
  }

  return data.map((item) => ({
    id: item.id_subcompanies?.id,
    subCompañia: item.id_subcompanies?.razon_social,
  }));
}

//TODO: si se queda comentado borrar :D
export async function getUserRole(userId) {
  const { data, error } = await supabase
    .from("auth_user_roles")
    .select("role_id(id, role_name)");

  if (error) {
    console.error(error);
    return [];
  }
  return data.map((item) => ({
    // userName: `${item.first_name} ${item.last_name}`,
    id: item.role_id?.id,
    rol: item.role_id?.role_name,
  }));
}

export async function saveCourseFinalized(
  id_user,
  id_course,
  id_state,
  dateFinalized
) {
  const { data, error } = await supabase
    .from("th_capacitacion_history_courses")
    .select("id")
    .eq("id_user", id_user)
    .eq("id_course", id_course)
    .eq("id_state", id_state);

  if (error) {
    console.error("Error fetching history courses", error);
    return;
  }

  if (data.length === 0) {
    const { error: insertError } = await supabase
      .from("th_capacitacion_history_courses")
      .insert([
        {
          id_user: id_user,
          id_course: id_course,
          id_state: id_state,
          date_end: dateFinalized,
        },
      ]);

    if (insertError) {
      console.error("Error saving course finalization", insertError);
    }
  } else {
    const { error: updateError } = await supabase
      .from("th_capacitacion_history_courses")
      .update({ date_end: dateFinalized })
      .eq("id_user", id_user)
      .eq("id_course", id_course)
      .eq("id_state", id_state);

    if (updateError) {
      console.error("Error updating course finalization", updateError);
    }
  }
}

export async function addVideo(bucket, path, video) {
  try {
    const { data, error } = await supabase.storage
      .from(bucket)
      .upload(`${path}/${video.name}`, video);

    if (error) {
      throw new Error("Error al insertar el video");
    }
  } catch (error) {
    throw new Error(error.message);
  }
}

// Guardar curso
export async function saveCourse(course, bucket, path, files) {
  if (files && files.length > 0) {
    try {
      const imageName = await saveFile(bucket, path, files);
      course.image = imageName;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  try {
    const { data, error } = await supabase
      .from("dim_capacitacion_cursos")
      .insert([
        {
          nombre_curso: course.nombre,
          descripcion: course.description,
          es_pago: course.isPaid,
          uuid_creado: course.id_user,
          uuid_actualizado: course.id_user,
          nombre_miniatura: course.image,
          valor_curso: course.price,
          estado_curso: 6,
          plan_activo: false,
          publicado: false,
        },
      ]);

    if (error) {
      throw new Error("Error al insertar el curso");
    }
  } catch (error) {
    throw new Error(error.message);
  }
}

//Editar Cursos
export async function editCourse(course, id_curso) {
  try {
    const { data, error } = await supabase
      .from("dim_capacitacion_cursos")
      .update({
        nombre_curso: course.nombre,
        descripcion: course.description,
        es_pago: course.isPaid,
        uuid_actualizado: course.id_user,
        nombre_miniatura: course.image,
        valor_curso: course.price,
        estado_curso: course.state,
      })
      .eq("id", id_curso);

    if (error) {
      console.error(error);
      throw new Error("Error al actualizar el curso");
    }
  } catch (error) {
    throw new Error(error.message);
  }
}

// Eliminar curso
export async function deleteCourse(courseId, bucket, path) {
  try {
    // Paso 1: Obtener los módulos del curso
    const { data: modules, error: getModulesError } = await supabase
      .from("dim_capacitacion_modulos")
      .select("id")
      .eq("curso_id", courseId);

    if (getModulesError) {
      throw new Error(
        "Error al obtener los módulos: " + getModulesError.message
      );
    }

    if (modules.length > 0) {
      for (const module of modules) {
        const { data: videos, error: getVideosError } = await supabase
          .from("dim_capacitacion_videos")
          .select("id")
          .eq("id_modulo", module.id);

        if (getVideosError) {
          throw new Error(
            "Error al obtener los videos: " + getVideosError.message
          );
        }

        const videoIdList = videos.map((video) => video.id);

        // Eliminar comentarios de los videos
        const { error: deleteCommentsError } = await supabase
          .from("th_video_commentaries")
          .delete()
          .in("video_id", videoIdList);

        if (deleteCommentsError) {
          throw new Error(
            "Error al eliminar los comentarios de los videos: " +
              deleteCommentsError.message
          );
        }

        // Eliminar el progreso de los videos
        const { error: deleteProgressError } = await supabase
          .from("th_capacitacion_video_progress")
          .delete()
          .in("id_video", videoIdList);

        if (deleteProgressError) {
          throw new Error(
            "Error al eliminar el progreso de los videos: " +
              deleteProgressError.message
          );
        }

        // Eliminar los videos
        const { error: deleteVideosError } = await supabase
          .from("dim_capacitacion_videos")
          .delete()
          .in("id", videoIdList);

        if (deleteVideosError) {
          throw new Error(
            "Error al eliminar los videos: " + deleteVideosError.message
          );
        }
      }

      // Paso 2: Eliminar los módulos
      const { error: deleteModulesError } = await supabase
        .from("dim_capacitacion_modulos")
        .delete()
        .eq("curso_id", courseId);

      if (deleteModulesError) {
        throw new Error(
          "Error al eliminar el módulo: " + deleteModulesError.message
        );
      }
    }

    // Paso 3: Eliminar relaciones de cursos y usuarios
    const { error: deleteUserCoursesError } = await supabase
      .from("th_user_courses")
      .delete()
      .eq("course_id", courseId);

    if (deleteUserCoursesError) {
      throw new Error(
        "Error al eliminar las relaciones con los usuarios: " +
          deleteUserCoursesError.message
      );
    }

    // Paso 4: Eliminar miniatura del curso
    const { data: courseData, error: getCourseError } = await supabase
      .from("dim_capacitacion_cursos")
      .select("nombre_miniatura")
      .eq("id", courseId)
      .single();

    if (getCourseError) {
      throw new Error("Error al obtener el curso: " + getCourseError.message);
    }

    // Eliminar miniatura si existe
    if (courseData?.nombre_miniatura) {
      const fileName = courseData.nombre_miniatura;
      const { error: deleteFileError } = await supabase.storage
        .from(bucket)
        .remove([`${path}/${fileName}`]);

      if (deleteFileError) {
        throw new Error(
          "Error al eliminar el archivo de la miniatura: " +
            deleteFileError.message
        );
      }
    }

    // Paso 5: Eliminar el curso
    const { error: deleteCourseError } = await supabase
      .from("dim_capacitacion_cursos")
      .delete()
      .eq("id", courseId);

    if (deleteCourseError) {
      throw new Error(
        "Error al eliminar el curso: " + deleteCourseError.message
      );
    }

    alert("Curso eliminado correctamente.");
  } catch (error) {
    throw new Error(error.message);
  }
}

// Actualizar miniatura del curso
export async function updateCourse(courseId, newName, newImage) {
  const { error } = await supabase
    .from("dim_capacitacion_cursos")
    .update({ nombre_curso: newName, nombre_miniatura: newImage })
    .eq("id", courseId);

  if (error) {
    throw new Error("Error al actualizar el curso: " + error.message);
  }
}

export async function uploadImage(file) {
  const filePath = `cursos/miniaturas/${file.name}`;
  const { error } = await supabase.storage
    .from("cursos")
    .upload(filePath, file);

  if (error) {
    throw new Error("Error al subir la imagen: " + error.message);
  }

  const { data } = supabase.storage.from("cursos").getPublicUrl(filePath);
  return data;
}

async function saveFile(bucket, path, files) {
  try {
    if (files && files.length > 0) {
      const file = files[0];
      const courseName = path;
      const sanitizedFileName = sanitizeFileName(courseName);
      const fileExtension = file.name.split(".").pop();

      const uniqueFileName = `${sanitizedFileName}-${Date.now()}.${fileExtension}`;
      const filePath = `${path}/${uniqueFileName}`;

      const { data, error } = await supabase.storage
        .from(bucket)
        .upload(filePath, file);

      if (error) {
        throw new Error(`Error al subir el archivo: ${error.message}`);
      }

      return uniqueFileName;
    }
  } catch (error) {
    throw new Error(error.message);
  }
}

function sanitizeFileName(fileName) {
  return fileName.toLowerCase().replace(/[^a-z0-9]/g, "-");
}

export async function isFileExists(bucket, path, file) {
  const fileName = file.name;

  const { data: existingFileData, error: fileCheckError } =
    await supabase.storage.from(bucket).list(path, { search: fileName });

  if (fileCheckError) {
    throw new Error(`Error al verificar el archivo: ${fileName}`);
  }

  if (existingFileData && existingFileData.length > 0) {
    return true;
  }
  return false;
}

export async function downloadPicture(bucket, path) {
  try {
    const { data } = supabase.storage.from(bucket).getPublicUrl(`${path}`);

    const url = data.publicUrl;
    return url;
  } catch (error) {
    console.log("Error descargando imagen: ", error.message);
  }
}

export async function addPicture(bucket, src, picture) {
  const { data, error } = await supabase.storage
    .from(bucket)
    .upload(src, picture);

  if (error) {
    return ["error", error];
  } else {
    return ["data", data];
  }
}

export async function removePicture(bucket, src) {
  const { data, error } = await supabase.storage.from(bucket).remove([src]);
  console.log(error);
  if (error) {
    console.log(
      "Error al eliminar el archivo de la miniatura: " + error.message
    );
    return ["error", error];
  } else {
    return data;
  }
}

export async function fetchContactChannels() {
  const { data, error } = await supabase
    .from("dim_canales_contacto")
    .select("id, name:fuente");

  if (error) {
    return [];
  } else {
    console.log(data);
    return data;
  }
}

//obtener los comentarios de los videos
export async function getComments(videoId) {
  const { data, error } = await supabase
    .from("th_video_commentaries")
    .select("comentario, user_id, created_at")
    .eq("video_id", videoId)
    .order("created_at", { ascending: true });

  if (error) {
    console.error("Error al obtener comentarios:", error);
    return [];
  }
  return data;
}

//Insertar comentarios en videos
export async function addComments(videoId, userId, comentario) {
  const { data, error } = await supabase
    .from("th_video_commentaries")
    .insert([{ video_id: videoId, user_id: userId, comentario }]);

  if (error) {
    console.error("Error al agregar comentario:", error);
  }
  return data;
}
