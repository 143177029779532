import { toast } from "sonner";
import { Button } from "./Button";
import { ResetPassword } from "./ServiceDesk/Password";
import { useEffect, useState } from "react";
import { ViewField } from "./ViewFiledText";

import { supabase, getUserData } from "utils/SupabaseClient";

export function Profile() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [passwordSucces, setPasswordSucces] = useState(false);

  useEffect(() => {
    async function getUserInformation() {
      const user = await getUserData();
      const { data, error } = await supabase
        .from("users")
        .select("*")
        .eq("id", user.id);
      if (!error) {
        setName(data[0].first_name);
        setLastName(data[0].last_name);
        setEmail(data[0].email);
        setPhone(data[0].phone);
      }
    }
    getUserInformation();
  }, []);

  useEffect(() => {
    async function changePassword() {
      const { error } = await supabase.auth.updateUser({
        password: password,
      });

      if (error) {
        toast("Error al actualizar la contraseña");
      }
    }

    if (passwordSucces) {
      changePassword();
    }
  }, [passwordSucces]);

  const savePassword = () => {
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;

    if (password === "" || confirmPassword === "") {
      toast.error("Debe completar los campos de contraseña");
      return;
    } else if (password !== confirmPassword) {
      toast.error("Las contraseñas no coinciden");
      return;
    } else if (!passwordRegex.test(password)) {
      toast.error(
        "La contraseña debe tener al menos 8 caracteres, incluyendo un número y una letra"
      );
      return;
    } else {
      toast.success("Contraseña actualizada correctamente");
      setPasswordSucces(true);
    }
  };

  return (
    <div className="grid grid-cols-2 gap-4">
      <div className="col-span-2 text-sm font-bold leading-6 text-gray-900">
        Datos personales
      </div>
      <ViewField label={"Nombre"} value={name} />
      <ViewField label={"Apellido"} value={lastName} />
      <ViewField label={"Correo"} value={email} />
      <ViewField label={"Telefono"} value={phone} />
      <div className="col-span-2 text-sm font-bold leading-6 text-gray-900">
        Cambiar contraseña
      </div>
      <ResetPassword
        title={"Contraseña"}
        field={"password"}
        onChange={setPassword}
      />
      <ResetPassword
        title={"Confirmar contraseña"}
        field={"confirmPassword"}
        onChange={setConfirmPassword}
      />
      <div>
        <Button text={"Cambiar contraseña"} onClick={savePassword} />
      </div>
    </div>
  );
}
