import { useNavigate } from "react-router-dom";

import { Button } from "components/common/Button";
import { ModalBG } from "components/common/ModalBG";

export function ModalPlan({ plan, users, newPlan, onClose }) {
  const navigate = useNavigate();
  return (
    <ModalBG open={newPlan} onClose={onClose} title="Mejora tu plan">
      <div className="text-center">
        <h3 className="text-lg font-bold">
          Un plan de pago incluye mejores funcionalidades
        </h3>
        <p className="mb-4">
          Estas usando el plan {plan.plan_type}. Mejora tu plan para obtener mas
          usuarios y mucho más. Puedes cancelarlo en cualquier momento.
        </p>
        <h3 className="text-lg font-bold">
          Has invitado a {users} de {plan.user_count} usuarios.
        </h3>
        <p>Mejora tu plan y obten más usuarios.</p>
        <div className="flex flex-row-reverse">
          <Button
            text="Mejorar plan"
            type="button"
            onClick={() => {
              navigate("/budget");
            }}
          />
        </div>
      </div>
    </ModalBG>
  );
}
