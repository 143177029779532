import { useState } from "react";
import { Button } from "./Button";
import { FileUploadComponent } from "./ServiceDesk/FileUploadComponent";

export function NewConnection({
  projectList,
  setSelectedProject,
  setFileType,
  fileType,
  handleFileChange,
  selectedFilesProps,
  base64Props,
  informationUpload,
}) {
  const [infoFiles, setInfoFiles] = useState([]);

  const handleChangeProject = (project) => {
    setSelectedProject(project.target.value);
  };

  const handleChangeFileType = (e) => {
    setFileType(e.target.value);
  };

  return (
    <>
      <div className="grid grid-cols-2 mb-4">
        <div className="p-2">
          <label htmlFor="project">Proyecto: </label>
          <select
            id="project"
            className="border border-slate-300 rounded-sm p-1 my-2 w-full"
            onChange={handleChangeProject}
          >
            {projectList.map((project) => (
              <option key={project} value={project}>
                {project}
              </option>
            ))}
          </select>
          <span className="text-gray-500">Selecciona un proyecto.</span>
        </div>

        <div className="p-2">
          <label htmlFor="fileType">Tipo de archivo: </label>
          <select
            id="fileType"
            className="border border-slate-300 rounded-sm p-1 my-2 w-full"
            onChange={handleChangeFileType}
            value={fileType}
          >
            <option value="">Seleccione un tipo</option>
            <option value="document">Documento (DOC)</option>
            <option value="database">Archivo BD</option>
          </select>
          <span className="text-gray-500">Selecciona el tipo de archivo.</span>
        </div>

        <div className="p-2">
          <label htmlFor="file">Documento: </label>
          <FileUploadComponent
            selectedFilesProps={selectedFilesProps}
            setInfoFiles={setInfoFiles}
            text={informationUpload}
            base64Props={base64Props}
            // accept=".txt, .xls, .xlsx, .xlsm, .xlsb, .xltx, .xlt, .xlam, .csv, .dbf, .prn, .ods, .doc, .docx, .dot, .dotx, .docm, .dotm, .rtf, .odt, .wps, .htm, .html, .pdf"
            accept=".xls, .xlsx, .doc, .docx"

          />
        </div>
      </div>

      <Button
        text="Cargar"
        onClick={() => handleFileChange(infoFiles)}
        type={"submit"}
      />
    </>
  );
}
