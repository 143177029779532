import { useState } from "react";
import { LessIcon } from "components/SvgIcons/AddIcon copy";

export function SectionSubmodules({ onClick, text }) {
  // Estado para manejar la visibilidad del modal y la posición del puntero
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });

  // Función para actualizar la posición del modal según el puntero
  const handleMouseMove = (e) => {
    setModalPosition({
      top: e.clientY + 10, // Desplazamos un poco el modal respecto al puntero
      left: e.clientX + 10, // Desplazamos un poco el modal respecto al puntero
    });
  };

  // Mostrar el modal cuando el puntero entra en el label
  const handleMouseEnter = () => {
    setIsModalVisible(true);
  };

  // Ocultar el modal cuando el puntero sale del label
  const handleMouseLeave = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="flex justify-between">
      <button
        className="rounded-full stroke-red-500 hover:bg-red-500 hover:stroke-white py-2 px-1 transition-all h-[25px] mt-[6px]"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseMove}
        onClick={onClick}
      >
        <LessIcon h={"10"} w={"15"} />
      </button>

      {/* Aquí está el modal que sigue al puntero */}
      {isModalVisible && (
        <div
          className="absolute bg-red-700 bg-opacity-70 text-white p-2 rounded"
          style={{
            top: modalPosition.top,
            left: modalPosition.left,
          }}
        >
          Eliminar {text}
        </div>
      )}
    </div>
  );
}
