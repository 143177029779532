import { getUserData } from "utils/SupabaseClient";
import { getPBI } from "hooks/commonData";
import { useEffect, useState } from "react";
import { Module } from "components/Module";
import { ReportInformation } from "./ReportInformation";
import { ChargingComponent } from "components/ChargingComponent";

export function Reports({ nombre }) {
  const [pbiData, setPbiData] = useState([{}]);

  useEffect(() => {
    getUserData().then((r) => {
      getPBI(r.id).then((r) => setPbiData(r));
    });
  }, []);

  return (
    <Module title={`Reportes de ${nombre}`} back={false}>
      {pbiData.length !== 0 ? (
        <>
          {pbiData.map((item) => (
            <ReportInformation link={item.link_pbi} name={item.name} />
          ))}
        </>
      ) : (
        <ChargingComponent />
      )}
    </Module>
  );
}
