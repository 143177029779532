import { Route, Routes } from "react-router-dom";
import { RoleProvider } from "./auth/admin/RoleContex";
import { RoleManagement } from "./auth/admin/RoleManagement";
import { SignupForm } from "./login/SignupForm";
import { Profile } from "./profile";
import { Training } from "./training";
import { Course } from "./training/course";
import { NewCourse } from "./training/new";
import { TrainingPMO } from "./training/trainingPMO";
import { EditCoursesPMO } from "./training/trainingPMO/editCoursePMO";
import { AdminCourse } from "./training/trainingPMO/AdminCourse";
import { BIModule } from "./bi";
import { Develop } from "./support";
import { ViewChange } from "./support/modules/changes/ViewChange";
import { ViewProblem } from "./support/modules/problems/ViewProblem";
import { ViewRequest } from "./support/modules/requests/ViewRequest";
import { ViewIncident } from "./support/modules/incidents/ViewIncident";
import { ViewRequeriment } from "./support/modules/requeriments/ViewRequeriment";
import { Sidebar, SidebarItem } from "components/common/Sidebar";
import { Budget } from "./budget";
import PricingPlans from "./plans";

export function App({ nombre, apellido, email, items }) {
  return (
    <RoleProvider>
      <div className="flex overflow-hidden">
        <Sidebar user={`${nombre} ${apellido}`} email={email}>
          {items.map((item) => (
            <SidebarItem
              key={`${item.text}-${item.to}`}
              icon={item.icon}
              text={item.text}
              active={item.active}
              alert={item.alert}
              to={item.to}
            />
          ))}
        </Sidebar>
        <main className="grow bg-[#ebebeb] overflow-hidden">
          <div className="p-5">
            <Routes>
              <Route path="/welcome" element={<SignupForm />} />
              <Route
                path="/profile"
                element={<Profile nombre={nombre} apellido={apellido} />}
              />
              <Route path="/" element={<BIModule />} />
              <Route path="/training" element={<Training />} />
              <Route path="/training/new" element={<NewCourse />} />
              <Route path="/training/course/:id_course" element={<Course />} />
              <Route
                path="/training/course/:id_course/video/:id_video"
                element={<Course />}
              />
              <Route path="/trainingpmo" element={<TrainingPMO />} />
              <Route
                path="/trainingpmo/edit/:id_course"
                element={<EditCoursesPMO />}
              />
              <Route
                path="/trainingpmo/admin-course"
                element={<AdminCourse />}
              />
              <Route path="/support" element={<Develop />} />
              <Route path="/support/1/:issue" element={<ViewRequest />} />
              <Route path="/support/2/:issue" element={<ViewRequeriment />} />
              <Route path="/support/3/:issue" element={<ViewChange />} />
              <Route path="/support/4/:issue" element={<ViewIncident />} />
              <Route path="/support/5/:issue" element={<ViewProblem />} />
              <Route path="/admin" element={<RoleManagement />} />
              <Route path="/budget" element={<Budget />} />
              <Route path="/plans" element={<PricingPlans />} />
              <Route path="*" element={<h1>404 Not Found</h1>} />
            </Routes>
          </div>
        </main>
      </div>
    </RoleProvider>
  );
}
