export function Subtitle({ text, size }) {
  return (
    <label
      className={`${
        size ? `text-${size}` : "text-lg"
      } font-bold leading-6 text-sky-800`}
    >
      {text}
    </label>
  );
}
