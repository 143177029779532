export function NewIcon() {
  return (
    <svg
      viewBox="0 0 76 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className=" stroke-white hover:stroke-[#5883D5] fill-[#5883D5] hover:fill-white transition-colors w-full"
    >
      <circle cx="38" cy="38" r="38" />
      <path
        d="M15.7241 38H60.2758M38 15.7241V60.2758"
        className="stroke-[2px] stroke-linecap-round stroke-linejoin-round"
      />
    </svg>
  );
}
