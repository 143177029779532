import { ChargingComponent } from "./ChargingComponent";
import { Table } from "./Table";
import { TRow } from "./Table";

export function ViewTable({ rows = [], tittles }) {
  return (
    <Table tittles={tittles}>
      {rows.length > 0 ? (
        rows.map((row, index) => <TRow key={index} row={row} />)
      ) : (       
      <tr>
          <td colSpan={tittles.length} className="text-center">
            <ChargingComponent/>
          </td>
        </tr>
      )}
    </Table>
  );
}
