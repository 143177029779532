import { Sidebar, SidebarItem } from "components/Sidebar";
import { Route, Routes } from "react-router-dom";
import { TrainingPMO } from "./trainingPMO";
import { EditCoursesPMO } from "./trainingPMO/editCoursePMO";
import { NewCourse } from "modules/training/new";


export function AppPMO({ nombre, apellido, email, modules }) {
  return (
    <div className="flex">
      <Sidebar user={`${nombre} ${apellido}`} email={email}>
        {modules.map((item, index) => (
          <SidebarItem
            key={index}
            icon={item.icon}
            text={item.text}
            active={item.active}
            alert={item.alert}
            to={item.to}
          />
        ))}
      </Sidebar>
      <main className="grow bg-[#ebebeb]">
        <div className="p-[20px]">
          <Routes>
            <Route path="/" element={<h1>Bienvenid@! </h1>} />
            <Route path="/training" element={<TrainingPMO />} />
            <Route path="/training/new" element={<NewCourse/>} />
            <Route path="/training/edit/:id_course" element={<NewCourse/>} />
            <Route
              path="/training/course/:id_course"
              element={<EditCoursesPMO />}
            />
            <Route path="*" element={<h1>404 Not Found</h1>} />
          </Routes>
        </div>
      </main>
    </div>
  );
}
