import { downloadImage } from "hooks/commonData";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { StatusDetail } from "../StatusDetail";
import { Button } from "components/Button";

export function TarjetCoursesPMO({ to, title, image, status, colorId, id }) {
  const [avatarUrl, setAvatarUrl] = useState(null);
  const navigate = useNavigate();

  const handleEdit = () => {
    navigate(`/training/edit/${id}`)
  }

  useEffect(() => {
    downloadImage(image).then((r) => setAvatarUrl(r));
  }, [image]);

  return (
    <div className=" lg:bg-white lg:hover:scale-105 rounded-md px-[8px] py-[8px] transition-transform border-b border-red lg:border-none group">
      <Link
        to={to}
      >

        <div className="flex justify-between ml-1 ">
          <p className="text-lg text-black slide-down-animation">{title}</p>
          <div>
            <StatusDetail colorId={colorId} statusName={status} />
          </div>
        </div>

        <img
          src={avatarUrl}
          alt={title}
          className="w-full h-[200px] rounded-lg slide-down-animation"
        />
      </Link>
      <div
        className="mt-3 opacity-0 group-hover:opacity-100 transition-opacity duration-300 cursor-pointer"
      >
        <Button text={"Editar"}
          onClick={handleEdit}
        />
      </div>

    </div>
  );
}
