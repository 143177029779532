import { downloadPicture } from "hooks/commonData";
import { useEffect, useState } from "react";
import { formatDate } from "utils/Operations";

export function ReportBanner({ report, setActive, setReport }) {
  const handleClick = () => {
    setActive(true);
    setReport(report);
  };
  const defaultImg =
    "https://hiflqitghybyfrjspcdu.supabase.co/storage/v1/object/public/miniaturaReportes/miniaturas/Default/MiniatureDefault.png";

  const [miniature, setMiniature] = useState("");

  useEffect(() => {
    if (report) {
      downloadPicture("miniaturaReportes", report.miniature).then((r) => {
        setMiniature(r);
      });
    }
  }, [report]);

  return (
    <div
      onClick={handleClick}
      className={`appear-animation bg-white border border-slate-300 text-sky-900 hover:scale-110 hover:bg-yellow-50 transition-all rounded-md p-4 flex flex-col items-center cursor-pointer`}
    >
      <h2 className="border-b border-b-slate-300 text-center uppercase font-bold mb-2 w-full">
        {report.title}
      </h2>
      <img
        className="h-40 rounded-lg"
        src={report.miniature ? miniature : defaultImg}
        alt={`report-${report.title}`}
      />
      <section className="w-full py-2">
        <p className="text-sm">
          <strong>Descripción: </strong> {report.description}
        </p>
        <p className="pt-2 text-sm">
          <strong>Actualizado: </strong> {formatDate(report.updated_at)}
        </p>

        {report.templates[0]?.data_source && (
          <p className="text-sm">
            {report.templates.length} Orígenes de datos de tipo{" "}
            {report.templates[0].data_source?.type}.
          </p>
        )}
      </section>
    </div>
  );
}
