import { PencilSquareIcon } from "@heroicons/react/20/solid";
import { EditCompany } from "api/user-module/EditCompany";
import { BackGroundSections } from "components/common/BackGroundSections";
import { Button } from "components/common/Button";
import { ButtonSave } from "components/common/ButtonSave";
import { ChargingComponent } from "components/common/ChargingComponent";
import { InputText } from "components/common/InputText";
import { Subtitle } from "components/common/Subtitle";
import { TextInformation } from "components/common/TextInformation";
import { BackIcon } from "components/svg/BackIcon";
import { getOrganizationDetails } from "hooks/commonData";
import { useEffect, useState } from "react";

export function CompaniesVisual({ submodule, companyId }) {
  const [edit, setEdit] = useState(true);

  const [recharge, setRecharge] = useState(false);
  const [isLoading, setisLoading] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [nit, setNit] = useState("");

  const [companyData, setCompanyData] = useState([]);

  useEffect(() => {
    getOrganizationDetails(companyId, "all").then((r) => {
      setCompanyData(r);
      setRecharge(false);
    });
  }, [companyId, recharge]);

  useEffect(() => {
    if (companyData?.length !== 0) {
      setName(companyData?.company_name);
      setEmail(companyData?.email);
      setPhone(companyData?.celular);
      setAddress(companyData?.address);
      setNit(companyData?.nit);
    }
  }, [companyData]);

  const saveUser = async () => {
    EditCompany({
      companyId,
      name,
      nit,
      address,
      email,
      setisLoading,
      setRecharge,
      setEdit,
    });
  };

  return (
    <div className={submodule === "Facturación" ? "" : "hidden"}>
      <BackGroundSections>
        <div className="w-full mb-3">
          <Subtitle text={`Edición de Facturación`} />
        </div>
        <TextInformation text={"Datos asociados a la facturación."} />

        <div className={!isLoading ? "hidden" : "appear-animation"}>
          <ChargingComponent />
        </div>
        <div className={isLoading ? "hidden" : "appear-animation"}>
          <div className="flex justify-end">
            <div
              className={`${
                !edit ? "flex appear-animation" : "hidden"
              }  transition-all`}
            >
              <ButtonSave HadleSubmit={saveUser} />
              <button onClick={() => setEdit(!edit)} className="mt-4 ">
                <BackIcon w={36} h={36} />
              </button>
            </div>

            <div
              className={`${
                edit ? "flex appear-animation" : "hidden"
              }  transition-all`}
            >
              <Button
                onClick={() => setEdit(!edit)}
                text={"editar"}
                className="bg-sky-800 hover:bg-yellow-500 grow p-2 text-white rounded-lg transition-colors"
                icon={<PencilSquareIcon className="h-6 pb-1" />}
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-2">
            <InputText
              placeholder="Nombre de Compañía"
              value={name}
              setValue={setName}
              disabled={edit}
            />
          </div>

          <div className="grid grid-cols-2 gap-2">
            <InputText
              placeholder="Email"
              value={email}
              setValue={setEmail}
              disabled={edit}
            />
            <InputText
              placeholder="Teléfono"
              value={phone}
              setValue={setPhone}
              disabled={edit}
            />
            <InputText
              placeholder="Dirección"
              value={address}
              setValue={setAddress}
              disabled={edit}
            />
            <InputText placeholder="NIT" value={nit} setValue={setNit} />
          </div>
        </div>
      </BackGroundSections>
    </div>
  );
}
