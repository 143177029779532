import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { supabase, getUserData } from "utils/SupabaseClient";
import { getUserDetails, getUserModules } from "hooks/commonData";
import { App } from "./App";
import { toast } from "sonner";

export function Layout() {
  const [user, setUser] = useState(null);
  const [items, setItems] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSession = async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      if (!session) {
        navigate("/login");
      } else {
        const userData = await getUserData();

        const { data: rolesData, error: rolesError } = await supabase
          .from("auth_user_roles")
          .select("role_id")
          .eq("user_id", userData?.id)
          .single();

        if (rolesError) {
          toast.error("Error al obtener el rol del usuario");
          return;
        }

        const roleId = rolesData?.role_id;
        if (roleId === 7) {
          setItems((prevItems) =>
            prevItems.map((item) =>
              item.text === "Capacitación"
                ? { ...item, to: "/trainingpmo" }
                : item
            )
          );
        }

        getUserDetails(userData.id, "plan").then((r) => {
          localStorage.setItem(
            "Plan",
            String(r?.company_id?.id_plan?.plan_type)
          );
        });

        const { data, error } = await supabase
          .from("auth_users")
          .select("first_name, last_name, email")
          .eq("id", `${userData.id}`);

        if (!error && data.length > 0) {
          setUser(data[0]);
        }
      }
    };

    fetchSession();
  }, []);

  useEffect(() => {
    getUserModules()
      .then((data) => {
        const formattedItems = data.map((module) => {
          return {
            icon: <i className={`lni lni-${module.icon}`}></i>,
            text: module.modulo,
            active: false,
            alert: false,
            to: module.path,
          };
        });

        setItems(formattedItems);
      })
      .catch((error) => {
        toast.error("Error al cargar los módulos", error);
        setItems([]);
      });
  }, []);

  return (
    <App
      nombre={user ? user.first_name : "...cargando"}
      apellido={user ? user.last_name : "...cargando"}
      email={user ? user.email : "...cargando"}
      items={items}
    />
  );
}
