export function DropDownIcon({ w, h, isopen }) {
  return (
    <svg
      width={w}
      height={h}
      viewBox="0 0 24 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={
        !isopen
          ? {
              transition: "transform 0.3s ease-in-out",
              transform: "rotate(180deg)",
            }
          : {
              transition: "transform 0.3s ease-in-out",
              transform: "rotate(360deg)",
            }
      }
    >
      <path
        d="M22.0386 9.48503e-08L24 1.93343L13.3101 12.4643C13.1388 12.6341 12.9351 12.7688 12.7108 12.8607C12.4864 12.9527 12.2458 13 12.0028 13C11.7598 13 11.5192 12.9527 11.2948 12.8607C11.0704 12.7688 10.8667 12.6341 10.6955 12.4643L0 1.93343L1.96145 0.00182224L12 9.88583L22.0386 9.48503e-08Z"
        fill="black"
        transform={!isopen ? "rotate(180 12 6.5)" : "rotate(180 12 6.5)"} // Aplica la rotación si isopen es true
      />
    </svg>
  );
}
