import { Form } from "components/ServiceDesk/Forms";
import { Text } from "components/ServiceDesk/Text";
import { Select } from "components/ServiceDesk/Select";
import { TextArea } from "components/ServiceDesk/TextArea";
import { Files } from "components/ServiceDesk/Files";

import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { toast } from "sonner";

import { getUserDetails, getEnumFieldItems, getProjectDetails } from "hooks/commonData";
import { saveCustumerChange } from "api/changes/route";
import { getUserData } from "utils/SupabaseClient";
import { ChargingComponent } from "components/ChargingComponent";

export function Changes({ ticketTypeId }) {
  const navigate = useNavigate();


  const [user, setUser] = useState("");
  const [enumServices, setEnumServices] = useState([]);
  const [enumProjects, setEnumProjects] = useState([]);
  const [enumUrgencies, setEnumUrgencies] = useState([]);
  const [enumReasons, setEnumReasons] = useState([]);
  const [enumCustomerImpacts, setEnumCustomerImpacts] = useState([]);
  const [loading, setLoading] = useState(false);

  const consultarProyectos = user !== "";

  useEffect(() => {
    getUserData().then((data) => {
      setUser(data.id);
    });
    getEnumFieldItems(10).then((data) => {
      setEnumUrgencies(data);
    });
    getEnumFieldItems(8).then((data) => {
      setEnumReasons(data);
    });
    getEnumFieldItems(6).then((data) => {
      setEnumCustomerImpacts(data);
    });
  }, []);

  useEffect(() => {
    if (consultarProyectos) {
      getUserDetails(user, 'projects').then((data) => {
        setEnumProjects(data.projects_users);
      });
    }
  }, [consultarProyectos]);

  const [selectedService, setSelectedService] = useState(-1);
  const [selectedProject, setSelectedProject] = useState(-1);
  const [summary, setSummary] = useState("");
  const [description, setDescription] = useState("");
  const [selectedUrgency, setSelectedUrgency] = useState(-1);
  const [selectedReason, setSelectedReason] = useState(-1);
  const [selectedCustomerImpact, setSelectedCustomerImpact] = useState(-1);
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [files, setFiles] = useState([]);


  useEffect(() => {
    if (selectedProject != -1) {
      getProjectDetails(selectedProject, "services").then((data) => {
        setEnumServices(data.projects_services);
      });
    }
    else { setEnumServices([]) }
  }, [selectedProject]);


  const handleSave = async () => {
    if (selectedService === -1) {
      toast.error("Por favor selecciona un servicio.");
      return;
    }

    if (selectedProject === -1) {
      toast.error("Por favor selecciona un proyecto.");
      return;
    }

    if (summary === "") {
      toast.error("Por favor escribe un resumen.");
      return;
    }

    if (description === "") {
      toast.error("Por favor escribe una descripción.");
      return;
    }

    if (selectedUrgency === -1) {
      toast.error("Por favor selecciona una urgencia.");
      return;
    }

    if (selectedReason === -1) {
      toast.error("Por favor selecciona un motivo.");
      return;
    }

    if (selectedCustomerImpact === -1) {
      toast.error(
        "Por favor selecciona un impacto hacia clientes y/o usuarios."
      );
      return;
    }
    setLoading(true);
    try {
      try {
        const result = await saveCustumerChange({
          ticketTypeId,
          projectId: selectedProject,
          summary,
          description,
          serviceId: selectedService,
          urgencyValue: selectedUrgency,
          reasonValue: selectedReason,
          customerImpactValue: selectedCustomerImpact,
          additionalInfo,
          files,
        });

        if (result?.error) {
          toast.error(result.error);
          return;
        }

        toast.success("Cambio guardado exitosamente.");
      } catch (error) {
        console.error('Error inesperado:', error.message);
        return;
      }
      ChargingComponent();
      navigate("/issues");

    } catch (error) {
      toast.error(`${error.message || 'Error desconocido'}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mx-auto max-w-7xl p-6 lg:p-8">
      {loading ? <ChargingComponent/> : (
        <Form
        title={"Cambios"}
        description={"Suministra mas detalles acerca del cambio a realizar."}
        onClick={handleSave}
      >
        <Select
          title={"Proyecto"}
          field={"project"}
          onChange={setSelectedProject}
          required={true}
          options={enumProjects.map((proyecto) => ({
            value: proyecto.project_id,
            name: proyecto.project.project_name,
          }))}
        />
        <Select
          title={"Servicio"}
          field={"service"}
          onChange={setSelectedService}
          required={true}
          options={enumServices.map((item) => ({
            value: item.services.id,
            name: item.services.item_value,
          }))}
        />
        <Text title="Resumen" onChange={setSummary} field="summary" />
        <TextArea
          title={"Descripción"}
          onChange={setDescription}
          required={true}
          description={
            "Detalla a profundidad el motivo del cambio y las implicaciones."
          }
        />
        <Select
          title="Urgencia"
          onChange={setSelectedUrgency}
          required={true}
          options={enumUrgencies.map((urgencia) => ({
            value: urgencia.item_value,
            name: urgencia.item_value,
          }))}
        />
        <Select
          title="Motivo"
          onChange={setSelectedReason}
          required={true}
          options={enumReasons.map((motivo) => ({
            value: motivo.item_value,
            name: motivo.item_value,
          }))}
        />
        <Select
          title={"Impacto hacia clientes y/o usuarios"}
          onChange={setSelectedCustomerImpact}
          required={true}
          options={enumCustomerImpacts.map((impactoCliente) => ({
            value: impactoCliente.item_value,
            name: impactoCliente.item_value,
          }))}
        />
        <TextArea
          field={"additionalInfo"}
          onChange={setAdditionalInfo}
          title={"Información adicional"}
          required={true}
          description={"Proporciona información relevante acerca del cambio"}
        />
        <Files title={"Adjuntos"} message={"PNG, JPG, SVG, XLSX, CSV, PDF, DOC up to 50MB"} files={files} setFiles={setFiles} />
      </Form>
      )}
      
    </div>
  );
}
