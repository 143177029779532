import { useState } from "react";

import { LogoSiigo } from "components/svg/LogoSiigo";
import { QuickBooksIcon } from "components/svg/QuickBooksIcon";
import { NewFile } from "modules/bi/submodules/connections/NewModal";
import { ButtonIntegration } from "components/tailwind/ButtonIntegration";
import { OtherModal } from "modules/bi/submodules/integrations/OtherModal";
import { NewRowModal } from "modules/bi/submodules/integrations/NewRowModal";

export function IntegrationsBanner({
  setActive,
  isConnections,
  items,
  setRecharge,
  isActive,
}) {
  const [newRow, setNewRow] = useState(false);
  const [otherRow, setOtherRow] = useState(false);
  const [quickbootRow, setQuickbootRow] = useState(false);

  const handleNewRow = () => {
    setNewRow(!newRow);
    if (setActive) {
      setActive(newRow);
    }
  };

  const handleSuccess = () => {
    setNewRow(!newRow);
    if (setActive) {
      setActive(newRow);
    }
    if (setRecharge) {
      setRecharge(true);
    }
  };

  const handleOtherRow = () => {
    setOtherRow(!otherRow);
  };

  const handleQuickbootRow = () => {
    setQuickbootRow(!quickbootRow);
  };

  return (
    <>
      <div className="bg-gradient-to-l to-sky-700 from-20% via-slate-300 via-30% from-yellow-500 to-90% mt-2 rounded-md">
        <div className="p-2 2xl:p-4 flex">
          <div className="grow">
            <h2 className="text-white text-lg 2xl:text-xl font-semibold">
              {isConnections ? "Conexión archivos" : "Integración por API"}
            </h2>
            {isConnections && (
              <span className="text-white text-sm">Cargar archivos</span>
            )}
            <div className="p-2 2xl:p-4 flex gap-4">
              <ButtonIntegration
                children={<LogoSiigo h={40} w={40} />}
                onClick={handleNewRow}
              />
              <ButtonIntegration
                children={<QuickBooksIcon className="w-20" />}
                onClick={handleQuickbootRow}
              />
              {!isConnections && (
                <ButtonIntegration
                  children={
                    <label className="text-xl font-bold text-[#2E5DA1]">
                      Otro
                    </label>
                  }
                  onClick={handleOtherRow}
                />
              )}
            </div>
          </div>
          <img src="assets/gift/watch.gif" className="self-end w-36" />
        </div>
      </div>
      {!isConnections && (
        <>
          <NewRowModal
            isActive={isActive}
            newRow={newRow}
            provider="siigo"
            onClose={handleNewRow}
          />
          <NewRowModal
            isActive={isActive}
            newRow={quickbootRow}
            provider="quickboot"
            onClose={handleQuickbootRow}
          />
          <OtherModal
            isActive={isActive}
            otherRow={otherRow}
            onClose={handleOtherRow}
          />
        </>
      )}
      {isConnections && (
        <>
          <NewFile
            isActive={isActive}
            newRow={newRow}
            onClose={handleSuccess}
            files={items ? items : []}
            provider="siigo"
          />
          <NewFile
            isActive={isActive}
            newRow={quickbootRow}
            onClose={handleQuickbootRow}
            files={items ? items : []}
            provider="quickboot"
          />
        </>
      )}
    </>
  );
}
