import { TRow } from "./Table";
import { Table } from "./Table";
import { ChargingComponent } from "components/common/ChargingComponent";

export function ViewTable({ rows = [], titles }) {
  return (
    <Table titles={titles}>
      {rows.length > 0 ? (
        rows.map((row, index) => <TRow key={index} row={row} />)
      ) : (
        <tr>
          <td colSpan={titles.length} className="text-center">
            <ChargingComponent />
          </td>
        </tr>
      )}
    </Table>
  );
}
