import { SectionSubmodules } from "./SectionSubmodules";

export function Subsection({ sectionId, subsection, onRemoveSubsection }) {
  const handleRemoveSubsection = () => {
    onRemoveSubsection(sectionId, subsection.id);
  };

  return (
    <div className="flex">
      <SectionSubmodules onClick={handleRemoveSubsection} text={"Sección"} />
      <input
        defaultValue={subsection.title}
        className="border-none rounded-lg w-full"
      />
    </div>
  );
}
