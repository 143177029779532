import { supabase } from "utils/SupabaseClient";

export const getDocFiles = async (project) => {
  try {
    const { data, error } = await supabase.storage
      .from("archivos")
      .list(`${project}/DOC/`, {
        limit: 100,
        offset: 0,
        sortBy: { column: "name", order: "asc" },
      });

    if (error) {
      throw error;
    }

    return data;
  } catch (error) {
    console.error("Error al obtener los archivos:", error);
    return [];
  }
};
