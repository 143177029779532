import React, { useState } from "react";

const MercadoPagoButton = ({ title, price, courseId }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handlePurchase = async () => {
    setIsLoading(true);

    try {
      const response = await fetch("http://localhost:3001/create-order", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          title: title,
          price: price,
          quantity: 1,
          courseId: courseId,
        }),
      });

      const data = await response.json();

      if (data.init_point) {
        window.location.href = data?.init_point;
      } else {
        alert("Hubo un problema al crear la preferencia de pago.");
      }
    } catch (error) {
      console.error("Error al crear la preferencia de pago:", error);
      alert("Hubo un error al procesar tu solicitud.");
    }

    setIsLoading(false);
  };
  
  return (
    <div className="mt-4">
      <button
        className={`bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition ${isLoading ? "cursor-not-allowed" : ""}`}
        onClick={handlePurchase}
        disabled={isLoading}
      >
        {isLoading ? "Procesando..." : "Comprar ahora"}
      </button>
    </div>
  );
};

export default MercadoPagoButton;
