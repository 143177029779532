import { useEffect, useState } from "react";

import { getUserDetails } from "hooks/commonData";
import { getUserData } from "utils/SupabaseClient";
import { fetchReports, fetchTemplates } from "api/bi-module/reports";

import { NewReport } from "./NewReport";
import { ReportBanner } from "./ReportBanner";
import { Button } from "components/common/Button";
import { AddReportButton } from "./AddReportButton";
import { ReportInformation } from "./ReportInformation";
import { DropDownIcon } from "components/svg/DropDownIcon";
import { ItemTemplate, TemplateBanner } from "./TemplateBanner";
import { GradiendBanner } from "components/common/GradiendBanner";
import { ChargingComponent } from "components/common/ChargingComponent";

export function Reports({
  submodule,
  globalRecharge,
  setSubmodule,
  connectionsSize,
  connectionsActive,
}) {
  const [report, setReport] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [isTemplate, setIsTemplate] = useState(false);
  const [reportList, setReportList] = useState([]);
  const [open, setOpen] = useState(false);
  const [isRecharge, setIsRecharge] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    setSubmodule(submodule);
  }, [submodule]);

  useEffect(() => {
    fetchTemplates().then((r) => {
      setTemplates(r);
    });
    getUserData().then((user) => {
      getUserDetails(user.id, "subcompanies-relation").then((reports) => {
        fetchReports(reports.company).then((r) => {
          setReportList(r);
          if (r) {
            setIsRecharge(false);
          }
        });
      });
    });
  }, [isRecharge]);

  const handleOpen = () => {
    setOpen(!open);
    if (isSuccess) {
      setIsSuccess(false);
    }
  };

  return (
    <div
      className={`${
        submodule !== "Reportes" ? "hidden" : "slide-down-animation "
      }`}
    >
      {!templates && <ChargingComponent />}
      {templates && (
        <>
          {(isActive || isTemplate) && (
            <ReportInformation
              report={report}
              globalRecharge={globalRecharge}
              isTemplate={isTemplate}
              setActive={isActive ? setIsActive : setIsTemplate}
              connectionsActive={connectionsActive}
              setReport={setReport}
              setIsRecharge={setIsRecharge}
            />
          )}
          {!(isActive || isTemplate) && (
            <div className="mt-1">
              <div className="w-full">
                <div className="flex justify-end ">
                  <Button
                    type="button"
                    className="cursor-pointer "
                    onClick={() => setIsOpen(!isOpen)}
                    icon={
                      <DropDownIcon
                        className={"w-6 h-6 fill-sky-800"}
                        isopen={isOpen}
                      />
                    }
                  />
                </div>
              </div>
              <div
                className={`overflow-hidden transition-all 
                  ${isOpen ? " h-full" : "h-0 "}
                  `}
                style={{
                  animation: isOpen
                    ? "expandHeigth 0.2s ease-in-out forwards"
                    : "hideHeigth 0.2s ease-in-out forwards",
                }}
              >
                <GradiendBanner>
                  <h1 className="text-center text-white font-bold uppercase w-full">
                    ¡Todos tus datos en un solo lugar!
                  </h1>
                </GradiendBanner>
                <TemplateBanner>
                  {templates.map((template) => (
                    <ItemTemplate
                      key={template.id}
                      report={template}
                      setReport={setReport}
                      title={template.title}
                      setActive={setIsTemplate}
                      icon={template.id_proveedor}
                      custom
                    />
                  ))}
                  <ItemTemplate to="Cotiza un desarrollo a la medida" />
                </TemplateBanner>
              </div>

              <div className="flex w-full items-center">
                <hr className="border border-slate-300 grow" />
                <h2 className="text-sky-700 text-center uppercase font-bold px-4">
                  Tus reportes
                </h2>
                <hr className="border border-zinc-300 grow" />
              </div>
              <div
                className={`transition-all slide-down-animation overflow-y-auto max-h-full md:max-h-[${
                  !isOpen ? "460px" : "340px"
                }] 2xl:max-h-[${!isOpen ? "800px" : "600px"}] p-4`}
              >
                {reportList.length !== 0 ? (
                  <div
                    className={`grid grid-cols-1 md:grid-cols-${
                      reportList.length === 1 ? "3" : "4"
                    } 2xl:grid-cols-4 gap-4 m-6`}
                  >
                    {reportList.map((report) => (
                      <ReportBanner
                        key={report.id}
                        report={report}
                        setActive={setIsActive}
                        setReport={setReport}
                      />
                    ))}
                    <AddReportButton handleOpen={handleOpen} />
                  </div>
                ) : (
                  <AddReportButton handleOpen={handleOpen} />
                )}
              </div>
            </div>
          )}
        </>
      )}
      <NewReport
        open={open}
        isActive={reportList.length < connectionsSize}
        onClose={handleOpen}
        setIsRecharge={setIsRecharge}
        isSuccess={isSuccess}
        setIsSuccess={setIsSuccess}
      />
    </div>
  );
}
