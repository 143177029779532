import React, { useEffect, useState } from "react";

const MultipleSelect = ({
  setValue,
  OptionsSelected,
  list,
  title,
  required,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    if (OptionsSelected) {
      // Store the value (ID) instead of just the label
      setSelectedOptions(OptionsSelected.map((item) => item.value));
    }
  }, [OptionsSelected]);

  const toggleOption = (option) => {
    setSelectedOptions((prevSelected) => {
      if (prevSelected.includes(option.value)) {
        return prevSelected.filter((item) => item !== option.value);
      } else {
        return [...prevSelected, option.value];
      }
    });
  };

  useEffect(() => {
    // Pass the selected values to the parent component
    setValue(selectedOptions);
  }, [selectedOptions, setValue]);

  return (
    <div>
      <label className="ml-2 text-md font-sans font-semibold text-slate-950">
        <span
          className={`${
            required && selectedOptions.length !== 0
              ? "hidden"
              : "appear-animation"
          } text-red-500 text-lg font-bold transtitio-all appear-animation`}
        >
          *
        </span>{" "}
        {`${title}:`}
      </label>
      <div className="flex w-full p-2 overflow-x-auto">
        <div className="flex flex-wrap gap-2 w-max">
          {list.map((item) => (
            <button
              className={`rounded-lg px-4 py-2 text-sm text-gray-700 hover:bg-yellow-100 slide-down-animation transition-all ${
                selectedOptions.includes(item.value)
                  ? "bg-blue-300 scale-110"
                  : "border border-slate-300 bg-white"
              }`}
              key={item.value} // Use item.value as the key
              href="#"
              onClick={(e) => {
                e.preventDefault();
                toggleOption({ label: item.label, value: item.value });
              }}
            >
              {item.label}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MultipleSelect;
