export function AddIcon({ className }) {
  return (
    <svg
className={className}
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      // className={`${className ? className : "stroke-black"}`}
    >
  <path
    d="M7.65515 18.5001H29.3448M18.5 7.65527V29.3449"
    class="stroke-current stroke-2 stroke-linecap-round stroke-linejoin-round"
  />
    </svg>
  );
}
