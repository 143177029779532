import { toast } from "sonner";

import {
  assignActiveCoursesToUser,
  assignFreeCoursesToUser,
  getCoursesStudent,
  getPublishedCourses,
  getUserCompanyPlan,
} from "hooks/commonData";
import { convertToBlob } from "utils/Operations";
import { getUserData, supabase } from "utils/SupabaseClient";

export async function GetCourse({
  setLoading,
  setUserCourses,
  setCourses,
  setCourseStates,
}) {
  const user = await getUserData();
  const userCourses = await getCoursesStudent(user.id);
  setUserCourses(userCourses);

  const allCourses = await getPublishedCourses();
  setCourses(allCourses);

  const userPlanData = await getUserCompanyPlan(user.id);
  if (!userPlanData) {
    setLoading(false);
    return;
  }

  if (userPlanData.id_plan === 1) {
    await assignFreeCoursesToUser(user.id);
  } else {
    await assignActiveCoursesToUser(user.id);
  }

  const statesResponse = await supabase
    .from("dim_capacitacion_estados_cursos")
    .select("id, nombre_estado, color_id");

  if (!statesResponse.error) {
    setCourseStates(statesResponse.data);
  }

  setLoading(false);
}

export async function uploadFile({ fileName, bucket, file }) {
  const fileConverted = await convertToBlob(file);

  const { data, error } = await supabase.storage
    .from(bucket)
    .upload(fileName, fileConverted, {
      contentType: "video/mp4",
    });

  if (error) {
    toast.error("Error al subir el archivo");
  }

  return data;
}
