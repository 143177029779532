import { useState } from "react";

export function InputText({
  className,
  placeholder,
  setValue,
  value,
  type,
  defaultValue,
  disabled,
  required,
  field,
}) {
  const [onFocus, setOnFocus] = useState(false);

  return (
    <div className={`${className}`}>
      <label className={`text-black bg-white transition-all  text-sm `}>
        {placeholder}{" "}
        <span className="text-red-500 text-lg font-bold">
          {required && !value && "*"}
        </span>
      </label>

      <input
        id={field}
        name={field}
        onFocus={() => setOnFocus(true)}
        onBlur={() => setOnFocus(false)}
        type={type ? type : "text"}
        onChange={(e) => setValue(e.target.value)}
        defaultValue={defaultValue}
        disabled={disabled}
        className={`border border-gray-300 focus:border-[#F1C40F] focus:ring-0 focus:outline-hidden transition-all outline-offset-0 w-full p-2 text-sm rounded-md ${
          !!placeholder && "mt-2"
        }`}
        value={value}
        required={required}
        placeholder={onFocus ? "" : !placeholder ? "" : placeholder + "..."}
      />
    </div>
  );
}
