import { DropDownModule } from "modules/training/course/modules/DropDownModule";
import { Module } from "components/Module";
import { downloadImage, getCoursesStudent, getModule } from "hooks/commonData";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BackGroundSections } from "components/BackGroundSections";
import { StatusDetail } from "components/StatusDetail";
import { getUserData } from "utils/SupabaseClient";
import { ProgressBar } from "components/ProgressBar";
import { AutoHeigth } from "components/AutoHeigth";

export function Course() {
  const { id_course } = useParams();
  const [modules, setModules] = useState([{}]);
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [userId, setUserId] = useState([{}]);
  const [stateCourse, setStateCourse] = useState([{}]);
  const [totalVideos, setTotalVideos] = useState(0);
  const [totalVideosVistos, setTotalVideosVistos] = useState(0);

  useEffect(() => {
    getModule(id_course).then((r) => setModules(r));
  }, [id_course]);

  const course = modules.map((item) => item.info_curso);
  const nameCourse = course[0]?.nombre_curso;
  const miniature = course[0]?.nombre_miniatura;
  const colorId = stateCourse[0]?.estado_curso?.color_id;
  const status = stateCourse[0]?.estado_curso?.nombre_estado;

  useEffect(() => {
    getUserData().then((r) => setUserId(r?.id));
    getCoursesStudent(userId).then((r) => setStateCourse(r));
    downloadImage(miniature).then((r) => setAvatarUrl(r));
  }, [miniature, userId]);

  return (
    <Module title={`Curso ${nameCourse}`} linkToBack={"/training"}>
      <BackGroundSections>
        <div>
          <div className="grid grid-cols-2">
            <div className="mb-2 flex">
              <StatusDetail colorId={colorId} statusName={status} />
            </div>
            <div>
              <ProgressBar
                videosLength={totalVideos}
                videoWatched={totalVideosVistos}
              />
            </div>
          </div>
        </div>
        <div className="lg:grid lg:grid-cols-3">
          <img
            src={avatarUrl}
            alt=""
            className="grow rounded-lg slide-down-animation overflow-hidden curs"
          />
          <div className="col-span-2">
            <AutoHeigth maxPx={"400px"}>
              {modules.map((item, index) => (
                <DropDownModule
                  userId={userId}
                  module={item.nombre_modulo}
                  id_module={item.id}
                  id_course={id_course}
                  setTotalVideos={setTotalVideos}
                  setTotalVideosVistos={setTotalVideosVistos}
                  key={index}
                />
              ))}
            </AutoHeigth>
          </div>
        </div>
      </BackGroundSections>
    </Module>
  );
}
