import { Form } from "components/ServiceDesk/Forms";
import { Text } from "components/ServiceDesk/Text";
import { Select } from "components/ServiceDesk/Select";
import { TextArea } from "components/ServiceDesk/TextArea";
import { Files } from "components/ServiceDesk/Files";

import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getUserDetails, getProjectDetails } from "hooks/commonData";

import { saveCustumerRequirement } from "api/requeriments/route";

import { toast } from "sonner";
import { getUserData } from "utils/SupabaseClient";
import { ChargingComponent } from "components/ChargingComponent";

export function Requeriments({ ticketTypeId }) {
  const navigate = useNavigate();

  const [user, setUser] = useState("");
  const [enumServices, setEnumServices] = useState([]);
  const [enumProjects, setEnumProjects] = useState([]);
  const [loading, setLoading] = useState(false);

  const consultarProyectos = user !== "";


  useEffect(() => {
    getUserData().then((data) => {
      setUser(data.id);
    });
  }, []);

  useEffect(() => {
    if (consultarProyectos) {
      getUserDetails(user, "projects").then((data) => {
        setEnumProjects(data.projects_users);
      });
    }
  }, [consultarProyectos]);

  const [selectedService, setSelectedService] = useState(-1);
  const [selectedProject, setSelectedProject] = useState(-1);
  const [summary, setSummary] = useState("");
  const [justify, setJustify] = useState("");
  const [profile, setProfile] = useState("");
  const [objective, setObjective] = useState("");
  const [benefit, setBenefit] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [files, setFiles] = useState([]);


  useEffect(() => {
    if (selectedProject != -1) {
      getProjectDetails(selectedProject, "services").then((data) => {
        setEnumServices(data.projects_services);
      });
    }
    else { setEnumServices([]) }
  }, [selectedProject]);


  const handleSave = async () => {
    if (selectedService === -1) {
      toast.error("Por favor selecciona un servicio.");
      return;
    }

    if (selectedProject === -1) {
      toast.error("Por favor selecciona un proyecto.");
      return;
    }

    if (summary === "") {
      toast.error("Por favor escribe un resumen.");
      return;
    }

    if (justify === "") {
      toast.error("Por favor escribe una justificación.");
      return;
    }

    if (profile === "") {
      toast.error("Por favor escribe un perfil.");
      return;
    }

    if (objective === "") {
      toast.error("Por favor escribe un objetivo.");
      return;
    }

    if (benefit === "") {
      toast.error("Por favor escribe un beneficio.");
      return;
    }

    setLoading(true);

    try {
      try {
        const result = await saveCustumerRequirement({
          ticketTypeId,
          projectId: selectedProject,
          summary,
          description: "No aplica descripción",
          serviceId: selectedService,
          justify,
          profile,
          objective,
          benefit,
          additionalInfo,
          files,
        });

        if (result?.error) {
          toast.error(result.error);
          return;
        }
        toast.success("Requerimiento creado exitosamente.");
      } catch (error) {
        console.error('Error inesperado:', error.message);
        return;
      }
      ChargingComponent();
      navigate("/issues");
    } catch (error) {
      toast.error(`${error.message || 'Error desconocido'}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mx-auto max-w-7xl p-6 lg:p-8">
      {loading ? <ChargingComponent /> : (
        <Form
          title={"Requerimientos"}
          description={"Describe tu requerimiento."}
          onClick={handleSave}
        >
          <Select
            title={"Proyecto"}
            field={"project"}
            onChange={setSelectedProject}
            required={true}
            options={enumProjects.map((proyecto) => ({
              value: proyecto.project_id,
              name: proyecto.project.project_name,
            }))}
          />
          <Select
            title={"Servicio"}
            field={"service"}
            onChange={setSelectedService}
            required={true}
            options={enumServices.map((item) => ({
              value: item.services.id,
              name: item.services.item_value,
            }))}
          />
          <Text title="Resumen" field={"summary"} required={true} onChange={setSummary} />
          <TextArea
            title={"Justificación del proyecto"}
            field={"justify"}
            onChange={setJustify}
            required={true}
            description={"Para que se va a realizar el proyecto."}
          />
          <TextArea
            title={"Como (perfil)"}
            field={"profile"}
            onChange={setProfile}
            required={true}
            description={"Perfil de la persona que va a usar la herramienta."}
          />
          <TextArea
            title={"Quiero (objetivo"}
            field={"objective"}
            onChange={setObjective}
            required={true}
            description={
              "Describe la intención, no las funciones que usan. ¿Qué es lo que están intentando lograr realmente?"
            }
          />
          <TextArea
            title={"Para (beneficio)"}
            field={"benefit"}
            onChange={setBenefit}
            required={true}
            description={"¿Qué beneficio se espera obtener?"}
          />
          <TextArea
            field={"Aditional info"}
            onChange={setAdditionalInfo}
            required={true}
            title={"Información adicional"}
          />
           <Files title={"Adjuntos"} message={"PNG, JPG, SVG, XLSX, CSV, PDF, DOC up to 50MB"} files={files} setFiles={setFiles} />
        </Form>
      )}
    </div>
  );
}
