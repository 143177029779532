import { useState, useEffect } from "react";
import { InputText } from "./InputText";

export function ConfirmDelete({ message, onConfirm }) {
  const [text, setText] = useState("");
  const [isDelete, setIsDelete] = useState(false);

  useEffect(() => {
    if (text.toLowerCase() == "eliminar") {
      setIsDelete(true);
      onConfirm(true);
    } else {
      onConfirm(false);
      setIsDelete(false);
    }
  }, [text]);

  return (
    <>
      <label className="block text-sm font-medium leading-6 text-gray-900">
        Por favor, escribe la palabra <strong>"Eliminar"</strong> {message}.
      </label>
      <div className="mt-2">
        <InputText type="text" value={text} setValue={setText} />
      </div>
      {!isDelete && (
        <p className="text-red-500">Por favor escribe correctamente.</p>
      )}
    </>
  );
}
