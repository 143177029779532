import { toast } from "sonner";
import { supabase } from "utils/SupabaseClient";

export async function RemoveModule({
  currentModule,
  setRecharge,
  setIsLoading,
}) {
  try {
    setIsLoading(true);
    const moduleId = currentModule;
    const videoIdList = [];

    const { data: videosData, error: videosError } = await supabase
      .from("dim_capacitacion_videos")
      .select("id")
      .eq("id_modulo", moduleId);

    if (videosError) {
      throw new Error(
        "Error al obtener los videos del módulo: " + videosError.message
      );
    }

    videosData.forEach((video) => videoIdList.push(video.id));

    const { error: deleteProgressError } = await supabase
      .from("th_capacitacion_video_progress")
      .delete()
      .in("id_video", videoIdList);

    if (deleteProgressError) {
      throw new Error(
        "Error al eliminar el progreso de los videos: " +
          deleteProgressError.message
      );
    }

    const { error: deleteCommentsError } = await supabase
      .from("th_video_commentaries")
      .delete()
      .in("video_id", videoIdList);

    if (deleteCommentsError) {
      throw new Error(
        "Error al eliminar los comentarios de los videos: " +
          deleteCommentsError.message
      );
    }

    const { error: deleteVideosError } = await supabase
      .from("dim_capacitacion_videos")
      .delete()
      .in("id", videoIdList);

    if (deleteVideosError) {
      throw new Error(
        "Error al eliminar los videos: " + deleteVideosError.message
      );
    }

    const { error: deleteModuleError } = await supabase
      .from("dim_capacitacion_modulos")
      .delete()
      .eq("id", moduleId);

    if (deleteModuleError) {
      throw new Error(
        "Error al eliminar el módulo: " + deleteModuleError.message
      );
    }

    toast.success("Módulo y videos eliminados correctamente.");
    setRecharge(true);
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
    toast.error("Error al eliminar el módulo: " + error.message);
  }
}
