import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

function PageNumber({ number, page, setCurrentPage, getData, limit, issue }) {
  const handleClik = () => {
    setCurrentPage(number);  
    getData(limit, (number - 1) * limit, issue);
  };

  return (
    <button
      className={`border relative z-10 inline-flex items-center  px-4 py-2 text-sm font-semibold text-black focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600
                 ${number === page && "bg-sky-700 text-white"}`}
      onClick={handleClik}
    >
      {number}
    </button>
  );
}

export function Pagination({ size, getData }) {
  const totalPages = Math.ceil(size / 10);
  const pages = Array.from({ length: totalPages }, (_, i) => i + 1);
  const [currentPage, setCurrentPage] = useState(1);
  const { issue } = useParams();
  const [limit, setLimit] = useState(10);

  const handleNext = () => {
    const nextPage = Math.min(currentPage + 1, totalPages);
    setCurrentPage(nextPage); 
    getData(limit, (nextPage - 1) * limit, issue); 
  };

  const handlePrevious = () => {
    const prevPage = Math.max(currentPage - 1, 1);
    setCurrentPage(prevPage); 
    getData(limit, (prevPage - 1) * limit, issue); 
  };

  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
      <div className="flex flex-1 justify-between sm:hidden">
        <button
          className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
          onClick={handlePrevious}
          disabled={currentPage === 1}
        >
          Anterior
        </button>
        <button
          className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
          onClick={handleNext}
          disabled={currentPage === totalPages}
        >
          Siguiente
        </button>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Mostrando <span className="font-medium">{(currentPage - 1) * limit + 1}</span> a{" "}
            <span className="font-medium">{currentPage * limit}</span> de{" "}
            <span className="font-medium">{size}</span> resultados
          </p>
        </div>
        <div>
          <nav aria-label="Pagination" className="isolate inline-flex -space-x-px rounded-md shadow-sm">
            <button
              className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
              disabled={currentPage === 1}
              onClick={handlePrevious}
            >
              <span className="sr-only">Anterior</span>
              <ChevronLeftIcon aria-hidden="true" className="h-5 w-5" />
            </button>
            {pages.map((page) => (
              <PageNumber
                key={page}
                number={page}
                page={currentPage}
                setCurrentPage={setCurrentPage}
                getData={getData}
                limit={limit}
                issue={issue}
              />
            ))}
            <button
              className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
              disabled={currentPage === totalPages}
              onClick={handleNext}
            >
              <span className="sr-only">Siguiente</span>
              <ChevronRightIcon aria-hidden="true" className="h-5 w-5" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}
