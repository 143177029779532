import { buttonColor } from "utils/Colors";

export function Button({ isIcon, icon, text, onClick, type }) {
  return (
    <button className={buttonColor} onClick={onClick} type={type}>
      <div className={`${isIcon === true && "flex justify-center"}`}>
        <div className="mr-3">{isIcon === true && icon}</div>
        <label className="cursor-pointer">{text}</label>
      </div>
    </button>
  );
}
