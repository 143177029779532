import { useEffect, useState } from "react";

import { getSubmodules, getUserDetails } from "hooks/commonData";
import {
  connectionToConnections,
  fetchConnections,
} from "api/bi-module/datasource";
import { getUserData } from "utils/SupabaseClient";
import { connectionToIntegrations } from "api/bi-module/integration";

import { Tabs } from "components/common/Tabs";
import { Reports } from "./submodules/reports";
import { ViewDocFiles } from "./submodules/documents";
import { Integrations } from "./submodules/integrations";
import { ConnectSource } from "./submodules/connections";
import { ChargingComponent } from "components/common/ChargingComponent";

export function BIModule() {
  const [plan, setPlan] = useState(null);
  const [items, setItems] = useState(null);
  const [active, setActive] = useState("Reportes");
  const [isRecharge, setIsRecharge] = useState(null);
  const [connections, setConnections] = useState([]);

  useEffect(() => {
    getUserData().then((user) =>
      getUserDetails(user.id, "plan").then((r) => {
        setPlan(r);
      })
    );
    getSubmodules(9).then((r) => {
      setItems(r);
    });
  }, []);

  useEffect(() => {
    getUserData().then((user) => {
      getUserDetails(user.id, "subcompanies-relation").then((subcompanies) => {
        fetchConnections(subcompanies.company).then((r) => {
          setConnections(r);
          setIsRecharge(false);
        });
      });
    });
  }, [isRecharge]);

  return (
    <>
      {!items && <ChargingComponent />}
      {items && (
        <>
          <Tabs items={items} setActive={setActive} active={active} />
          <Reports
            submodule={active}
            setSubmodule={setActive}
            globalRecharge={setIsRecharge}
            connectionsSize={plan ? plan.company_id.id_plan.report_count : 2}
            connectionsActive={
              plan
                ? plan.company_id.id_plan.connection_count >=
                  connections.filter((connection) => connection.type !== "API")
                    .length +
                    1
                : false
            }
            integrationsActive={
              plan
                ? plan.company_id.id_plan.integration_count >=
                  connections.filter((connection) => connection.type === "API")
                    .length +
                    1
                : false
            }
          />
          <ConnectSource
            submodule={active}
            setSubmodule={setActive}
            connections={connectionToConnections(connections)}
            setIsRecharge={setIsRecharge}
            isActive={
              plan
                ? plan.company_id.id_plan.connection_count >=
                  connections.filter((connection) => connection.type !== "API")
                    .length +
                    1
                : false
            }
          />
          <Integrations
            submodule={active}
            setSubmodule={setActive}
            connections={connectionToIntegrations(connections)}
            isActive={
              plan
                ? plan.company_id.id_plan.integration_count >=
                  connections.filter((connection) => connection.type === "API")
                    .length +
                    1
                : false
            }
            setIsRecharge={setIsRecharge}
          />
          <ViewDocFiles submodule={active} setSubmodule={setActive} />
        </>
      )}
    </>
  );
}
