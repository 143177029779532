import React, { useState, useEffect } from "react";
import { getComments, addComments, getAllUser } from "hooks/commonData";
import { SendIcon } from "components/svg/SendIcon";

const Commentaries = ({ videoId, userId }) => {
  const [comentarios, setComentarios] = useState([]);
  const [nuevoComentario, setNuevoComentario] = useState("");
  const [usuarios, setUsuarios] = useState([]);

  useEffect(() => {
    const fetchUsuarios = async () => {
      const usuariosData = await getAllUser();
      setUsuarios(usuariosData);
    };

    const fetchComentarios = async () => {
      const comentariosData = await getComments(videoId);
      setComentarios(comentariosData);
    };

    fetchUsuarios();
    fetchComentarios();
  }, [videoId]);

  const getNombreUsuario = (userId) => {
    const usuario = usuarios.find((usuario) => usuario.id === userId);
    return usuario ? `${usuario.first_name} ${usuario.last_name}` : "Desconocido";
  };

  const formatearFecha = (fecha) => {
    return new Date(fecha).toISOString().split('T')[0];
  };

  const handleEnviarComentario = async () => {
    if (nuevoComentario.trim()) {
      await addComments(videoId, userId, nuevoComentario);
      setNuevoComentario("");
      const comentariosData = await getComments(videoId);
      setComentarios(comentariosData);
    }
  };

  return (
    <div className="comentarios-container">
      <h3 className="text-xl font-semibold mb-4">Comentarios</h3>
      <div className="comentarios-list overflow-y-auto max-h-96" style={{ maxHeight: "380px", overflowY: "auto" }}>
        {comentarios.map((comentario, index) => (
          <div key={index} className="comentario-item mb-3 p-2 border-b">
            <div className="text-sm text-gray-500">{getNombreUsuario(comentario.user_id)}</div>
            <div className="text-base">{comentario.comentario}</div>
            <div className="text-xs text-gray-400">{formatearFecha(comentario.created_at)}</div>
          </div>
        ))}
      </div>
      <div className="comentarios-input mt-4 flex">
        <input
          type="text"
          className="bg-gray-100 p-2 rounded-l-md grow"
          placeholder="Escribe un comentario..."
          value={nuevoComentario}
          onChange={(e) => setNuevoComentario(e.target.value)}
        />
        <button
          className="bg-blue-500 p-2 rounded-r-md text-white"
          onClick={handleEnviarComentario}
        >
          <SendIcon className="h-6 w-6" />
        </button>
      </div>
    </div>
  );
};

export default Commentaries;
