export function GradiendBanner({ children, colors }) {
  return (
    <div
      className={`${
        colors
          ? colors
          : "bg-gradient-to-l to-sky-700 from-20% via-slate-300 via-30% from-yellow-500 to-90% rounded-md mb-2 flex justify-center items-center py-4"
      }`}
    >
      {children}
    </div>
  );
}
