import { decode } from "base64-arraybuffer";

const { toast } = require("sonner");
const { supabase } = require("utils/SupabaseClient");

// Obtener detalles de un usuario

export async function getUserDetails(user, details) {
  let selectInput = "*";

  switch (details) {
    case "ids":
      selectInput = "company_id, role_id";
      break;
    case "basic":
      selectInput =
        "company_id(company_name), first_name, last_name, phone, additional_phone, email, role_id(role_name) created_by(email), modified_by(email)";
      break;
    case "projects":
      selectInput =
        "projects_users!user_id(project_id, project: project_id(project_name))";
      break;
    case "tickets":
      selectInput = "tickets!created_by(id)";
      break;
    case "all":
      selectInput = "*";
      break;
    default:
      selectInput = "*";
  }
  const { data, error } = await supabase
    .from("users")
    .select(selectInput)
    .eq("id", user);
  if (error) {
    toast.error("Error al obtener el detalle del usuario");
  }
  return data[0];
}

// Obtener detalles de un proyecto

export async function getProjectDetails(project, details) {
  let selectInput = "*";

  switch (details) {
    case "ids":
      selectInput = "id, company_id, manager_id, created_by, modified_by";
      break;
    case "basic":
      selectInput = `id, project_name, code, description, company_id(company_name), manager_id(email), created_by(email), modified_by(email)`;
      break;
    case "services":
      selectInput = `id,  projects_services(services: enum_field_items(id, item_value))`;
      break;
    case "users":
      selectInput = "id, projects_users(user_id)";
      break;
    case "tickets":
      selectInput = "id, tickets(id)";
      break;
    case "all":
      selectInput = "*";
      break;
    default:
      selectInput = "*";
  }
  const { data, error } = await supabase
    .from("projects")
    .select(selectInput)
    .eq("id", project);
  if (error) {
    toast.error("Error al obtener el detalle del proyecto");
  }
  return data[0];
}

// Obtener detalle de un ticket

export async function getTicketDetails(ticket, details) {
  let selectInput = "*";

  switch (details) {
    case "ids":
      selectInput =
        "id,project_id, type_id, assigned_to, status_id, created_by, modified_by";
      break;
    case "basic":
      selectInput = `id, title, description, project_id(project_name), created_at, type_id(type_name), service_id(item_value),
                     assigned_to(email), status_id(status_name), created_by(email), modified_by(email)`;
      break;
    case "fields":
      selectInput =
        "id, ticket_fields(field_definition_id(id, field_name), field_value)";
      break;
    case "all":
      selectInput = "*, ticket_fields(field_definition_id, field_value)";
      break;
    default:
      selectInput = "*";
  }

  const { data, error } = await supabase
    .from("tickets")
    .select(selectInput)
    .eq("id", ticket);
  if (error) {
    toast.error("Error al obtener el detalle del ticket");
  }
  return data[0];
}

// Obtener detalle de una empresa

export async function getOrganizationDetails(organization, details) {
  let selectInput = "*";

  switch (details) {
    case "basic":
      selectInput = `id, company_name, nit, address(project_name), industry, created_by(email), modified_by(email)`;
      break;
    case "users":
      selectInput =
        "id, users!company_id(id, first_name, last_name, email, role_id(id, role_name))";
      break;
    case "projects":
      selectInput = "id, proejcts(id, project_name)";
      break;
    case "all":
      selectInput = "*";
      break;
    default:
      selectInput = "*";
  }

  const { data, error } = await supabase
    .from("companies")
    .select(selectInput)
    .eq("id", organization);
  if (error) {
    toast.error("Error al obtener el detalle de la empresa");
  }
  return data[0];
}

// Obtener todos los proyectos

export async function getAllProjects() {
  const { data, error } = await supabase.from("projects").select("*");
  if (error) {
    toast.error("Error obteniendo proyectos", error);
    return [];
  }
  return data;
}

// Obtener todos los tickets del usuario actual
export async function getAllUserTickets(loggedUserId, limit, offset, dataFilter) {
  try {
    // Obtener los detalles del usuario, como los proyectos a los que tiene acceso
    const projects = await getUserDetails(loggedUserId, "projects");

    if (!projects || !projects.projects_users || projects.projects_users.length === 0) {
      toast.error("No se encontraron proyectos asociados al usuario.");
      return [];
    }

    // Iniciar la consulta
    const query = supabase
      .from("tickets")
      .select(
        "id, title, created_at, description, type_id, type: type_id(type_name), status_id, status: status_id(status_name), service_id(item_value)",
        { count: 'exact' }
      )
      .in(
        "project_id",
        projects.projects_users.map((project) => project.project_id)
      )
      .range(offset, offset + limit - 1);

    if (dataFilter.stateSelected && dataFilter.stateSelected !== "-1") {
      query.eq("status_id", dataFilter.stateSelected);
    }
    if (dataFilter.projectSelected && dataFilter.projectSelected !== "-1") {
      query.eq("project_id", dataFilter.projectSelected);
    }

    if (dataFilter.dateStart) {
      let startDateTime = `${dataFilter.dateStart} 00:00:00`;
      query.gte("created_at::date", startDateTime); // gte: mayor o igual que
    }

    if (dataFilter.dateEnd) {
      let endDateTime = `${dataFilter.dateEnd} 00:00:00`;
      query.lte("created_at", endDateTime); // lte: menor o igual que
    }

    const { data, count, error } = await query;
    // Manejo de errores de la consulta
    if (error) {
      toast.error("Error obteniendo los tickets", error);
      return [];
    }

    return { data, count, error };

  } catch (error) {
    toast.error("Error inesperado al obtener los tickets", error);
    return [];
  }
}

// Obtener todos los tickets de un usuario

export async function getAllUserProjects(userId) {
  const projects = await getUserDetails(userId, "projects");

  const { data, error } = await supabase
    .from("projects")
    .select(
      "id, project_name, description, projects_services(services: enum_field_items(item_value))"
    )
    .in(
      "id",
      projects.projects_users.map((project) => project.project_id)
    );
  if (error) {
    toast.error("Error obteniendo los tickets", error);
    return [];
  }
  return data;
}

// Obtener los tipos de ticket

export async function getTicketTypes() {
  const { data, error } = await supabase
    .from("ticket_types")
    .select("id, type_name");
  if (error) {
    toast.error("Error obteniendo el tipo de ticket", error);
    return [];
  }
  return data;
}

// Items de los desplgables de los formularios de tickets

export async function getEnumFieldItems(field) {
  const { data, error } = await supabase
    .from("enum_field_items")
    .select("id,item_value, field_definition_id")
    .eq("field_definition_id", field);
  if (error) {
    toast.error("Error obteniendo los items del desplegable", error);
    return [];
  }
  return data;
}

// obtener todos los estados de los tickets

export async function getStatusValues() {
  const { data, error } = await supabase
    .from("ticket_statuses")
    .select("id, status_name");
  if (error) {
    toast.error("Error obteniendo los items del desplegable", error);
    return [];
  }
  return data;
}
// obtener los cursos

export async function getCourses(id) {
  let query = supabase.from("dim_cursos").select(
    `
      id,
      nombre_curso,
      descripcion,
      nombre_miniatura,
      valor_curso,
      estado_curso,
      estado: estado_curso(nombre_estado,color_id)
    `
  );

  if (id) {
    query = query.eq("id", id);
  }

  const { data, error } = await query;
  if (error) {
    toast.error("Error obteniendo los items del desplegable", error);
    return [];
  }

  return data;
}
// obtener los cursos comprados

export async function getCoursesStudent(id_usuario) {
  let query = supabase
    .from("curso_comprado")
    .select(
      `
    id_curso,
    estado_curso(id, nombre_estado , color_id),
    info_curso: id_curso(
    nombre_curso,
      descripcion,
      nombre_miniatura,
      valor_curso,
      estado_curso)
    `
    )
    .eq("id_usuario", id_usuario);
  const { data, error } = await query;
  if (error) {
    toast.error("Error obteniendo los items del desplegable", error);
    return [];
  }
  return data;
}

// obtener los cursos comprados

export async function getUserFiles(id_user) {
  let query = supabase
    .from("user_files")
    .select(
      "user_project, file_name, created_at, user(first_name, last_name), user"
    )
    .eq("user", id_user);
  const { data, error } = await query;
  if (error) {
    toast.error("Error obteniendo los items del desplegable", error);
    return [];
  }
  return data;
}

// obtener los cursos comprados

export async function getVideosWatched(id_usuario, id_video) {
  let query = supabase
    .from("estado_videos_usuarios")
    .select(
      `
id_estado
    `
    )
    .eq("id_usuario", id_usuario)
    .eq("id_video", id_video);
  const { data, error } = await query;
  if (error) {
    toast.error("Error obteniendo los items del desplegable", error);
    return [];
  }
  return data;
}

// obtener modulos
export async function getModule(course_id) {
  let query = supabase
    .from("dim_modulos")
    .select(
      `
      id,
      nombre_modulo,
      info_curso: curso_id(nombre_curso,nombre_miniatura)
      `
    )
    .eq("curso_id", course_id)
    .order('id', { ascending: true });

  const { data, error } = await query;

  if (error) {
    toast.error("Error obteniendo los items del desplegable", error);
    return [];
  }
  return data;
}

// obtener pbi
export async function getSUPPLIES(video_id) {
  let query = supabase
    .from("dim_insumos")
    .select(`name_file`)
    .eq("id_video", video_id);

  const { data, error } = await query;
  if (error) {
    toast.error("Error obteniendo los items del desplegable", error);
    return [];
  }
  return data;
}
// obtener insumoa
export async function getPBI(user_id) {
  let query = supabase
    .from("dashboards_pbi")
    .select(`id, link_pbi, name`)
    .eq("id_user", user_id);

  const { data, error } = await query;

  if (error) {
    toast.error("Error obteniendo los items del desplegable", error);
    return [];
  }
  return data;
}

// obtener Videos de los modulos
export async function getVideos(module_id, video_id, userId) {
  let query = supabase
    .from("dim_videos")
    .select(
      `
  id,
  titulo_video,
  url,
  info_curso:id_modulo(nombre_modulo, curso_id(id,nombre_curso, descripcion)),
  id_modulo
  `)

  if (module_id !== "") {
    query.eq("id_modulo", module_id)
  }

  if (video_id !== "") {
    query.eq("id", video_id);
  }

  const { data, error } = await query;

  if (error) {
    toast.error("Error obteniendo los items del desplegable", error);
    return [];
  }

  // Esperar todas las promesas de `getStatusVideo` con `Promise.all`
  const videosConEstado = await Promise.all(
    data.map(async (video) => {
      try {
        // Obtener el progreso del video
        const data = await getStatusVideo(userId, video.id);
        if (data.data) {
          if (data.data.length > 0) {
            video.visto = true;
          } else {
            video.visto = false;
          }
        }
      } catch (err) {
        console.error("Error al obtener el estado del video:", err);
        video.status = false;
      }

      return video;
    })
  );

  return videosConEstado;
}

// descargar archivos
export const DownloadFile = async (bucket, path) => {
  try {
    const { data, error } = await supabase.storage.from(bucket).download(path[0].name_file);
    if (error) throw new Error("An error occured downloading files");

    return data;
  } catch (error) {
    console.log(error);
  }
};
// obtener las imagenes "miniaturas" de los cursos

export async function downloadImage(path) {
  try {
    const { data } = supabase.storage
      .from("cursos")
      .getPublicUrl(`miniaturas/${path}`);

    const url = data.publicUrl;
    return url;
  } catch (error) {
    console.log("Error descargando imagen: ", error.message);
  }
}

// obtener las imagenes "miniaturas" de los cursos

export async function downloadVideo(path) {
  try {
    const { data } = supabase.storage.from("videos").getPublicUrl(`${path}`);

    const url = data.publicUrl;
    return url;
  } catch (error) {
    console.log("Error descargando imagen: ", error.message);
  }
}

// Obtiene el estado del los videos para saber si ya fue visto o no
export async function getStatusVideo(id_user, id_video) {

  try {
    if (id_user) {
      const { data, error } = await supabase
        .from('video_progress')
        .select("*")
        .eq("id_usuario", id_user)
        .eq("id_video", id_video)

      if (error) {
        throw new Error(error)
      }

      return { data: data, error: error }
    }
    return { data: null, error: null }
  } catch (error) {
    return { data: null, error: error.message }
  }
}

// Insertar un registro para saber si el video fue visto
export async function insertStatusVideo(id_user, id_video, id_curso, startDate) {
  try {

    const { data, error } = await supabase
      .from('video_progress')
      .insert([
        { "id_usuario": id_user, "id_video": id_video, "id_curso": id_curso, "fecha_inicio": startDate },
      ])

    if (error) {
      throw new Error("Error al insertar el progreso del video")
    }
    return data;
  } catch (error) {
    throw new Error(error.message)
  }
}

// Actualizar el estado de los videos
export async function updatetStatusVideo(id_user, id_video, pogress, viewTime, viewDate, endDate) {
  try {
    let body = {};
    if (viewDate) {
      body.fecha_ulma_vista = viewDate;
    }
    if (endDate) {
      body.fecha_fin = endDate;
    }

    const { data, error } = await supabase
      .from('video_progress')
      .update(body)
      .eq("id_usuario", id_user)
      .eq("id_video", id_video)

    if (error) {
      throw new Error("Error al insertar el progreso del video")
    }
    return data;
  } catch (error) {
    throw new Error(error.message)
  }
}

export async function getCountVideos(courseId) {
  const { data, error } = await supabase
    .from("dim_videos")
    .select("id_modulo(curso_id)");

  if (error) {
    console.error("Error obteniendo videos:", error);
    return 0;
  }

  const count = data.filter(video => video.id_modulo?.curso_id === parseInt(courseId, 10)).length;
  return count;
}

export async function getCountVideosViewed(userId, courseId) {
  let query = supabase
    .from("video_progress")
    .select("*")
    .eq("id_usuario", userId)
    .eq("id_curso", courseId);

  const { data, error } = await query;
  if (error) {
    console.error(error)
  }

  return data.length;
}

export async function getDataUser(user_id) {
  const { data, error } = await supabase
    .from("users")
    .select("role_id, first_name, last_name")
    .eq("id", `${user_id}`);
  if (error) {
    console.error(error)
  }
  return data;
}

export async function saveCurseFinalized(id_user, id_course, id_state, dateFinalized) {

  const { data, error } = await supabase
    .from("history_courses")
    .select("id")
    .eq("id_user", id_user)
    .eq("id_course", id_course)
    .eq("id_state", id_state)

  //  try {
  //   const {dataHistory, errorHistory} = await supabase
  //     .from("history_courses")
  //     .insert({"id_user":id_user, "id_course":id_course, "id_state":id_state, "data_end":dateFinalized })

  //   if(errorHistory){
  //     throw new Error("Error al insertar el curso finalizado")
  //   }
  //  } catch (error) {
  //   throw new Error(error.message);
  //  }
}

export async function addVideo(bucket, path, video) {
  try {
    const { data, error } = await supabase
      .storage
      .from(bucket)
      .upload(`${path}/${video.name}`, video)

    if (error) {
      throw new Error("Error al insertar el video")
    }
  } catch (error) {
    throw new Error(error.message)
  }
}

export async function saveCourse(course, bucket, path, files) {

  if (files) {
    try {
      await saveFile(bucket, path, files);
    } catch (error) {
      throw new Error(error.message);
    }
  }

  try {
    const { data, error } = await supabase
      .from("dim_cursos")
      .insert([{
        "nombre_curso": course.nombre,
        "descripcion": course.description,
        "es_pago": course.isPaid,
        "uuid_creado": course.id_user,
        "uuid_actualizado": course.id_user,
        "nombre_miniatura": course.image,
        "valor_curso": course.price,
        "estado_curso": course.state
      }])

    if (error) {
      throw new Error("Error al insertar el curso")
    }
  } catch (error) {
    throw new Error(error.message)
  }
}

export async function saveFile(bucket, path, files) {
  // Verificamos si alguno de los archivos ya existe
  for (const file of files) {
    const fileName = file.name;
    if (!isFileExists(bucket, path, file)) {
      throw new Error(`El archivo o imagen '${fileName}' ya existe.`);
    }
  }

  for (const file of files) {
    const fileName = file.name;
    const base64File = await convertToBase64(file);
    const base64FileString = base64File.replace(/^data:.+;base64,/, "");
    const decodedBuffer = decode(base64FileString);
    const fileBlob = new Blob([decodedBuffer]);

    const { error: uploadFiles } = await supabase.storage
      .from(bucket)
      .upload(`${path}/${fileName}`, fileBlob, {
        contentType: file.type,
      });

    if (uploadFiles && uploadFiles.statusCode === "409") {
      throw new Error(`El archivo o imagen '${fileName}' ya existe`);
    }
  }
}

export async function isFileExists(bucket, path, file) {
  const fileName = file.name;

  const { data: existingFileData, error: fileCheckError } = await supabase.storage
    .from(bucket)
    .list(path, { search: fileName });

  if (fileCheckError) {
    throw new Error(`Error al verificar el archivo: ${fileName}`);
  }

  if (existingFileData && existingFileData.length > 0) {
    return true;
  }
  return false;
}

export async function getCourse(idCourse) {
  
let { data: dim_cursos, error } = await supabase
.from('dim_cursos')
.select('*')
.eq("id", idCourse)

return dim_cursos;
}

function convertToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      // El resultado es la cadena Base64 (con el prefijo data:...)
      resolve(reader.result.split(',')[1]);  // Elimina el prefijo `data:*/*;base64,`
    };

    reader.onerror = (error) => reject(error);

    reader.readAsDataURL(file);  // Convierte el archivo a Base64
  });
}