import { toast } from "sonner";
import {
  createChannelsInfo,
  createSubcompany,
  createUser,
  createUserRole,
  createUserSubcompanyRelation,
  setChannelsInfo,
} from "./route";
import { getUserData } from "utils/SupabaseClient";

export async function InsertNewUser({
  setIsSuccess,
  setIsLoading,
  selectedCountry,
  selectedState,
  selectedCity,
  phoneCodes,
  firstName,
  lastName,
  phoneNumber,
  jobTitle,
  companyName,
  contactInformation,
}) {
  setIsLoading(true);

  try {
    const user = await getUserData();
    if (!user) {
      throw new Error("No user found");
    }

    const countryIsoCode = selectedCountry?.name || null;
    const stateIsoCode = selectedState?.name || null;
    const cityName = selectedCity?.name || null;
    const phoneCode =
      phoneCodes.filter((item) => item.name === countryIsoCode).phonecode ||
      null;

    const userResponse = await createUser({
      id: user.id,
      firstName,
      lastName,
      email: user.email,
      phoneNumber,
      jobTitle,
      countryIsoCode,
      stateIsoCode,
      cityName,
      phoneCode,
    });

    const roleResponse = await createUserRole({
      id: user.id,
    });

    const subCompanyResponse = await createSubcompany({
      user: userResponse.data[0],
      name: companyName,
    });

    if (contactInformation) {
      const channelResponse = await setChannelsInfo({
        contactInformation: contactInformation.name,
      });

      const createChannelResponse = await createChannelsInfo({
        id: userResponse.data[0].id,
        type: channelResponse.data[0].id,
      });
    }

    const subCompanyId = subCompanyResponse[0].id;
    await createUserSubcompanyRelation(user.id, subCompanyId);

    setIsLoading(false);
    setIsSuccess(true);

    toast.success("Tu cuenta ha sido registrada correctamente");
    window.location.reload();
  } catch (error) {
    console.log("error", error);
    setIsLoading(false);
    toast.error("Error por favor intente de nuevo");
  }
}
