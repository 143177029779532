import { toast } from "sonner";
import { supabase } from "utils/SupabaseClient";

export async function EditModuleName({
  newName,
  currentModule,
  setRecharge,
  setEdit,
}) {
  try {
    const { error } = await supabase
      .from("dim_capacitacion_modulos")
      .update({ nombre_modulo: newName })
      .eq("id", currentModule);

    if (error) {
      throw error;
    } else {
      toast.success("Módulo actualizado correctamente.");

      setRecharge(true);
      setEdit(false);
    }
  } catch (error) {
    toast.error("Error al actualizar el módulo: " + error.message);
  }
}
