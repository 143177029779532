import { toast } from "sonner";
import { getUserData, supabase } from "utils/SupabaseClient";

export async function EditSubCompany({
  active,
  companyId,
  subCompany,
  subCompanyInsert,
  name,
  lastName,
  nit,
  indicative,
  phone,
  mail,
  setIsLoading,
  setError,
  setSuccess,
  setRecharge,
}) {
  setIsLoading(true);

  const user = await getUserData();

  const items = {
    created_by: user.id,
    updated_by: user.id,
    activo: active,
    company_id: companyId,
    razon_social: subCompany,
    nombre_responsable: name,
    apellido_responsable: lastName,
    tipo_documento: "NIT",
    numero_documento: nit,
    correo_responsable: mail,
    indicativo_celular: indicative,
    celular_responsable: phone,
  };

  const { data: Insercion, error: ErrInsercion } = await supabase
    .from("auth_user_subcompanies")
    .update(items)
    .eq("id", subCompanyInsert);

  if (ErrInsercion) {
    toast.error("¡No fue posible guardar la subcompañia!");

    setError(true);
    setRecharge(true);
  } else {
    toast.success("¡Subcompañia Guardada exitosamente!");
    setSuccess(true);
    setRecharge(true);
  }
}
