import clsx from "clsx";

export function ActiveButton({
  setIsToggled,
  isToggled,
  size = { wCircle: "w-6", hCircle: "h-6", wBg: "w-[65px]", hBg: "h-[40px]" },
}) {
  const { wCircle, hCircle, wBg, hBg } = size; // Desestructuración más clara

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  return (
    <div
      onClick={handleToggle}
      className={clsx(
        wBg ? wBg : "w-[65px]",
        hBg ? hBg : "h-[40px]",
        "flex items-center cursor-pointer",
        {
          "bg-yellow-500": isToggled,
          "bg-gray-300": !isToggled,
        },
        "p-2 rounded-full transition-all"
      )}
    >
      <div
        className={clsx(
          wCircle ? wCircle : "w-6",
          hCircle ? hCircle : "h-6",
          "bg-white rounded-full shadow-md transform transition-transform duration-300",
          {
            "translate-x-full": isToggled,
            "translate-x-0": !isToggled,
          }
        )}
      />
    </div>
  );
}
