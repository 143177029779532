export function TD({ item, responsive }) {
  return (
    <td
      className={`px-3 md:px-6 py-4 border-b border-slate-300 ${
        responsive && `hidden ${responsive}:table-cell`
      }`}
    >
      {item}
    </td>
  );
}
