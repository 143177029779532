import { Button } from "./Button";

export function ButtonAddFooter({ onClick }) {
  return (
    <div className="flex w-full items-center appear-animation">
      <hr className="border-2  border-slate-400 grow border-dashed" />

      <Button
        onClick={onClick}
        text="Añadir módulo"
        className="text-slate-400 text-lg text-center border-3 border-dashed border-slate-400 rounded-full px-6 hover:bg-slate-400 hover:text-white transition-all"
      />

      <hr className="border-2 border-slate-400 grow border-dashed" />
    </div>
  );
}
