export function DataPreview({ count, high }) {
    return (
        <div className="flex flex-col gap-3">
            {
                Array.from({length:count}).map((_, index) =>
                <div
                key={index}
                className={`bg-gray-500 w-full ${high} animate-fade-slide`}
                >
                </div>
            )
            }
        </div>
    );
  }