import { TextInformation } from "components/common/TextInformation";

export function ImageWithDescription({ path, textInformation, h }) {
  return (
    <div className={`relative rounded-lg overflow-hidden ${h} `}>
      {/* Imagen de fondo */}
      <img
        src={path}
        className="absolute inset-0 w-full h-full object-cover"
        alt="background"
      />

      {/* Contenedor del texto sobre la imagen */}
      <div className="flex flex-col h-full">
        <div className="flex-1 divide-y-1 relative z-10 h-full mb-4"></div>
        <div className="flex  relative z-10 text-sm text-white bg-black bg-black/70 p-4">
          <TextInformation text={textInformation} />
        </div>
      </div>
    </div>
  );
}
