import { Route, Routes } from "react-router-dom";

import { Sidebar, SidebarItem } from "../components/Sidebar";

import { Develop } from "./develop";
import { Training } from "./training";
import { NewCourse } from "./training/new";

import { DatabaseManagement } from "./databases";
import { NewDatabase } from "./databases/NewDatabase";
import { ViewDatabases } from "./databases/ViewDatabases";
import { DeleteDatabase } from "./databases/DeleteDatabase";
import { Course } from "./training/course";
import { ReportInformation } from "./reports/ReportInformation";
import { Reports } from "./reports";
import { ViewDocFiles } from "./documents";
import { Profile } from "components/Profile";

import { getUserModules } from "api/utils/NavbarItems";
import { useEffect, useState } from "react";
import { toast } from "sonner";

export function App({ nombre, apellido, email, isTrainee, items }) {


  return (
    <div className="flex">
      <Sidebar user={`${nombre} ${apellido}`} email={email}>
        {items.map((item, index) => (
          <SidebarItem
            key={index}
            icon={item.icon}
            text={item.text}
            active={item.active}
            alert={item.alert}
            to={item.to}
          />
        ))}
      </Sidebar>
      <main className="grow bg-[#ebebeb]">
        <div className="p-[20px]">
          <Routes>
            {isTrainee ? (
              <>
                <Route path="/training" element={<Training />} />
                <Route path="/training/new" element={<NewCourse />} />
                <Route
                  path="/training/course/:id_course"
                  element={<Course />}
                />
                <Route
                  path="/training/course/:id_course/video/:id_video"
                  element={<Course />}
                />
              </>
            ) : (
              <>
                <Route path="/databases" element={<DatabaseManagement />} />
                <Route path="/databases/new" element={<NewDatabase />} />
                <Route
                  path="/databases/view"
                  element={<ViewDatabases user={nombre.toLowerCase()} />}
                />
                <Route path="/databases/delete" element={<DeleteDatabase />} />
                <Route
                  path="/reports"
                  element={<Reports nombre={`${nombre} ${apellido}`} />}
                />
                <Route
                  path="/ReportInformation/:id_pbi"
                  element={<ReportInformation />}
                />
                <Route path="/develop" element={<Develop />} />
                <Route
                  path="/documents"
                  element={<ViewDocFiles user={nombre.toLowerCase()} />}
                />
                <Route path="/training" element={<Training />} />
                <Route path="/training/new" element={<NewCourse />} />
                <Route
                  path="/training/course/:id_course"
                  element={<Course />}
                />
                <Route
                  path="/training/course/:id_course/video/:id_video"
                  element={<Course />}
                />
              </>
            )}
            <Route path="/" element={<Event />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="*" element={<h1>404 Not Found</h1>} />
          </Routes>
        </div>
      </main>
    </div>
  );
}

function Event() {
  return (
    <img
      className="w-full max-h-[90vh] object-cover"
      src="assets/events/power_bi_day.png"
      alt="power_bi_day"
    />
  );
}
