import { ModalBG } from "components/common/ModalBG";
import { useEffect, useState } from "react";
import { AddSubcompany } from "api/user-module/AddSubCompany";
import { StructureModalSubCompany } from "./StructureModalSubCompany";

export function ModalAddSubCompanie({
  setIsOpen,
  isOpen,
  setRecharge,
  nombre,
  apellido,
  companyId,
  countries,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const [subCompany, setSubCompany] = useState("");
  const [nit, setNit] = useState("");
  const [indicative, setIndicative] = useState("");
  const [phone, setPhone] = useState("");
  const [mail, setMail] = useState("");

  useEffect(() => {
    if (success || error) {
      setIsOpen(false);
      setIsLoading(false);
      setSuccess(false);
      setError(false);
      setSubCompany("");
      setNit("");
      setIndicative("");
      setPhone("");
      setMail("");
    }
  }, [success, error]);

  const funcion = async () => {
    await AddSubcompany({
      companyId,
      subCompany,
      nombre,
      apellido,
      nit,
      indicative,
      phone,
      mail,
      setIsLoading,
      setError,
      setSuccess,
      setRecharge,
    });
  };

  return (
    <ModalBG
      open={isOpen}
      onClose={() => setIsOpen(false)}
      title={"Añadir Nueva Sub Compañia"}
    >
      <StructureModalSubCompany
        modalType="add"
        isLoading={isLoading}
        setSubCompany={setSubCompany}
        subCompany={subCompany}
        setMail={setMail}
        mail={mail}
        setNit={setNit}
        nit={nit}
        countries={countries}
        setIndicative={setIndicative}
        indicative={indicative}
        setPhone={setPhone}
        phone={phone}
        form={funcion}
      />
    </ModalBG>
  );
}
