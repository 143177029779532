import { BackGroundSections } from "components/common/BackGroundSections";
import { Button } from "components/common/Button";
import { Subtitle } from "components/common/Subtitle";
import { Row } from "components/table/Row";
import { Table } from "components/table/Table";
import { TD } from "components/table/TD";
import { getUserDetails } from "hooks/commonData";
import { useEffect, useState } from "react";
import { getUserData } from "utils/SupabaseClient";
import { ModalAddSubCompanie } from "./ModalAddSubCompanie";
import { FiEdit } from "react-icons/fi";
import { ModalEditSubCompanie } from "./ModalEditSubCompanie";
import { Country } from "country-state-city";

export function SubCompaniesVisual({ submodule, nombre, apellido, companyId }) {
  const [data, setData] = useState([]);
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [recharge, setRecharge] = useState(false);
  const [countries, setCountries] = useState([]);
  const [dataEdit, setDataEdit] = useState([]);

  const nameRows = [
    { label: "Sub compañia", responsive: null },
    { label: "NIT", responsive: "md" },
    { label: "Responsable", responsive: "md" },
    { label: "Activo", responsive: "md" },
    { label: "Editar", responsive: null },
  ];

  useEffect(() => {
    getUserData().then((user) => {
      getUserDetails(user.id, "subcompanies").then((e) => {
        setRecharge(false);
        setData(e.company.projects);
      });
    });
  }, [recharge]);

  useEffect(() => {
    const countriesData = Country?.getAllCountries();

    if (countriesData) {
      setCountries(
        countriesData.map((item) => ({
          label: `${item.phonecode} ${item.name}`,
          value: `${item.phonecode}`,
        }))
      );
    } else {
      console.error("No se pudieron obtener los países");
    }
  }, []);

  return (
    <div className={submodule === "Sub Compañias" ? "" : "hidden"}>
      <ModalAddSubCompanie
        isOpen={isOpenAdd}
        setIsOpen={setIsOpenAdd}
        setRecharge={setRecharge}
        nombre={nombre}
        apellido={apellido}
        companyId={companyId}
        countries={countries}
      />

      <ModalEditSubCompanie
        isOpen={isOpenEdit}
        setIsOpen={setIsOpenEdit}
        setRecharge={setRecharge}
        companyId={companyId}
        countries={countries}
        dataEdit={dataEdit}
      />

      <BackGroundSections>
        <div className="flex justify-between mb-1">
          <Subtitle text={`Sub Compañias Asociadas`} />

          <div>
            <Button
              className="appear-animation bg-sky-800 hover:bg-yellow-500 grow p-2 text-white rounded-lg transition-colors"
              text="añadir +"
              onClick={() => setIsOpenAdd(true)}
            />
          </div>
        </div>
        <Table nameRows={nameRows}>
          {data.map((item, index) => (
            <Row
              key={`${item.nombre_responsable}${item.apellido_responsable}${index}`}
            >
              <TD item={item.razon_social} />
              <TD item={item.numero_documento} responsive="md" />
              <TD
                item={String(
                  `${item.nombre_responsable} ${item.apellido_responsable}`
                ).replaceAll("null", "")}
                responsive="md"
              />
              <TD
                item={String(item.activo)
                  .replaceAll("true", "activo")
                  .replaceAll("false", "inactivo")}
                responsive="md"
              />
              <TD
                item={
                  <FiEdit
                    onClick={() => {
                      setIsOpenEdit(true);
                      setDataEdit(item);
                    }}
                    className="w-7 h-7 ml-5 mt-2 border-b border-slate-300 hover:bg-slate-100 hover:scale-105 rounded-lg transition-all p-[2px]"
                  />
                }
                responsive="md"
              />
            </Row>
          ))}
        </Table>
      </BackGroundSections>
    </div>
  );
}
