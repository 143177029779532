import { toast } from "sonner";
import { Exit } from "../svg/ExitIcon";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { supabase } from "utils/SupabaseClient";
import React, {
  useState,
  useEffect,
  useRef,
  createContext,
  useContext,
} from "react";
import { Bars3Icon } from "@heroicons/react/20/solid";
import { PlanLeyend } from "./PlanLeyend";
import { PlisBILogo } from "components/svg/PlisBILogo";

const SidebarContext = createContext({ expanded: true });

export function Sidebar({ user, email, children }) {
  const [expanded, setExpanded] = useState(true);
  const navigate = useNavigate();

  async function signOut() {
    const { error } = await supabase.auth?.signOut();
    console.log(error);
    if (error) {
      toast.error(error);
    } else {
      localStorage?.clear();
      navigate("/");
    }
  }

  const navRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef?.current?.contains(event?.target)) {
        setExpanded(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <aside className="h-screen">
      <nav
        ref={navRef}
        className="absolute md:relative top-0  h-full z-20 md:z-0"
      >
        <div>
          <Bars3Icon
            className={`${
              expanded ? "hidden" : ""
            } absolute p-1.5 rounded-full bg-gray-50 hover:bg-gray-100 m-3 trantition-all w-10`}
            onClick={() => setExpanded((curr) => !curr)}
          />
          <div
            className={`${
              !expanded
                ? "slide-left-hidden-animation "
                : "slide-left-animation   "
            }  flex flex-col bg-white border-r-2 border-gray-100 shadow-xs h-screen`}
          >
            <div className="p-4 pb-2 flex justify-between items-center">
              <div className="">
                <PlisBILogo
                  className={`transtition-all ${
                    expanded ? "w-30 h-16 appear-animation" : "w-0"
                  }`}
                />
              </div>

              <button
                onClick={() => setExpanded((curr) => !curr)}
                className={`${
                  expanded ? "bg-gray-50 hover:bg-gray-100" : ""
                } p-1.5 rounded-lg`}
              >
                <i
                  className={
                    expanded ? "lni lni-shift-left" : "lni lni-shift-right "
                  }
                ></i>
              </button>
            </div>
            <PlanLeyend expanded={expanded} />
            <SidebarContext.Provider value={{ expanded }}>
              <ul className="flex-1 px-3">{children}</ul>
            </SidebarContext.Provider>

            <div className="border-t border-slate-300 flex p-3">
              <img
                alt="Usuario"
                className="rounded-md w-10 h-10"
                src="assets/img/icons/principal.png"
              />
              <div
                className={`flex justify-between items-center overflow-hidden transition-all ${
                  expanded ? "w-52 ml-3" : "w-0"
                }`}
              >
                <div className="leading-4">
                  <h4 className="font-semibold">{user}</h4>
                  <span className="text-xs text-gray-600">{email}</span>
                </div>
                <button
                  className="py-1 px-[3px] transition-colors mb-[2px] rounded-full"
                  onClick={signOut}
                  aria-label="Cerrar sesión"
                >
                  <Exit h="20" w="20" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </aside>
  );
}

export function SidebarItem({ icon, text, active, to, alert }) {
  const { expanded } = useContext(SidebarContext);

  return (
    <li>
      <Link
        to={to}
        className={`relative flex items-center py-2 px-3 my-1 font-medium rounded-md cursor-pointer transition-colors group hover:bg-yellow-50 text-sky-800 z-20`}
      >
        {icon}
        <span
          className={`overflow-hidden transition-all  ${
            expanded ? "w-52 ml-3 truncate" : "w-0 hidden"
          }`}
          style={{
            animation: expanded
              ? "expand 0.4s ease-in-out forwards"
              : "hide 0.2s ease-in-out forwards",
          }}
        >
          {text}
        </span>
        {alert && (
          <div
            className={`${
              alert ? "" : "hidden"
            } absolute right-2 w-2 h-2 rounded bg-yellow-500 z-30`}
          />
        )}
        <div
          className={`${
            expanded ? "hidden" : ""
          } z-10 absolute truncate left-full rounded-md px-2 py-1 ml-6 bg-yellow-100 text-yellow-800 text-sm invisible opacity-20 -translate-x-3 transition-all group-hover:visible group-hover:opacity-100 group-hover:translate-x-0`}
        >
          {text}
        </div>
      </Link>
    </li>
  );
}
