import { useEffect, useState } from "react";

import { SiAmazonapigateway } from "react-icons/si";

import { DeleteApiConection, UpdatePassword } from "api/bi-module/integration";

import { Text } from "components/tailwind/Text";
import { Dropdown } from "components/common/Dropdown";
import { LineIcon } from "components/svg/LineIcon";
import { ModalBG } from "components/common/ModalBG";
import { ButtonSave } from "components/common/ButtonSave";
import { ModalSuccess } from "components/modal/ModalSuccess";
import { ConfirmDelete } from "components/common/ConfirmDelete";
import { ChargingComponent } from "components/common/ChargingComponent";

function DeleteModal({ item, open, onClick }) {
  const [isDelete, setIsDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const handleDelete = async () => {
    setIsLoading(true);
    let deleteData = await DeleteApiConection({ id: item.id });
    if (deleteData) {
      setIsLoading(false);
      setIsSuccess(true);
    } else {
      setIsLoading(false);
      setIsSuccess(false);
    }
  };
  return (
    <ModalBG title="Eliminar conexion" open={open} onClose={onClick}>
      {!isLoading && (
        <>
          {!isSuccess && (
            <>
              <h1 className="block text-md font-medium leading-6 text-gray-900">
                ¿Seguro que quiere eliminar la <strong>conexion</strong> de la
                empresa <strong>{item.company}</strong>?.
              </h1>
              <ConfirmDelete message="para confirmar" onConfirm={setIsDelete} />
              <div className="flex justify-end pt-2">
                <button
                  onClick={handleDelete}
                  className="bg-red-500 px-4 py-2 rounded-md text-white hover:bg-red-400"
                  disabled={!isDelete}
                >
                  Eliminar
                </button>
              </div>
            </>
          )}
          {isSuccess && (
            <ModalSuccess
              title="Eliminado"
              message="Conexión eliminada correctamente, por favor recarga la página nuevamente."
              onClose={onClick}
            />
          )}
        </>
      )}
      {isLoading && <ChargingComponent />}
    </ModalBG>
  );
}

function EditModal({ newRow, onClose, item }) {
  const [password, setPassword] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const handleSubmit = async () => {
    setIsLoading(true);
    let resetPassword = await UpdatePassword({ password, id: item.id });
    if (resetPassword) {
      setIsSuccess(true);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setIsSuccess(false);
    }
  };
  return (
    <ModalBG open={newRow} onClose={onClose} title="Editar contraseña">
      {!isLoading && (
        <>
          {!isSuccess && (
            <>
              <Text
                title="Contraseña"
                field="integrations-update-email"
                onChange={setPassword}
                value={password}
              />
              <ButtonSave HadleSubmit={handleSubmit} />
            </>
          )}
          {isSuccess && (
            <ModalSuccess
              title={"Cambio de Contraseña"}
              message={
                "Se ha cambiado la contraseña correctamente, recarga la pagina para poder visualizar la contraseña actualizada"
              }
              onClose={onClose}
            />
          )}
        </>
      )}
      {isLoading && <ChargingComponent />}
    </ModalBG>
  );
}

function Row({ item, setRecharge }) {
  const [eliminar, setEliminar] = useState(false);
  const [newRow, setNewRow] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);

  const handleDelete = () => {
    setEliminar(!eliminar);
    setRecharge();
  };
  const handleNewRow = () => {
    setNewRow(!newRow);
    setRecharge();
  };
  const showPassword = () => {
    setIsShowPassword(!isShowPassword);
  };
  return (
    <>
      <tr className="bg-white border-b">
        <th
          scope="row"
          className="px-3 md:px-6 py-4 font-medium text-gray-900 whitespace-nowrap hidden md:table-cell"
        >
          {item.company}
        </th>
        <td className="px-3 md:px-6 py-4 hidden lg:table-cell">
          {item.report}
        </td>
        <td className="px-3 md:px-6 py-4 hidden lg:table-cell">
          {item.provider}
        </td>
        <td className="px-3 md:px-6 py-4 hidden xl:table-cell">{item.nit}</td>
        <td className="px-3 md:px-6 py-4 hidden lg:table-cell">
          {item.user_name}
        </td>
        <td className="px-3 md:px-6 py-4">
          <p className="hidden md:table-cell">
            {isShowPassword ? `${item.password}` : "********"}
          </p>
          <div className="md:hidden">
            <File item={item} />
          </div>
        </td>
        <td className="px-3 md:px-6 py-4">
          <div className="hidden md:table-cell">
            <div className="flex">
              <LineIcon name="eye" color="blue" onClick={showPassword} />
              {setRecharge && (
                <>
                  <LineIcon name="minus" color="red" onClick={handleDelete} />
                  <LineIcon name="pencil" color="blue" onClick={handleNewRow} />
                </>
              )}
            </div>
          </div>
          <div className="md:hidden">
            <Dropdown
              firstName="Eliminar"
              downloadAction={handleNewRow}
              secondName="Editar"
              deleteAction={handleDelete}
            />
          </div>
        </td>
      </tr>
      <DeleteModal item={item} onClick={handleDelete} open={eliminar} />
      <EditModal item={item} newRow={newRow} onClose={handleNewRow} />
    </>
  );
}

function File({ item }) {
  return (
    <div className="flex min-w-0 gap-x-4">
      <div className="h-12 w-12 flex items-center justify-center rounded-full bg-gray-50 text-2xl">
        <SiAmazonapigateway className="text-yellow-500" />
      </div>
      <div className="min-w-0 flex-auto">
        <p className="text-sm font-semibold leading-6 text-gray-900">
          {item.company}
        </p>
        <p className="mt-1 truncate text-xs leading-5 text-gray-500">
          {item.provider} - {item.nit}
        </p>
      </div>
    </div>
  );
}

export function Table({ items, setRecharge, isActive }) {
  const [totalItems, setTotalItems] = useState(0);
  useEffect(() => {
    if (items) setTotalItems(items.length);
  }, [items]);
  return (
    <>
      {items === null ? (
        <ChargingComponent />
      ) : (
        <>
          <div className="relative shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500">
              <thead className="text-xs text-sky-800 uppercase bg-gray-50 top-0 z-20">
                <tr>
                  <th
                    scope="col"
                    className="px-3 mix-blend-normal py-3 hidden md:table-cell"
                  >
                    Empresa
                  </th>
                  <th
                    scope="col"
                    className="px-3 mix-blend-normal py-3 hidden lg:table-cell"
                  >
                    Reporte
                  </th>
                  <th
                    scope="col"
                    className="px-3 mix-blend-normal py-3 hidden lg:table-cell"
                  >
                    Proveedor
                  </th>
                  <th
                    scope="col"
                    className="px-3 mix-blend-normal py-3 hidden xl:table-cell"
                  >
                    NIT
                  </th>
                  <th
                    scope="col"
                    className="px-3 mix-blend-normal py-3 hidden lg:table-cell"
                  >
                    Usuario
                  </th>
                  <th scope="col" className="px-3 mix-blend-normalpx-6 py-3">
                    Contraseña
                  </th>
                  <th scope="col" className="px-3 mix-blend-normalpx-6 py-3">
                    Acción
                  </th>
                </tr>
              </thead>
              <tbody>
                {isActive && (
                  <tr className="bg-white border-b">
                    <th
                      scope="row"
                      colSpan="7"
                      className="px-3 md:px-6 py-4 font-medium text-gray-500 whitespace-nowrap text-center"
                    >
                      Tu plan no cuenta con conexiones API...
                    </th>
                  </tr>
                )}
                {!isActive && (
                  <>
                    {items.length <= 0 && (
                      <tr className="bg-white border-b">
                        <th
                          scope="row"
                          colSpan="7"
                          className="px-3 md:px-6 py-4 font-medium text-gray-500 whitespace-nowrap text-center"
                        >
                          No tienes ninguna conexión, puedes agregar una
                          conexión...
                        </th>
                      </tr>
                    )}
                    {items.length >= 1 && (
                      <>
                        {items.map((item) => (
                          <Row
                            key={item.id}
                            item={{
                              id: item.id,
                              company: item.subcompany_id.razon_social,
                              report: item.report_id.project_name,
                              provider: item.provider,
                              nit: item.subcompany_id.numero_documento,
                              user_name: item.user_api,
                              password: item.access_key,
                            }}
                            setRecharge={setRecharge}
                          />
                        ))}
                      </>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
          {totalItems > 10 && (
            <PaginationTable totalItems={totalItems} perPage={10} />
          )}
        </>
      )}
    </>
  );
}

function PaginationTable({ totalItems, perPage }) {
  const [pagesList, setPagesList] = useState(null);

  useEffect(() => {
    const pages = Math.ceil(totalItems / perPage);
    const newPagesList = [];
    for (let i = 1; i <= pages; i++) {
      newPagesList.push(i);
    }
    setPagesList(newPagesList);
  }, [totalItems, perPage]);

  return (
    <nav
      className="flex items-center flex-column flex-wrap md:flex-row justify-between pt-4"
      aria-label="Table navigation"
    >
      <span className="text-sm font-normal text-gray-500 mb-4 md:mb-0 block w-full md:inline md:w-auto">
        Mostrando <span className="font-semibold text-gray-900">1-10</span> de{" "}
        <span className="font-semibold text-gray-900">{totalItems}</span>
      </span>
      <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
        <li>
          <a
            href="#"
            className="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700"
          >
            Anterior
          </a>
        </li>
        {pagesList &&
          pagesList.map((page) => {
            <li>
              <a
                href="#"
                className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
              >
                {page}
              </a>
            </li>;
          })}
        <li>
          <a
            href="#"
            className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700"
          >
            Siguiente
          </a>
        </li>
      </ul>
    </nav>
  );
}
