import { Subtitle } from "components/common/Subtitle";
import { AutoHeigth } from "components/common/AutoHeigth";
import { BackGroundSections } from "components/common/BackGroundSections";
import { ChangePasswordSection } from "./ChangePasswordSection";
import { DataUser } from "./DataUser";
import { ChargingComponent } from "components/common/ChargingComponent";
import { useState } from "react";
import { UserImage } from "./UserImage";
import { getProvider } from "utils/SupabaseClient";

export function ProfileVisual({ submodule }) {
  const [isLoading, setisLoading] = useState(false);

  return (
    <div className={`${submodule === "Perfil" ? "" : "hidden"}`}>
      <BackGroundSections>
        <div className={isLoading ? "appear-animation" : "hidden"}>
          <ChargingComponent />
        </div>

        <div className={!isLoading ? "appear-animation" : "hidden"}>
          <AutoHeigth maxPx={"94px"}>
            <div className="text-[#1a3365] mt-2">
              <Subtitle text={`Perfil de Usuario`} />
            </div>
            <div className="grid md:grid-cols-4 gap-4">
              <UserImage />
              <div className=" md:col-span-3">
                <DataUser setisLoading={setisLoading} />
              </div>
            </div>
            {getProvider !== "google" && (
              <>
                <div className="text-[#1a3365] mt-6">
                  <Subtitle text={`Cambiar contraseña`} />
                </div>
                <ChangePasswordSection userProvider={getProvider} />
              </>
            )}
          </AutoHeigth>
        </div>
      </BackGroundSections>
    </div>
  );
}
