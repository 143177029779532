import { toast } from "sonner";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { getUserDetails } from "hooks/commonData";
import { getUserData } from "utils/SupabaseClient";
import { createReport } from "api/bi-module/reports";

import { Button } from "components/common/Button";
import { ModalBG } from "components/common/ModalBG";
import { TextArea } from "components/tailwind/TextArea";
import { InputText } from "components/common/InputText";
import { ModalSuccess } from "components/modal/ModalSuccess";
import { ChargingComponent } from "components/common/ChargingComponent";
import { FileUploadComponent } from "components/tailwind/FileUploadComponent";

export function NewReport({
  open,
  onClose,
  setIsRecharge,
  setIsSuccess,
  isSuccess,
  isActive,
}) {
  const [urlReport, setUrlReport] = useState(null);
  const [reportName, setReportName] = useState(null);
  const [subcompany, setSubcompany] = useState(null);
  const [description, setDescription] = useState(null);
  const [subcompanies, setSubcompanies] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSave, setIsSave] = useState(false);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [length, setLength] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      setUrlReport("");
      setReportName("");
      setDescription("");
    }
  }, [isSuccess]);

  useEffect(() => {
    if (!urlReport || !reportName || !description) {
      setIsSave(false);
    } else {
      if (length > 1) {
        setIsSave(false);
        toast.error("¡Solo puedes subir una miniatura!");
      } else {
        setIsSave(true);
      }
    }
  }, [length, urlReport]);

  useEffect(() => {
    getUserData().then((user) =>
      getUserDetails(user.id, "subcompanies-relation").then(
        (subcompaniesList) => {
          setSubcompanies(subcompaniesList.company);
        }
      )
    );
  }, []);

  const handleSave = async () => {
    setIsLoading(true);
    await createReport({
      file: selectedFiles,
      provider: 48,
      subcompany_id:
        subcompanies.length === 1
          ? subcompanies[0].id_subcompanies.id
          : subcompany,
      url_report: urlReport,
      report_name: reportName,
      description: description,
      is_project: true,
    });
    setIsLoading(false);
    setIsSuccess(true);
    setIsRecharge(true);
  };

  return (
    <ModalBG
      title={"Nuevo reporte".toUpperCase()}
      open={open}
      onClose={onClose}
    >
      {!isSuccess && (
        <>
          {!isLoading && (
            <>
              {isActive && (
                <div className="slide-down-animation overflow-y-auto max-h-full md:max-h-80 2xl:max-h-full p-4">
                  <InputText
                    setValue={setReportName}
                    value={reportName}
                    placeholder="Nombre del reporte"
                    required
                  />
                  <TextArea
                    field="report-description"
                    title="Descripción del reporte"
                    onChange={setDescription}
                    value={description}
                    required
                  />
                  <InputText
                    placeholder="Link del reporte"
                    setValue={setUrlReport}
                    value={urlReport}
                    required
                  />
                  <FileUploadComponent
                    text="Subir miniatura"
                    redText=".png y .jpg"
                    accept=".png, .jpg"
                    setLength={setLength}
                    setSelectedFilesProps={setSelectedFiles}
                  />
                  <div className="flex justify-end pt-4">
                    {!isSave ? (
                      <div className="border border-red-500 bg-red-400 text-white text-center text-xs font-bold p-2 rounded-md w-full">
                        Por favor, llena todos los campos
                      </div>
                    ) : (
                      <Button
                        type="button"
                        text="Cargar reporte"
                        onClick={handleSave}
                      />
                    )}
                  </div>
                </div>
              )}
              {!isActive && (
                <div className="text-center">
                  <h3 className="text-lg font-bold">
                    Un plan de pago incluye mejores funcionalidades
                  </h3>
                  <p className="mb-4">
                    Estas usando el plan Free. Mejora tu plan para obtener
                    conexiones a reportes y mucho más. Puedes cancelarlo en
                    cualquier momento.
                  </p>
                  <h3 className="text-lg font-bold">
                    Has utilizado todos los reportes disponibles en tu plan.
                  </h3>
                  <p>Mejora tu plan y obten más carácteristicas.</p>
                  <div className="flex flex-row-reverse">
                    <Button
                      text="Mejorar plan"
                      type="button"
                      onClick={() => {
                        navigate("/plans");
                      }}
                    />
                  </div>
                </div>
              )}
            </>
          )}
          {isLoading && <ChargingComponent />}
        </>
      )}
      {isSuccess && (
        <ModalSuccess
          title="Reporte creado"
          message="¡Tu reporte se ha creado exitosamente!"
          onClose={onClose}
        />
      )}
    </ModalBG>
  );
}
