import { FaTimes } from 'react-icons/fa';

export function CoursePurchaseModal({ isOpen, onClose, course }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-black/50 flex justify-center items-center">
      <div className="bg-white p-6 rounded-md w-96">
        <button
          className="absolute top-2 right-2 text-xl"
          onClick={onClose}
        >
          <FaTimes />
        </button>
        <h2 className="text-2xl font-semibold text-sky-700 mb-2">{course.title}</h2>
        <img src={course.image} alt={course.title} className="w-full rounded-lg mb-4" />
        <p className="text-gray-700 mb-4">{course.description}</p>
        <div className="flex justify-between items-center">
          <span className="text-xl font-bold text-green-600">${course.price}</span>
          <button
            onClick={() => console.log("Comprar curso")}
            className="bg-blue-600 text-white py-2 px-4 rounded-md"
          >
            Comprar
          </button>
        </div>
      </div>
    </div>
  );
}
