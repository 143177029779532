import { TrashIcon } from "@heroicons/react/20/solid";
import { EditModuleName } from "api/training-module/EditModuleName";
import { RemoveModule } from "api/training-module/RemoveModule";
import { Button } from "components/common/Button";
import { InputText } from "components/common/InputText";
import { DeleteModal } from "components/modal/ModalDelete";
import { BackIcon } from "components/svg/BackIcon";
import { getVideosWithoutState } from "hooks/commonData";
import { useEffect, useState } from "react";
import { VideoUploadModal } from "./VideoUploadModal";

export function Section({ sectionId, title, setRecharge, idCurso }) {
  const [videoData, setVideoData] = useState([]);

  const [newName, setNewName] = useState(title);
  const [edit, setEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [upload, setIsUpload] = useState(false);
  const [success, setIsSuccess] = useState(false);

  useEffect(() => {
    getVideosWithoutState(sectionId).then((r) => setVideoData(r));
  }, [sectionId]);

  const handleEditModuleName = async () => {
    await EditModuleName({
      newName,
      currentModule: sectionId,
      setRecharge,
      setEdit,
    });
  };

  const handleRemoveModule = async () => {
    await RemoveModule({
      currentModule: sectionId,
      setRecharge,
      setIsLoading,
    });
  };

  const HandleEdit = () => {
    setEdit(!edit);
  };

  const handleOpenDelete = () => {
    setIsDelete(!isDelete);
  };

  const handleOpenUpload = () => {
    setIsUpload(!upload);
  };

  return (
    <div className="mb-4 p-4 border rounded-lg shadow-md appear-animation">
      <DeleteModal
        handleDelete={handleRemoveModule}
        isLoading={isLoading}
        isSuccess={false}
        title="Eliminar Modulo"
        onClose={handleOpenDelete}
        open={isDelete}
        message="para confirmar"
      />
      <VideoUploadModal
        isOpen={upload}
        onClose={handleOpenUpload}
        setRecharge={setRecharge}
        idCurso={idCurso}
        currentModule={sectionId}
      />
      <div className="flex justify-between items-center">
        <h3
          className={
            !edit
              ? "text-md font-semibold appear-animation cursor-pointer"
              : "hidden"
          }
          onClick={HandleEdit}
        >
          {title}
        </h3>
        <Button
          icon={<TrashIcon className="h-6 text-white-600 cursor-pointer" />}
          onClick={handleOpenDelete}
          className={
            !edit
              ? "cursor-pointer h-10 bg-slate-400 text-white font-bold rounded-md w-10 hover:bg-red-500 transition-all"
              : "hidden"
          }
        />
        <div className={edit ? "appear-animation flex" : "hidden"}>
          <InputText value={newName} setValue={setNewName} type="text" />
        </div>
        <div className={edit ? "appear-animation flex" : "hidden"}>
          <Button
            text="Guardar"
            onClick={handleEditModuleName}
            className="appear-animation bg-sky-800 hover:bg-yellow-500 px-2 py-1 text-white rounded-lg transition-colors cursor-pointer"
          />
          <BackIcon h="40" w="40" onClick={HandleEdit} />
        </div>
      </div>
      <div className="mt-2">
        <div className={videoData.length !== 0 ? "appear-animation" : "hidden"}>
          {videoData.map((item) => (
            <p>{item.titulo_video}</p>
          ))}
        </div>
        <p className={videoData.length === 0 ? "appear-animation" : "hidden"}>
          No hay video cargado.
        </p>

        <Button
          onClick={handleOpenUpload}
          text="Subir Video"
          className="mt-4 bg-blue-500 text-white py-2 px-4 rounded-lg"
        />
      </div>
    </div>
  );
}
