import { ButtonSave } from "components/common/ButtonSave";
import { ChargingComponent } from "components/common/ChargingComponent";
import { InputText } from "components/common/InputText";
import { SearchSelect } from "components/tailwind/SearchSelect";
import { useEffect, useState } from "react";
import { ActiveButton } from "../users/ActiveButton";

export function StructureModalSubCompany({
  principal,
  modalType,
  isLoading,
  setIsActive,
  active,
  setSubCompany,
  subCompany,
  setMail,
  mail,
  setNit,
  nit,
  countries,
  setIndicative,
  indicative,
  setPhone,
  phone,
  setName,
  name,
  setLastName,
  lastName,
  form,
}) {
  const [conditions, setConditions] = useState(false);

  useEffect(() => {
    const checkConditions = () => {
      if (modalType === "edit") {
        if (
          !subCompany &&
          !mail &&
          !nit &&
          !indicative &&
          !phone &&
          !name &&
          !lastName
        ) {
          setConditions(false);
        } else {
          setConditions(true);
        }
      } else if (modalType === "add") {
        if (subCompany && mail && nit && indicative && phone) {
          setConditions(true);
        } else {
          setConditions(false);
        }
      }
    };

    checkConditions(); // Llamar a la función para validar al inicio
  }, [modalType, subCompany, mail, nit, indicative, phone, name, lastName]); // Agregar dependencias aquí

  return (
    <div>
      <div className={isLoading ? "appear-animation" : "hidden"}>
        <ChargingComponent />
      </div>

      <div className={!isLoading ? "appear-animation" : "hidden"}>
        {modalType === "edit" && !principal && (
          <div className="flex">
            <ActiveButton setIsToggled={setIsActive} isToggled={active} />
            <label className="mt-2 ml-2">
              {`subcompañia ${String(active)
                .replace("true", "activa")
                .replace("false", "inactiva")}`}
            </label>
          </div>
        )}
        <InputText
          setValue={setSubCompany}
          value={subCompany}
          placeholder={"Nombre Subcompañia"}
          required
        />
        {modalType === "edit" && (
          <div className="grid grid-cols-2 gap-2">
            <InputText
              setValue={setName}
              value={name}
              placeholder={"Nombre Responsable"}
              required
            />

            <InputText
              setValue={setLastName}
              value={lastName}
              placeholder={"Apellido Responsable"}
              required
            />
          </div>
        )}
        <div className="grid grid-cols-2 gap-2">
          <InputText
            setValue={setMail}
            value={mail}
            placeholder={"Correo"}
            required
          />
          <InputText
            setValue={setNit}
            value={nit}
            placeholder={"NIT"}
            required
          />
        </div>
        <div className="grid grid-cols-3 gap-2">
          <SearchSelect
            items={countries}
            setOption={setIndicative}
            option={indicative}
            title={"Indicativo"}
          />
          <div className="col-span-2">
            <InputText
              setValue={setPhone}
              value={phone}
              placeholder={"Teléfono"}
              required
            />
          </div>
        </div>

        {conditions ? (
          <ButtonSave HadleSubmit={form} />
        ) : (
          <div className="flex justify-end mt-2">
            <label className="bg-red-200 border-2 border-red-700 text-red-700 p-1 rounded-lg">
              te faltan campos
            </label>
          </div>
        )}
      </div>
    </div>
  );
}
