import { NewIcon } from "components/SvgIcons/NewIcon";
import { Link } from "react-router-dom";

export function NewCourseButton() {
  return (
    <Link
      to="/training/new"
      className="fixed bottom-4 right-4 slide-down-animation"
    >
      <NewIcon h={"50"} w={"50"} />
    </Link>
  );
}
