import { useEffect, useState } from "react";
import { getCourses } from "hooks/commonData";
import { Module } from "components/common/Module";
import { ChargingComponent } from "components/common/ChargingComponent";
import { NewCourseButton } from "../trainingComponetsPMO/NewCourseButton";
import { TarjetCoursesPMO } from "modules/training/trainingComponetsPMO/TarjetCoursesPMO";
import { useRoleContext } from "modules/auth/admin/RoleContex";
import { FiSearch } from "react-icons/fi";

export function TrainingPMO() {
  const { userRole } = useRoleContext();
  const [courses, setCourses] = useState([{}]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 250);

    return () => clearTimeout(timeoutId);
  }, [searchTerm]);

  useEffect(() => {
    setLoading(true);
    getCourses().then((r) => setCourses(r));
    setLoading(false);
  }, []);

  const filteredCourses = courses.filter((course) =>
    course.nombre_curso
      ?.toLowerCase()
      .includes(debouncedSearchTerm.toLowerCase())
  );

  if (userRole !== 7) {
    return <div>No tienes permisos para acceder a este contenido</div>;
  }

  return (
    <Module title="Cursos Actuales" back={false}>
      <NewCourseButton />
      {loading ? (
        <ChargingComponent />
      ) : (
        <>
          <div className="mb-4 relative">
            <input
              type="text"
              placeholder="Buscar cursos..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="p-2 pl-10 pr-4 border rounded-md w-full"
            />
            <FiSearch
              className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500"
              size={20}
            />
          </div>

          <div
            className={`lg:grid lg:grid-cols-4 gap-4 p-4 slide-down-animation`}
          >
            {filteredCourses?.map((item, index) => (
              <TarjetCoursesPMO
                colorId={item.estado?.color_id}
                image={item.nombre_miniatura}
                status={item.estado?.nombre_estado}
                title={item.nombre_curso}
                to={`/training/course/${item.id}`}
                key={index}
                id={item.id}
              />
            ))}
          </div>
        </>
      )}
    </Module>
  );
}
