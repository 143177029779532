import { useState, useEffect } from "react";

import {
  getSiigoIntegrations,
  NewIntegration,
} from "api/bi-module/integration";
import { getUserDetails } from "hooks/commonData";

import { getUserData } from "utils/SupabaseClient";

import { Text } from "components/tailwind/Text";
import { Select } from "components/tailwind/Select";
import { ButtonSave } from "components/common/ButtonSave";

export function FormAPI({
  newRow,
  provider,
  setIsSuccess,
  setIsLoading,
  isActive,
}) {
  const [usuario, setUsuario] = useState("");
  const [contraseña, setContraseña] = useState("");
  const [idSubCompanie, setIdSubCompanies] = useState();
  const [isNewProyect, setIsNewProyect] = useState();
  const [projects, setProjects] = useState([]);
  const [subcompanies, setSubCompanies] = useState([]);
  const [subcompanyName, setCompanyName] = useState();
  const [proyectName, setProyectName] = useState();
  const [isValidUser, setIsValidUser] = useState(false);

  const HadleSubmit = async () => {
    setIsLoading(true);
    const subirData = NewIntegration({
      subcompany_id: idSubCompanie,
      provider: provider,
      user_api: usuario,
      access_key: contraseña,
      proyect_id: isNewProyect,
      subcompany_name: subcompanyName,
      proyect_name: proyectName,
    });
    if (subirData) {
      setIsLoading(false);
      setIsSuccess(true);
      newRow = false;
    } else {
      setIsLoading(false);
      setIsSuccess(false);
    }
  };

  useEffect(() => {
    getSiigoIntegrations().then((project) => {
      setProjects(project);
    });
    getUserData().then((user) => {
      getUserDetails(user.id, "subcompanies").then((project) => {
        setSubCompanies(project.company.projects);
      });
    });
  }, []);

  useEffect(() => {
    const dataproyect = projects.filter((item) => isNewProyect === item.id);
    const datasubcompanies = subcompanies.filter(
      (item) => idSubCompanie === item.id
    );
    if (dataproyect !== null && dataproyect.length > 0) {
      setProyectName(dataproyect[0].project_name);
    }
    if (datasubcompanies !== null && datasubcompanies.length > 0) {
      setCompanyName(datasubcompanies[0].razon_social);
    }
  }, [isNewProyect, idSubCompanie]);

  useEffect(() => {
    const expresionRegular = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setIsValidUser(expresionRegular.test(usuario) ? true : false);
  }, [usuario]);

  return (
    <>
      <section>
        <p className="font-semibold">Información Empresa</p>
        <Select
          title="Seleccionar Sub Empresa"
          value={idSubCompanie}
          options={subcompanies.map((item) => ({
            value: item.id,
            name: item.razon_social,
          }))}
          field={true}
          onChange={setIdSubCompanies}
          required={false}
        />
      </section>
      <section className="pt-4">
        <p className="font-semibold">
          Información del Api {provider.toUpperCase()}
        </p>
        <div className="grid grid-cols-2 gap-4">
          <Text
            title="Correo eléctronico"
            field="user-email"
            onChange={setUsuario}
            value={usuario}
          />
          <Text
            title="Contraseña"
            field="integrations-email"
            onChange={setContraseña}
            value={contraseña}
          />
        </div>
      </section>
      {isValidUser && (
        <>
          <section className="pt-4">
            <p className="font-semibold">Informes</p>
            <Select
              title="Seleccionar informe"
              value={isNewProyect}
              options={projects.map((item) => ({
                value: item.id,
                name: item.project_name,
              }))}
              field={true}
              onChange={setIsNewProyect}
              required={false}
            />
          </section>
          {isActive && <ButtonSave HadleSubmit={HadleSubmit} />}
          {!isActive && (
            <p className="py-1 text-xs text-center text-sky-700 bg-sky-100 mt-2 font-bold">
              Cambia de plan para utilizar esta funcionalidad
            </p>
          )}
        </>
      )}
    </>
  );
}
