import { useState } from "react";

export function FilterComponent({ data, setvalue }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="">
      <button type="button" onClick={() => setIsOpen(!isOpen)}></button>
      <div>
        {isOpen && (
          <div
            className="absolute mt-2 w-60 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            <input></input>

            <div className="py-1 slide-down-animation h-[200px] overflow-x-auto">
              {data.map((item) => (
                <button
                  className="border-[1px] border-b-slate-200"
                  onClick={() => setvalue(item)}
                >
                  {item}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
