import { useEffect, useState } from "react";
import { DropDownModule } from "modules/training/course/modules/DropDownModule";
import { Module } from "components/Module";
import { BackGroundSections } from "components/BackGroundSections";
import { useParams } from "react-router-dom";
import { AutoHeigth } from "components/AutoHeigth";
import { downloadImage, getCoursesStudent, getModule, getCountVideos, getCountVideosViewed, getDataUser, saveCurseFinalized, getSUPPLIES } from "hooks/commonData";
import { ProgressBar } from "components/ProgressBar";
import { StatusDetail } from "components/StatusDetail";
import { getUserData } from "utils/SupabaseClient";
import { VideosCourseComponents } from "components/VideosCourseComponents";
import { Button } from "components/Button";
import { DownloadFile } from "hooks/commonData";
import { DownloadIcon } from "components/SvgIcons/DownloadIcon";
import fileDownload from "js-file-download";
import CertificateGenerator from "components/CertificateGenerator";

export function Course() {
  const { id_course } = useParams();
  const [modules, setModules] = useState([{}]);
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [userId, setUserId] = useState();
  const [stateCourse, setStateCourse] = useState([{}]);
  const [url, setUrl] = useState();
  const [video, setVideo] = useState();
  // const [progressVideo, setProgressVideo] = useState();
  // const [durationVideo, setDurationVideo] = useState();
  const [countVideos, setCoutVideos] = useState();
  const [countVideosView, setCountVideosView] = useState();
  const [dataUser, setDataUser] = useState();
  const [insumos, setInsumos] = useState([]);


  const VideoName = video && video[0]?.titulo_video;


  const downloadFile = async () => {
    const file = await DownloadFile("cursos", insumos);
    fileDownload(file, "Power BI Insumo.zip");
  };

  const course = modules.map((item) => item.info_curso);
  const nameCourse = course[0]?.nombre_curso;
  const miniature = course[0]?.nombre_miniatura;
  const colorId = stateCourse[0]?.estado_curso?.color_id;
  const status = stateCourse[0]?.estado_curso?.nombre_estado;

  useEffect(() => {
    getUserData().then((r) => (setUserId(r?.id)));
    getCoursesStudent(userId).then((r) => setStateCourse(r));
    downloadImage(miniature).then((r) => setAvatarUrl(r));
  }, [miniature, userId]);

  useEffect(() => {
    getModule(id_course).then((r) => setModules(r));
  }, [id_course]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const countViewed = await getCountVideosViewed(userId, id_course);
        setCountVideosView(countViewed);

        const countTotal = await getCountVideos(id_course);
        setCoutVideos(countTotal);
      } catch (error) {
        console.error("Error fetching video counts:", error);
      }
    };

    if (userId) {
      fetchData();
    }

    if(video){
      getSUPPLIES(video[0]?.id).then(r=>(setInsumos(r)))
    }

    if (userId) {
      fetchData();
    }
  }, [userId, video]);

  useEffect(() => {
    if (userId) {
      getDataUser(userId).then((data) => {
        setDataUser(data[0]);
      })
    }
  }, [userId])

  return (
    <Module title={`Curso ${nameCourse}`} linkToBack={"/training"}>
      <BackGroundSections>
        <div>
          <div className="grid grid-cols-3">
            <div className="mb-2 flex col-span-2">
              {
                <StatusDetail colorId={colorId} statusName={countVideosView === countVideos ? "Finalizado" : countVideosView === 0 ? "Activo" : countVideosView >= 1 ? "En Progreso" : status} />
              }

            </div>
            <div>
              <ProgressBar
                videosLength={countVideos}
                videoWatched={countVideosView}
              />
            </div>
          </div>
        </div>
        <div className="lg:grid lg:grid-cols-3">

          <div className="col-span-2">
            {
              url ? <BackGroundSections>
                <VideosCourseComponents src={url} />
                <BackGroundSections>
                  <div className="flex justify-between">
                    <h2 className="text-xl font-semibold ">{VideoName}</h2>
                    <div className={`${insumos.length !== 0 ? '': 'hidden'}`}>
                      <Button
                        text={"Descargar Insumos"}
                        onClick={downloadFile}
                        isIcon={true}
                        icon={<DownloadIcon h={"20"} w={"20"} />}
                      />
                    </div>
                  </div>
                </BackGroundSections>
              </BackGroundSections>
                :
                <img
                  src={avatarUrl}
                  alt=""
                  className="grow rounded-lg slide-down-animation overflow-hidden curs"
                />
            }
          </div>
          <div>
            <AutoHeigth maxPx={"400px"}>
              {modules && modules.map((item, index) => (
                <DropDownModule
                  userId={userId}
                  module={item.nombre_modulo}
                  id_module={item.id}
                  id_course={id_course}
                  setUrl={setUrl}
                  setVideo={setVideo}
                  key={index}
                />
              ))}
            </AutoHeigth>
          </div>
          <div className="p-2">
            {
              countVideosView === countVideos && <CertificateGenerator name={`${dataUser?.first_name} ${dataUser?.last_name}`} course={nameCourse} />
            }
          </div>
        </div>
      </BackGroundSections>
    </Module>
  );
}
