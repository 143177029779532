import { useEffect, useState } from "react";
import { ViewTable } from "../../components/ViewTable";
import { BackGroundSections } from "components/BackGroundSections";
import { Module } from "components/Module";
import { getUserData } from "utils/SupabaseClient";
import { getDocFiles } from "./Consulta";
import { ChargingComponent } from "components/ChargingComponent";
import { Button } from "components/Button";
import { DownloadIcon } from "components/SvgIcons/DownloadIcon";
import fileDownload from "js-file-download";
import { supabase } from "utils/SupabaseClient";
import * as XLSX from "xlsx";
import mammoth from "mammoth";
import { Modal } from "components/Modal";

export function ViewDocFiles() {
  const tittles = [
    "Proyecto",
    "Archivo",
    "Fecha de Carga",
    "Ver archivo",
    "Descargar archivo",
  ];

  const [dataArray, setDataArray] = useState([]);
  const [loading, setLoading] = useState(true);
  const [idUser, setIdUser] = useState("");
  const [selectedProject, setSelectedProject] = useState("Proyecto Lanuza");
  const [filePreviewContent, setFilePreviewContent] = useState("");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    getUserData().then((r) => setIdUser(r.id));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const files = await getDocFiles(selectedProject);
        console.log(files);
        const newArray = files.map((item) => {
          const date = new Date(item.created_at);
          const formattedDate = date.toLocaleDateString("es-CO", {
            day: "2-digit",
            month: "short",
            year: "numeric",
          });

          return {
            project: selectedProject,
            file_name: item.name,
            created_at: formattedDate,
            verArchivo: (
              <Button
                text={"Ver"}
                onClick={() => handlePreview(item.name)}
                isIcon={false}
              />
            ),
            acciones: (
              <Button
                text={"Descargar"}
                onClick={() => handleDownload(item.name)}
                isIcon={true}
                icon={<DownloadIcon h={"20"} w={"20"} />}
              />
            ),
          };
        });
        setDataArray(newArray);
      } catch (error) {
        console.error("Error al traer archivos de la carpeta DOC:", error);
      } finally {
        setLoading(false);
      }
    };

    if (idUser) {
      fetchData();
    }
  }, [idUser, selectedProject]);

  const handleDownload = async (fileName) => {
    try {
      const { data, error } = await supabase.storage
        .from("archivos")
        .download(`${selectedProject}/DOC/${fileName}`);

      if (error) throw error;
      fileDownload(data, fileName);
    } catch (error) {
      console.error("Error al descargar el archivo:", error);
    }
  };

  const handlePreview = async (fileName) => {
    try {
      const { data, error } = await supabase.storage
        .from("archivos")
        .download(`${selectedProject}/DOC/${fileName}`);

      if (error) throw error;
      const fileExt = fileName.split(".").pop().toLowerCase();

      if (fileExt === "xlsx") {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "binary" });
          const sheetNames = workbook.SheetNames;
          const sheet = workbook.Sheets[sheetNames[0]];
          const html = XLSX.utils.sheet_to_html(sheet);
          setFilePreviewContent(html);
          setShowModal(true);
        };
        reader.readAsBinaryString(data);
      } else if (fileExt === "docx") {
        const arrayBuffer = await data.arrayBuffer();
        const result = await mammoth.convertToHtml({ arrayBuffer });
        setFilePreviewContent(result.value);
        setShowModal(true);
      } else {
        setFilePreviewContent("El archivo no es compatible para vista previa.");
        setShowModal(true);
      }
    } catch (error) {
      console.error("Error al obtener el archivo para vista previa:", error);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setFilePreviewContent("");
  };

  return (
    <Module title="Ver Archivos DOC" back={true} linkToBack={"/databases"}>
      <BackGroundSections>
        <div style={{ marginBottom: "20px" }}>
          <label htmlFor="projectSelect" style={{ marginRight: "10px" }}>
            Selecciona un proyecto:
          </label>
          <select
            id="projectSelect"
            onChange={(e) => setSelectedProject(e.target.value)}
            value={selectedProject}
          >
            <option value="Proyecto Lanuza">Proyecto Lanuza</option>
            <option value="Proyecto BI_Vistas">Proyecto BI_Vistas</option>
          </select>
        </div>

        {loading ? (
          <ChargingComponent />
        ) : (
          <ViewTable to="/databases" rows={dataArray} tittles={tittles} />
        )}
      </BackGroundSections>

      {showModal && (
        <Modal onClose={closeModal}>
          <h3>Vista previa del archivo</h3>
          {filePreviewContent && (
            <div dangerouslySetInnerHTML={{ __html: filePreviewContent }} />
          )}
        </Modal>
      )}
    </Module>
  );
}
