import { Tabs } from "components/common/Tabs";
import { ProfileVisual } from "./submodules/perfil/ProfileVisual";
import { useEffect, useState } from "react";
import { getSubmodules, getUserDetails } from "hooks/commonData";
import { SubCompaniesVisual } from "./submodules/subcompanies/SubCompaniesVisual";
import { getPlan, getUserData } from "utils/SupabaseClient";
import { UsersVisual } from "./submodules/users/UsersVisual";
import { CompaniesVisual } from "./submodules/companies";

export function Profile({ nombre, apellido }) {
  const submodule = localStorage.getItem("Submodule");
  const [active, setActive] = useState("Perfil");
  const [items, setItems] = useState(
    submodule === null ? [{ nombre: "cargando..." }] : submodule
  );
  const [companyId, setCompanyId] = useState(null);
  const [company, setCompany] = useState(null);

  useEffect(() => {
    if (!submodule) {
      getSubmodules(4).then((r) => {
        if (getPlan === "Plis BI Gratis") {
          setItems(r.filter((i) => i.id !== 6));
        } else {
          setItems(r);
        }
        localStorage.setItem("submodule", r);
      });
    }
    getUserData().then((user) => {
      getUserDetails(user.id, "basic").then((e) => {
        setCompanyId(e.company_id);
      });
    });
  }, [submodule]);

  return (
    <>
      <Tabs items={items} setActive={setActive} active={active} />
      <ProfileVisual submodule={active} />
      <UsersVisual
        submodule={active}
        companyId={companyId}
        setCompany={setCompany}
        company={company}
      />
      <SubCompaniesVisual
        companyId={companyId}
        submodule={active}
        nombre={nombre}
        apellido={apellido}
        company={company}
      />
      <CompaniesVisual submodule={active} companyId={companyId} />
    </>
  );
}
