export const getColor = (state) => {
  switch (state) {
    case 1:
      return "bg-gray-400";
    case 2:
      return "bg-blue-500";
    case 3:
      return "bg-emerald-500";
  }
};

export const getIconColor = (type) => {
  switch (type) {
    case 1:
      return "bg-blue-500";
    case 2:
      return "bg-emerald-500";
    case 3:
      return "bg-purple-500";
    case 4:
      return "bg-yellow-500";
    case 5:
      return "bg-red-500";
  }
};

export const types = {
  1: <i className="lni lni-envelope"></i>,
  2: <i className="lni lni-add-files"></i>,
  3: <i className="lni lni-reload"></i>,
  4: <i className="lni lni-keyword-research"></i>,
  5: <i className="lni lni-bug"></i>,
};
