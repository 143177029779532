import { useEffect, useState } from "react";
import { Button } from "components/common/Button";
import { ModalBG } from "components/common/ModalBG";
import { ChargingComponent } from "components/common/ChargingComponent";
import { ModalSuccess } from "components/modal/ModalSuccess";
import { FileUploadComponent } from "components/tailwind/FileUploadComponent";
import { VideoUpload } from "api/training-module/VideoUpload";

export function VideoUploadModal({
  isOpen,
  onClose,
  idCurso,
  currentModule,
  setRecharge,
}) {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [file, setFile] = useState(null);

  const handleVideoUpload = async () => {
    setIsLoading(true); // Comienza la carga
    await VideoUpload({
      files: file,
      id_curso: idCurso,
      currentModule,
      setRecharge,
      setIsLoading,
      setUploadProgress,
      setIsSuccess,
    });
  };

  useEffect(() => {
    if (!isOpen) {
      setIsSuccess(false);
      setUploadProgress(0); // Resetea el progreso cuando el modal se cierra
    }
  }, [isOpen]);

  return (
    <ModalBG open={isOpen} onClose={onClose} title="Subir Video">
      <div className={!isLoading ? "appear-animation" : "hidden"}>
        {!isSuccess ? (
          <div className="flex flex-col items-center">
            <FileUploadComponent
              accept=".mp4"
              setLength={() => {}}
              redText="Solo están permitidos archivos .mp4"
              setSelectedFilesProps={setFile}
              text="Selecciona un video para subir"
              recharge={isSuccess}
            />
            <div className="mt-4 flex space-x-4">
              <Button onClick={onClose} text="Cancelar" />
              <Button onClick={handleVideoUpload} text="Subir" />
            </div>
          </div>
        ) : (
          <ModalSuccess
            title="¡Éxito!"
            message="El video se ha subido correctamente."
            onClose={onClose}
          />
        )}
      </div>
      {isLoading && (
        <>
          <ChargingComponent />
          {/* <div className="relative pt-1 mb-4">
            <div className="flex mb-2 items-center justify-between">
              <div>
                <span className="text-xs font-semibold inline-block py-1 uppercase">
                  Subiendo video... {uploadProgress}%
                </span>
              </div>
            </div>
            <div className="flex mb-2 items-center justify-between">
              <div className="w-full bg-gray-200 rounded-full">
                <div
                  className="bg-blue-600 text-xs leading-none py-1 text-center text-white"
                  style={{ width: `${uploadProgress}%` }}
                ></div>
              </div>
            </div>
          </div> */}
        </>
      )}{" "}
      {/* Componente de carga */}
      {/* {uploadProgress > 0 && uploadProgress < 100 && (
      )} */}
    </ModalBG>
  );
}
