export function StatusDetail({ colorId, statusName }) {
  let color = "";

  if (colorId === 1) {
    color = "bg-green-200 border-green-400 text-green-400";
  } else if (colorId === 2) {
    color = "bg-red-200 border-red-400 text-red-400";
  } else if (colorId === 3) {
    color = "bg-blue-200 border-blue-400 text-blue-400";
  } else if (colorId === 4) {
    color = "bg-slate-200 border-slate-400 text-slate-400";
  }

  return (
    <div className="flex justify-end">
      <label className={`${color} border-[2px] rounded-md px-[5px]`}>
        {statusName}
      </label>
    </div>
  );
}
