import { toast } from "sonner";
import { supabase } from "utils/SupabaseClient";

export async function UpdateUser({
  user,
  active,
  name,
  lastName,
  phone,
  email,
  subCompany,
  documento,
  typeDocument,
  password,
  setIsLoading,
  setSuccess,
  setError,
  setIsOpenEdit,
  setRecharge,
}) {
  setIsLoading(true);

  if (password !== "") {
    const { error: ErrPassword } = await supabase.auth.updateUser({
      password: password,
    });

    if (ErrPassword) {
      toast.error("¡no se ha podido actulizar la clave!");
      setError(true);
      return;
    }
  }
  console.log(active);
  const itemsUser = {
    activo: active,
    first_name: name,
    last_name: lastName,
    phone: phone,
    email: email,
    documento: documento,
    tipo_documento: typeDocument,
  };

  const { data: User, error: ErrUser } = await supabase
    .from("auth_users")
    .update(itemsUser)
    .eq("id", user);

  const { data: DataDelete, error: ErrDelete } = await supabase
    .from("th_user_subcompanies")
    .delete()
    .eq("id_user", user);

  const { data: DataInsert, error: ErrInsert } = await supabase
    .from("th_user_subcompanies")
    .insert(
      subCompany.map((item) => ({
        id_user: user,
        id_subcompanies: item,
      }))
    )
    .select();

  if (ErrUser || ErrDelete || ErrInsert) {
    toast.error("¡error al actualizar el usuario!");
    setError(true);
    setIsLoading(false);
  } else {
    toast.success("¡Usuario actualizado correctamente!");
    setSuccess(true);
    setIsLoading(false);
    setIsOpenEdit(false);
  }

  return setRecharge(true);
}
