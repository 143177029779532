import { handleDownload } from "api/tickets/route";

export function Comments({ comments, formatDate, handleUpdateComment }) {
    return (
        <>
            {comments.map((comment, index) => (
                <div key={index} className="mt-4 w-full">
                    <div className="flex items-start justify-between">
                        <div className="flex">
                            <img
                                alt="PlisBI Icon"
                                src="../../assets/img/icons/principal.png"
                                className="h-8 w-8 rounded-full"
                            />
                            <div className="pl-4">
                                <p className="text-sm mt-1">
                                    <strong>{comment.user} </strong> {comment.updated_at !== comment.created_at ? formatDate(comment.updated_at) : formatDate(comment.created_at)}
                                    <span className="italic ml-2 text-gray-500">{comment.updated_at !== comment.created_at ? "(Editado)" : ""}</span>
                                </p>
                                <p className="mt-2 text-sm">{comment.comment}</p>
                                {
                                    comment.urlFiles.map((file, i) => {
                                        const name = file.file_name.split('/');
                                        return (
                                            <button key={i} className="text-blue-500 text-xs"
                                                onClick={() => handleDownload(file.file_name)}
                                            >
                                                {name[name.length - 1]}
                                            </button>
                                        );
                                    })

                                }
                            </div>
                        </div>
                        <div className="flex items-center cursor-pointer"
                            onClick={() => { handleUpdateComment(comment.comment, comment.id) }}
                        >
                            <a href="#comment"><i className="lni lni-pencil-alt"></i></a>
                        </div>
                    </div>
                    <hr className="my-4" />
                </div>
            ))
            }
        </>
    )
}