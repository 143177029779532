import { createCustumerTicket } from "api/tickets/route";
import { getUserDetails } from "hooks/commonData";
import { toast } from "sonner";
import { getUserData, supabase } from "utils/SupabaseClient";

export async function Question({
  settings,
  tecnology,
  description,
  nameConsult,
  setIsLoading,
  setError,
  setSuccess,
}) {
  setIsLoading(true);

  const descriptionComplete = `${description}
  
  Herramientas:
  ${settings.length !== 0 ? settings : "ninguna"}
  
  Tecnologias:
  ${tecnology.length !== 0 ? tecnology : "ninguna"}
      `;

  const user = await getUserData();
  const subcompany = await getUserDetails(user.id, "subcompanies");

  const subCompanyName = subcompany?.company?.projects.find((e) =>
    e?.razon_social.includes("Principal")
  );

  const dataTicket = await createCustumerTicket({
    ticketTypeId: 1,
    serviceId: 47,
    projectId: "04a4d30d-d133-4281-98db-33fb5a401adc",
    description: descriptionComplete,
    summary: nameConsult,
    subcompanyId: subCompanyName?.id,
  });

  const items = {
    herramientas: String(settings)
      .replace("[", "")
      .replace("]", "")
      .replace('"', ""),
    tecnologias: String(tecnology)
      .replace("[", "")
      .replace("]", "")
      .replace('"', ""),
    nombre_consulta: nameConsult,
    descipcion_general: description,
    seccion: "Tengo una Pregunta",
  };

  const { data, error } = await supabase.from("th_comercial").insert(items);

  if (!dataTicket || error) {
    toast.error("¡No fue posible enviar tu solicitud!");

    setError(true);
  } else {
    setSuccess(true);
  }
}
