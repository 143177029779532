import { Develope } from "api/budget/Develope";
import { Button } from "components/common/Button";
import { GradiendBanner } from "components/common/GradiendBanner";
import { InputText } from "components/common/InputText";
import { ModalBG } from "components/common/ModalBG";
import { TextInformation } from "components/common/TextInformation";
import { SearchSelect } from "components/tailwind/SearchSelect";
import { TextArea } from "components/tailwind/TextArea";
import { Country, State, City } from "country-state-city";

import { useEffect, useState } from "react";
import { StepsComponent } from "./StepsComponent";
import { ChargingComponent } from "components/common/ChargingComponent";

export function ModalAditionalInfo({
  aditionalInfo,
  setAditionalInfo,
  settings,
  tecnology,
  description,
  nameConsult,
  setIsLoading,
  setError,
  setSuccess,
  isLoading,
}) {
  const [title, setTitle] = useState("");

  const [countryData, setCountryData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);

  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [country, setCountry] = useState(null);
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [location, setLocation] = useState("");
  const [mail, setMail] = useState("");
  const [phone, setPhone] = useState("");
  const [aditionalDescription, setAditionalDescription] = useState("");

  const [cont, setcont] = useState(0);

  const nextSections = () => {
    setcont(cont + 1);
  };

  const BackSections = () => {
    setcont(cont - 1);
  };

  useEffect(() => {
    const countries = Country.getAllCountries().map((c) => ({
      label: c.name,
      value: c.isoCode,
    }));

    setCountryData(countries);
  }, []);

  useEffect(() => {
    if (country) {
      const states = State.getStatesOfCountry(country).map((s) => ({
        label: s?.name,
        value: s?.isoCode,
      }));

      setStateData(states);
      setCityData([]);
      setState(null);
    }
  }, [country]);

  useEffect(() => {
    if (state) {
      const cities = City.getCitiesOfState(country, setState).map((c) => ({
        label: c.name,
        value: c.id,
      }));

      setCityData(cities);
    }
  }, [setState, country]);

  const Insert = async () => {
    await Develope({
      settings,
      tecnology,
      description,
      nameConsult,
      name,
      company,
      country,
      state,
      city,
      location,
      mail,
      phone,
      aditionalDescription,
      setIsLoading,
      setError,
      setSuccess,
      setAditionalInfo,
    });
  };

  const steps = [
    { label: "", value: 1 },
    { label: "Propuesta Dirigida a", value: 2 },
    { label: "Locación de la empresa", value: 3 },
    { label: "Vía de Contacto", value: 4 },
  ];

  useEffect(() => {
    const title = steps.filter((o, i) => i === cont);
    setTitle(title[0]?.label);
  }, [cont]);

  return (
    <ModalBG
      open={aditionalInfo}
      onClose={() => setAditionalInfo(false)}
      title="Información adicional"
    >
      {isLoading ? (
        <ChargingComponent />
      ) : (
        <>
          <div className="mx-3 my-2 md:mx-10 md:mb-6">
            <StepsComponent countSteps={steps} currentStep={cont} />
          </div>

          <div className={cont === 0 ? "appear-animation" : "hidden"}>
            <GradiendBanner>
              <h1 className="text-white text-md text-center p-6">
                ¡Nos encanta tu proyecto! Para iniciar la construcción de tu
                propuesta nos faltaría solo la siguiente información.
              </h1>
            </GradiendBanner>
          </div>

          <div className="block md:hidden">
            <TextInformation text={`${title}:`} />
          </div>

          <div className={cont === 1 ? "appear-animation" : "hidden"}>
            <div className="md:grid md:grid-cols-2 md:gap-2">
              <InputText
                placeholder="Nombre responsable"
                setValue={setName}
                value={name}
                required
              />
              <InputText
                placeholder="De la empresa"
                setValue={setCompany}
                value={company}
                required
              />
            </div>
          </div>
          <div className={cont === 2 ? "appear-animation" : "hidden"}>
            <div className="md:grid md:grid-cols-2 md:gap-2">
              <InputText
                placeholder="Ubicada en la dirección"
                setValue={setLocation}
                value={location}
                required
              />
              <SearchSelect
                title="País"
                items={countryData}
                setOption={setCountry}
                option={country}
                required
              />
              <SearchSelect
                title="Estado/provincia/departamento"
                items={stateData}
                setOption={setState}
                option={state}
              />
              <SearchSelect
                title="Ciudad (opcional)"
                items={cityData}
                setOption={setCity}
                option={city}
              />
            </div>
          </div>

          <div className={cont === 3 ? "appear-animation" : "hidden"}>
            <InputText
              placeholder="Agendar reunión o enviar propuesta al correo"
              setValue={setMail}
              value={mail}
              required
            />
            <InputText
              placeholder="Para solucionar dudas escribir al número celular"
              setValue={setPhone}
              value={phone}
              required
            />

            <TextArea
              title="Observación adicional para tener en cuenta"
              value={aditionalDescription}
              onChange={setAditionalDescription}
            />
          </div>
          <div
            className={`mt-2 flex ${
              cont === 0 ? "justify-end" : "justify-between"
            }`}
          >
            {cont === 1 || cont === 2 || cont === 3 ? (
              <Button onClick={BackSections} text={"atras"} />
            ) : (
              <></>
            )}
            {cont === 0 || cont === 1 || cont === 2 ? (
              <Button onClick={nextSections} text={"siguiente"} />
            ) : (
              <>
                {name && company && location && country && mail && phone ? (
                  <Button onClick={Insert} text={"enviar"} />
                ) : (
                  <div>
                    <span className="mt-1 rounded-lg w-full border-2 border-red-700 bg-red-200 text-red-700 p-2 appear-animation">
                      ¡Faltan campos!
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
        </>
      )}
    </ModalBG>
  );
}
