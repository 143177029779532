
import { useNavigate, useParams } from "react-router-dom";
import { Module } from "../../../components/Module";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useEffect, useState } from "react";
import { ModuleItem } from "./ModuleItem";
import { Text } from "components/ServiceDesk/Text";
import { Select } from "components/ServiceDesk/Select";
import { Files } from "components/ServiceDesk/Files";
import { InputNumber } from "components/InputNumber";
import { Button } from "components/Button";
import { toast } from "sonner";
import { getCourse, saveCourse } from "hooks/commonData";
import { getUserData } from "utils/SupabaseClient";

export function NewCourse() {
  const [userId, setUserId] = useState();
  const [nameNewCourse, setNameNewCourse] = useState();
  const [descriptionNewCourse, setDescriptionNewCourse] = useState();
  const [isPaid, setIsPaid] = useState();
  const [files, setFiles] = useState([]);
  const [price, setPrice] = useState();
  const [course, setCourse] = useState({});

  const navigate = useNavigate();
  const param = useParams();

  let optionsIsPad = [{ "value": "TRUE", "name": "SI" }, { "value": "FALSE", "name": "NO" }]

  const handleCancel = () => {
    navigate("/training");
  }

  const handleSaveCourse = async () => {
    if (!nameNewCourse) {
      return toast.error("El nombre es obligatorio");
    }

    if (!descriptionNewCourse) {
      return toast.error("La descripción es obligatoria");
    }

    if (isPaid === "-1") {
      return toast.error("Escoja si el curso será o no pago");
    }

    if (isPaid === "true") {
      if (!price) {
        return toast.error("El precio es obligatorio");
      }
    }
    let course = {
      "nombre": nameNewCourse,
      "description": descriptionNewCourse,
      "isPaid": isPaid,
      "id_user": userId,
      "id_user": userId,
      "image": files[0]?.name,
      "price": price,
      "state": 5
    }

    try {
      await saveCourse(course, "cursos", "miniaturas", files)
      toast.success("Curso creado con exito");
      navigate("/training");
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    getUserData().then((data) => {
      setUserId(data.id);
    });
  }, []);

  useEffect(() => {
    if (param.id_course) {
      getCourse(param.id_course).then((dato) => {
        setCourse(dato[0]);
      })
    }
  }, [])

  return (
    <Module
      title={"Creación de un Nuevo Curso"}
      back={true}
      linkToBack={"/training"}
    >
      <div className="ml-1 grow mt-2">
        <div class="">
          <div class=" bg-white p-2 rounded-lg mx-auto col-span-2 2xl:w-8/12 xl:w-7/12">
            <div>
              <Text title={"Nombre"} onChange={setNameNewCourse} value={course && course.nombre_curso} required={true} />
              <Text title={"Descripción"} onChange={setDescriptionNewCourse} value={course && course.descripcion} required={true} />
              <div className="flex gap-5 w-full">
                <Select title={"Es de pago"} value={isPaid} options={optionsIsPad} field={"TRUE"} onChange={setIsPaid} required={true} />
                <InputNumber title={"Precio"} field={"precio"} onChange={setPrice} value={course && course.valor_curso  } required={isPaid == "true" && true} />
              </div>
              <Files title={"Imagen"} message={"PNG, JPG up to 50MB"} files={files} setFiles={setFiles} disabled={files.length > 0 && true} />
            </div>

            <Button text={"Guardar"} onClick={handleSaveCourse} />
            {
              param.id_course && <span className="ml-2"><Button text={"Cancelar"} onClick={handleCancel} /></span>
            }
            {/* <DndContext
              colisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={defaultList}
                strategy={verticalListSortingStrategy}
              >
                {defaultList.map((item) => (
                  <ModuleItem Module={item} />
                ))}

              </SortableContext>
            </DndContext> */}
          </div>
          {/* <div class="ml-2 col-span-2 bg-white p-2 rounded-lg">03</div> */}
        </div>
      </div>
    </Module>
  );
}
