import { useEffect, useState } from "react";
import { getVideos, getVideosWatched } from "hooks/commonData";
import { DropDownIcon } from "components/SvgIcons/DropDownIcon";
import { Link } from "react-router-dom";
import { getUserData } from "utils/SupabaseClient";

export function DropDownModuleInVideo({
  module,
  id_module,
  id_video,
  setMainVideo,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [videos, setVideos] = useState([{}]);
  const [videosVistos, setVideosVistos] = useState([{}]);

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    getVideos(id_module, "").then((r) => setVideos(r));
    getUserData().then((r) =>
      getVideosWatched(r.id, id_video).then((r) => setVideosVistos(r))
    );
  }, [id_module, id_video]);

  const handleOnCLick = (e) => {
    setMainVideo(e);
  };

  return (
    <div className="col-span-2">
      <div className="mr-2">
        <button
          className={`w-full bg-white p-2 rounded-lg slide-down-animation h-[40px] flex justify-between ml-2 hover:bg-yellow-100 transition-colors  ${
            isOpen ? "bg-yellow-100" : ""
          }`}
          onClick={handleOpen}
        >
          <label className="text-start transition duration-300 ease-in-out">
            {module}
          </label>
          <div className={`flex pt-[1px] mr-2 justify-end`}>
            <DropDownIcon w={"20"} h={"20"} isopen={isOpen} />
          </div>
        </button>
      </div>
      <div
        className={`m-2 border-t border-slate-400 ${!isOpen ? "hidden" : ""}`}
      >
        {videos.map((item) => (
          <div
            className={`${
              Number(id_video) === Number(item.id) ? "bg-blue-100" : "bg-white"
            } p-2 border-b border-slate-400 hover:bg-blue-100 hover:bg-gradient-to-r transition-colors rounded-lg`}
          >
            <Link
              className="slide-down-animation"
              to={`/training/course/${item.info_curso?.curso_id?.id}/video/${item.id}`}
              key={item.id}
              onClick={() => handleOnCLick(item.id)}
            >
              <h1>{item.titulo_video}</h1>
              <span
                className={`h-[10px] w-[10px] mt-2 rounded-full ${
                  videosVistos[0]?.id_estado === 7
                    ? "bg-green-400"
                    : "bg-slate-300"
                }`}
              ></span>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}
